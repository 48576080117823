import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrCreateSubUserTemplate, DrDataTable, DrInput, DrSelectBox } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Field, Formik } from "formik";
import $ from 'jquery';
import { generatePassword } from '../../utils/utils';
import { createSubUser, getSubUsers, resetSubSellerPassword, updateSubUser } from '../../state-management/actions/customer.actions';
import { setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { render as EmailRender } from '@react-email/render';
import i18next from 'i18next';
import { sendMail } from '../../state-management/actions/utilities.actions';
import Restricted from '../../security/Restricted';
import { modals } from '../../utils/constants';
import noImage from '../../assets/img/users/profile1.png'

function SubSellersPage() {
  const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN } = process.env;
  const { t } = useTranslation('subSellersPage');
  const customerInfo = useSelector(state => state.customer.customerInfo);
  const subUsers = useSelector(state => state.customer.subUsers);
  const roles = useSelector(state => state.customer.roles);
  const selectedStore = useSelector(state => state.stores.selectedStore);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (roles) {
      setRoleOptions(roles.map(x => ({ value: x.id, label: x.roleName })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedStore) {
      dispatch(getSubUsers({ customerMainSellerId: selectedStore?.customerMainSellerId }));
      if (roles) {
        setRoleOptions(roles.map(x => ({ value: x.id, label: x.roleName })));
      }
    }
    setSelectedUser(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore]);

  const unCheckAllSelections = () => {
    $(document.querySelectorAll('#subUsersTable tr')).removeClass("selected");
    $(document.querySelectorAll('input[type="checkbox"]:checked')).prop("checked", false);
  }

  const columns = [
    { data: "customerSubUserId", title: "", width: "5%", minWidth: "70px", searchable: false, checkall: true, checkData: "customerSubUserId" },
    { data: "firstName", title: t("firstName"), minWidth: "90px", searchable: false },
    { data: "lastName", title: t("lastName"), minWidth: "90px", searchable: false },
    { data: "email", title: t("email"), width: "20%", minWidth: "150px", searchable: false },
    { data: "roleList", title: t("role"), width: "20%", minWidth: "90px", searchable: false },
    { data: null, title: "", width: "20%", minWidth: "90px", maxWidth: "90px", searchable: false },
    { data: null, title: "", width: "20%", minWidth: "50px", maxWidth: "50px", searchable: false }
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <div>
              <input type="checkbox" className="ckbox ms-3" name="name" value={rowData.customerSubUserId}
                onChange={(e) => {
                  e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                }}
              />
              <div className="card-aside-img cursor-pointer" style={{ marginLeft: '15px', textAlign: 'start' }} >
                <div className="img-sm p-0 m-0 rounded-0">
                  <img src={rowData.profilePicture || noImage} alt="img" data-tag="pic-modal-opener"
                    onClick={(e) => {
                      e.preventDefault();
                      if (rowData.profilePicture) {
                        dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [rowData.profilePicture] || [noImage] }))
                      }

                    }}
                  ></img>
                </div>
              </div>
            </div>
          </>);
      }
    },
    {
      targets: 1,
      orderable: true,
      className: "text-center",
      width: "20%",
      searchable: true,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <span className="fs-12 my-auto mx-0 p-0">{rowData.firstName}</span>
        );
      }
    },
    {
      targets: 2,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <span className="fs-12 my-auto mx-0 p-0">{rowData.lastName}</span>
        );
      }
    },
    {
      targets: 3,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="fs-12 my-auto mx-0 p-0 text-break">{rowData.email}</span><br />
          </>);
      }
    },
    {
      targets: 4,
      orderable: true,
      searchable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            {rowData.roleList && rowData.roleList.length > 0 && rowData.roleList.map((item) => (
              <span className="badge bg-info tx-11 m-1">{item.roleName}</span>
            ))
            }
          </>
        );
      }
    },
    {
      targets: 5,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div>
            <button className="btn btn-teal-ghost btn-wave waves-effect waves-light shadow btn-sm" value={rowData.customerSubUserId}
              onClick={(e) => {
                e.preventDefault();
                handleResetPassword(rowData);
              }}
            >
              Reset Password
            </button>
          </div>
        )
      }
    },
    {
      targets: 6,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div>
            <button className="btn btn-icon btn-danger btn-wave waves-effect waves-light shadow" value={rowData.customerSubUserId}
              onClick={(e) => {
                e.preventDefault();
                handleUpdateSubUser(rowData, true);
              }}
            >
              <i className="fe fe-trash-2" ></i>
            </button>
          </div>
        )
      }
    }
  ];

  const initialValues = {
    parentCustomerSubUserId: customerInfo?.customerId || "",
    customerMainSellerId: selectedStore?.customerMainSellerId || "",
    customerSubUserId: selectedUser?.customerSubUserId,
    firstName: selectedUser?.firstName || "",
    lastName: selectedUser?.lastName || "",
    email: selectedUser?.email || "",
    roleIds: selectedUser?.roleList ? roleOptions.filter(function (o) {
      return selectedUser?.roleList.find(r => r.id === o.value);
    }) : []
  };

  const subUserInfoSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name is required'),
    lastName: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    //roleIds: Yup.string().required('Role is required'),

    customerSubUserId: Yup.string().nullable(true),

    // temporaryPassword: Yup.string().when('customerSubUserId', {
    //   is: (value) => value,
    //   then: () => Yup.string().nullable(),
    //   otherwise: () => Yup.string().required('Password is required')
    //     .matches(/^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/i, 'Your password should be a minimum of 8 characters. Should have at least one letter and one number.'),
    // })

  });

  const rowClickedCallBack = async (rowData) => {
    setSelectedUser({ ...rowData, temporaryPassword: "" });
    // dispatch(saveCustomerInfo(customerSubUserId));
  }

  const handleResetPassword = async (values) => {

    var temporaryPassword = generatePassword(10);
    let body = <DrCreateSubUserTemplate temporaryPassword={temporaryPassword} siteUrl={window.location.origin} firstName={values.firstName} mainUserFirstName={customerInfo.firstName ? customerInfo.firstName : "SellerSmart"} ></DrCreateSubUserTemplate>;

    var emailData = {
      emailTo: values.email,
      emailToName: values.firstName + ' ' + values.lastName,
      emailSubject: "Welcome! to SellerSmart",
      emailBody: EmailRender(body)
    }

    var customerData = {
      customerSubUserId: values.customerSubUserId,
      newPassword: temporaryPassword
    };

    dispatch(resetSubSellerPassword({ emailData: emailData, customerData: customerData }));
  }

  const handleUpdateSubUser = async (values, isDeleted) => {
    var selectedRoles = (values.roleIds && values.roleIds?.map(a => a.value)) || [];
    var data = {
      parentCustomerSubUserId: values.parentCustomerSubUserId,
      customerSubUserId: values.customerSubUserId,
      customerMainSellerId: values.customerMainSellerId,
      roleIds: [],
      isDeleted: isDeleted || false
    }

    data.roleIds.push(...selectedRoles);

    await Promise.all([
      dispatch(withActionPromise(updateSubUser(data)))
    ]).then((e) => {
      if (isDeleted) {
        setSelectedUser(null);
      }
    });
  }

  const handleCreateSubUser = async (values) => {

    var temporaryPassword = generatePassword(10);
    let body = <DrCreateSubUserTemplate temporaryPassword={temporaryPassword} siteUrl={window.location.origin} firstName={values.firstName} mainUserFirstName={customerInfo.firstName ? customerInfo.firstName : "SellerSmart"} ></DrCreateSubUserTemplate>;

    var emailData = {
      emailTo: values.email,
      emailToName: values.firstName + ' ' + values.lastName,
      emailSubject: "Welcome! to SellerDoping",
      emailBody: EmailRender(body)
    }

    var selectedRoles = values.roleIds.map(a => a.value);
    var data = {
      parentCustomerSubUserId: values.parentCustomerSubUserId,
      customerMainSellerId: values.customerMainSellerId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roleIds: [],
      password: temporaryPassword,
      emailData: emailData
    }

    data.roleIds.push(...selectedRoles);

    dispatch(withActionPromise(createSubUser(data)));
  }

  const handleSubmit = async (values) => {
    if (values.customerSubUserId) {
      handleUpdateSubUser(values);
    }
    else {
      handleCreateSubUser(values);
    }
  }

  const testEmail = async (values) => {
    var temporaryPassword = generatePassword(10);
    let body = <DrCreateSubUserTemplate temporaryPassword={temporaryPassword} siteUrl={window.location.origin} firstName={values.firstName || "TEST"} mainUserFirstName="SellerDoping" ></DrCreateSubUserTemplate>;

    var emailData = {
      emailTo: values.email,
      emailToName: values.firstName + ' ' + values.lastName,
      emailSubject: "Welcome! to SellerDoping",
      emailBody: EmailRender(body)
    }

    dispatch(sendMail(emailData));
  }


  return (
    <>
      <div>
        <DrBreadCrump
          breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.subSeller'), navigationPath: "" }]} >
        </DrBreadCrump>
        <div className="row row-md row-lg d-flex justify-content-center">
          <div className="col-md-8 col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validateOnBlur={true}
                  onSubmit={handleSubmit}
                  validationSchema={subUserInfoSchema}
                  enableReinitialize={true}
                  validateOnMount={true}
                >
                  {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    setTouched,
                    setFieldValue

                  }) => (
                    <form>
                      <div className={`row row-md row-lg my-1 px-3 justify-content-end`} >
                        <div className="col-auto my-auto">
                          <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                            <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                              onClick={(e) => {
                                e.preventDefault();
                                unCheckAllSelections();
                                setSelectedUser(null);
                              }}>
                              <i className="w-6 me-2 fa fa-plus"></i>
                              {t('addNewSeller')}
                            </button>
                          </Restricted>
                        </div>
                      </div>
                      <div className="row row-md row-lg px-3">
                        <div className="col-md-6 col-lg-6">
                          <div className="form-group has-validation">
                            <label>{t('firstName')}</label>
                            <DrInput value={values.firstName} className={`form-control`} placeholder={t('firstName')} name="firstName" type="text"
                              onChange={handleChange}
                              onBlur={() => { setTouched({ ...touched, 'firstName': true, 'customerSubUserId': true }) }}
                              isInvalid={(touched.firstName && errors.firstName)}
                              isValid={touched.firstName && !errors.firstName}
                              validationText={errors.firstName}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <div className="form-group has-validation">
                            <label>{t('lastName')}</label>
                            <DrInput value={values.lastName} className={`form-control`} placeholder={t('lastName')} name="lastName" type="text"
                              onChange={handleChange}
                              onBlur={() => { setTouched({ ...touched, 'lastName': true }) }}
                              isInvalid={(touched.lastName && errors.lastName)}
                              isValid={touched.lastName && !errors.lastName}
                              validationText={errors.lastName}
                            />
                          </div>
                        </div>

                      </div>
                      <div className="row row-md row-lg px-3">
                        <div className="col-mg-6 col-lg-6 mt-3">
                          <div className="form-group has-validation">
                            <label>{t('email')}</label>
                            <DrInput value={values.email}
                              className={`form-control ${values.customerSubUserId && values.customerSubUserId !== "" ? "disabled text-muted" : ""}`}
                              disabled={values.customerSubUserId && values.customerSubUserId !== "" ? true : false}
                              placeholder={t('email')} name="email" type="text"
                              onChange={handleChange}
                              onBlur={() => { setTouched({ ...touched, 'email': true }) }}
                              isInvalid={(touched.email && errors.email)}
                              isValid={touched.email && !errors.email}
                              validationText={errors.email}
                            />
                          </div>
                        </div>
                        <div className="col-mg-6 col-lg-6 mt-3">
                          <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                            <div className="form-group has-validation">
                              <label>{t('roleOptions')}</label>
                              <Field
                                onBlur={() => { setTouched({ ...touched.roleIds, 'roleIds': true }) }}
                                onChange={(e) => {
                                  if (e) {
                                    var result = e.map(a => a.id);
                                    setFieldValue("roleIds", result);
                                  }
                                }}
                                isMulti={true}
                                name={'roleIds'}
                                component={DrSelectBox}
                                placeholder="Role Selection"
                                options={roleOptions} />
                            </div>
                          </Restricted>
                        </div>
                      </div>
                      <div className="row row-md row-lg my-1 px-3 justify-content-between">
                        <div className="col-auto my-auto">
                          <Restricted allowedTo={[REACT_APP_ROLES_SUPERADMIN]}>
                            <button className="btn btn-purple btn-wave waves-effect waves-light shadow"
                              onClick={(e) => {
                                e.preventDefault();
                                testEmail(values);
                              }}>
                              <i className="me-2 fe fe-check"></i>
                              Test Mail
                            </button>
                          </Restricted>
                        </div>
                        <div className="col-auto my-auto">
                          <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                              onClick={(e) => {
                                e.preventDefault();
                                if (isValid) {
                                  handleSubmit(values);
                                }
                              }}
                            >{t('saveChanges')}</button>
                          </Restricted>
                        </div>
                      </div>
                    </form>
                  )
                  }
                </Formik>
                <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                  <div id="subUsersTable" className="row mb-5">
                    <div className="col-lg-12 col-xl-12 col-md-12">
                      <div className="card custom-card">
                        <div className="card-body horizontal-scrollable">
                          {subUsers && <>
                            <DrDataTable
                              data={subUsers}
                              columns={columns}
                              columnDefs={columnDefs}
                              doesHaveBorder={false}
                              headerSearch={false}
                              search={false}
                              minWidth="850px"
                              isRowClickEnabled={true}
                              rowClickedCallBack={rowClickedCallBack}
                            />
                          </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Restricted>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default SubSellersPage;