
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable } from '../../components/component-index';
import { problematicProductMockData } from '../../utils/mocks-data';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function ProblematicProductReportPage() {
    const { t } = useTranslation('problematicProductReportPage');
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableData(problematicProductMockData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { data: "sku", title: t('dataTable.sku'), width: "15%", minWidth: "120px", searchable: true },
        { data: "asin", title: t('dataTable.asin'), width: "5%", minWidth: "10px", searchable: true },
        { data: "fieldname", title: t('dataTable.fieldName'), width: "5%", minWidth: "70px", searchable: true },
        { data: "alerttype", title: t('dataTable.alertType'), width: "5%", minWidth: "55px", searchable: true },
        { data: "explanation", title: t('dataTable.explanation'), width: "5%", minWidth: "20px", searchable: true },
        { data: "updateDate", title: t('dataTable.updateDate'), width: "20%", minWidth: "80px", searchable: true },
        { data: null, title: "", width: "3%", searchable: false }
    ];

    const columnDefs = [

        {
            targets: 0,
            orderable: true,
            className: "text-center",
            width: "20%",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.sku}</span><br />
                    </>)
            }
        },
        {
            targets: 1,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.asin}</span><br />
                    </>);
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.fieldname}</span><br />
                    </>);
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.alerttype}</span><br />
                    </>);
            }
        },
        {
            targets: 4,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(

                    <span className="badge bg-info">{rowData.explanation}</span>
                );
            }
        },
        {
            targets: 5,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <span style={{ marginBottom: '85px' }}>{rowData.updateDate}</span>

                    </div>);
            }
        },
        {
            targets: 6,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>

                        <span id="droprightMenuButton" className="text-muted" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18 cursor-pointer"></i></span>
                        <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Detayları görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Güncelleme geçmişini görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Amazon.com'da görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Amazon.ca'da görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Amazon.ca'da teklifleri görüntüleyin
                            </span>
                        </div>
                    </div>)
            }
        }
    ];

    return (
        <div style={{ marginBottom: '150px' }}>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.reportingAndAnalysis'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.problematicProducts'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div id="inventoryTable" className="row mb-5">
                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                        <div className="card-body horizontal-scrollable">
                            {tableData && <>
                                <DrDataTable
                                    data={tableData}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    //search={true}
                                    minWidth="850px"
                                />
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProblematicProductReportPage