import types from '../actions/create-smartpricer-filter.actions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SMARTPRICER_SNAPSHOT_META:
            return { ...state, ...action.payload };

        case types.SET_SMARTPRICER_FILTER_TYPE:
            return { ...state, "filterTypeSelection": action.payload };

        case types.SET_SMARTPRICER_QUESTIONS:
            return {
                ...state, "smartpricerQuestions": action.payload
            };

        case types.SET_SMARTPRICER_TEMPLATE_QUESTIONS:
            return {
                ...state, "smartpricerTemplateQuestions": action.payload
            };

        case types.SET_SMARTPRICER_TARGET_CATEGORY:
            return { ...state, "targetCategoryId": action.payload };

        case types.SET_SMARTPRICER_TARGET_ASINS:
            return {
                ...state, "targetProductsAsins": action.payload
            };

        case types.SET_SMARTPRICER_NAME:
            return { ...state, "smartpricerName": action.payload };

        case types.CLEAR_SMARTPRICER_INFORMATION:
            return initialState;

        case types.SET_SNAPSHOT_SMARTPRICER_PAGE_SUBMISSION:
            return {
                ...state, "pageSubmissions": {
                    ...state.pageSubmissions,
                    ...action.payload
                }
            };

        default:
            return state;
    }
};

export default reducer;

const initialState = {
    "customerMainSellersId": "",
    "filterTypeSelection": "",
    "smartpricerQuestions": [],
    "smartpricerTemplateQuestions": [],
    "targetType": null,
    "targetCategoryId": "",
    "targetProductsAsins": [],
    "smartpricerName": "",
    "pageSubmissions":
    {
        "filterTypeSelectionPage": false,
        "smartpricerQuestionsPage": false,
        "smartpricerTargetPage": false,
        "smartpricerNamePage": false
    },
    "smartpricerFilterSubmitted": false
}
