import ReactDOM from 'react-dom/client';
import React from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store, persistor } from './state-management/configureStore';
import { DrCookieConsent } from './components/component-index';
import history from './history';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <HashRouter history={history}>
                {/* <Suspense fallback={<div>Loading....</div>}> */}
                <App />
                {/* </Suspense> */}
                <DrCookieConsent />
            </HashRouter>
        </PersistGate>
    </Provider >
);