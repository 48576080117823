import { useDispatch, useSelector } from "react-redux";
import { withActionPromise } from "../../../state-management/actions/app.actions";
import { setMyStorePageSelections } from "../../../state-management/actions/settings.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DrPackageWarningLimitExceeds(pageKey) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation('drPackageWarnings');
    const customerPackageInfo = useSelector(state => state.customer.customerPackageInfo);
    const inventorySummary = useSelector(state => state.reports.inventorySummary);

    return (
        <>
            <div className="alert alert-danger alert-dismissible fade show custom-alert-icon shadow-sm bg-danger bg-opacity-10" role="alert">
                <svg className="svg-danger" xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 0 24 24" width="1.5rem" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM12 17.3c-.72 0-1.3-.58-1.3-1.3 0-.72.58-1.3 1.3-1.3.72 0 1.3.58 1.3 1.3 0 .72-.58 1.3-1.3 1.3zm1-4.3h-2V7h2v6z"></path></svg>

                <div className="row justify-content-left ms-2 ps-3">
                    <div className="col-auto">

                        <div className="row mb-2">
                            <div className="col-auto">
                                <span className="fw-bold">
                                    Your store's inventory is at risk. Following features will soon be expired or their limits will be exceed!
                                </span>

                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-auto">
                                <ul className="mb-0 list-unstyled">
                                    <li className="d-flex align-items-top mb-2">
                                        <span className="me-2">
                                            <i className="bx bxs-circle fs-9 me-2 op-5 text-danger"></i>
                                        </span>
                                        <span>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <span>Your subscribed inventory product count limit: {customerPackageInfo?.totalProductCount}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <span>Current inventory product count: {inventorySummary?.totalProductCount}</span>
                                                </div>
                                            </div>
                                            
                                        </span>
                                    </li>
                                    {/* <li className="d-flex align-items-top mb-2">
                                        <span className="me-2">
                                            <i className="bx bxs-circle fs-9 me-2 op-5 text-danger"></i>
                                        </span>
                                        <span>
                                            You can check your package expirations at SellerDoping to see if your package is expied or may be there is a payment problem.
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-top mb-2">
                                        <span className="me-2">
                                            <i className="bx bxs-circle fs-9 me-2 op-5 text-danger"></i>
                                        </span>
                                        <span>
                                            You can try to re-connect your account to Amazon by clicking the buttom below.
                                        </span>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DrPackageWarningLimitExceeds
