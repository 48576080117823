import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DrToastr from "../Toastr/dr-toastr.component";
import { DrConnectAmazonWarning, DrPackageWarningLimitExceeds, DrSubscriptionSuspended } from "../../component-index";
import { useLocation } from "react-router-dom";
import { getRouteByPath } from "../../../routes";
import Restricted from "../../../security/Restricted";

function DrContainer({ children }) {
    const customer = useSelector(state => state.customer);
    const customerPackageInfo = useSelector(state => state.customer.customerPackageInfo);
    const location = useLocation();
    const [showConnectAmazonWarning, setShowConnectAmazonWarning] = useState(false);
    const [showPackageWarningLimitExceeds, setShowPackageWarningLimitExceeds] = useState(false);
    const [showSubscriptionSuspended, setShowSubscriptionSuspended] = useState(false);
    const [currentPageKey, setCurrentPageKey] = useState("");


    const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_SUSPENDED_ADMIN,
        REACT_APP_ROLES_STORE, REACT_APP_ROLES_GUEST,
        REACT_APP_ROLES_USER, REACT_APP_ROLES_ORDERS,
        REACT_APP_ROLES_INVENTORY, REACT_APP_ROLES_REPORT, REACT_APP_ROLES_LOGISTICS } = process.env;

    useEffect(() => {
        if (location?.pathname) {

            var currentRoute = getRouteByPath(location?.pathname);
            if (currentRoute) {
                setCurrentPageKey(currentRoute.name);

                if (currentRoute.name === "mainPackage"
                    || currentRoute.name === "subFeatures"
                    || currentRoute.name === "login"
                    || currentRoute.name === "error"
                    || currentRoute.name === "payment"

                    || currentRoute.name === "marketSelection"
                    || currentRoute.name === "storePreferences"
                    || currentRoute.name === "warehouseSelection"
                    || currentRoute.name === "connectAmazon"
                    || currentRoute.name === "completeCreateStore"

                    || currentRoute.name === "smartPricerOptions"
                    || currentRoute.name === "smartPricerFilters"
                    || currentRoute.name === "smartPricerTarget"
                    || currentRoute.name === "smartPricerComplete"
                    || currentRoute.name === "smartPricerCompleted"
                ) {
                    setShowConnectAmazonWarning(false);
                    setShowPackageWarningLimitExceeds(false);
                    setShowSubscriptionSuspended(false);
                }
                else {
                    // setShowConnectAmazonWarning(true);
                    // setShowPackageWarningLimitExceeds(true);
                    // setShowSubscriptionSuspended(true);
                }
                // if (currentRoute.name === "inventory") {
                //     setShowPackageWarningLimitExceeds(true);
                // }
                // else {
                //     setShowPackageWarningLimitExceeds(false);
                // }
            }
            // if (customer.isLoggedin && location.pathname === "/") {
            //     if (customer?.customerInfo?.role?.includes(REACT_APP_ROLES_STORE)) {
            //         navigate(getRouteByKey("WAREHOUSE_HOME"));
            //     }
            //     else {
            //         navigate(getRouteByKey("HOME"));
            //     }

            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, customer?.customerInfo?.role]);

    return (

        // <div className={`main-content side-content ${customer.isLoggedin ? "main-content-pt-0" : ""}`} style={{marginBottom:'50px'}}>
        //     <div className="side-app">
        //         <div className="main-container container">
        //             {children}
        //         </div>
        //     </div>
        // </div>
        <>

            <div className="main-content app-content position-relative mb-5">
                <DrToastr />

                <Restricted allowedTo={[
                    REACT_APP_ROLES_SUSPENDED_ADMIN,
                    REACT_APP_ROLES_ADMIN
                ]}>
                    {showConnectAmazonWarning && <DrConnectAmazonWarning></DrConnectAmazonWarning>}
                </Restricted>

                <Restricted allowedTo={[
                    REACT_APP_ROLES_SUSPENDED_ADMIN,
                    REACT_APP_ROLES_ADMIN,
                    REACT_APP_ROLES_ORDERS,
                    REACT_APP_ROLES_LOGISTICS,
                    REACT_APP_ROLES_INVENTORY,
                    REACT_APP_ROLES_REPORT,
                ]}>
                    {showPackageWarningLimitExceeds && <DrPackageWarningLimitExceeds pageKey={currentPageKey}></DrPackageWarningLimitExceeds>}
                </Restricted>

                <Restricted allowedTo={[
                    REACT_APP_ROLES_SUSPENDED_ADMIN,
                    REACT_APP_ROLES_ADMIN
                ]}>
                    {showSubscriptionSuspended && <DrSubscriptionSuspended></DrSubscriptionSuspended>}
                </Restricted>
                <div className="container-fluid">
                    {children}
                </div>
            </div>
        </>

    );
}

export default DrContainer;

