export const inventoryMockData = {
    "totalRecordCount": 1,
    "filterRecordCount": 0,
    "data": [
        {
            "customerMainSellerProductId": "gd553d55-c5bc-4875-8f19-ac9f996d4a2d",
            "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
            "asin": "B0C49H9VP8",
            "productId": "6947f780-266b-43eb-93d3-36d25f0ba119",
            "productPicture": "https://m.media-amazon.com/images/I/71IvI0KkQaL.jpg",
            "productName": "VM VOUGEMARKET Black White Duvet Cover Queen Size Abstract Marble Pattern Bedding Set 100% Cotton Modern Comforter Cover (1 Gradient Duvet Cover+2 Pillowcases)",
            "productSerialNumber": "",
            "brandName": "VM VOUGEMARKET",
            "categoryName": "Home & Kitchen",
            "price": 67.3686,
            "desi": 2,
            "shippingCost": 0,
            "customsDutyPrice": 6.53,
            "customsDutyPriceCurrencyCode": "USD",
            "customsDutyRatePrice": 0,
            "progressPayment": 100.861,
            "profitPercent": 7.876829,
            "profitPrice": 7.3645554,
            "amazonFeePercent": 15,
            "amazonFeePrice": 17.799002,
            "warehousePrice": 26.12785,
            "sellingPrice": 118.66,
            "quantity": 20,
            "status": 4,
            "sku": "MesutTest2_27121",
            "modelNumber": "",
            "isFavourite": false,
            "updatedDate": "2024-05-08T07:13:36.748282",
            "isApprove": true
        },
        {
            "customerMainSellerProductId": "fd553d55-c5bc-4875-8f19-ac9f996d4a2d",
            "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
            "asin": "B0C49H9VP9",
            "productId": "6947f780-266b-43eb-93d3-36d25f0ba119",
            "productPicture": "https://m.media-amazon.com/images/I/71IvI0KkQaL.jpg",
            "productName": "VM VOUGEMARKET Black White Duvet Cover Queen Size Abstract Marble Pattern Bedding Set 100% Cotton Modern Comforter Cover (1 Gradient Duvet Cover+2 Pillowcases)",
            "productSerialNumber": "",
            "brandName": "VM VOUGEMARKET",
            "categoryName": "Home & Kitchen",
            "price": 67.3686,
            "desi": 2,
            "shippingCost": 0,
            "customsDutyPrice": 6.53,
            "customsDutyPriceCurrencyCode": "USD",
            "customsDutyRatePrice": 0,
            "progressPayment": 100.861,
            "profitPercent": 7.876829,
            "profitPrice": 7.3645554,
            "amazonFeePercent": 15,
            "amazonFeePrice": 17.799002,
            "warehousePrice": 26.12785,
            "sellingPrice": 118.66,
            "quantity": 20,
            "status": 4,
            "sku": "MesutTest2_27121",
            "modelNumber": "",
            "isFavourite": false,
            "updatedDate": "2024-05-08T07:13:36.748282",
            "isApprove": false
        },
        {
            "customerMainSellerProductId": "0d553d55-c5bc-4875-8f19-ac9f996d4a2d",
            "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
            "asin": "B0C49H9VP7",
            "productId": "6947f780-266b-43eb-93d3-36d25f0ba119",
            "productPicture": "https://m.media-amazon.com/images/I/71IvI0KkQaL.jpg",
            "productName": "VM VOUGEMARKET Black White Duvet Cover Queen Size Abstract Marble Pattern Bedding Set 100% Cotton Modern Comforter Cover (1 Gradient Duvet Cover+2 Pillowcases)",
            "productSerialNumber": "",
            "brandName": "VM VOUGEMARKET",
            "categoryName": "Home & Kitchen",
            "price": 67.3686,
            "desi": 2,
            "shippingCost": 0,
            "customsDutyPrice": 6.53,
            "customsDutyPriceCurrencyCode": "USD",
            "customsDutyRatePrice": 0,
            "progressPayment": 100.861,
            "profitPercent": 7.876829,
            "profitPrice": 7.3645554,
            "amazonFeePercent": 15,
            "amazonFeePrice": 17.799002,
            "warehousePrice": 26.12785,
            "sellingPrice": 118.66,
            "quantity": 20,
            "status": 4,
            "sku": "MesutTest2_27121",
            "modelNumber": "",
            "isFavourite": false,
            "updatedDate": "2024-05-08T07:13:36.748282",
            "isApprove": false
        }
    ]
}



export const detailedSearchList = [
    {
        onlyMe: true,
        onlyBuyBox: true,
        noBuybox: false,
        onlyLowest: true,
        noLowest: false,
        onlyMinimumPrice: true
    },
    {
        onlyMe: false,
        onlyBuyBox: true,
        noBuybox: true,
        onlyLowest: false,
        noLowest: false,
        onlyMinimumPrice: false
    },
    {
        onlyMe: true,
        onlyBuyBox: false,
        noBuybox: false,
        onlyLowest: false,
        noLowest: true,
        onlyMinimumPrice: true
    }
];

export const bestSellingMockData = [
    {
        productName: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
        asin: " B09Y5KNXXN",
        sku: "AMZRJP11688508570",
        category: "Baby Products",
        brand: "Munchkin",
        amount: "11",
    },
    {
        productName: "MANCJE LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
        asin: " A07Y5KNXXN",
        sku: "AMZRJP11688508570",
        category: "Car Products",
        brand: "Lotus",
        amount: "15",
    },
    {
        productName: "LUA-VIKE VIPRAETE",
        asin: " B09Y5KJSCC",
        sku: "ZR43411688508570",
        category: "Car Products",
        brand: "Lake",
        amount: "11"
    },

];
export const updateListMockData = [
    {
        price: "18",
        asin: " B09Y5KNXXN",
        sku: "AMZRJP11688508570",
        category: "Baby Products",
        brand: "11",
        minPrice: "11",
        maxPrice: "25",
        time: "Updated",
        action: "1 dakika önce"
    },
    {
        price: "18",
        asin: " B09Y5KNXXN",
        sku: "AMZRJP11688508570",
        category: "Baby Products",
        brand: "13",
        minPrice: "11",
        maxPrice: "25",
        time: "Updated",
        action: "1 dakika önce"
    },
    {
        price: "18",
        asin: " B09Y5KNXXN",
        sku: "AMZRJP11688508570",
        category: "Baby Products",
        brand: "12",
        minPrice: "11",
        maxPrice: "25",
        time: "Updated",
        action: "1 dakika önce"
    },

];
export const duplicateMockData = [
    {
        productName: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
        asin: " B09Y5KNXXN",
        sku: "AMZRJP11688508570",
        category: "Baby Products",
        updateDate: "1 dakika önce",
    },
    {
        productName: "MANCJE LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
        asin: " A07Y5KNXXN",
        sku: "AMZRJP11688508570",
        category: "Car Products",
        updateDate: "10 dakika önce",
    },
    {
        productName: "LUA-VIKE VIPRAETE",
        asin: " B09Y5KJSCC",
        sku: "ZR43411688508570",
        category: "Car Products",
        updateDate: "11 dakika önce"
    },
    {
        productName: "KRMAKLS-VIKE VIPRAETE",
        asin: " B09Y5KJSCC",
        sku: "ZR43411688508570",
        category: "Car Products",
        updateDate: "20 dakika önce"
    },

];

export const productVariantsMockData = [
    {

        asin: " B09Y5KNXXN",
        createDate: "1 dakika önce",
    },
    {
        asin: " A07Y5KNXXN",
        createDate: "10 dakika önce",
    },
    {
        asin: " B09Y5KJSCC",
        createDate: "11 dakika önce"
    },
    {
        asin: " B09Y5KJSCC",
        createDate: "20 dakika önce"
    },

];


export const categoriesMockData = [
    {
        category: "AUTO_ACCESSORY",
        percentile: "%23",
        estimatedprofit: "$243.70",
        order: "35",
        productcount: "4",
    },
    {
        category: "ART_CRAFT_KIT",
        percentile: "&12",
        estimatedprofit: "$111.70",
        order: "46",
        productcount: "2",
    },
    {
        category: "ARTIFICIAL_TREE",
        percentile: "%31",
        estimatedprofit: "$343.70",
        order: "65",
        productcount: "7",
    },
    {
        category: "AIR_FRYER",
        percentile: "%13",
        estimatedprofit: "$767.70",
        order: "12",
        productcount: "32",
    },
    {
        category: "ABIS_MUSIC",
        percentile: "%53",
        estimatedprofit: "$550.70",
        order: "6",
        productcount: "117",
    },

];
export const blacklistMockData = [
    {
        id: "1",
        category: "ABIS_MUSIC",
        created: " 27.05.2023",
    },
    {
        id: "2",
        category: "AIR_FRYER",
        created: "27.05.2023",
    },
    {
        id: "3",
        category: "ARTIFICIAL_TREE",
        created: "27.05.2023",
    },
    {
        id: "4",
        category: "ART_CRAFT_KIT",
        created: "27.05.2023",
    },
    {
        id: "5",
        category: "AUTO_ACCESSORY",
        created: "27.05.2023",
    }
];
export const brandlistMockData = [
    {
        brand: "Camel",
        percentile: " Delivered",
        estimatedprofit: "CN$50.70",
        order: "Tamam",
        productcount: "5",
    },
    {
        brand: "Redbul",
        percentile: " Delivered",
        estimatedprofit: "CN$50.70",
        order: "Tamam",
        productcount: "5",
    },
    {
        brand: "Camel",
        percentile: " Delivered",
        estimatedprofit: "CN$50.70",
        order: "Tamam",
        productcount: "5",
    },
    {
        brand: "Korko",
        percentile: " Delivered",
        estimatedprofit: "CN$50.70",
        order: "Tamam",
        productcount: "5",
    },
    {
        brand: "Yorgo",
        percentile: " Delivered",
        estimatedprofit: "CN$50.70",
        order: "Tamam",
        productcount: "5",
    },

];
export const brandblackwhitelistMockData = [
    {
        brand: "Yorgo",
        trademark: "aaa",
        created: " 27.05.2023",
    },
    {
        brand: "Korko",
        trademark: "bbb",
        created: " 27.05.2023",
    },
    {
        brand: "Redbul",
        trademark: "ccc",
        created: " 27.05.2023",
    },
    {
        brand: "Camel",
        trademark: "ddd",
        created: " 27.05.2023",
    },
    {
        brand: "Camel",
        trademark: "eee",
        created: " 27.05.2023",
    },
    {
        brand: "Camel",
        trademark: "fff",
        created: " 27.05.2023",
    },
    {
        brand: "House",
        trademark: "ggg",
        created: " 27.05.2023",
    },
];
export const restrictedAndExcludedProductsMockData = [
    {
        productName: "Product AA",
        asin: "B11Y5KNXXN",
        brand: "Zonli",
        createdDate: "3 ay önce",
    },
    {
        productName: "Product BB",
        asin: "B13Y5KNXXN",
        brand: "Monli",
        createdDate: "5 ay önce",

    },
    {
        productName: "Product CC",
        asin: "B09Y5KNXXN",
        brand: "Tonli",
        createdDate: "8 ay önce",
    },
    {
        productName: "Product 5",
        asin: "B12Y5KNXXN",
        brand: "Zonli",
        createdDate: "3 ay önce",
    },
    {
        productName: "Product 6",
        asin: "B99Y5KNXXN",
        brand: "Monli",
        createdDate: "5 ay önce",
    },
    {
        productName: "Product 7",
        asin: "B87Y5KNXXN",
        brand: "Tonli",
        createdDate: "8 ay önce",
    },
    {
        productName: "Product 15",
        asin: "B44Y5KNXXN",
        brand: "Zonli",
        createdDate: "3 ay önce",
    },
    {
        productName: "Product 16",
        asin: "B67Y5KNXXN",
        brand: "Monli",
        createdDate: "5 ay önce",
    },
    {
        productName: "Product 17",
        asin: "B69Y5KNXXN",
        brand: "Tonli",
        createdDate: "8 ay önce",
    },
    {
        productName: "Product 18",
        asin: "B88Y5KNXXN",
        brand: "Tonli",
        createdDate: "8 ay önce",
    },
    {
        productName: "Product 19",
        asin: "B00Y5KNXXN",
        brand: "Tonli",
        createdDate: "8 ay önce",
    },
    {
        productName: "Product 20",
        asin: "B01Y5KNXXN",
        brand: "Tonli",
        createdDate: "8 ay önce",
    },
    {
        productName: "Product 25",
        asin: "B02Y5KNXXN",
        brand: "Zonli",
        createdDate: "3 ay önce",
    },
    {
        productName: "Product 26",
        asin: "B03Y5KNXXN",
        brand: "Monli",
        createdDate: "5 ay önce",
    },
    {
        productName: "Product 27",
        asin: "B04Y5KNXXN",
        brand: "Tonli",
        createdDate: "8 ay önce",
    }
];
export const salesReportMockData = [
    {
        orderNo: "701-4275008-1293805",
        buyerOrderNo: "114-9003653-8337068",
        status: "Delivered",
        price: "CN$102.99",
        earning: "CN$9.79",
        earning2: "13%",
        date: "8 ay önce",
        fee: "CN$6.25",
    },
    {
        orderNo: "702-4826094-9142628",
        buyerOrderNo: "114-9003653-8337068",
        status: " Delivered",
        price: "CN$41.66",
        earning: "CN$1.33",
        earning2: "4%",
        date: "4 ay önce",
        fee: "CN$6.25",
    },
    {
        orderNo: "701-9987595-0200238",
        buyerOrderNo: "114-9003653-8337068",
        status: "Delivered",
        price: "CN$70.02",
        earning: "CN$-5.46",
        earning2: "-8%",
        date: "8 ay önce",
        fee: "CN$6.25",
    },
    {
        orderNo: "702-4826094-9142628",
        buyerOrderNo: "114-9003653-8337068",
        status: " Delivered",
        price: "CN$41.66",
        earning: "CN$1.33",
        earning2: "4%",
        date: "4 ay önce",
        fee: "CN$6.25",
    },
    {
        orderNo: "702-4826094-9142628",
        buyerOrderNo: "114-9003653-8337068",
        status: " Delivered",
        price: "CN$41.66",
        earning: "CN$1.33",
        earning2: "4%",
        date: "4 ay önce",
        fee: "CN$6.25",
    },
    {
        orderNo: "702-4826094-9142628",
        buyerOrderNo: "114-9003653-8337068",
        status: " Delivered",
        price: "CN$41.66",
        earning: "CN$1.33",
        earning2: "4%",
        date: "4 ay önce",
        fee: "CN$6.25",
    }

];
export const problematicProductMockData = [
    {
        fieldname: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
        asin: " B09Y5KNXXN",
        sku: "AMZRJP11688508570",
        alerttype: "Baby Products",
        explanation: "Baby Products",
        updateDate: "1 dakika önce",
    },
    {
        fieldname: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
        asin: " B09Y5KNXXN",
        sku: "AMZRJP11688508570",
        alerttype: "Baby Products",
        explanation: "Baby Products",
        updateDate: "1 dakika önce",
    },
    {
        fieldname: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
        asin: " B09Y5KNXXN",
        sku: "AMZRJP11688508570",
        alerttype: "Baby Products",
        explanation: "Baby Products",
        updateDate: "1 dakika önce",
    },
    {
        fieldname: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
        asin: " B09Y5KNXXN",
        sku: "AMZRJP11688508570",
        alerttype: "Baby Products",
        explanation: "Baby Products",
        updateDate: "1 dakika önce",
    },

];


export const myStoresMockData = [];

export const warehouseOrdersMockData = [
    {
        "id": "3f676e87-69f3-4c27-8e02-9e7359b42188",
        "customerMainSellerId": "e1afa174-bde1-48ae-b643-ae0b57164295",
        "buyerEmail": "c7f6mlhnywk2pqs@marketplace.amazon.ca",
        "buyerName": "",
        "sellersEmail": "sellersmart@gmail.com",
        "orderNumber": "702-1851066-9364219",
        "salesChannel": "Amazon.ca",
        "orderType": "StandardOrder",
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "imageUrl": null,
        "firstASIN": "B08JH9ZDR1",
        "warehouseSuitName": "W-2343"
    }
]

export const carrierPackages = [
    {
        "carrierCode": "ups_walleted",
        "name": "Package",
        "code": "package",
        "domestic": true,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "name": "UPS  Express® Box - Large",
        "code": "ups__express_box_large",
        "domestic": true,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "name": "UPS 10 KG Box®",
        "code": "ups_10_kg_box",
        "domestic": false,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "name": "UPS 25 KG Box®",
        "code": "ups_25_kg_box",
        "domestic": false,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "name": "UPS Express® Box",
        "code": "ups_express_box",
        "domestic": true,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "name": "UPS Express® Box - Medium",
        "code": "ups_express_box_medium",
        "domestic": true,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "name": "UPS Express® Box - Small",
        "code": "ups_express_box_small",
        "domestic": true,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "name": "UPS Express® Pak",
        "code": "ups_express_pak",
        "domestic": true,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "name": "UPS Letter",
        "code": "ups_letter",
        "domestic": true,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "name": "UPS Tube",
        "code": "ups_tube",
        "domestic": true,
        "international": true
    }
]

export const carrierServices = [
    {
        "carrierCode": "ups_walleted",
        "code": "ups_ground_saver",
        "name": "UPS® Ground Saver",
        "domestic": true,
        "international": false
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_ground",
        "name": "UPS® Ground",
        "domestic": true,
        "international": false
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_ground_international",
        "name": "UPS Ground® (International)",
        "domestic": false,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_standard_international",
        "name": "UPS Standard®",
        "domestic": false,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_3_day_select",
        "name": "UPS 3 Day Select®",
        "domestic": true,
        "international": false
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_worldwide_saver",
        "name": "UPS Worldwide Saver®",
        "domestic": false,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_worldwide_express",
        "name": "UPS Worldwide Express®",
        "domestic": false,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_2nd_day_air",
        "name": "UPS 2nd Day Air®",
        "domestic": true,
        "international": false
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_worldwide_expedited",
        "name": "UPS Worldwide Expedited®",
        "domestic": false,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_worldwide_express_plus",
        "name": "UPS Worldwide Express Plus®",
        "domestic": false,
        "international": true
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_2nd_day_air_am",
        "name": "UPS 2nd Day Air AM®",
        "domestic": true,
        "international": false
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_next_day_air_saver",
        "name": "UPS Next Day Air Saver®",
        "domestic": true,
        "international": false
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_next_day_air",
        "name": "UPS Next Day Air®",
        "domestic": true,
        "international": false
    },
    {
        "carrierCode": "ups_walleted",
        "code": "ups_next_day_air_early_am",
        "name": "UPS Next Day Air® Early",
        "domestic": true,
        "international": false
    }
]


export const messagesMockData = [
    {
        messageId: "1",
        amazonOrderNumber: "702-1173636-9225028",
        orderId: "7ee037a8-6c58-422b-9057-ef04690c3114",
        emailTo: "mesut@gmail.com",
        firstName: "Mesut",
        lastName: "Bayir",
        lastMessageDate: "2023-01-29T11:04:09Z",
        totalMessageCount: 3,
        messageSource: 1,
        isRead: false
    },
    {
        messageId: "2",
        amazonOrderNumber: "701-7772425-3119457",
        orderId: "364a3cdb-fc27-4c2b-a6f0-57cc889eed00",
        emailTo: "koray@gmail.com",
        firstName: "Koray",
        lastName: "Papağan",
        lastMessageDate: "2022-11-22T12:41:56Z",
        totalMessageCount: 2,
        messageSource: 1,
        isRead: true
    },
    {
        messageId: "3",
        amazonOrderNumber: "702-0335281-5869059",
        orderId: "2c4c3088-0bb4-46c0-aede-0a8e6760fc4c",
        emailTo: "serkan@gmail.com",
        firstName: "Serkan",
        lastName: "Doğan",
        lastMessageDate: "2022-11-15T11:36:55Z",
        totalMessageCount: 1,
        messageSource: 1,
        isRead: false
    },
    {
        messageId: "4",
        amazonOrderNumber: "702-7423687-5156205",
        orderId: "14697e10-cc56-4796-b134-a31d3f2d80c9",
        emailTo: "ismail@gmail.com",
        firstName: "İsmail Cem",
        lastName: "Babaoğlu",
        lastMessageDate: "2023-01-06T16:33:00Z",
        totalMessageCount: 2,
        messageSource: 1,
        isRead: true
    }
];

export const messageDetailsMockData = [
    {
        emailFrom: "mesut@gmail.com",
        firstName: "Mesut",
        lastName: "Bayir",
        sendDate: "2024-02-01T01:05:55Z",
        emailBody: "",
        isSendMe: true,
        messageText: `Hello - are you able to provide any information on the shipping/tracking of this item? It was supposed to be delivered yesterday and I have not received it. `,

    },
    {
        emailFrom: "sellerzeynepyucel@gmail.com",
        firstName: "Zeynep",
        lastName: "Yücel",
        sendDate: "2024-03-03T09:47:55Z",
        emailBody: "",
        isSendMe: false,
        messageText: `Hello Candice,\n\nWe've received your message regarding shipment tracking information for order 701-5085630-6133803. Listed below is the requested information.\n\n--------------------\nCarrier: Canada Post\nTracking number: 7262314449192019\n--------------------\n\nSincerely,\nGaint XL Shop `,

    },
    {
        emailFrom: "sellerzeynepyucel@gmail.com",
        firstName: "Zeynep",
        lastName: "Yücel",
        sendDate: "2024-03-06T17:12:11Z",
        emailBody: "",
        isSendMe: false,
        messageText: `Hi Candice:

        Thank you for shopping with us. Amazon seller Giant XL Shop has encountered an unexpected problem with completing your order.
        
        Message from seller Giant XL Shop:
        
        Our dear customer,
        
        When we control the systems, we see that your product has been delivered to you. Your satisfaction is very important to us.
        Did the product reach you? Did it earn your satisfaction? Your feedback is very important to us.
        
        Best Regards
        Giant XL Shop
        
        You can send an anonymous message to the seller Giant XL Shop, that does not expose your real email address, by replying to this email.
        
        If you were contacted inappropriately by the seller, please report this message.
        
        We hope to see you again soon. `,

    },
    {
        emailFrom: "mesut@gmail.com",
        firstName: "Mesut",
        lastName: "Bayir",
        sendDate: "2024-03-07T13:21:27Z",
        emailBody: "",
        isSendMe: true,
        messageText: `Hello - thank you. :) `,

    }
]

export const mywalletMockData = {
    myCurrency: 352.83,
    mycurrencyCode: "USD",
    myCards: [
        {
            cardId: "guid",
            cardHolderName: "Koray Papağan",
            cardNumber: "5445000044542344",
            isDefault: true
        },
        {
            cardId: "guid",
            cardHolderName: "Mesut Bayir",
            cardNumber: "344500004454234",
            isDefault: false
        },
        {
            cardId: "guid",
            cardHolderName: "Serkan Doğan",
            cardNumber: "454500004454234",
            isDefault: false
        },
        {
            cardId: "guid",
            cardHolderName: "Serkan Doğan",
            cardNumber: "634500004454234",
            isDefault: false
        }
    ]
}

export const walletSummaryMockData = [
    {
        id: "1",
        currency: 11.2,
        currencyCode: "$",
        transactionType: "1", //0 -> income, 1-> outgoing
        transactionDate: "2024-02-24T11:36:55Z",
        transactionSource: "SellerDoping",
        description: "Geri İade"
    },
    {
        id: "2",
        currency: 350,
        currencyCode: "$",
        transactionType: "1", //0 -> income, 1-> outgoing
        transactionDate: "2024-02-25T14:36:55Z",
        transactionSource: "Wallet",
        description: "Cüzdana Yüklendi"
    },
    {
        id: "3",
        currency: 50,
        currencyCode: "$",
        transactionType: "2", //0 -> income, 1-> outgoing
        transactionDate: "2024-02-01T11:36:55Z",
        transactionSource: "Wallet",
        description: "Otomatik Aylık Üyelik Cüzdandan Çekildi"
    },
    {
        id: "4",
        currency: 50,
        currencyCode: "$",
        transactionType: "2", //0 -> income, 1-> outgoing
        transactionDate: "2024-01-01T11:36:55Z",
        transactionSource: "Wallet",
        description: "Otomatik Aylık Üyelik Cüzdandan Çekildi"
    },
    {
        id: "5",
        currency: 50,
        currencyCode: "$",
        transactionType: "2", //0 -> income, 1-> outgoing
        transactionDate: "2023-12-01T11:36:55Z",
        transactionSource: "Wallet",
        description: "Otomatik Aylık Üyelik Cüzdandan Çekildi"
    }

]

export const inventoryPageFilterMockData = {
    onlySoldByMe: false,
    onlyIfNoBuyboxPriceAtDestinationMarket: false,
    onlyIfMeBuyboxSeller: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfMeLowestSeller: "0",
    onlyIfPrimeSellersAtDestinationMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfWithSalesRankAtDestinationMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfWithChineseSellersAtDestinationMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfWithAmazonSellersAtDestinationMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfProductPriceIsMinOrMax: "0", // 0 - kapalı, 1 - Min, 2 - Max


    onlyIfShippingPriceIsHigherThanPriceAtSourceMarket: false,
    onlyIfUnAvailableProductsAtSourceMarket: false,
    onlyIfBrandAndSellerNameIsSameAtSourceMarket: false,
    onlyIfWithSalesRankAtSourceMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfSoldByAmazonAtSourceMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfFBAProductsAtSourceMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfWithCouponAtSourceMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır



    onlyIfChangeToFulfilledByAmazon: false,
    onlyIfWithDiscount: false,
    onlyIfNoShip: false,
    onlyIfNoShippingInfo: false,
    onlyIfNoImportFeeInfo: false,
    onlyIfPriceAppearsInCart: false,
    onlyIfNoPriceInfo: false,

    onlyIfAsinIsDifferent: false,
    onlyIfProfitBasedOnAsin: false, // asin bazlı kar belirlediğim yani smartpricerde asin bazlı stratejisi olanlar
    onlyIfIHaveSoldBefore: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfTrademarkIsRegistered: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfSellerDopingProducts: "0", // 0 - kapalı, 1 - evet, 2 - hayır
    onlyIfNoDeliveryDateInfo: "0", // 0 - kapalı, 1 - global ürünler, 2 - sellerdoping ürünleri, 3 - hepsi

    minPrice: null,
    maxPrice: null,
    minQuantity: null,
    maxQuantity: null,
    minTotalCost: null,
    maxTotalCost: null,

    minPriceCouponValue: null,
    maxPriceCouponValue: null,
    minPercentCouponValue: null,
    maxPercentCouponValue: null,  // bazı ürünlerde direk 15 dolar indirim kuponu var örnek bazılarında %5 örneğin eğer iki kupon tipine göre filtre girersem ürünleri joinlemen gerekir

    minProfitPercent: null,
    maxProfitPercent: null,
    productCreatedDateStart: null,
    productCreatedDateEnd: null, // tarih aralığında eklenen ürünler

    lastUpdatedDateStart: null, // son 3 gün içinde eklenen ürünler isticem örneğin ben sana tarih göndericem o tarihten büyük güncelleme tarihi olanları gönderirsin

    productCategory: "guid",
    productSubCategory: "string", // elle giriliyor 
    productBrand: "string", // elle giriliyor
    minProductSourceMarketPrice: null,
    maxProductSourceMarketPrice: null,
    minShippingPrice: null,
    maxShippingPrice: null,

    minPrimeSellerCountAtSourceMarket: null,
    maxPrimeSellerCountAtSourceMarket: null,

    minSourceMarketBuyboxSellersBrandCount: null,
    maxSourceMarketBuyboxSellersBrandCount: null, // source markette buybox satıcısı olanın sattığı marka sayısı

    minQuantityAtSourceMarket: null,
    maxQuantityAtSourceMarket: null,

    minSalesRankAtSourceMarket: null,
    maxSalesRankAtSourceMarket: null,

    starsValue: null, // decimal
    starsOperation: "<", // < , <= , >, >= , =

    reviewCount: null, // int
    reviewOperation: "<", // < , <= , >, >= , =

    minSellerDopingCost: null,
    maxSellerDopingCost: null,

    minSellerDopingLBS: null, // decimal
    maxSellerDopingLBS: null,


    minSalesRankAtDestinationMarket: null,
    maxSalesRankAtDestinationMarket: null,

    minOtherSellersCount: null,
    maxOtherSellersCount: null,

    minPricePercentFromBuyboxSellerAndMe: null,// decimal
    maxPricePercentFromBuyboxSellerAndMe: null,// decimal

    minPricePercentFromLowestSellerAndMe: null,// decimal
    maxPricePercentFromLowestSellerAndMe: null,// decimal

    minPricePercentFromAveragePriceAndMyPrice: null,// decimal
    maxPricePercentFromAveragePriceAndMyPrice: null,// decimal

    minPricePercentFromManufacturerAndMe: null,// decimal
    maxPricePercentFromManufacturerAndMe: null,// decimal

    minPricePercentFromAmazonAndMe: null,// decimal
    maxPricePercentFromAmazonAndMe: null,// decimal

    minPricePercentFromCompetitionThresholdAndMe: null,// decimal
    maxPricePercentFromCompetitionThresholdAndMe: null,// decimal


}

export const smartPricerQuestionMockData = {

    "id": "00000000-0000-0000-0000-000000000000",
    "smartPricerName": null,
    "smartPricerStrategy": 0,
    "customerMainSellerId": "00000000-0000-0000-0000-000000000000",
    "productCategoryId": null,
    "asins": [],
    smartPricerCategories: [
        {
            "id": "71fb6696-1f15-463b-a1f1-f5e60c2624f0",
            "categoryName": "Taşıma süresini devredışı bırak",
            "categoryDescription": "",
            "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
            "typeName": "BiggerThan",       //*
            "inputType": "TextBox-Numeric", //*
            "answerId": null,
            "answerText": null,
            "sortOrder": 2,
            "smartPricerTypeSubs": [],
            "activeValueInputFormat": ""//*
        },
        {
            "id": "59f394cd-e0d4-45ec-8f28-5674bc72b724",
            "categoryName": "Mağaza ve ürün olarak  Backorder takip et",
            "categoryDescription": "",
            "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
            "typeName": "Yes/No",
            "inputType": "CheckBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 3,
            "smartPricerTypeSubs": [],
            "activeValueInputFormat": ""//*
        },
        {
            "id": "cb12872d-7a83-4267-868c-ebb2b5a9b797",
            "categoryName": "Feedback sayısı - Mağaza sayısı",
            "categoryDescription": "",
            "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
            "typeName": "BiggerThan",
            "inputType": "TextBox-Numeric",
            "answerId": null,
            "answerText": null,
            "sortOrder": 4,
            "smartPricerTypeSubs": [],
            "activeValueInputFormat": ""//*
        },
        {
            "id": "f2aa540f-cad1-4c85-ab54-ad344718151d",
            "categoryName": "Geri bildirim puanı",
            "categoryDescription": "",
            "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
            "typeName": "LessThan",
            "inputType": "Textbox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 5,
            "smartPricerTypeSubs": [],
            "activeValueInputFormat": ""//*
        },
        {
            "id": "30b93438-8342-4c29-8f9b-4325632d8b3e",
            "categoryName": "Rekabet",
            "categoryDescription": "",
            "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
            "typeName": "Selection",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 6,
            smartPricerTypeSubs: [
                {
                    "id": "9d11e913-2462-4915-8819-565c2cd197e5",
                    "typeSubName": "Buybox ve en düşük FBA",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "5a95e36e-7fb3-4d1f-af80-69c8d608cfd4",
                    "typeSubName": "Sadece Buybox",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "00fd6f45-e424-4d46-83fd-1ae70574b0a6",
                    "typeSubName": "En düşük FBS ve FBM",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "9e17a385-60b6-4c8b-9884-76d05c019d8d",
                    "typeSubName": "Sadece FBA rekabet et",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "116ab476-15f9-4ff4-926f-1f08db43e309",
                    "typeSubName": "En düşük",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "66eedd9c-4934-458b-8f68-81af80e159bf",
                    "typeSubName": "En düşük FBM",
                    "isSelected": false,
                    "optionType": 0//*
                }
            ],
            "activeValueInputFormat": ""
        },
        {
            "id": "ffb1d634-d255-4921-ab6a-1506353f4f31",
            "categoryName": "Satıcıları Amazon ID'lerine göre tutun",
            "categoryDescription": "",
            "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
            "typeName": "Selection",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 7,
            smartPricerTypeSubs: [
                {
                    "id": "cdb343b5-a4d9-425c-b5f3-0f99e1d839b7",
                    "typeSubName": "Pasif",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "8ce006a4-c91b-4699-b05b-4da550bbb031",
                    "typeSubName": "Aktif",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "a6c54d3e-9083-4a27-9762-1787056d511b",
                    "typeSubName": "Liste ekle",
                    "isSelected": false,
                    "optionType": 999//*
                }
            ],
            "activeValueInputFormat": "merchant-list-dd|add-merchants-button" //*
        },
        {
            "id": "94e0a3d7-6429-489a-a954-1bc7aecd93f7",
            "categoryName": "Amazon ile Rekabet etme",
            "categoryDescription": "",
            "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
            "typeName": "Yes/No",
            "inputType": "CheckBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 8,
            "smartPricerTypeSubs": [],
            "activeValueInputFormat": ""
        },
        {
            "id": "a7472b21-efb1-4d53-9c74-c1beca61d1bb",
            "categoryName": "Rekabet durumunda nasıl fiyatlandırılsın  (Tutar yada Oran)",
            "categoryDescription": "",
            "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
            "typeName": "Calculation",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 10,
            smartPricerTypeSubs: [
                {
                    "id": "d062e2b2-e0b1-4f6d-bdd4-c0f6fc7d9950",
                    "typeSubName": "Fiyatları eşitle",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "ded7c1a3-dade-45bd-8f76-e6bd4e2bafc0",
                    "typeSubName": "Üzerinde kal",
                    "isSelected": false,
                    "optionType": 1//*
                },
                {
                    "id": "7717aeff-bce5-42c3-8e54-0af2c3bb5a45",
                    "typeSubName": "Buyboxkazan buybox altına in",
                    "isSelected": false,
                    "optionType": 1//*
                },
                {
                    "id": "0ed1b5e5-59a7-415d-94a0-d67c7f63a253",
                    "typeSubName": "Buyboxkazan minumuma kadar düşür",
                    "isSelected": false,
                    "optionType": 1//*
                },
                {
                    "id": "5f16b6b2-5d41-46cb-91a0-6e1fe2b3b9eb",
                    "typeSubName": "Fiyatın altına in",
                    "isSelected": false,
                    "optionType": 1//*
                }
            ],
            "activeValueInputFormat": "price-percent-dd|numeric-textbox" //*
        },
        {
            "id": "17df3c3f-acee-420c-9281-fdd9a5389760",
            "categoryName": "Rekabet fiyat, fiyatla aynı kalırsa",
            "categoryDescription": "",
            "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
            "typeName": "Selection",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 11,
            smartPricerTypeSubs: [
                {
                    "id": "6d0a4201-9bfb-4781-a5f8-03596641d445",
                    "typeSubName": "Min fiyat kullan",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "e5412739-74c1-47c1-b63e-038af3451c67",
                    "typeSubName": "Repicer yapma",
                    "isSelected": false,
                    "optionType": 0//*
                }
            ],
            "activeValueInputFormat": ""
        },
        {
            "id": "81fbe28b-574e-440c-8daa-21f46e347a78",
            "categoryName": "Stok miktarına göre kural uygulansın  mı?",
            "categoryDescription": "",
            "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
            "typeName": "Selection",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 12,
            smartPricerTypeSubs: [
                {
                    "id": "59457b37-0474-4ee0-85f3-0b5dc3fc0c19",
                    "typeSubName": "Fiyat belirleme",
                    "isSelected": false,
                    "optionType": 1 // optionType 1 - fiyat belirlemeli
                },
                {
                    "id": "98ad8536-87a3-4749-9e6f-d547e663921b",
                    "typeSubName": "Stok daha fazla ve daha az adet",
                    "isSelected": false,
                    "optionType": 0//*
                }
            ],
            "activeValueInputFormat": "text=Stok|more-less-dd|numeric-textbox|\n|text=Price|min-max-price-dd|price-percent-dd|numeric-textbox"//*
        },
        {
            "id": "de2cdf9b-282e-4d91-83e9-5980349fd1ac",
            "categoryName": "Rekabet fiyatı minimum altına inerse",
            "categoryDescription": "",
            "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
            "typeName": "Selection",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 13,
            smartPricerTypeSubs: [
                {
                    "id": "81793691-3161-46f2-820c-6e2776ac234e",
                    "typeSubName": "Min fiyat kullan",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "312ea140-c0f3-4c3d-8892-ea101d209fa0",
                    "typeSubName": "Repicer yapma",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "000000000",  // bu opsion bizde yok eklemek lazım
                    "typeSubName": "Fiyat belirleme",
                    "isSelected": false,
                    "optionType": 1 // optionType 1 - fiyat belirlemeli
                }
            ],
            "activeValueInputFormat": "min-max-price-dd|price-percent-dd|numeric-textbox" //*
        },
        {
            "id": "17ecf11b-1b92-4b5c-b3f3-514ed59d26e8",
            "categoryName": "Stok bittiğinde maksimum fiyatı kullan",
            "categoryDescription": "",
            "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
            "typeName": "Yes/No",
            "inputType": "CheckBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 14,
            "smartPricerTypeSubs": [],
            "activeValueInputFormat": ""
        },
        {
            "id": "ce9d2868-7f66-4cef-8552-6567875471c2",
            "categoryName": "FBA rekabeti ayrıca olsun mu (Tutar yada Oran)",
            "categoryDescription": "",
            "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
            "typeName": "Calculation",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 15,
            smartPricerTypeSubs: [
                {
                    "id": "7120ab46-460d-4406-bbe7-11ced1cb5130",
                    "typeSubName": "Altında kal",
                    "isSelected": false,
                    "optionType": 1 // optionType 1 - fiyat belirlemeli
                },
                {
                    "id": "98aeee57-16b0-4f06-b3e2-62021f21b6b9",
                    "typeSubName": "Üstünde kal",
                    "isSelected": false,
                    "optionType": 1 // optionType 1 - fiyat belirlemeli
                },
                {
                    "id": "2eb49f56-c31f-4497-8ce6-cf5caf02eb46",
                    "typeSubName": "Fiyatları eşitle",
                    "isSelected": false,
                    "optionType": 0
                }
            ],
            "activeValueInputFormat": "price-percent-dd|numeric-textbox" //*
        },
        {
            "id": "b9e7ae43-5a68-4027-8172-acf8ee7ff9d1",
            "categoryName": "Amazon'a göre fiyatlama (Tutar veya Oran) ",
            "categoryDescription": "",
            "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
            "typeName": "Calculation",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 16,
            smartPricerTypeSubs: [
                {
                    "id": "8304ebc5-0336-44fb-9698-683118f27934",
                    "typeSubName": "Altında kal",
                    "isSelected": false,
                    "optionType": 1 // optionType 1 - fiyat belirlemeli
                },
                {
                    "id": "50921037-9a9c-4bdb-8e92-4ad02d4385b3",
                    "typeSubName": "Üstünde kal",
                    "isSelected": false,
                    "optionType": 1 // optionType 1 - fiyat belirlemeli
                },
                {
                    "id": "c73b26da-c099-4e29-8ea1-f43a9d33e9e2",
                    "typeSubName": "Fiyatları eşitle",
                    "isSelected": false,
                    "optionType": 0
                }
            ],
            "activeValueInputFormat": "price-percent-dd|numeric-textbox" //*
        },
        {
            "id": "2e09b6d9-ae1a-45f4-b03e-c953a5e6b4f7",
            "categoryName": "Listede başka satıcı yoksa",
            "categoryDescription": "",
            "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
            "typeName": "Calculation",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 17,
            smartPricerTypeSubs: [
                {
                    "id": "033c659f-01a2-4059-a27d-902fb04179b9",
                    "typeSubName": "Kademeli fiyat arttır",
                    "isSelected": false,
                    "optionType": 2 // optionType 2 - kademeli fiyat belirlemeli
                },
                {
                    "id": "88fed535-bed6-4787-ac33-a8e4de1ca017",
                    "typeSubName": "Fiyat belirleme",
                    "isSelected": false,
                    "optionType": 1 // optionType 1 - fiyat belirlemeli
                },
                {
                    "id": "6205d7cd-21a0-40fb-91e8-05f34e33f500",
                    "typeSubName": "Repricer Yapma",
                    "isSelected": false,
                    "optionType": 0
                },
                {
                    "id": "8e0e51f3-0b6a-4930-8b48-5925692dcf80",
                    "typeSubName": "Maksimum fiyat kullan",
                    "isSelected": false,
                    "optionType": 0
                },
                {
                    "id": "b9e47ab5-71a7-421c-b76c-23ed7523a22a",
                    "typeSubName": "Minumum fiyat kullan",
                    "isSelected": false,
                    "optionType": 0
                }
            ],
            "activeValueInputFormat": "optiontype=1??min-max-price-dd|optiontype=2??minutes-dd|price-percent-dd|numeric-textbox" //*
        },
        {
            "id": "606b0300-49e9-4b69-8551-0da61ff3510a",
            "categoryName": "Listede buybox yoksa",
            "categoryDescription": "",
            "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
            "typeName": "Selection",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 18,
            smartPricerTypeSubs: [
                {
                    "id": "bb55227c-2dfd-4dd0-861f-77e8f5adfbe2",
                    "typeSubName": "En düşük fiyatta kal",
                    "isSelected": false,
                    "optionType": 0
                },
                {
                    "id": "9b9929de-52c2-4a09-bef6-5d046ae67acc",
                    "typeSubName": "Fiyatı belirle",
                    "isSelected": false,
                    "optionType": 1
                },
                {
                    "id": "bc35de54-1d28-493a-abf4-b92e50fa0439",
                    "typeSubName": "Repricer Yapma",
                    "isSelected": false,
                    "optionType": 0
                },
                {
                    "id": "6968f6d5-11ca-4f62-975e-a0d5c5c5483b",
                    "typeSubName": "Maksimum fiyat kullan",
                    "isSelected": false,
                    "optionType": 0
                },
                {
                    "id": "0fd0f016-207b-4d75-99e9-960e7295e404",
                    "typeSubName": "Minumum fiyat kullan",
                    "isSelected": false,
                    "optionType": 0
                }
            ],
            "activeValueInputFormat": "min-max-price-dd|price-percent-dd|numeric-textbox" //*
        },
        {
            "id": "0dbb6a6d-6dad-42d0-bb63-409cb67451cf", // bu soruyu kaldıracakmışız ozan söyledi
            "categoryName": "Satış hızına göre karar verilsin mi?",
            "categoryDescription": "",
            "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
            "typeName": "Calculation",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 19,
            smartPricerTypeSubs: [
                {
                    "id": "113f9033-0235-45d3-ad5a-899993300b89",
                    "typeSubName": "Zaman",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "1114496d-563e-4e21-8dd3-7a30ff3932d9",
                    "typeSubName": "Adet",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "afc964dd-ec0c-4af1-98c6-5c1f64ffa8d4",
                    "typeSubName": "Fiyatlandır",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "d2e44f72-89a1-4678-907c-5bc30da97f70",
                    "typeSubName": "Daha çok",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "3fcc4dfb-e6fa-4241-acec-38b78d2d1bf2",
                    "typeSubName": "Daha az",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "ee37e392-155e-46ef-bcc9-c153cc495262",
                    "typeSubName": "Aktif",
                    "isSelected": false,
                    "optionType": 0//*
                },
                {
                    "id": "7e35338e-8ca3-4c30-a6d9-98e55f457115",
                    "typeSubName": "Pasif",
                    "isSelected": false,
                    "optionType": 0//*
                }
            ],
            "activeValueInputFormat": ""
        },
        {
            "id": "f6a3fb5e-c746-4d48-918b-e31c87583f2e",
            "categoryName": "Buybox'ı kazandığında",
            "categoryDescription": "",
            "parentId": "f02acf99-244d-44e3-a272-90594f417024",
            "typeName": "Calculation",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": null,
            "sortOrder": 21,
            smartPricerTypeSubs: [
                {
                    "id": "485c105e-6433-48ae-9ba1-abc9d1998efc",
                    "typeSubName": "Repricer yapma",
                    "isSelected": false,
                    "optionType": 0
                },
                {
                    "id": "155cf188-a4a5-4ef9-98a5-4f468e9b5e24",
                    "typeSubName": "Fiyatı düşür",
                    "isSelected": false,
                    "optionType": 0
                },
                {
                    "id": "df8780ad-51fe-4fa5-ab90-2bb99a551cf1",
                    "typeSubName": "Fiyatı arttır kendi fiyatına göre (Senden bir sonraki)",
                    "isSelected": false,
                    "optionType": 2
                },
                {
                    "id": "230fc8e0-c75b-4607-8b77-2f5c3bed00be",
                    "typeSubName": "Rakibe göre fiyat arttır (en pahallıya göre)",
                    "isSelected": false,
                    "optionType": 1
                }
            ],
            "activeValueInputFormat": "optiontype=2??minutes-dd|price-percent-dd|numeric-textbox" //*
        },
        {
            "id": "66a19ce8-0913-48dd-a027-6aaf80354285",
            "categoryName": "Min. Kâr - Getiri",
            "categoryDescription": "",
            "parentId": "9fe81f48-ee29-4d68-80a0-4ae8158e902a",
            "typeName": "Calculation",       //*
            "inputType": "TextBox-Numeric", //*
            "answerId": null,
            "answerText": null,
            "sortOrder": 23,
            "smartPricerTypeSubs": [],
            "activeValueInputFormat": ""
        },
        {
            "id": "66a19ce8-0913-48dd-a027-6aaf80354285",
            "categoryName": "Max. Kâr - Getiri",
            "categoryDescription": "",
            "parentId": "9fe81f48-ee29-4d68-80a0-4ae8158e902a",
            "typeName": "Calculation",       //*
            "inputType": "TextBox-Numeric", //*
            "answerId": null,
            "answerText": null,
            "sortOrder": 24,
            "smartPricerTypeSubs": [],
            "activeValueInputFormat": ""
        },

        // {
        //     "id": "x6a3fb5e-c746-4d48-918b-e31c87583f2e",
        //     "categoryName": "deneme",
        //     "categoryDescription": "",
        //     "parentId": "f02acf99-244d-44e3-a272-90594f417024",
        //     "typeName": "Calculation",
        //     "inputType": "DropdownBox",
        //     "answerId": null,
        //     "answerText": null,
        //     "sortOrder": 21,
        //     smartPricerTypeSubs: [
        //         {
        //             "id": "485c105e-6433-48ae-9ba1-abc9d1998efc",
        //             "typeSubName": "Repricer yapma",
        //             "isSelected": false,
        //             "optionType": 0
        //         },
        //         {
        //             "id": "155cf188-a4a5-4ef9-98a5-4f468e9b5e24",
        //             "typeSubName": "Fiyatı düşür",
        //             "isSelected": false,
        //             "optionType": 1
        //         },
        //         {
        //             "id": "df8780ad-51fe-4fa5-ab90-2bb99a551cf1",
        //             "typeSubName": "Fiyatı arttır kendi fiyatına göre (Senden bir sonraki)",
        //             "isSelected": false,
        //             "optionType": 2
        //         }
        //     ],
        //     "activeValueInputFormat": "text=Minutes|optiontype=1??minutes-dd|optiontype=2??minutes-dd|\n|optiontype=2??price-percent-dd|optiontype=2??numeric-textbox" //*
        // },



        {
            "id": "w6a3fb5e-c746-4d48-918b-e31c87583f2e",
            "categoryName": "deneme",
            "categoryDescription": "",
            "parentId": "f02acf99-244d-44e3-a272-90594f417024",
            "typeName": "Calculation",
            "inputType": "DropdownBox",
            "answerId": null,
            "answerText": "cad|12",
            "sortOrder": 21,
            smartPricerTypeSubs: [
                {
                    "id": "485c105e-6433-48ae-9ba1-abc9d1998efc",
                    "typeSubName": "hi.birşey yapam",
                    "isSelected": false,
                    "optionType": 0
                },
                {
                    "id": "155cf188-a4a5-4ef9-98a5-4f468e9b5e24",
                    "typeSubName": "Fiyatı düşür",
                    "isSelected": false,
                    "optionType": 1
                },
                {
                    "id": "s55cf188-a4a5-4ef9-98a5-4f468e9b5e24",
                    "typeSubName": "Fiyatı düşür",
                    "isSelected": false,
                    "optionType": 2
                }
            ],
            "activeValueInputFormat": "optiontype=1??minutes-dd|optiontype=2??price-percent-dd|optiontype=2??numeric-textbox" //*
        },


    ]

}


export const orderDetailSaleSummaryMockData = {
    currencyCode: "usd",
    price: 39.87,
    shippingCost: 11.45,
    cost: 23.32,
    sellerDopingShippingCost: 15.22,
    importTaxDuty: 0,
    amazonFee: 10.5,
    feeRefund: 0,
    tax: 36.6,
    earning: 42.98,
    profit: 19.2
}


export const ordersMockData = [
    {
        "id": "60a6327c-a56e-441d-968b-37b4a7fe269c",
        "customerMainSellerId": "df792f62-47d2-44b9-8945-1f292fbd25a0",
        "buyerEmail": "776649sybj2nwhk@marketplace.amazon.ca",
        "buyerName": null,
        "buyerCounty": null,
        "buyerTaxingRegion": null,
        "buyerCompanyLegalName": null,
        "purchaseOrderNumber": null,
        "orderNumber": "702-7912556-1088208",
        "earliestDeliveryDate": "2024-07-12T07:00:00",
        "earliestShipDate": "2024-07-08T07:00:00",
        "salesChannel": "Amazon.ca",
        "orderStatus": "PartiallyShipped",
        "numberOfItemsShipped": 0,
        "orderType": "StandardOrder",
        "isPremiumOrder": false,
        "isPrime": false,
        "fulfillmentChannel": "MFN",
        "numberOfItemsUnshipped": 1,
        "hasRegulatedItems": false,
        "isReplacementOrder": false,
        "isSoldByAB": false,
        "latestShipDate": "2024-07-09T06:59:59",
        "shipServiceLevel": "Std CA D2D Dom",
        "isISPU": false,
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "latestDeliveryDate": "2024-07-19T06:59:59",
        "purchaseDate": "2024-07-08T01:02:42",
        "isAccessPointOrder": false,
        "paymentMethod": "Other",
        "isBusinessOrder": false,
        "ordertotalCurrencyCode": "CAD",
        "ordertotalAmount": 82.14,
        "isGlobalExpressEnabled": false,
        "lastUpdateDate": "2024-07-08T01:33:34",
        "shipmentServiceLevelCategory": "Standard",
        "paymentMethodDetails": "Standard",
        "hasAutomatedShippingSettings": null,
        "orderShipFromLocationAddresId": "00000000-0000-0000-0000-000000000000",
        "orderShippingAddresId": "00000000-0000-0000-0000-000000000000",
        "buyerSellerOrderNumber": null,
        "buyerSellerOrderDescription": null,
        "warehouseSuitName": null,
        "sellerMailAddress": null,
        "isGift": false,
        "trackingNumber": "1ZEK63836806854099",
        "orderUrl": null,
        "isOrderable": true,
        "orderStatusId": 3,
        "products": [
            {
                "imageUrl": "https://m.media-amazon.com/images/I/61HgxLpEqJL.jpg",
                "asin": "B01N2V5M1A",
                "quantityOrdered": 1,
                "quantityShipped": 0
            },
            {
                "imageUrl": "https://m.media-amazon.com/images/I/91mQZEeg0LL.jpg",
                "asin": "B0B5ZK7V4H",
                "quantityOrdered": 1,
                "quantityShipped": 0
            },
            {
                "imageUrl": "https://m.media-amazon.com/images/I/51Od+8vYHbL.jpg",
                "asin": "B0BNQGP17C",
                "quantityOrdered": 1,
                "quantityShipped": 0
            }
        ]
    },
    {
        "id": "6384d387-2e69-481d-97be-4d36049ab886",
        "customerMainSellerId": "df792f62-47d2-44b9-8945-1f292fbd25a0",
        "buyerEmail": "p5nyhvgwk962ggv@marketplace.amazon.ca",
        "buyerName": null,
        "buyerCounty": null,
        "buyerTaxingRegion": null,
        "buyerCompanyLegalName": null,
        "purchaseOrderNumber": null,
        "orderNumber": "702-2787362-6282629",
        "earliestDeliveryDate": "2024-07-11T07:00:00",
        "earliestShipDate": "2024-07-08T07:00:00",
        "salesChannel": "Amazon.ca",
        "orderStatus": "PartiallyShipped",
        "numberOfItemsShipped": 1,
        "orderType": "StandardOrder",
        "isPremiumOrder": false,
        "isPrime": false,
        "fulfillmentChannel": "MFN",
        "numberOfItemsUnshipped": 0,
        "hasRegulatedItems": false,
        "isReplacementOrder": false,
        "isSoldByAB": false,
        "latestShipDate": "2024-07-09T06:59:59",
        "shipServiceLevel": "Std CA D2D Dom",
        "isISPU": false,
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "latestDeliveryDate": "2024-07-16T06:59:59",
        "purchaseDate": "2024-07-07T02:56:35",
        "isAccessPointOrder": false,
        "paymentMethod": "Other",
        "isBusinessOrder": false,
        "ordertotalCurrencyCode": "CAD",
        "ordertotalAmount": 73.6,
        "isGlobalExpressEnabled": false,
        "lastUpdateDate": "2024-07-07T10:17:36",
        "shipmentServiceLevelCategory": "Standard",
        "paymentMethodDetails": "Standard",
        "hasAutomatedShippingSettings": null,
        "orderShipFromLocationAddresId": "00000000-0000-0000-0000-000000000000",
        "orderShippingAddresId": "00000000-0000-0000-0000-000000000000",
        "buyerSellerOrderNumber": null,
        "buyerSellerOrderDescription": null,
        "warehouseSuitName": null,
        "sellerMailAddress": null,
        "isGift": false,
        "trackingNumber": "1ZEK63836826965431",
        "orderUrl": null,
        "isOrderable": true,
        "orderStatusId": 3,
        "products": [
            {
                "imageUrl": "https://m.media-amazon.com/images/I/91mQZEeg0LL.jpg",
                "asin": "B0B5ZK7V4H",
                "quantityOrdered": 1,
                "quantityShipped": 0
            },
            {
                "imageUrl": "https://m.media-amazon.com/images/I/51Od+8vYHbL.jpg",
                "asin": "B0BNQGP17C",
                "quantityOrdered": 1,
                "quantityShipped": 0
            }
        ]
    },
    {
        "id": "0bb44d69-4dec-4eeb-b17b-5f6998401c76",
        "customerMainSellerId": "df792f62-47d2-44b9-8945-1f292fbd25a0",
        "buyerEmail": "sxc3g6lyvm890zz@marketplace.amazon.ca",
        "buyerName": null,
        "buyerCounty": null,
        "buyerTaxingRegion": null,
        "buyerCompanyLegalName": null,
        "purchaseOrderNumber": null,
        "orderNumber": "701-7537077-8745066",
        "earliestDeliveryDate": "2024-07-11T07:00:00",
        "earliestShipDate": "2024-07-08T07:00:00",
        "salesChannel": "Amazon.ca",
        "orderStatus": "PartiallyShipped",
        "numberOfItemsShipped": 1,
        "orderType": "StandardOrder",
        "isPremiumOrder": false,
        "isPrime": false,
        "fulfillmentChannel": "MFN",
        "numberOfItemsUnshipped": 0,
        "hasRegulatedItems": false,
        "isReplacementOrder": false,
        "isSoldByAB": false,
        "latestShipDate": "2024-07-09T06:59:59",
        "shipServiceLevel": "Std CA D2D Dom",
        "isISPU": false,
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "latestDeliveryDate": "2024-07-16T06:59:59",
        "purchaseDate": "2024-07-06T00:35:11",
        "isAccessPointOrder": false,
        "paymentMethod": "Other",
        "isBusinessOrder": false,
        "ordertotalCurrencyCode": "CAD",
        "ordertotalAmount": 38.07,
        "isGlobalExpressEnabled": false,
        "lastUpdateDate": "2024-07-06T08:17:16",
        "shipmentServiceLevelCategory": "Standard",
        "paymentMethodDetails": "Standard",
        "hasAutomatedShippingSettings": null,
        "orderShipFromLocationAddresId": "00000000-0000-0000-0000-000000000000",
        "orderShippingAddresId": "00000000-0000-0000-0000-000000000000",
        "buyerSellerOrderNumber": "114-0828502-7658642",
        "buyerSellerOrderDescription": "Extension OrderNumber update",
        "warehouseSuitName": null,
        "sellerMailAddress": null,
        "isGift": false,
        "trackingNumber": ".",
        "orderUrl": null,
        "isOrderable": true,
        "orderStatusId": 3,
        "products": [
            {
                "imageUrl": "https://m.media-amazon.com/images/I/51Od+8vYHbL.jpg",
                "asin": "B0BNQGP17C",
                "quantityOrdered": 1,
                "quantityShipped": 0
            }
        ]
    }
]


export const selectedOrderDetail = {
    "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
    "imageUrl": null,
    "buyerEmail": "d7yhscc51720f2n@marketplace.amazon.ca",
    "buyerName": null,
    "buyerCounty": null,
    "buyerTaxingRegion": null,
    "buyerCompanyLegalName": null,
    "purchaseOrderNumber": null,
    "orderNumber": "702-1470893-9983455",
    "earliestDeliveryDate": "2024-05-03T10:00:00",
    "earliestShipDate": "2024-04-29T10:00:00",
    "salesChannel": "Amazon.ca",
    "orderStatus": "Unshipped",
    "numberOfItemsShipped": 0,
    "orderType": "StandardOrder",
    "isPremiumOrder": false,
    "isPrime": false,
    "fulfillmentChannel": "MFN",
    "numberOfItemsUnshipped": 1,
    "hasRegulatedItems": false,
    "isReplacementOrder": false,
    "isSoldByAB": false,
    "latestShipDate": "2024-05-01T09:59:59",
    "shipServiceLevel": "Std CA D2D Dom",
    "isISPU": false,
    "marketplaceId": "A2EUQ1WTGCTBG2",
    "latestDeliveryDate": "2024-05-11T09:59:59",
    "purchaseDate": "2024-04-26T15:37:47",
    "isAccessPointOrder": false,
    "paymentMethod": "Other",
    "isBusinessOrder": false,
    "ordertotalCurrencyCode": "CAD",
    "ordertotalAmount": 33.29,
    "isGlobalExpressEnabled": false,
    "lastUpdateDate": "2024-04-26T16:07:24",
    "shipmentServiceLevelCategory": "Standard",
    "paymentMethodDetails": "Standard",
    "hasAutomatedShippingSettings": null,
    "defaultShipFromLocationAddress": {
        "addressLine1": "Kashechewan, #15646431",
        "addressLine2": null,
        "addressLine3": null,
        "city": "Kashechewan",
        "county": null,
        "district": null,
        "stateOrRegion": "ON",
        "municipality": null,
        "postalCode": "P0L 1S0",
        "countryCode": "CA",
        "phone": null,
        "name": "canada"
    },
    "shippingAddress": {
        "addressLine1": null,
        "addressLine2": null,
        "addressLine3": null,
        "city": "Toronto",
        "county": null,
        "district": null,
        "stateOrRegion": "Ontario",
        "municipality": null,
        "postalCode": "M5G 2R2",
        "countryCode": "CA",
        "phone": null,
        "name": null
    },
    "orderDetailList": [
        {
            "orderId": "3fa09ba7-d673-4324-9554-0d442b3c57f1",
            "imageUrl": "https://m.media-amazon.com/images/I/61hH5Cl6ADL.jpg",
            "deemedResellerCategory": null,
            "asin": "B0C4P1Q638",
            "sellerSKU": "SF-CA-9470196344",
            "orderItemId": "99034490971721",
            "title": "Electric Toothbrush Holder for Bathroom, Plastic Bathroom Countertop Organizer Compartment, Vanity Organizer Tray, Countertop Organizer Box, Kids Desk Organizers and Storage",
            "quantityOrdered": 2,
            "quantityShipped": 0,
            "productInfoNumberOfItems": 5,
            "itemPriceCurrencyCode": "CAD",
            "itemPriceAmount": 19.46,
            "shippingCurrencyCode": "CAD",
            "shippingPriceAmount": 29.46,
            "itemTaxCurrencyCode": "CAD",
            "itemTaxAmount": 3.83,
            "shippingTaxAmount": null,
            "shippingTaxCurrencyCode": null,
            "shippingDiscountCurrencyCode": null,
            "shippingDiscountAmount": null,
            "shippingDiscountTaxCurrencyCode": null,
            "shippingDiscountTaxAmount": null,
            "promotionDiscountCurrencyCode": "CAD",
            "promotionDiscountAmount": 0,
            "promotionDiscountTaxCurrencyCode": "0.00",
            "promotionDiscountTaxAmount": 0,
            "codFeeCurrencyCode": null,
            "codFeeAmount": null,
            "codFeeDiscountCurrencyCode": null,
            "codFeeDiscountAmount": null,
            "isGift": false,
            "conditionNote": null,
            "conditionId": "New",
            "conditionSubtypeId": "New",
            "scheduledDeliveryStartDate": null,
            "scheduledDeliveryEndDate": null,
            "priceDesignation": null,
            "serialNumberRequired": null,
            "isTransparency": false,
            "iossNumber": null,
            "storeChainStoreId": null,
            "isBuyerRequestedCancel": false,
            "buyerCancelReason": "",
            "serialNumbers": null,
            "amazonOrderNumber": null,
            "amazonOrderDescription": null,
            "trackingNumber": null,
            "deliveryTime": null
        },
        {
            "orderId": "3fa09ba7-d673-4324-9554-0d442b3c57f1",
            "imageUrl": "https://m.media-amazon.com/images/I/619mkoZCL6L.jpg",
            "deemedResellerCategory": null,
            "asin": "B0C4P1Q638",
            "sellerSKU": "SF-CA-9470196344",
            "orderItemId": "99034490971721",
            "title": "Electric Toothbrush Holder for Bathroom, Plastic Bathroom Countertop Organizer Compartment, Vanity Organizer Tray, Countertop Organizer Box, Kids Desk Organizers and Storage",
            "quantityOrdered": 1,
            "quantityShipped": 0,
            "productInfoNumberOfItems": 5,
            "itemPriceCurrencyCode": "CAD",
            "itemPriceAmount": 29.46,
            "shippingCurrencyCode": "CAD",
            "shippingPriceAmount": 29.46,
            "itemTaxCurrencyCode": "CAD",
            "itemTaxAmount": 3.83,
            "shippingTaxAmount": null,
            "shippingTaxCurrencyCode": null,
            "shippingDiscountCurrencyCode": null,
            "shippingDiscountAmount": null,
            "shippingDiscountTaxCurrencyCode": null,
            "shippingDiscountTaxAmount": null,
            "promotionDiscountCurrencyCode": "CAD",
            "promotionDiscountAmount": 0,
            "promotionDiscountTaxCurrencyCode": "0.00",
            "promotionDiscountTaxAmount": 0,
            "codFeeCurrencyCode": null,
            "codFeeAmount": null,
            "codFeeDiscountCurrencyCode": null,
            "codFeeDiscountAmount": null,
            "isGift": false,
            "conditionNote": null,
            "conditionId": "New",
            "conditionSubtypeId": "New",
            "scheduledDeliveryStartDate": null,
            "scheduledDeliveryEndDate": null,
            "priceDesignation": null,
            "serialNumberRequired": null,
            "isTransparency": false,
            "iossNumber": null,
            "storeChainStoreId": null,
            "isBuyerRequestedCancel": false,
            "buyerCancelReason": "",
            "serialNumbers": null,
            "amazonOrderNumber": null,
            "amazonOrderDescription": null,
            "trackingNumber": null,
            "deliveryTime": null
        },
        {
            "orderId": "3fa09ba7-d673-4324-9554-0d442b3c57f1",
            "imageUrl": "https://m.media-amazon.com/images/I/51iUdYZKzFL.jpg",
            "deemedResellerCategory": null,
            "asin": "B0C4P1Q638",
            "sellerSKU": "SF-CA-9470196344",
            "orderItemId": "99034490971721",
            "title": "Electric Toothbrush Holder for Bathroom, Plastic Bathroom Countertop Organizer Compartment, Vanity Organizer Tray, Countertop Organizer Box, Kids Desk Organizers and Storage",
            "quantityOrdered": 1,
            "quantityShipped": 0,
            "productInfoNumberOfItems": 5,
            "itemPriceCurrencyCode": "CAD",
            "itemPriceAmount": 29.46,
            "shippingCurrencyCode": "CAD",
            "shippingPriceAmount": 29.46,
            "itemTaxCurrencyCode": "CAD",
            "itemTaxAmount": 3.83,
            "shippingTaxAmount": null,
            "shippingTaxCurrencyCode": null,
            "shippingDiscountCurrencyCode": null,
            "shippingDiscountAmount": null,
            "shippingDiscountTaxCurrencyCode": null,
            "shippingDiscountTaxAmount": null,
            "promotionDiscountCurrencyCode": "CAD",
            "promotionDiscountAmount": 0,
            "promotionDiscountTaxCurrencyCode": "0.00",
            "promotionDiscountTaxAmount": 0,
            "codFeeCurrencyCode": null,
            "codFeeAmount": null,
            "codFeeDiscountCurrencyCode": null,
            "codFeeDiscountAmount": null,
            "isGift": false,
            "conditionNote": null,
            "conditionId": "New",
            "conditionSubtypeId": "New",
            "scheduledDeliveryStartDate": null,
            "scheduledDeliveryEndDate": null,
            "priceDesignation": null,
            "serialNumberRequired": null,
            "isTransparency": false,
            "iossNumber": null,
            "storeChainStoreId": null,
            "isBuyerRequestedCancel": false,
            "buyerCancelReason": "",
            "serialNumbers": null,
            "amazonOrderNumber": null,
            "amazonOrderDescription": null,
            "trackingNumber": null,
            "deliveryTime": null
        },
        {
            "orderId": "3fa09ba7-d673-4324-9554-0d442b3c57f1",
            "imageUrl": "https://m.media-amazon.com/images/I/51iUdYZKzFL.jpg",
            "deemedResellerCategory": null,
            "asin": "B0C4P1Q638",
            "sellerSKU": "SF-CA-9470196344",
            "orderItemId": "99034490971721",
            "title": "Electric Toothbrush Holder for Bathroom, Plastic Bathroom Countertop Organizer Compartment, Vanity Organizer Tray, Countertop Organizer Box, Kids Desk Organizers and Storage",
            "quantityOrdered": 1,
            "quantityShipped": 0,
            "productInfoNumberOfItems": 5,
            "itemPriceCurrencyCode": "CAD",
            "itemPriceAmount": 29.46,
            "shippingCurrencyCode": "CAD",
            "shippingPriceAmount": 29.46,
            "itemTaxCurrencyCode": "CAD",
            "itemTaxAmount": 3.83,
            "shippingTaxAmount": null,
            "shippingTaxCurrencyCode": null,
            "shippingDiscountCurrencyCode": null,
            "shippingDiscountAmount": null,
            "shippingDiscountTaxCurrencyCode": null,
            "shippingDiscountTaxAmount": null,
            "promotionDiscountCurrencyCode": "CAD",
            "promotionDiscountAmount": 0,
            "promotionDiscountTaxCurrencyCode": "0.00",
            "promotionDiscountTaxAmount": 0,
            "codFeeCurrencyCode": null,
            "codFeeAmount": null,
            "codFeeDiscountCurrencyCode": null,
            "codFeeDiscountAmount": null,
            "isGift": false,
            "conditionNote": null,
            "conditionId": "New",
            "conditionSubtypeId": "New",
            "scheduledDeliveryStartDate": null,
            "scheduledDeliveryEndDate": null,
            "priceDesignation": null,
            "serialNumberRequired": null,
            "isTransparency": false,
            "iossNumber": null,
            "storeChainStoreId": null,
            "isBuyerRequestedCancel": false,
            "buyerCancelReason": "",
            "serialNumbers": null,
            "amazonOrderNumber": null,
            "amazonOrderDescription": null,
            "trackingNumber": null,
            "deliveryTime": null
        },
        {
            "orderId": "3fa09ba7-d673-4324-9554-0d442b3c57f1",
            "imageUrl": "https://m.media-amazon.com/images/I/51iUdYZKzFL.jpg",
            "deemedResellerCategory": null,
            "asin": "B0C4P1Q638",
            "sellerSKU": "SF-CA-9470196344",
            "orderItemId": "99034490971721",
            "title": "Electric Toothbrush Holder for Bathroom, Plastic Bathroom Countertop Organizer Compartment, Vanity Organizer Tray, Countertop Organizer Box, Kids Desk Organizers and Storage",
            "quantityOrdered": 1,
            "quantityShipped": 0,
            "productInfoNumberOfItems": 5,
            "itemPriceCurrencyCode": "CAD",
            "itemPriceAmount": 29.46,
            "shippingCurrencyCode": "CAD",
            "shippingPriceAmount": 29.46,
            "itemTaxCurrencyCode": "CAD",
            "itemTaxAmount": 3.83,
            "shippingTaxAmount": null,
            "shippingTaxCurrencyCode": null,
            "shippingDiscountCurrencyCode": null,
            "shippingDiscountAmount": null,
            "shippingDiscountTaxCurrencyCode": null,
            "shippingDiscountTaxAmount": null,
            "promotionDiscountCurrencyCode": "CAD",
            "promotionDiscountAmount": 0,
            "promotionDiscountTaxCurrencyCode": "0.00",
            "promotionDiscountTaxAmount": 0,
            "codFeeCurrencyCode": null,
            "codFeeAmount": null,
            "codFeeDiscountCurrencyCode": null,
            "codFeeDiscountAmount": null,
            "isGift": false,
            "conditionNote": null,
            "conditionId": "New",
            "conditionSubtypeId": "New",
            "scheduledDeliveryStartDate": null,
            "scheduledDeliveryEndDate": null,
            "priceDesignation": null,
            "serialNumberRequired": null,
            "isTransparency": false,
            "iossNumber": null,
            "storeChainStoreId": null,
            "isBuyerRequestedCancel": false,
            "buyerCancelReason": "",
            "serialNumbers": null,
            "amazonOrderNumber": null,
            "amazonOrderDescription": null,
            "trackingNumber": null,
            "deliveryTime": null
        }
    ]
}

export const trackingStatusMockData = [
    {
        "customerMainSellerId": "0",
        "buyerSellerOrderStatus": "DELIVERED TO WAREHOUSE",
        "buyerSellerOrderNumber": "",
        "updatedDate": "2024-06-23T14:36:11Z",
        "trackingNumber": "",
        "carrierName": "",
        "groupName": "0"
    },
    {
        "customerMainSellerId": "1",
        "buyerSellerOrderStatus": "SHIPPED",
        "buyerSellerOrderNumber": "",
        "updatedDate": "2024-07-27T17:04:50Z",
        "trackingNumber": "PBXSA045337935",
        "carrierName": "Pitney Bowes",
        "groupName": "1"
    },
    {
        "customerMainSellerId": "2",
        "buyerSellerOrderStatus": "DELIVERED",
        "buyerSellerOrderNumber": "",
        "updatedDate": "2024-07-28T08:11:23Z",
        "trackingNumber": "PBXSA045337935",
        "carrierName": "Pitney Bowes",
        "groupName": "2"
    }
]


export const warehousesMockData = [
    {
        "id": "84ddd5ae-12fd-4e9d-a937-3bd4b0bdf070",
        "marketId": "d3d6f74a-3b6f-4260-9866-77d58b03742f",
        "wareHouseName": "shipping garage ",
        "addressLine1": "",
        "addressLine2": "",
        "addressLine3": "",
        "city": "",
        "district": "",
        "stateOrRegion": "",
        "municipality": "",
        "postalCode": "",
        "phone": "",
        "wareHouseShortName": "shipping garage",
        "sellMarginDefault": "",
        "logo": "",
        "isDefault": false
    },
    {
        "id": "318abb46-2208-48ba-b9a2-243208575566",
        "marketId": "d3d6f74a-3b6f-4260-9866-77d58b03742f",
        "wareHouseName": "Depo",
        "addressLine1": "",
        "addressLine2": "",
        "addressLine3": "",
        "city": "",
        "district": "",
        "stateOrRegion": "",
        "municipality": "",
        "postalCode": "",
        "phone": "",
        "wareHouseShortName": "DP",
        "sellMarginDefault": "",
        "logo": "",
        "isDefault": false
    },
    {
        "id": "d3b1f7d5-b760-4378-932b-93bf1df1f744",
        "marketId": "d3d6f74a-3b6f-4260-9866-77d58b03742f",
        "wareHouseName": "New Warehouse",
        "addressLine1": "",
        "addressLine2": "",
        "addressLine3": "",
        "city": "",
        "district": "",
        "stateOrRegion": "",
        "municipality": "",
        "postalCode": "",
        "phone": "",
        "wareHouseShortName": "NW",
        "sellMarginDefault": "",
        "logo": "",
        "isDefault": false
    },
    {
        "id": "e9e5939e-7ce4-4412-b8bc-2e6160252d06",
        "marketId": "d3d6f74a-3b6f-4260-9866-77d58b03742f",
        "wareHouseName": "us depo",
        "addressLine1": "3011 Exchange CTS Suite # 105",
        "addressLine2": "",
        "addressLine3": "",
        "city": "West Palm Beach",
        "district": "Florida",
        "stateOrRegion": "US",
        "municipality": "",
        "postalCode": "33409",
        "phone": "+1 561 909 7346",
        "wareHouseShortName": "SW.",
        "sellMarginDefault": "",
        "logo": "",
        "isDefault": false
    },
    {
        "id": "dd84265f-9866-4ed0-b6c1-d3d8a3d886e1",
        "marketId": "d3d6f74a-3b6f-4260-9866-77d58b03742f",
        "wareHouseName": "UPS",
        "addressLine1": "aaaa",
        "addressLine2": "aaaa",
        "addressLine3": "ssss",
        "city": "aasas",
        "district": "sasa",
        "stateOrRegion": "AX",
        "municipality": "asas",
        "postalCode": "asas",
        "phone": "asas",
        "wareHouseShortName": "UPS",
        "sellMarginDefault": "asas",
        "logo": "",
        "isDefault": true
    },
    {
        "id": "dc5f789b-c44d-4879-81b8-691ade6f3545",
        "marketId": "6fdac382-b16f-4705-b4c3-1f1c9b4505f4",
        "wareHouseName": "Amazon",
        "addressLine1": "",
        "addressLine2": "",
        "addressLine3": "",
        "city": "",
        "district": "",
        "stateOrRegion": "",
        "municipality": "",
        "postalCode": "",
        "phone": "",
        "wareHouseShortName": "Amz.",
        "sellMarginDefault": "",
        "logo": "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg",
        "isDefault": false
    },
    {
        "id": "2b86b634-4040-4ec4-a0f5-077f7dd3a41c",
        "marketId": "6fdac382-b16f-4705-b4c3-1f1c9b4505f4",
        "wareHouseName": "Fedex",
        "addressLine1": null,
        "addressLine2": null,
        "addressLine3": null,
        "city": null,
        "district": null,
        "stateOrRegion": null,
        "municipality": null,
        "postalCode": null,
        "phone": "",
        "wareHouseShortName": null,
        "sellMarginDefault": null,
        "logo": "",
        "isDefault": true
    }
]


export const smartpricerStrategiesMockData = [
    {
        "id": "538a64ab-05cb-4449-a2b9-89df2049f424",
        "smartPricerName": "asin bazli",
        "smartPricerStrategy": 2,
        "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
        "productCategoryId": null,
        "asins": [
            "B088B7Z4VB"
        ],
        "isBuyBoxGroup": false,
        "isLowestGroup": false,
        "isDefault": false
    },
    {
        "id": "7dfd8141-7260-4c83-afe0-7f86ccd338f1",
        "smartPricerName": "buybox",
        "smartPricerStrategy": 0,
        "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
        "productCategoryId": null,
        "asins": [
            "B0BX3X5WM2"
        ],
        "isBuyBoxGroup": true,
        "isLowestGroup": false,
        "isDefault": false

    },
    {
        "id": "a6e85415-530e-4c3d-9f75-37a53d7643c9",
        "smartPricerName": "Lowest",
        "smartPricerStrategy": 0,
        "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
        "productCategoryId": null,
        "asins": [],
        "isBuyBoxGroup": false,
        "isLowestGroup": true,
        "isDefault": true
    },
    {
        "id": "b6451822-a08f-4b2b-89cd-8944fadd7ef0",
        "smartPricerName": "Video Games Strategy",
        "smartPricerStrategy": 1,
        "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
        "productCategoryId": "10ab7871-c5d8-4bc3-96e1-a4cafb92d0d8",
        "asins": [],
        "isBuyBoxGroup": false,
        "isLowestGroup": false,
        "isDefault": false
    }
]


export const copyrightBrandsMockData = [
    {
        "brandName": "NIKE S.R.L.",
        "serialNumber": "79376276",
        "trademarkCountry": "USA",
        "codes": ""
    },
    {
        "brandName": "NIKE, INC.",
        "serialNumber": "85669743",
        "trademarkCountry": "USA",
        "codes": ""
    },
    {
        "brandName": "JPMORGAN CHASE BANK, N.A.",
        "serialNumber": "86430379",
        "trademarkCountry": "USA",
        "codes": ""
    },
    {
        "brandName": "ALTER DOMUS (US) LLC, AS COLLATERAL AGENT",
        "serialNumber": "73473643",
        "trademarkCountry": "USA",
        "codes": ""
    },
    {
        "brandName": "CHEN, ZHENMU",
        "serialNumber": "90421746",
        "trademarkCountry": "USA",
        "codes": ""
    },
    {
        "brandName": "BAUER HOCKEY, LLC",
        "serialNumber": "88904770",
        "trademarkCountry": "USA",
        "codes": ""
    },
    {
        "brandName": "SHENZHEN NICAI TECHNOLOGY CO.,LTD.",
        "serialNumber": "88347805",
        "trademarkCountry": "USA",
        "codes": ""
    },
    {
        "brandName": "SNKSTR LLC",
        "serialNumber": "88676035",
        "trademarkCountry": "USA",
        "codes": ""
    }
]


export const orderGraphichsMockData = {
    "xAxis": [
        {
            "data": "07/25/2024 00:00:00",
            "dataType": "DateTime"
        }
    ],
    "yAxis": [
        {
            "data": "1",
            "dataType": "Int32"
        }
    ]
}


export const paymentPageMockData = [
    {
        "id": "9db5dc8d-ee16-450b-af8e-7120bda03bb4",
        "planName": "Extra Sub User +1",
        "productCount": 0,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 100,
        "discount": 0,
        "userCount": 1,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-15T07:39:25.838255",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T12:22:57.191649"
    },
    {
        "id": "f5bc8f95-e76c-4e08-9f3a-b2deac3e1d1e",
        "planName": "Add Drop Amazon to Amazon Option",
        "productCount": 0,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 250,
        "discount": 0,
        "userCount": 0,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": true,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "1a016f57-74d0-49a4-ab56-2138d7501a7e,c12cc666-a4b3-4ebe-b777-db9e1503f353",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-15T07:36:08.443305",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T12:23:14.006703"
    },
    {
        "id": "55027759-29e3-4fa5-8901-c83d2b532bdd",
        "planName": "Add SmartPricer",
        "productCount": 0,
        "isFBA": false,
        "isSmartPricer": true,
        "isWarehouse": false,
        "price": 500,
        "discount": 25,
        "userCount": 0,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "1a016f57-74d0-49a4-ab56-2138d7501a7e,c12cc666-a4b3-4ebe-b777-db9e1503f353,2aa876c7-35a2-4bd0-84a4-619db6da8efd",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-15T07:32:53.237887",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T12:23:31.344266"
    },
    {
        "id": "5e09f862-7a60-4e49-bd16-032038255257",
        "planName": "Extra Inventory Storage 1",
        "productCount": 1000,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 200,
        "discount": 0,
        "userCount": 0,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-11T16:58:39.066297",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T07:31:29.23982",
        "couponList": [
            {
                "id": "344f11c1-62ac-4846-a71b-5620452fbd51",
                "couponName": "Coupon 1",
                "couponCode": "SD10000000",
                "discountRate": 10,
                "maxUseCount": 10,
                "startDate": "2024-08-01T00:00:00",
                "endDate": "2024-08-31T00:00:00",
                "multipleUseCount": 1,
                "maxDiscountPrice": 300,
                "minPackagePrice": 1000,
                "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
                "createdDate": "2024-08-12T11:56:49.222821",
                "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
                "updatedDate": "2024-08-12T12:09:32.278055"
            }
        ]
    },
    {
        "id": "1a016f57-74d0-49a4-ab56-2138d7501a7e",
        "planName": "Free",
        "productCount": 1000,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 0,
        "discount": 0,
        "userCount": 1,
        "sellerCount": 1,
        "isDisable": false,
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
        "mainPlan": true,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 100,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-11T15:39:52.259847",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-14T10:04:30.765556"
    },
    {
        "id": "93f57d8d-3130-4cf8-b033-51f887d280aa",
        "planName": "Extra Inventory Storage 2",
        "productCount": 1500,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 300,
        "discount": 0,
        "userCount": 0,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-11T17:05:04.685121",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T07:31:52.876747"
    },
    {
        "id": "985f7911-904b-4a56-8b3d-f3333a71dbaf",
        "planName": "Add Warehouse Option",
        "productCount": 0,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": true,
        "price": 150,
        "discount": 0,
        "userCount": 0,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "1a016f57-74d0-49a4-ab56-2138d7501a7e,c12cc666-a4b3-4ebe-b777-db9e1503f353,2aa876c7-35a2-4bd0-84a4-619db6da8efd",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-15T07:34:17.763214",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T12:26:24.779758"
    },
    {
        "id": "c12cc666-a4b3-4ebe-b777-db9e1503f353",
        "planName": "Second Plan",
        "productCount": 2000,
        "isFBA": true,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 200,
        "discount": 10,
        "userCount": 2,
        "sellerCount": 2,
        "isDisable": false,
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
        "mainPlan": true,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 200,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-11T16:52:08.579894",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-14T12:42:42.429976"
    },
    {
        "id": "2aa876c7-35a2-4bd0-84a4-619db6da8efd",
        "planName": "Thirth Plan",
        "productCount": 2500,
        "isFBA": true,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 450,
        "discount": 10,
        "userCount": 3,
        "sellerCount": 2,
        "isDisable": false,
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
        "mainPlan": true,
        "isDropAmazon2Amazon": true,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 500,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-11T17:02:08.653271",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-14T12:42:49.992379",
        "couponList": [
            {
                "id": "344f11c1-62ac-4846-a71b-5620452fbd51",
                "couponName": "Coupon 1",
                "couponCode": "SD10000000",
                "discountRate": 10,
                "maxUseCount": 10,
                "startDate": "2024-08-01T00:00:00",
                "endDate": "2024-08-31T00:00:00",
                "multipleUseCount": 1,
                "maxDiscountPrice": 300,
                "minPackagePrice": 1000,
                "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
                "createdDate": "2024-08-12T11:56:49.222821",
                "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
                "updatedDate": "2024-08-12T12:09:32.278055"
            },
            {
                "id": "d1ef303b-e7de-4602-9c57-da1d234caffe",
                "couponName": "Coupon 2",
                "couponCode": "SD10000001",
                "discountRate": 10,
                "maxUseCount": 5,
                "startDate": "2024-08-01T00:00:00",
                "endDate": "2024-08-31T00:00:00",
                "multipleUseCount": 1,
                "maxDiscountPrice": 200,
                "minPackagePrice": 500,
                "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
                "createdDate": "2024-08-12T12:11:33.138194",
                "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
                "updatedDate": "2024-08-12T12:11:33.138195"
            }
        ]
    },
    {
        "id": "b54c5d03-0d8d-44c3-9b6d-9f5904cb370f",
        "planName": "Fifth Plan",
        "productCount": 6000,
        "isFBA": true,
        "isSmartPricer": true,
        "isWarehouse": true,
        "price": 1000,
        "discount": 10,
        "userCount": 5,
        "sellerCount": 5,
        "isDisable": true,
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
        "mainPlan": true,
        "isDropAmazon2Amazon": true,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 2000,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-14T08:19:53.931335",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-14T15:02:12.376115"
    },
    {
        "id": "7bedca47-70a5-4097-8a90-29ca935ecb92",
        "planName": "Forth Plan",
        "productCount": 4000,
        "isFBA": true,
        "isSmartPricer": true,
        "isWarehouse": true,
        "price": 750,
        "discount": 15,
        "userCount": 5,
        "sellerCount": 3,
        "isDisable": false,
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
        "mainPlan": true,
        "isDropAmazon2Amazon": true,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 1000,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-11T17:25:11.426826",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T06:47:27.73432"
    },
    {
        "id": "a4508ec0-791d-4704-85b5-594e08639144",
        "planName": "Extra Seller Count +3",
        "productCount": 0,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 500,
        "discount": 0,
        "userCount": 0,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-15T07:38:43.700618",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T07:39:57.259372"
    },
    {
        "id": "be1b078f-f600-4ace-818c-4604bcc8c6ad",
        "planName": "Extra Seller Count +2",
        "productCount": 0,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 350,
        "discount": 0,
        "userCount": 0,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-15T07:38:26.464257",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T07:40:08.016678"
    },
    {
        "id": "5f2e37a9-de0d-491c-8319-31f04caf8fc0",
        "planName": "Extra Seller Count +1",
        "productCount": 0,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 200,
        "discount": 0,
        "userCount": 0,
        "sellerCount": 1,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-15T07:38:05.78589",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T07:40:17.199345"
    },
    {
        "id": "ce829930-0996-4e5a-a26b-ccbbc6b2ba08",
        "planName": "Extra Inventory Storage 3",
        "productCount": 2000,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 400,
        "discount": 0,
        "userCount": 0,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-15T07:29:33.689778",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T07:40:36.794646"
    },
    {
        "id": "cee0f636-1d3d-46fc-8559-9fb43fb2b941",
        "planName": "Add FBA Option",
        "productCount": 0,
        "isFBA": true,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 300,
        "discount": 0,
        "userCount": 0,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "1a016f57-74d0-49a4-ab56-2138d7501a7e",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-15T07:33:22.136991",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T10:22:27.028105"
    },
    {
        "id": "1f17d748-667c-4bc6-9019-15076ed422ce",
        "planName": "Extra Sub User +2",
        "productCount": 0,
        "isFBA": false,
        "isSmartPricer": false,
        "isWarehouse": false,
        "price": 200,
        "discount": 0,
        "userCount": 2,
        "sellerCount": 0,
        "isDisable": false,
        "description": "",
        "mainPlan": false,
        "isDropAmazon2Amazon": false,
        "trail": 0,
        "isVisible": true,
        "scanningCount": 0,
        "mustPlan": "",
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-15T07:39:45.584567",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-15T12:22:44.664097"
    }
]



export const buyoutCarrierSelectionMockData = {
    "orderDetail": {
        "orderNumber": "702-9932969-9045009",
        "latestShipDate": "2024-08-20T06:59:59",
        "orderDetailList": [
            {
                "orderId": "dffb07ba-ffdc-4b6d-bd7c-0674f8c8fd3c",
                "imageUrl": "https://m.media-amazon.com/images/I/71u9S3hxANL.jpg",
                "deemedResellerCategory": null,
                "asin": "B0CJCKGRW1",
                "sellerSKU": "0708testyukleme_97564",
                "orderItemId": "107495976036801",
                "title": "The Book. The Ultimate Guide to Rebuilding a Civilization - Inspirational Science Books for Adults - Unique Artifact - Knowledge Encyclopedia with Over 400 Pages of Detailed & Catchy Illustrations [Hardcover] [2022] HUNGRY MINDS",
                "quantityOrdered": 1,
                "quantityShipped": 0,
                "productInfoNumberOfItems": 1,
                "itemPriceCurrencyCode": "CAD",
                "itemPriceAmount": 240.25,
                "shippingCurrencyCode": "CAD",
                "shippingPriceAmount": 240.25,
                "itemTaxCurrencyCode": "CAD",
                "itemTaxAmount": 12.01,
                "shippingTaxAmount": null,
                "shippingTaxCurrencyCode": null,
                "shippingDiscountCurrencyCode": null,
                "shippingDiscountAmount": null,
                "shippingDiscountTaxCurrencyCode": null,
                "shippingDiscountTaxAmount": null,
                "promotionDiscountCurrencyCode": "CAD",
                "promotionDiscountAmount": 0,
                "promotionDiscountTaxCurrencyCode": "0.00",
                "promotionDiscountTaxAmount": 0,
                "codFeeCurrencyCode": null,
                "codFeeAmount": null,
                "codFeeDiscountCurrencyCode": null,
                "codFeeDiscountAmount": null,
                "isGift": false,
                "conditionNote": null,
                "conditionId": "New",
                "conditionSubtypeId": "New",
                "scheduledDeliveryStartDate": null,
                "scheduledDeliveryEndDate": null,
                "priceDesignation": null,
                "serialNumberRequired": null,
                "isTransparency": false,
                "iossNumber": null,
                "storeChainStoreId": null,
                "isBuyerRequestedCancel": false,
                "buyerCancelReason": "",
                "serialNumbers": null,
                "trackingNumber": null,
                "deliveryTime": null,
                "isBuybox": false,
                "price": 162.85744,
                "desi": 5.4189625,
                "shippingCost": 0,
                "customsDutyPrice": 0,
                "progressPayment": 204.2125,
                "profitPercent": 6.4855156,
                "profitPrice": 12.437592,
                "amazonFeePercent": 15,
                "amazonFeePrice": 36.037502,
                "warehousePrice": 28.91746,
                "sellingPrice": 240.25
            }
        ]
    },

    "warehouseShipping": [
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": "427ffee0-e3d1-4afe-95f7-0678b52f1ad5",
            "wareHouseShippingId": "4d92ae01-8fd1-47a1-9ea7-1bf07829de17",
            "shippingId": "bf0b55ef-b819-40c6-a37f-3b0f8b4750db",
            "shippingName": "Fedex",
            "shippingLogo": "https://www.fedex.com/content/dam/fedex-com/logos/logo.png",
            "countryCode": "US",
            "mapWareHouseId": 95550,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "usps_first_class_mail",
            "isSelected": true,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-08-30T06:59:59",
            "profitPrice": 11.34,
            "profitPercent": 3.67,
            "shippingCost": 22.5,
            "shippingCostCurrencyCode": "USD"
        },
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": 10.67,
            "profitPercent": 2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
        ,
        {
            "wareHouseId": "41c1babf-b824-40ac-b68d-23149c95ce9e",
            "wareHouseName": "us depo",
            "warehouseLogo": "https://www.sellbrite.com/wp-content/uploads/ShipStation-logo-blue-int-1024x410.png",
            "wareHouseSuitId": null,
            "wareHouseShippingId": "66cebc93-3275-4690-8643-f8359f9b4d47",
            "shippingId": "a43af726-daa4-4f5f-bf48-ffd3ad9f7ec6",
            "shippingName": "Asendia",
            "shippingLogo": "",
            "countryCode": "US",
            "mapWareHouseId": 242546,
            "carrierCode": "stamps_com",
            "carrierServiceCode": "gp_plus",
            "isSelected": false,

            "earliestDeliveryDate": "2024-08-23T07:00:00",
            "latestDeliveryDate": "2024-09-02T06:59:59",
            "profitPrice": -10.67,
            "profitPercent": -2.48,
            "shippingCost": 18.5,
            "shippingCostCurrencyCode": "USD"
        }
    ]

}


export const customerPackageInfoMockData = {
    "customerMainId": "7a12c359-01f8-493a-acf1-c3c0d09a62aa",
    "customerFirstName": "eray",
    "customerLastName": "yucel",
    "totalProductCount": 1000,
    "totalUserCount": 1,
    "totalSellerCount": 1,
    "totalScanningCount": 100,
    "leftProductCount": 0,
    "leftUserCount": 0,
    "leftSellerCount": 0,
    "leftScanningCount": 0,
    "totalPrice": 1,
    "totalDiscount": 0,
    "customerPackages": [
        {
            "id": "9bd10ae6-6111-484d-833e-d98a2642ea7f",
            "planName": "Free",
            "productCount": 1000,
            "isFBA": false,
            "isSmartPricer": false,
            "isWarehouse": false,
            "price": 0,
            "discount": 0,
            "userCount": 1,
            "sellerCount": 1,
            "isDisable": false,
            "startDate": "2024-08-28T11:16:43.910546",
            "endDate": "2024-09-27T11:16:43.910195",
            "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
            "mainPlan": true,
            "isDropAmazon2Amazon": false,
            "trail": 0,
            "isVisible": true,
            "scanningCount": 100,
            "mustPlan": "",
            "couponName": "",
            "couponCode": "",
            "discountRate": 0,
            "renewal": false,
            "isPayment": true
        },
        {
            "id": "49fe7df8-0c3e-4860-8de5-e9d9767c7a57",
            "planName": "Testr",
            "productCount": 0,
            "isFBA": false,
            "isSmartPricer": true,
            "isWarehouse": false,
            "price": 1,
            "discount": 0,
            "userCount": 0,
            "sellerCount": 0,
            "isDisable": false,
            "startDate": "2024-08-28T11:16:43.910602",
            "endDate": "2024-09-27T11:16:43.910601",
            "description": "",
            "mainPlan": false,
            "isDropAmazon2Amazon": false,
            "trail": 0,
            "isVisible": true,
            "scanningCount": 0,
            "mustPlan": "1a016f57-74d0-49a4-ab56-2138d7501a7e,c12cc666-a4b3-4ebe-b777-db9e1503f353,2aa876c7-35a2-4bd0-84a4-619db6da8efd",
            "couponName": "",
            "couponCode": "",
            "discountRate": 0,
            "renewal": false,
            "isPayment": true
        }
    ]
}
