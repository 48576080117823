import $ from 'jquery';

function checkOptions() {

    let html = document.querySelector('html')

    // dark
    if (html && html.getAttribute('data-theme-color') === "dark") {
        $('#switchbtn-dark').prop('checked', true);
        $('#switchbtn-darkmenu').prop('checked', false);
        $('#switchbtn-darkheader').prop('checked', false);
        $('#switchbtn-lightmenu').prop('checked', false);
        $('#switchbtn-lightheader').prop('checked', false);
        $('#switchbtn-gradientheader').prop('checked', false);
        $('#switchbtn-gradientmenu').prop('checked', false);
        $('#switchbtn-colorheader').prop('checked', false);
        $('#switchbtn-colormenu').prop('checked', false);

    }

    // horizontal
    if (html && html.getAttribute('data-hor-style') === "hor-click") {
        $('#switchbtn-horizontal').prop('checked', true);
    }

    // horizontal-hover
    if (html && html.getAttribute('data-hor-style') === "hor-hover") {
        $('#switchbtn-horizontalHover').prop('checked', true);
    }

    //RTL 
    if (html && html.getAttribute('dir') === "rtl") {
        $('#switchbtn-rtl').prop('checked', true);
    }

    // light header 
    if (html.getAttribute('data-header-style') === "light") {
        $('#switchbtn-lightheader').prop('checked', true);
    }

    // color header 
    if (html && html.getAttribute('data-header-style') === "color") {
        $('#switchbtn-colorheader').prop('checked', true);
    }

    // gradient header 
    if (html && html.getAttribute('data-header-style') === "gradient") {
        $('#switchbtn-gradientheader').prop('checked', true);
    }

    // dark header 
    if (html && html.getAttribute('data-header-style') === "dark") {
        $('#switchbtn-darkheader').prop('checked', true);
    }

    // light menu
    if (html && html.getAttribute('data-menu-style') === 'light') {
        $('#switchbtn-lightmenu').prop('checked', true);
    }

    // color menu
    if (html && html.getAttribute('data-menu-style') === 'color') {
        $('#switchbtn-colormenu').prop('checked', true);
    }

    // gradient menu
    if (html && html.getAttribute('data-menu-style') === 'gradient') {
        $('#switchbtn-gradientmenu').prop('checked', true);
    }

    // dark menu
    if (html && html.getAttribute('data-menu-style') === 'dark') {
        $('#switchbtn-darkmenu').prop('checked', true);
    }

    //boxed 
    if (html && html.getAttribute('data-width') === 'boxed') {
        $('#switchbtn-boxed').prop('checked', true);
    }

    //scrollable 
    if (html && html.getAttribute('data-position') === 'scrollable') {
        $('#switchbtn-scrollable').prop('checked', true);
    }

    //noshadow 
    if (html && html.getAttribute('data-skins') === 'no-shadow') {
        $('#switchbtn-noshadow').prop('checked', true);
    }

    //centerlogo 
    if (html && html.getAttribute('data-logo') === 'centerlogo') {
        $('#switchbtn-centerlogo').prop('checked', true);
    }

    //vertical menus

    let verticalStyles = html.getAttribute('data-vertical-style');
    switch (verticalStyles) {
        case 'default':
            $('#switchbtn-defaultmenu').prop('checked', true);
            break;
        case 'closed':
            $('#switchbtn-closed').prop('checked', true);
            break;
        case 'icontext':
            $('#switchbtn-text').prop('checked', true);
            break;
        case 'overlay':
            $('#switchbtn-overlay').prop('checked', true);
            break;
        case 'hover':
            $('#switchbtn-hoversub').prop('checked', true);
            break;
        case 'hover1':
            $('#switchbtn-hoversub1').prop('checked', true);
            break;
        case 'doublemenu':
            $('#switchbtn-doublemenu').prop('checked', true);
            break;
        case 'doublemenu-tabs':
            $('#switchbtn-doublemenu-tabs').prop('checked', true);
            break;
        default:
            $('#switchbtn-defaultmenu').prop('checked', true);
            break;

    }
}


export function SetThemeColor(mode) {
    let html = document.querySelector('html');
    if (mode === "dark") {
        html.setAttribute('data-theme-color', 'dark');
        html.setAttribute('data-header-style', 'dark');
        html.setAttribute('data-menu-style', 'dark');
        $('#switchbtn-darkmenu').prop('checked', true);
        $('#switchbtn-darkheader').prop('checked', true);

        localStorage.setItem("dashleaddarktheme", true);
        localStorage.removeItem("dashleadlighttheme");
        localStorage.removeItem("dashleadbgColor");
        localStorage.removeItem("dashleadheaderbg");
        localStorage.removeItem("dashleadbgwhite");
        localStorage.removeItem("dashleadmenubg");

        localStorage.setItem("dashleadHeader", 'dark');
        localStorage.setItem("dashleadMenu", 'dark');

        checkOptions();

        // if (!document.body.classList.contains('auth-page')) {
        // 	let mainHeader = document.querySelector('.app-header');
        // 	mainHeader.style = "";
        // 	let appSidebar = document.querySelector('.app-sidebar');
        // 	appSidebar.style = "";
        // }
        document.querySelector('html').style = '';

    }
    else {
        html.setAttribute('data-theme-color', 'light');
        html.setAttribute('data-header-style', 'light');
        html.setAttribute('data-menu-style', 'light');
        $('#switchbtn-lightmenu').prop('checked', true);
        $('#switchbtn-lightheader').prop('checked', true);

        localStorage.setItem("dashleadlighttheme", true);
        localStorage.removeItem("dashleaddarktheme");
        localStorage.removeItem("dashleadbgColor");
        localStorage.removeItem("dashleadheaderbg");
        localStorage.removeItem("dashleadbgwhite");
        localStorage.removeItem("dashleadmenubg");
        localStorage.removeItem("dashleadtransparentBgColor");

        localStorage.setItem("dashleadHeader", 'light');
        localStorage.setItem("dashleadMenu", 'light');

        checkOptions();

        // if (!document.body.classList.contains('auth-page')) {
        // 	let mainHeader = document.querySelector('.app-header');
        // 	mainHeader.style = "";
        // 	let appSidebar = document.querySelector('.app-sidebar');
        // 	appSidebar.style = "";
        // }
        document.querySelector('html').style = '';

    }
}


export function toggleTheme() {
    let html = document.querySelector("html");
    if (html.getAttribute("data-theme-mode") === "dark") {
      html.setAttribute("data-theme-mode", "light");
      html.setAttribute("data-header-styles", "light");
      html.setAttribute("data-menu-styles", "light");
      if (!localStorage.getItem("primaryRGB")) {
        html.setAttribute("style", "");
      }
      html.removeAttribute("data-bg-theme");
      document.querySelector("#switcher-light-theme").checked = true;
      document.querySelector("#switcher-menu-light").checked = true;
      document.querySelector("#switcher-header-light").checked = true;
      document.querySelector("html").style.removeProperty("--body-bg-rgb", localStorage.bodyBgRGB);
      document.querySelector("html").style.removeProperty("--body-bg-rgb2", localStorage.bodyBgRGB);
      document.querySelector("html").style.removeProperty("--light-rgb", localStorage.bodyBgRGB);
      document.querySelector("html").style.removeProperty("--form-control-bg", localStorage.bodyBgRGB);
      document.querySelector("html").style.removeProperty("--input-border", localStorage.bodyBgRGB);
      checkOptions();
      document.querySelector("#switcher-header-light").checked = true;
      document.querySelector("#switcher-menu-light").checked = true;
      document.querySelector("#switcher-light-theme").checked = true;
      document.querySelector("#switcher-background4").checked = false;
      document.querySelector("#switcher-background3").checked = false;
      document.querySelector("#switcher-background2").checked = false;
      document.querySelector("#switcher-background1").checked = false;
      document.querySelector("#switcher-background").checked = false;
      localStorage.removeItem("sashdarktheme");
      localStorage.removeItem("sashMenu");
      localStorage.removeItem("sashHeader");
      localStorage.removeItem("bodylightRGB");
      localStorage.removeItem("bodyBgRGB");
    } else {
      html.setAttribute("data-theme-mode", "dark");
      html.setAttribute("data-header-styles", "dark");
      html.setAttribute("data-menu-styles", "dark");
      if (!localStorage.getItem("primaryRGB")) {
        html.setAttribute("style", "");
      }
      document.querySelector("#switcher-dark-theme").checked = true;
      document.querySelector("#switcher-menu-dark").checked = true;
      document.querySelector("#switcher-header-dark").checked = true;
      document.querySelector("html").style.removeProperty("--body-bg-rgb", localStorage.bodyBgRGB);
      document.querySelector("html").style.removeProperty("--body-bg-rgb2", localStorage.bodyBgRGB);
      document.querySelector("html").style.removeProperty("--light-rgb", localStorage.bodyBgRGB);
      document.querySelector("html").style.removeProperty("--form-control-bg", localStorage.bodyBgRGB);
      document.querySelector("html").style.removeProperty("--input-border", localStorage.bodyBgRGB);
      checkOptions();
      document.querySelector("#switcher-menu-dark").checked = true;
      document.querySelector("#switcher-header-dark").checked = true;
      document.querySelector("#switcher-dark-theme").checked = true;
      document.querySelector("#switcher-background4").checked = false;
      document.querySelector("#switcher-background3").checked = false;
      document.querySelector("#switcher-background2").checked = false;
      document.querySelector("#switcher-background1").checked = false;
      document.querySelector("#switcher-background").checked = false;
      localStorage.setItem("sashdarktheme", "true");
      localStorage.setItem("sashMenu", "dark");
      localStorage.setItem("sashHeader", "dark");
      localStorage.removeItem("bodylightRGB");
      localStorage.removeItem("bodyBgRGB");
    }
  }