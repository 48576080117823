import React from "react";
import Chart from "react-apexcharts";
import './chart.scss'

function DrPieChart({ chartData, options }) {

    var options = {
        series: chartData?.series || [],
        chart: {
            type: 'donut',
            height: '100%',
            toolbar: {
                show:true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                }
            }
        },
        labels: chartData?.labels || [],
        legend: {
            position: 'left',
            // formatter: function (val, opts) {
            //     return val + " - " + opts.w.globals.series[opts.seriesIndex]
            // },
            show: true,
            fontSize: '11px',
            // floating:true,
            // horizontalAlign: 'right',
            width: 'auto'
        },
        colors: chartData?.colors || ['var(--info-color)', 'var(--danger-color)', 'var(--success-color)'],
        dataLabels: {

            dropShadow: {
                enabled: false
            },
        },
        title: {
            text: 'Tamamlanan Satış',
            align: 'left',
            style: {
                fontSize: '13px',
                fontWeight: 'bold',
                color: 'var(--default-text-color)'
            },
        },

    };

    // const downloadPNG = (chartId) => {
    //     const chartInstance = ApexCharts.getChartByID(chartId);
    
    //     if (!chartInstance) {
    //         return;
    //     }
    
    //     chartInstance.exports.exportToPng();
    // }

    return (
        // <div style={{maxHeight:'230px'}}>
        <Chart
            options={options}
            series={options.series}
            type="donut"
            height="100%"
            // width="100%"
            className="apexcharts-canvas apexcharts-theme-light"
        />
        // </div>



    );
}

export default DrPieChart;

