const types = {
    CREATE_CUSTOMER_REQUESTED: "CREATE_CUSTOMER_REQUESTED",
    CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
    CREATE_CUSTOMER_FAILED: "CREATE_CUSTOMER_FAILED",

    CREATE_SUBUSER_REQUESTED: "CREATE_SUBUSER_REQUESTED",

    CREATE_EXTENSION_CUSTOMER_REQUESTED: "CREATE_EXTENSION_CUSTOMER_REQUESTED",
    CREATE_EXTENSION_CUSTOMER_SUCCESS: "CREATE_EXTENSION_CUSTOMER_SUCCESS",
    GET_EXTENSION_CUSTOMERS_REQUESTED: "GET_EXTENSION_CUSTOMERS_REQUESTED",
    GET_EXTENSION_CUSTOMERS_SUCCESS: "GET_EXTENSION_CUSTOMERS_SUCCESS"
}

export default types;

export const createCustomer = (payload) => {
    return { type: types.CREATE_CUSTOMER_REQUESTED, payload };
};

export const createCustomerFailed = (payload) => {
    return { type: types.CREATE_CUSTOMER_FAILED, payload };
};

export const createCustomerSuccess = (payload) => {
    return { type: types.CREATE_CUSTOMER_SUCCESS, payload };
};

export const createExtensionCustomer = (payload) => {
    return { type: types.CREATE_EXTENSION_CUSTOMER_REQUESTED, payload };
};

export const createExtensionCustomerSuccess = (payload) => {
    return { type: types.CREATE_EXTENSION_CUSTOMER_SUCCESS, payload };
};

export const getPreRegisteredPersonList = () => {
    return { type: types.GET_EXTENSION_CUSTOMERS_REQUESTED };
};

export const getPreRegisteredPersonListSuccess = (payload) => {
    return { type: types.GET_EXTENSION_CUSTOMERS_SUCCESS, payload };
};