import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiDelete, apiGet, apiPost, apiPut, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getShippings, getShippingsSuccess, getWarehouseHomePageDataSuccess, getWarehouseOrderInvoiceByIdSuccess, getWarehouseOrdersInvoicesSuccess, getWarehouseShippings, getWarehouseShippingsSuccess, getWarehouses, getWarehousesExcelSuccess, getWarehousesExcels, getWarehousesLostPackages, getWarehousesLostPackagesSuccess, getWarehousesSuccess, runGetWarehouseOrdersInvoicesSuccess } from '../actions/warehouses.actions';
import i18next from 'i18next';
import { store } from "../../state-management/configureStore";
import { getShipStationCarrierServices } from '../actions/shipstation.actions';
import Swal from 'sweetalert2';

function* runGetWarehouses(action) {
    const payload = action.payload;
    var path = ``;
    if (payload?.marketId) {
        path += `?marketId=${payload.marketId}`;
    }
    const response = yield call(apiGet(path, CONTROLLERS.WAREHOUSE, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield all([
            put(getWarehousesSuccess(response.data)),
            // put(getWarehouseShippings())
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error42') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runCreateWarehouse(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload.warehouseData, false, CONTROLLERS.WAREHOUSE, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehouses()),
            put(getWarehouseShippings()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info16'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error43') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateWarehouse(action) {
    const payload = action.payload;

    const response = yield call(apiPut(``, payload.warehouseData, false, CONTROLLERS.WAREHOUSE, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehouses()),
            put(getWarehouseShippings()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info16'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error44') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteWarehouse(action) {
    const payload = action.payload;
    var path = `?id=${payload}`;

    const response = yield call(apiDelete(path, CONTROLLERS.WAREHOUSE, true, true));
    if (response && response.isSuccess) {

        yield all([
            put(getWarehouses()),
            put(getWarehouseShippings()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info17'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error45') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetShippings() {
    const response = yield call(apiGet(``, CONTROLLERS.SHIPPING, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getShippingsSuccess(response.data));
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error46') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runCreateShipping(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload, false, CONTROLLERS.SHIPPING, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getShippings()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info18'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error47') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateShipping(action) {
    const payload = action.payload;

    const response = yield call(apiPut(``, payload, false, CONTROLLERS.SHIPPING, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getShippings()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info18'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error48') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteShipping(action) {
    const payload = action.payload;
    var path = `?id=${payload.shippingId}`;

    const response = yield call(apiDelete(path, CONTROLLERS.SHIPPING, true, true));
    if (response && response.isSuccess) {

        yield all([
            put(getShippings()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info19'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error49') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetWarehouseShippings(action) {
    const payload = action.payload;
    var path = ``;
    if (payload) {
        if (payload.marketId) {
            path += `?marketId=${payload.marketId}`;
        }

        if (payload.customerMainSellerId) {
            if (payload.marketId) {
                path += `&`
            }
            else {
                path += `?`
            }
            path += `customerMainSellerId=${payload.customerMainSellerId}`;
        }
    }

    const response = yield call(apiGet(path, CONTROLLERS.WAREHOUSESHIPPING, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        var promises = [];
        var carrierCodeArr = [];
        response.data.forEach(warehouseShipping => {
            warehouseShipping.shippings.forEach(shipping => {
                carrierCodeArr = carrierCodeArr.filter(x => x.code !== shipping.carrierCode).concat({ code: shipping.carrierCode })
            });
        });

        var shipstationCarrierServices = store.getState().shipstation?.shipstationCarrierServices;
        if (carrierCodeArr?.length > 0) {
            carrierCodeArr.forEach(carrierCode => {
                if (!(shipstationCarrierServices?.find(x => x.carrierCode === carrierCode))) {

                    promises.push(put(getShipStationCarrierServices(carrierCode.code)));
                    // promises.push(put(getShipStationCarrierPackages(carrierCode.code)));
                }

            });
        }
        yield all([
            ...promises,
            put(getWarehouseShippingsSuccess(response.data))

        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error50') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runCreateWarehouseShipping(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload.warehouseShippingData, false, CONTROLLERS.WAREHOUSESHIPPING, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehouseShippings({ marketId: payload?.marketId, customerMainSellerId: payload?.customerMainSellerId })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info20'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error51') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateWarehouseShipping(action) {
    const payload = action.payload;

    const response = yield call(apiPut(``, payload.warehouseShippingData, false, CONTROLLERS.WAREHOUSESHIPPING, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehouseShippings({ marketId: payload?.marketId, customerMainSellerId: payload?.customerMainSellerId })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info20'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error52') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteWarehouseShipping(action) {
    const payload = action.payload;
    var path = `?id=${payload.wareHouseShippingId}`;

    const response = yield call(apiDelete(path, CONTROLLERS.WAREHOUSESHIPPING, true, true));
    if (response && response.isSuccess) {
        yield all([
            put(getWarehouseShippings({ marketId: payload?.marketId, customerMainSellerId: payload?.customerMainSellerId })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info21'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error53') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runSaveWarehouseSuit(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload.warehouseSuitData, false, CONTROLLERS.WAREHOUSESUIT, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehouseShippings({ marketId: payload?.marketId, customerMainSellerId: payload?.warehouseSuitData.customerMainSellerId })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info22'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error54') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteWarehouseSuit(action) {
    const payload = action.payload;
    var path = `?WarehouseSuitId=${payload.warehouseSuitId}`;
    const response = yield call(apiDelete(path, CONTROLLERS.WAREHOUSESUIT, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehouseShippings({ marketId: payload?.marketId, customerMainSellerId: payload?.customerMainSellerId })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info23'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error55') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


function* runGetWarehousesExcel() {
    const response = yield call(apiGet(`/GetWarehouseExcel`, CONTROLLERS.SHIPSTATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getWarehousesExcelSuccess(response.data));
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetWarehouseExcelFile(action) {

    const payload = action.payload;

    var path = `/WarehouseExcelExport?FromMarketId=${payload.fromMarketId}&ToMarketId=${payload.toMarketId}`;

    const response = yield call(apiGet(path, CONTROLLERS.SHIPSTATION, true, true));
    if (response) {

        // yield put(getWarehousesExcelSuccess(response.data));
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runAddOrUpdateWarehouseExcel(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/AddOrUpdateWarehouseExcel`, payload, true, CONTROLLERS.SHIPSTATION, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehousesExcels()),

        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateWarehouseDefault(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/UpdateWarehouseDefault`, payload, false, CONTROLLERS.WAREHOUSE, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehouses()),

        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateWarehouseSuitDefault(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/UpdateWareHouseSuitDefault`, payload, false, CONTROLLERS.WAREHOUSESUIT, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehouseShippings({ customerMainSellerId: payload.customerMainSellerId }))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetNotFoundProducts() {
    var path = `/GetNotFoundProducts`;

    const response = yield call(apiGet(path, CONTROLLERS.WAREHOUSE, true, true));
    if (response) {
        yield put(getWarehousesLostPackagesSuccess(response.data));
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpsertNotFoundProduct(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/UpsertNotFoundProduct`, payload, false, CONTROLLERS.WAREHOUSE, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWarehousesLostPackages()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteNotFoundProduct(action) {
    const payload = action.payload;
    var path = `/DeleteNotFoundProduct?id=${payload}`;

    const response = yield call(apiDelete(path, CONTROLLERS.WAREHOUSE, true, true));
    if (response && response.isSuccess) {

        yield all([
            put(getWarehousesLostPackages()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetWarehouseHomePageData(action) {
    const payload = action.payload;
    var path = ``;

    // var path = `/GetOrderMarketId?marketId=${payload.marketId}`;
    // path += `&orderStatus=${payload.orderStatus}`;
    // path += `&createdAfter=${payload.createdAfter}`;


    var path = `/GetDashboard`;
    if (payload.marketId && payload.marketId !== "-1") {
        path += `?MarketId=${payload.marketId}`;
    }
    // if (payload.startDate) {
    //     path += `&StartDate=${payload.startDate}`;
    // }
    // if (payload.startDate) {
    //     path += `&EndDate=${payload.endDate}`;
    // }

    const response = yield call(apiGet(path, CONTROLLERS.CUSTOMERWAREHOUSE, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield all([
            put(getWarehouseHomePageDataSuccess(response.data)),
            // put(getWarehouseShippings())
        ]);
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error42') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetWarehouseOrdersInvoices(action) {
    var payload = action.payload;
    var path = `/GetWarehouseOrders?CustomerMainSellerId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.INVOICE, true, true));
    if (response) {
        yield put(getWarehouseOrdersInvoicesSuccess(response.data));
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetWarehouseOrderInvoiceById(action) {
    var payload = action.payload;
    var path = `/GetWarehouseOrderById?CustomerMainSellerId=${payload.customerMainSellerId}&OrderId=${payload.orderId}`;

    const response = yield call(apiGet(path, CONTROLLERS.INVOICE, true, true));
    if (response) {
        yield put(getWarehouseOrderInvoiceByIdSuccess(response.data));
    } else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}



export default function* warehousesSaga() {
    yield all([
        takeLatest(types.GET_WAREHOUSES_REQUESTED, safe(runGetWarehouses)),
        takeLatest(types.CREATE_WAREHOUSE_REQUESTED, safe(runCreateWarehouse)),
        takeLatest(types.UPDATE_WAREHOUSE_REQUESTED, safe(runUpdateWarehouse)),
        takeLatest(types.DELETE_WAREHOUSE_REQUESTED, safe(runDeleteWarehouse)),

        takeLatest(types.GET_SHIPPINGS_REQUESTED, safe(runGetShippings)),
        takeLatest(types.CREATE_SHIPPING_REQUESTED, safe(runCreateShipping)),
        takeLatest(types.UPDATE_SHIPPING_REQUESTED, safe(runUpdateShipping)),
        takeLatest(types.DELETE_SHIPPING_REQUESTED, safe(runDeleteShipping)),

        takeLatest(types.GET_WAREHOUSE_SHIPPINGS_REQUESTED, safe(runGetWarehouseShippings)),
        takeLatest(types.CREATE_WAREHOUSE_SHIPPING_REQUESTED, safe(runCreateWarehouseShipping)),
        takeLatest(types.UPDATE_WAREHOUSE_SHIPPING_REQUESTED, safe(runUpdateWarehouseShipping)),
        takeLatest(types.DELETE_WAREHOUSE_SHIPPING_REQUESTED, safe(runDeleteWarehouseShipping)),

        takeLatest(types.SAVE_WAREHOUSE_SUIT_REQUESTED, safe(runSaveWarehouseSuit)),
        takeLatest(types.DELETE_WAREHOUSE_SUIT_REQUESTED, safe(runDeleteWarehouseSuit)),

        takeLatest(types.GET_WAREHOUSES_EXCEL_REQUESTED, safe(runGetWarehousesExcel, types.GET_WAREHOUSES_EXCEL_FAILED)),
        takeLatest(types.SAVE_WAREHOUSE_EXCEL_REQUESTED, safe(runAddOrUpdateWarehouseExcel)),
        takeLatest(types.GET_WAREHOUSE_EXCEL_REQUESTED, safe(runGetWarehouseExcelFile)),

        takeLatest(types.UPDATE_WAREHOUSE_DEFAULT_REQUESTED, safe(runUpdateWarehouseDefault)),
        takeLatest(types.UPDATE_WAREHOUSE_SUIT_DEFAULT_REQUESTED, safe(runUpdateWarehouseSuitDefault)),
        takeLatest(types.SAVE_WAREHOUSE_LOST_PACKAGES_REQUESTED, safe(runUpsertNotFoundProduct)),
        takeLatest(types.DELETE_WAREHOUSE_LOST_PACKAGES_REQUESTED, safe(runDeleteNotFoundProduct)),
        takeLatest(types.GET_WAREHOUSE_LOST_PACKAGES_REQUESTED, safe(runGetNotFoundProducts)),
        takeLatest(types.GET_WAREHOUSE_HOME_PAGE_DATA_REQUESTED, safe(runGetWarehouseHomePageData)),
        takeLatest(types.GET_WAREHOUSE_ORDERS_INVOICES_REQUESTED, safe(runGetWarehouseOrdersInvoices)),
        takeLatest(types.GET_WAREHOUSE_ORDER_INVOICE_BY_ID_REQUESTED, safe(runGetWarehouseOrderInvoiceById)),
    ]);
}