const types = {
    GET_PRODUCT_KEEPA_DETAILS_REQUESTED: "GET_PRODUCT_KEEPA_DETAILS_REQUESTED",
    GET_PRODUCT_KEEPA_DETAILS_SUCCESS: "GET_PRODUCT_KEEPA_DETAILS_SUCCESS",
    CLEAR_KEEPA_DATA: "CLEAR_KEEPA_DATA"
}

export default types;

export const getProductKeepaDetails = (payload) => {
    return { type: types.GET_PRODUCT_KEEPA_DETAILS_REQUESTED, payload }
}

export const getProductKeepaDetailsSuccess = (payload) => {
    return { type: types.GET_PRODUCT_KEEPA_DETAILS_SUCCESS, payload };
};

export const clearAllKeepaData = () => {
    return { type: types.CLEAR_KEEPA_DATA }
}