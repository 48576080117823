import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrInput } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Field, Formik } from "formik";
import $ from 'jquery';
import { setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import i18next from 'i18next';
import { createShipping, deleteShipping, getShippings, updateShipping } from '../../state-management/actions/warehouses.actions';
import { buttonVariants, modals } from '../../utils/constants';
import { flaggedCountryOptions } from '../../components/hoc/Constants/dr-constants.component';

function ShippingsPage() {
  const { t } = useTranslation('shippingPage');
  const shippings = useSelector(state => state.warehouses.allShippings);
  const [selectedShipping, setSelectedShipping] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(withActionPromise(getShippings()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const unCheckAllSelections = () => {
    $(document.querySelectorAll('#shippingsTable tr')).removeClass("selected");
    $(document.querySelectorAll('input[type="checkbox"]:checked')).prop("checked", false);
  }

  const columns = [
    { data: "id", title: "", width: "13%", minWidth: "50px", maxWidth: "50px", searchable: false, checkall: true, checkData: "id" },
    { data: "logo", title: t("logo"), minWidth: "80px", maxWidth: "80px", searchable: false },
    { data: "shippingName", title: t("shippingName"), width: "20%", minWidth: "150px", searchable: false },
    { data: "countryCode", title: t("countryCode"), width: "10%", minWidth: "130px", searchable: false },
    { data: null, title: "", width: "20%", minWidth: "50px", maxWidth: "50px", searchable: false }
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <input type="checkbox" className="ckbox ms-3" name="name" value={rowData.id}
              onChange={(e) => {
                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
              }}
            />
          </>);
      }
    },
    {
      targets: 1,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <div className="card-aside-img cursor-pointer" >
              <div className="img-sm p-0 rounded-0 m-auto" style={{ width: '80px', height: '25px' }}>
                <img src={rowData.logo} alt="img" data-tag="pic-modal-opener"
                  onClick={(e) => {
                    e.preventDefault();
                    if (rowData.logo) {
                      dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [rowData.logo] }))
                    }
                  }}
                ></img>
              </div>
            </div>
          </>);
      }
    },
    {
      targets: 2,
      orderable: true,
      className: "text-center",
      width: "20%",
      searchable: true,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <span className="fs-12 my-auto mx-0 p-0">{rowData.shippingName}</span>
        );
      }
    },
    {
      targets: 3,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="fs-12 my-auto mx-0 p-0">{rowData.countryCode}</span><br />
          </>);
      }
    },
    {
      targets: 4,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div>
            <button className="btn btn-icon btn-danger btn-wave waves-effect waves-light shadow btn-sm" value={rowData.id}
              onClick={(e) => {
                e.preventDefault();
                handleDeleteShipping(rowData);
              }}
            >
              <i className="fe fe-trash-2" ></i>
            </button>
          </div>
        )
      }
    }
  ];

  const initialValues = {
    id: selectedShipping?.id,
    shippingName: selectedShipping?.shippingName || "",
    logo: selectedShipping?.logo || "",
    countryCode: selectedShipping?.countryCode || ""
  };

  const shippingSchema = Yup.object().shape({
    shippingName: Yup.string()
      .min(3, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Shipping name is required'),
    countryCode: Yup.string()
      .min(1, 'Too Short!')
      .max(5, 'Too Long!')
      .required('Country Code is required')

  });

  const rowClickedCallBack = async (rowData) => {
    setSelectedShipping({ ...rowData });
    // dispatch(saveCustomerInfo(customerSubUserId));
  }

  const handleDeleteShipping = async (values) => {
    dispatch(deleteShipping({ shippingId: values.id }));
  }

  const handleSubmit = async (values) => {
    var data = {
      id: values.id || null,
      shippingName: values?.shippingName || "",
      logo: values?.logo || "",
      countryCode: values?.countryCode || ""
    }

    if (data.id) {
      dispatch(updateShipping(data));
    }
    else {
      dispatch(createShipping(data));
    }
  }

  return (
    <>
      <div>
        <div className="page-header">
          <div>
            <DrBreadCrump
              breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.logisticsManagement'), navigationPath: "" },
              { navigationText: i18next.t('constants:breadCrumps.shippings'), navigationPath: "" }]} >
            </DrBreadCrump>
          </div>
        </div>
        <div className="row row-md row-lg d-flex justify-content-center">
          <div className="col-md-8 col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validateOnBlur={true}
                  onSubmit={handleSubmit}
                  validationSchema={shippingSchema}
                  enableReinitialize={true}
                  validateOnMount={true}
                >
                  {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    setTouched,
                    setFieldValue
                  }) => (
                    <form>
                      <div className={`row row-md row-lg my-1 px-3 justify-content-end`} >
                        <div className="col-auto my-auto">
                          <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                            onClick={(e) => {
                              e.preventDefault();
                              unCheckAllSelections();
                              setSelectedShipping(null);
                            }}>
                            <i className="me-2 fe fe-plus"></i>
                            {t('addNewShipping')}
                          </button>
                        </div>
                      </div>
                      <div className="row row-md row-lg px-3 justify-content-between">
                        <div className="col-auto">
                          <div className="form-group has-validation">
                            <label>{t('countryCode')}</label>
                            {/* <DrInput value={values.countryCode} className={`form-control ${(touched.countryCode && errors.countryCode) ? " is-invalid" : ""}`} placeholder={t('countryCode')} name="countryCode" type="text" onChange={handleChange}
                              onBlur={() => { setTouched({ ...touched, 'countryCode': true }) }} /> */}

                            <Field
                              onBlur={() => { setTouched({ ...touched, 'countryCode': true }) }}
                              // onChange={(value) => {
                              //   setFieldValue('countryCode', value);
                              // }}
                              className={`form-control w-fit ${(touched.countryCode && errors.countryCode) ? " is-invalid" : ""}`}
                              selectedValue={values.countryCode || ""}
                              optionClickCallBack={(value) => {
                                setFieldValue('countryCode', value);
                              }}
                              variant={buttonVariants.light}
                              name={t('countryCode')}
                              component={DrButtonDropdown}
                              placeholder={t('countryCode')}
                              options={flaggedCountryOptions} />

                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group has-validation">
                            <label>{t('shippingName')}</label>
                            <DrInput value={values.shippingName} className={`form-control`} placeholder={t('shippingName')} name="shippingName" type="text"
                              onChange={handleChange}
                              onBlur={() => { setTouched({ ...touched, 'shippingName': true, 'id': true }) }}
                              isInvalid={(touched.shippingName && errors.shippingName)}
                              isValid={touched.shippingName && !errors.shippingName}
                              validationText={errors.shippingName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-md row-lg px-3">
                        <div className="col-12 col-mg-12 col-lg-12 mt-3">
                          <div className="form-group has-validation">
                            <label>{t('logo')}</label>
                            <DrInput value={values.logo} className={`form-control`} placeholder={t('logo')} name="logo" type="text" onChange={handleChange}
                              onBlur={() => { setTouched({ ...touched, 'logo': true }) }}
                              isInvalid={(touched.logo && errors.logo)}
                              isValid={touched.logo && !errors.logo}
                              validationText={errors.logo}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-md row-lg my-1 px-3 justify-content-end">
                        <div className="col-auto my-auto">
                          <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                            onClick={(e) => {
                              e.preventDefault();
                              if (isValid) {
                                handleSubmit(values);
                              }
                            }}
                          >{t('saveChanges')}</button>
                        </div>
                      </div>
                    </form>
                  )
                  }
                </Formik>
                <div id="shippingsTable" className="row mb-5">
                  <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                      <div className="card-body horizontal-scrollable">
                        {shippings && <>
                          <DrDataTable
                            data={shippings}
                            columns={columns}
                            columnDefs={columnDefs}
                            doesHaveBorder={false}
                            headerSearch={false}
                            search={false}
                            minWidth="850px"
                            isRowClickEnabled={true}
                            rowClickedCallBack={rowClickedCallBack}
                          />
                        </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ShippingsPage;