import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getRouteByKey } from '../../../routes';
import { clearCreateStoreInformationState } from '../../../state-management/actions/create-store.actions';
import { useEffect, useState } from 'react';
import './dr-warehouse-markets-dropdown.component.scss'
import { setSelectedStore } from '../../../state-management/actions/store.actions';
import { useNavigate } from 'react-router-dom';

function DrWarehouseMarketsDropdown({

    onChange,
    disableInputChange,
    disabled,
    className,
    ...rest
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customerStores = useSelector(state => state.stores.customerStores);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const allMarkets = useSelector(state => state.markets.allMarkets);

    const { t } = useTranslation('drHeader');

    const [dropdownSource, setDropdownSource] = useState(null);

    useEffect(() => {
        if (allMarkets && customerStores && Object.keys(customerStores).length > 0) {
            setDropdownSource(allMarkets.filter(x => customerStores.some(y => y.marketSerialNumber === x.marketSerialNumber)));
        }
    }, [customerStores, allMarkets]);

    const inputChange = (marketId) => {
        if (customerStores) {
            dispatch(setSelectedStore(customerStores.find(x => x.marketId === marketId)));
        }
    }

    return (
        <>
            {/* <div className="nav-link icon align-middle d-flex align-items-center float-left" data-bs-toggle="dropdown" >
                <i className="flag flag-ca" style={{ marginRight: '10px', marginBottom: '2px' }} />
                <span className="noselect mb-2" style={{ fontSize: '15px' }}>{i18next.t('constants:countries.CA')}</span>
            </div> */}
            <div id="warehouseMarketsDropDown">
                <div className="dropdown border">
                    {!selectedStore && !dropdownSource &&
                        <div className="nav-link" style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(clearCreateStoreInformationState());
                                navigate(getRouteByKey("MARKET_SELECTION"));
                            }}>
                            <i className="w-6 me-2 fa fa-plus"></i>
                            <span className="noselect">{t('addnewcountry')}</span>
                        </div>
                    }

                    {dropdownSource &&
                        <div className="nav-link" data-bs-toggle="dropdown" style={{ cursor: 'pointer' }} >
                            <i className={`me-2 flag ${selectedStore && selectedStore.countryCode ? ("flag-" + (selectedStore.countryCode === 'UK' ? 'gb' : selectedStore.countryCode.toLowerCase())) : ""}`}></i>
                            <span className="noselect" style={{ fontSize: '13px', fontWeight: '500' }}>{selectedStore ? i18next.t(('constants:countries.' + selectedStore.countryCode)) : "Select"}</span>
                            <i className="fa fa-angle-down ms-2" />
                        </div>
                    }

                    {dropdownSource &&
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            <ul className="header-dropdown-list" >
                                {dropdownSource.map((market, index) => {
                                    let translateKey = 'constants:countries.' + market.countryCode;
                                    let flagClass = 'w-6 me-2 flag flag-' + (market.countryCode === 'UK' ? 'gb' : market.countryCode.toLowerCase());

                                    return (
                                        <li key={(index + 1)}>
                                            <div className="dropdown-item d-flex align-items-center position-relative"
                                                onClick={(e) => {
                                                    if (onChange)
                                                        onChange(market.id);
                                                    if (!disableInputChange)
                                                        inputChange(market.id);
                                                }}>
                                                <i className={flagClass} />
                                                <span className="noselect">{i18next.t(translateKey)}</span>
                                            </div>
                                        </li>
                                    )
                                })}
                                <li>
                                    <div className="dropdown-item d-flex align-items-center position-relative"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(clearCreateStoreInformationState());
                                            navigate(getRouteByKey("MARKET_SELECTION"));
                                        }}>
                                        <i className="w-6 me-2 fa fa-plus"></i>
                                        <span className="noselect">{t('addnewcountry')}</span>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    }
                </div>
            </div>


        </>
    )
}
export default DrWarehouseMarketsDropdown