import classNames from "classnames";
import MaskedInput from "react-text-mask";
function DrMaskedInput({
    field,
    mask,
    label,
    className,
    isValid,
    isInvalid,
    validationText,
    ...rest
}) {

    
    var inputClass = classNames({
        'is-invalid': isInvalid,
        'is-valid': isValid
    }, className);

    return (
        <>
            <MaskedInput
                className={inputClass}
                mask={mask}
                {...field}
                {...rest}
            />
            {isInvalid && <div type="text" className="invalid-feedback">
                {validationText}
            </div>}
        </>

    );
}
export default DrMaskedInput