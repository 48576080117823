import classNames from "classnames";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function DrPasswordInput({
    label,
    name,
    type,
    placeholder,
    className,
    value,
    showRevealButton,
    isValid,
    isInvalid,
    validationText,
    ...rest }) {

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    var inputClass = classNames({
        'is-invalid': isInvalid,
        'is-valid': isValid
    }, className);

    return (
            <div className="position-relative">
                <input
                    id={name}
                    type={showPassword ? "text" : type}
                    name={name}
                    value={value}
                    className={inputClass}
                    placeholder={placeholder}
                    onKeyDown={rest.onKeyDown}
                    {...rest}
                />
                {showRevealButton && <div className="position-absolute mt-2 me-0 pe-3 d-flex justify-items-center cursor-pointer" style={{top:'0', right:'0'}}>
                    <span onClick={toggleShowPassword}>
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                </div>}
                {isInvalid && <div type="text" className="invalid-feedback">
                {validationText}
            </div>}
            </div>
    )
}

export default DrPasswordInput;
