import { loginWithEmail } from "../../../../state-management/actions/customer.actions";
import * as Yup from 'yup';
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DrInput, DrPasswordInput } from "../../../component-index";
import { getRouteByKey } from "../../../../routes";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFish } from '@fortawesome/free-solid-svg-icons'
import { clearActiveModal, setActiveModal, withActionPromise } from "../../../../state-management/actions/app.actions";
import { modals } from "../../../../utils/constants";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

function DrLogin() {
    const { REACT_APP_ROLES_STORE, REACT_APP_ROLES_GUEST, REACT_APP_ROLES_USER } = process.env;
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { t } = useTranslation('drLogin');
    const createCustomerSnapshot = useSelector(state => state.createCustomerSnapshot);
    const customerInfo = useSelector(state => state.customer.customerInfo);

    var logoNavigateKey = classNames({
        'HOME': customerInfo?.isLoggedin && !(customerInfo?.role?.includes(REACT_APP_ROLES_USER) || customerInfo?.role?.includes(REACT_APP_ROLES_GUEST) || customerInfo?.role?.includes(REACT_APP_ROLES_STORE)),
        'LOGIN': !customerInfo?.isLoggedin,
        'MARKET_SELECTION': customerInfo?.isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_USER), // user
        'MAIN_PACKAGES': customerInfo?.isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_GUEST), //guest
        'WAREHOUSE_HOME': customerInfo?.isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_STORE) // store
    });

    const handleSubmit = async (values) => {
        Promise.all([
            dispatch(withActionPromise(clearActiveModal())),
            dispatch(withActionPromise(loginWithEmail(values)))
        ]).then((values) => {
            navigate(getRouteByKey(logoNavigateKey));
        });
    }

    const loginAccountSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Password is required')
            .matches(/^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/i, 'Your password should be a minimum of 8 characters. Should have at least one letter and one number.'),
    });

    return (
        <Formik
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize={true}
            validateOnMount={true}
            initialValues={{
                email: createCustomerSnapshot.email || '',
                password: ''
            }}
            validationSchema={loginAccountSchema}
            onSubmit={handleSubmit}>
            {({
                errors,
                values,
                touched,
                dirty,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid
            }) => (
                <div>
                    <form className="needs-validation mt-5 mb-1 me-4 ms-4">
                        <div className="form-group has-validation">
                            <label>{t('email')}</label>
                            <DrInput value={values.email} className={`form-control`} placeholder={(touched.email && errors.email) ? errors.email : t('email')} name="email" type="text" onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={(touched.email && errors.email)}
                                isValid={touched.email && !errors.email}
                                validationText={errors.email} />
                        </div>
                        <div className="form-group has-validation">
                            <label>{t('password')}</label>
                            <DrPasswordInput className={`form-control`} name="password" type="password" placeholder={t('password')} value={values.password} showRevealButton={true} onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={(touched.password && errors.password)}
                                isValid={touched.password && !errors.password}
                                validationText={errors.password} />
                        </div>
                        <div className="mt-3 text-end">
                            <FontAwesomeIcon icon={faFish} beat />
                            <span className="text-primary ms-2 cursor-pointer"
                                onClick={() => dispatch(setActiveModal({ name: modals.forgotpassword, isGlobal: true, title: i18next.t('drForgotPassword:title') }))}
                            >{t('forgotPassword')}</span>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-success btn-wave waves-effect waves-light shadow btn-block mt-4"
                                onClick={handleSubmit}
                            >{t('login')}</button>
                        </div>
                        <div className="mt-3 text-end">
                            <p className="mb-0">{t('dontHaveAccount')}
                                <span className="text-primary ms-2 cursor-pointer"
                                    onClick={() => {

                                        navigate('/login/' + "2");
                                        dispatch(clearActiveModal());
                                    }} >
                                    {t('signUp')}
                                </span>
                            </p>
                        </div>
                    </form>
                </div>
            )
            }
        </Formik>
    )
}

export default DrLogin