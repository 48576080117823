import React, { useEffect, useState } from 'react'
import { DrBreadCrump, DrButtonDropdown, DrInput, DrSelectBox, DrWarehousePricing } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { getMarketWarehouseSettings, updateCustomerStoreSettings } from '../../state-management/actions/store.actions';
import { useNavigate } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import { withActionPromise } from '../../state-management/actions/app.actions';
import i18next from 'i18next';
import { flaggedMarketOptions, handlingTimes, maxDeliveryDaysAll } from '../../components/hoc/Constants/dr-constants.component';
import { getWarehouseShippings, getWarehouses } from '../../state-management/actions/warehouses.actions';

function StorePricingPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('storePricingPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const selectedMarketWarehouseSettings = useSelector(state => state.stores.selectedMarketWarehouseSettings);
    const [warehouseMarketOption, setWarehouseMarketOption] = useState("");
    const [warehouseMarketOptions, setWarehouseMarketOptions] = useState(null);
    const allWarehouses = useSelector(state => state.warehouses.allWarehouses);
    const allWarehouseShippings = useSelector(state => state.warehouses.allWarehouseShippings);
    const [warehousesData, setWarehousesData] = useState([]);

    useEffect(() => {
        if (!allWarehouses || (allWarehouses && allWarehouses.length === 0)) {
            dispatch(withActionPromise(getWarehouses()));
        }
        if (!allWarehouseShippings || (allWarehouseShippings && allWarehouseShippings.length === 0)) {
            dispatch(withActionPromise(getWarehouseShippings({ customerMainSellerId: selectedStore.customerMainSellerId })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (allWarehouses) {
            let data = Object.assign([], allWarehouses);
            data.forEach(warehouse => {
                warehouse.warehouseShippings = Object.assign([], allWarehouseShippings && allWarehouseShippings.find(x => x.wareHouseId === warehouse.id)?.shippings);
                warehouse.customerMainSellerId = selectedStore?.customerMainSellerId;
                warehouse.wareHouseSuitId = allWarehouseShippings && allWarehouseShippings.find(x => x.wareHouseId === warehouse.id)?.wareHouseSuitId;
            });
            setWarehousesData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWarehouses, allWarehouseShippings]);

    useEffect(() => {
        if (selectedStore && warehouseMarketOption && warehouseMarketOption !== "") {
            dispatch(getMarketWarehouseSettings({
                marketId: warehouseMarketOption,
                customerMainSellerId: selectedStore?.customerMainSellerId
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseMarketOption]);

    useEffect(() => {
        if (selectedStore) {
            if (warehouseMarketOption && warehouseMarketOption !== "") {
                dispatch(withActionPromise(getMarketWarehouseSettings({
                    marketId: warehouseMarketOption,
                    customerMainSellerId: selectedStore?.customerMainSellerId
                })))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore]);

    useEffect(() => {
        if (warehousesData && warehousesData.length > 0) {
            var selectedMarketIds = [];
            warehousesData.forEach(warehouse => {
                let warehouseSelected = false;
                if (warehouse.warehouseShippings && warehouse.warehouseShippings.length > 0) {
                    warehouse.warehouseShippings.forEach(shipment => {
                        if (shipment.isSelected) {
                            warehouseSelected = true;
                        }
                    });
                    if (warehouseSelected) {
                        if (!selectedMarketIds.find(x => x.value === warehouse.marketId)) {
                            selectedMarketIds.push({ value: warehouse.marketId });
                        }
                    }
                }
            });

            let selectedWarehousesMarkets = flaggedMarketOptions.filter(function (o) {
                return selectedMarketIds.find(r => r.value === o.value);
            });

            setWarehouseMarketOptions(selectedWarehousesMarkets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehousesData]);

    const initialValues = {
        customerMainSellerID: selectedStore?.customerMainSellerId,
        marketId: selectedMarketWarehouseSettings?.marketId,
        enableSalesTaxRates: selectedMarketWarehouseSettings?.enableSalesTaxRates || false,
        aboveSalePrice: selectedMarketWarehouseSettings?.aboveSalePrice || null,
        aboveSalePriceRate: selectedMarketWarehouseSettings?.aboveSalePriceRate || null,
        aboveSalePriceOffset: selectedMarketWarehouseSettings?.aboveSalePriceOffset || null,
        belowSalePrice: selectedMarketWarehouseSettings?.belowSalePrice || null,
        belowSalePriceRate: selectedMarketWarehouseSettings?.belowSalePriceRate || null,
        belowSalePriceOffset: selectedMarketWarehouseSettings?.belowSalePriceOffset || null,
        enableCommercialInvoiceOption: selectedMarketWarehouseSettings?.enableCommercialInvoiceOption || false,
        enableOnlyFBAProducts: selectedMarketWarehouseSettings?.enableOnlyFBAProducts || false,
        enableMaxDelivery: selectedMarketWarehouseSettings?.enableMaxDelivery || false,
        maxDeliveryTime: selectedMarketWarehouseSettings?.maxDeliveryTime || null,
        enableAlternativeCarrier: selectedMarketWarehouseSettings?.enableAlternativeCarrier || false,
        enableShowProductLabelOnPackage: selectedMarketWarehouseSettings?.enableShowProductLabelOnPackage || false,
        enableShowASINOnPackage: selectedMarketWarehouseSettings?.enableShowASINOnPackage || false,
        enableHandlingTime: selectedMarketWarehouseSettings?.enableHandlingTime || false,
        handlingTime: selectedMarketWarehouseSettings?.handlingTime || null,
        enableInsurance: selectedMarketWarehouseSettings?.enableInsurance || false,
        costUnitList: selectedMarketWarehouseSettings?.costUnitList || []
        // ...selectedMarketWarehouseSettings
    }

    const handleSubmit = async (values) => {
        dispatch(updateCustomerStoreSettings(values));
    }

    const storeSettingsSchema = Yup.object().shape({

        enableSalesTaxRates: Yup.bool().required('Required'),
        aboveSalePrice: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        aboveSalePriceRate: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price rate is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        aboveSalePriceOffset: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price offset is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),

        belowSalePrice: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        belowSalePriceRate: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price rate is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        belowSalePriceOffset: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price offset is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),

        enableHandlingTime: Yup.bool().required('Required'),
        handlingTime: Yup.string().nullable().when('enableHandlingTime', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Handling Time is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        enableMaxDelivery: Yup.bool().required('Required'),
        maxDeliveryTime: Yup.string().nullable().when('enableMaxDelivery', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Max Delivery Time is required'),
            otherwise: () => Yup.string().nullable(),
        }),


        // disclaimer: Yup.boolean().oneOf([true], 'You must accept the disclaimer')
        // termsOfService: Yup.boolean()
        // .required("The terms and conditions must be accepted.")
        // .oneOf([true], "The terms and conditions must be accepted.")
    });

    const optionClickCallBack = (value) => {
        setWarehouseMarketOption(value);
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            onSubmit={handleSubmit}
            validationSchema={storeSettingsSchema}
            enableReinitialize={true}
            validateOnMount={true}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setTouched,
                setFieldValue
            }) => (

                <div>
                    <div className="page-header mb-0 mt-4 mb-2 row row-md row-lg justify-content-between w-100">
                        <div className="col-lg-auto col-md-auto col-sm-auto col-xs-12">
                            <DrBreadCrump
                                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.storePricing'), navigationPath: "" }]} >
                            </DrBreadCrump>
                        </div>
                        {warehouseMarketOptions && warehouseMarketOptions.length > 0 &&
                            <div className="col-lg-auto col-md-auto col-sm-auto col-xs-12 px-0" >
                                <DrButtonDropdown className="shadow" options={warehouseMarketOptions} optionClickCallBack={optionClickCallBack}>
                                </DrButtonDropdown>
                            </div>
                        }
                    </div>
                    <div className="row row-md row-lg d-flex justify-content-center">
                        <div className="col-md-8 col-lg-12">
                            <form>
                                <div className="row row-md row-lg justify-content-center">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                <div className="row row-md row-lg justify-content-end my-1 px-3">
                                                    <div className="col-lg-auto col-md-2">

                                                    </div>
                                                </div>

                                                {selectedStore && warehouseMarketOptions?.length > 0 &&
                                                    <section>
                                                        <div className="row row-md row-lg px-3">
                                                            <label htmlFor="salesTaxRates" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableTaxPercent')}
                                                                {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                            </label>
                                                            <div className="col-md-8 col-lg-8 mt-3">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <label className="custom-switch cursor-pointer mt-2">
                                                                            <DrInput id="salesTaxRates" value="" checked={values.enableSalesTaxRates} className="form-check-input" placeholder="" name="enableSalesTaxRates" type="checkbox"
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'enableSalesTaxRates': true }) }}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row row-md row-lg">
                                                                    <div className="col-auto pe-0 ps-0">
                                                                        <label className="ms-2 me-2 mt-2">{t('productPrice')}<i data-toggle="tooltip" title="greater than" data-placement="bottom" className="fe fe-chevron-right"></i></label>
                                                                    </div>
                                                                    <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                        <DrInput className="form-control" placeholder="" name="aboveSalePrice" type="number" step="0.01"
                                                                            value={values.aboveSalePrice || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                            onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'aboveSalePrice': true }) }}
                                                                            icon={<i className="fe fe-package"></i>}
                                                                            iconPosition="left"
                                                                            isInvalid={(touched.aboveSalePrice && errors.aboveSalePrice)}
                                                                            isValid={touched.aboveSalePrice && !errors.aboveSalePrice}
                                                                            validationText={errors.aboveSalePrice}
                                                                        />
                                                                    </div>
                                                                    <div className="col-auto pe-0 ps-0">
                                                                        <label className="ms-2 me-2 mt-2">{t('then')}</label>
                                                                    </div>
                                                                    <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                        <DrInput className="form-control" placeholder="" name="aboveSalePriceRate" type="number" step="0.01"
                                                                            value={values.aboveSalePriceRate || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                            onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'aboveSalePriceRate': true }) }}
                                                                            icon={<i className="fe fe-percent"></i>}
                                                                            iconPosition="left"
                                                                            isInvalid={(touched.aboveSalePriceRate && errors.aboveSalePriceRate)}
                                                                            isValid={touched.aboveSalePriceRate && !errors.aboveSalePriceRate}
                                                                            validationText={errors.aboveSalePriceRate}
                                                                        />
                                                                        
                                                                    </div>
                                                                    <div className="col-auto pe-0 ps-0">
                                                                        <label className="ms-2 me-2 mt-2">
                                                                            <i data-toggle="tooltip" title="greater than" data-placement="bottom" className="fe fe-plus"></i>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                        <DrInput className="form-control" placeholder="" name="aboveSalePriceOffset" type="number" step="0.01"
                                                                            value={values.aboveSalePriceOffset || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                            onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'aboveSalePriceOffset': true }) }}
                                                                            icon={<i className="fe fe-dollar-sign"></i>}
                                                                            iconPosition="left"
                                                                            isInvalid={(touched.aboveSalePriceOffset && errors.aboveSalePriceOffset)}
                                                                            isValid={touched.aboveSalePriceOffset && !errors.aboveSalePriceOffset}
                                                                            validationText={errors.aboveSalePriceOffset}
                                                                        />
                                                                        
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg">
                                                                    <div className="col-auto pe-0 ps-0">
                                                                        <label className="ms-2 me-2 mt-2">{t('productPrice')}<i data-toggle="tooltip" title="smaller than" data-placement="bottom" className="fe fe-chevron-left"></i></label>
                                                                    </div>
                                                                    <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                        <DrInput className="form-control" placeholder="" name="belowSalePrice" type="number" step="0.01"
                                                                            value={values.belowSalePrice || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                            onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'belowSalePrice': true }) }}
                                                                            icon={<i className="fe fe-package"></i>}
                                                                            iconPosition="left"
                                                                            isInvalid={(touched.belowSalePrice && errors.belowSalePrice)}
                                                                            isValid={touched.belowSalePrice && !errors.belowSalePrice}
                                                                            validationText={errors.belowSalePrice}
                                                                        />
                                                                        
                                                                    </div>
                                                                    <div className="col-auto pe-0 ps-0">
                                                                        <label className="ms-2 me-2 mt-2">{t('then')}</label>
                                                                    </div>
                                                                    <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                        <DrInput className="form-control" placeholder="" name="belowSalePriceRate" type="number" step="0.01"
                                                                            value={values.belowSalePriceRate || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                            onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'belowSalePriceRate': true }) }}
                                                                            icon={<i className="fe fe-percent"></i>}
                                                                            iconPosition="left"
                                                                            isInvalid={(touched.belowSalePriceRate && errors.belowSalePriceRate)}
                                                                            isValid={touched.belowSalePriceRate && !errors.belowSalePriceRate}
                                                                            validationText={errors.belowSalePriceRate}
                                                                        />
                                                                       
                                                                    </div>
                                                                    <div className="col-auto pe-0 ps-0">
                                                                        <label className="ms-2 me-2 mt-2">
                                                                            <i data-toggle="tooltip" title="greater than" data-placement="bottom" className="fe fe-plus"></i>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                        <DrInput className="form-control" placeholder="" name="belowSalePriceOffset" type="number" step="0.01"
                                                                            value={values.belowSalePriceOffset || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                            onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'belowSalePriceOffset': true }) }}
                                                                            icon={<i className="fe fe-dollar-sign"></i>}
                                                                            iconPosition="left"
                                                                            isInvalid={(touched.belowSalePriceOffset && errors.belowSalePriceOffset)}
                                                                            isValid={touched.belowSalePriceOffset && !errors.belowSalePriceOffset}
                                                                            validationText={errors.belowSalePriceOffset}
                                                                        />
                                                                        
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg">
                                                                    <div className="col-sm-12 pe-0 ps-2">
                                                                        <small className="custom-switch-description">{t('enableTaxPercentDescription')}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-3"></hr>
                                                        <div className="row row-md row-lg px-3">
                                                            <label htmlFor="commercialInvoiceOption" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableCommercialInvoiceOption')}
                                                                {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                            </label>
                                                            <div className="col-md-8 col-lg-8 mt-3">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <label className="custom-switch cursor-pointer mt-2">
                                                                            <DrInput id="commercialInvoiceOption" value="" checked={values.enableCommercialInvoiceOption} className="form-check-input" placeholder="" name="enableCommercialInvoiceOption" type="checkbox"
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'enableCommercialInvoiceOption': true }) }}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <small className="custom-switch-description">{t('enableCommercialInvoiceOptionDescription')}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-3"></hr>
                                                        <div className="row row-md row-lg px-3">
                                                            <label htmlFor="onlyFBAProducts" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableOnlyFBAProducts')}
                                                                {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                            </label>
                                                            <div className="col-md-8 col-lg-8 mt-3">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <label className="custom-switch cursor-pointer mt-2">
                                                                            <DrInput id="onlyFBAProducts" value="" checked={values.enableCommunalPoolAutoAddBrands} className="form-check-input" placeholder="" name="enableOnlyFBAProducts" type="checkbox"
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'enableOnlyFBAProducts': true }) }}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <small className="custom-switch-description">{t('enableOnlyFBAProductsDescription')}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-3"></hr>
                                                        <div className="row row-md row-lg px-3">
                                                            <label htmlFor="maxDeliveryInput" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableMaxDelivery')}
                                                                {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                            </label>
                                                            <div className="col-md-8 col-lg-8 mt-3">
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <label className="custom-switch cursor-pointer mt-2">
                                                                            <DrInput id="maxDeliveryInput" value="" checked={values.enableMaxDelivery} className="form-check-input" placeholder="" name="enableMaxDelivery" type="checkbox"
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'enableMaxDelivery': true }) }}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <Field onBlur={() => { setTouched({ ...touched.maxDeliveryTime, 'maxDeliveryTime': true }) }}
                                                                            name={'maxDeliveryTime'}
                                                                            component={DrSelectBox}
                                                                            placeholder={t('maxDelivery')}
                                                                            disabled={!values.enableMaxDelivery}
                                                                            options={maxDeliveryDaysAll} />
                                                                        {(touched.maxDeliveryTime && errors.maxDeliveryTimeF) && <label type="invalid" className="form-control is-invalid">
                                                                            {errors.maxDeliveryTime}
                                                                        </label>}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12 pe-0 ps-2">
                                                                        <small className="custom-switch-description">{t('enableMaxDeliveryDescription')}<br></br>
                                                                            {t('enableMaxDeliveryNote')}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-3"></hr>
                                                        <div className="row row-md row-lg px-3">
                                                            <label htmlFor="handlingTimeInput" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableHandlingTime')}
                                                                {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                            </label>
                                                            <div className="col-md-8 col-lg-8 mt-3">
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <label className="custom-switch cursor-pointer mt-2">
                                                                            <DrInput id="handlingTimeInput" value="" checked={values.enableHandlingTime} className="form-check-input" placeholder="" name="enableHandlingTime" type="checkbox"
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'enableHandlingTime': true }) }}
                                                                            />

                                                                        </label>
                                                                    </div>
                                                                    <div className="col-sm-6">
                                                                        <Field onBlur={() => { setTouched({ ...touched.handlingTime, 'handlingTime': true }) }}
                                                                            name={'handlingTime'}
                                                                            component={DrSelectBox}
                                                                            placeholder={t('handlingTime')}
                                                                            disabled={!values.enableHandlingTime}
                                                                            options={handlingTimes} />
                                                                        {(touched.handlingTime && errors.handlingTime) && <label type="invalid" className="form-control is-invalid">
                                                                            {errors.handlingTime}
                                                                        </label>}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12 pe-0 ps-2">
                                                                        <small className="custom-switch-description">{t('enableHandlingTimeDescription')}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-3"></hr>
                                                        <div className="row row-md row-lg px-3">
                                                            <label htmlFor="alternativeCarrier" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableAlternativeCarrier')}
                                                                {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                            </label>
                                                            <div className="col-md-8 col-lg-8 mt-3">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <label className="custom-switch cursor-pointer mt-2">
                                                                            <DrInput id="alternativeCarrier" value="" checked={values.enableAlternativeCarrier} className="form-check-input" placeholder="" name="enableAlternativeCarrier" type="checkbox"
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'enableAlternativeCarrier': true }) }}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <small className="custom-switch-description">{t('enableAlternativeCarrierDescription')}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-3"></hr>
                                                        <div className="row row-md row-lg px-3">
                                                            <label htmlFor="showProductLabelOnPackage" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableShowProductLabelOnPackage')}
                                                                {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                            </label>
                                                            <div className="col-md-8 col-lg-8 mt-3">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <label className="custom-switch cursor-pointer mt-2">
                                                                            <DrInput id="showProductLabelOnPackage" value="" checked={values.enableShowProductLabelOnPackage} className="form-check-input" placeholder="" name="enableShowProductLabelOnPackage" type="checkbox"
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'enableShowProductLabelOnPackage': true }) }}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <small className="custom-switch-description">{t('enableShowProductLabelOnPackageDescription')}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-3"></hr>
                                                        <div className="row row-md row-lg px-3">
                                                            <label htmlFor="showASINOnPackage" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableShowASINOnPackage')}
                                                                {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                            </label>
                                                            <div className="col-md-8 col-lg-8 mt-3">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <label className="custom-switch cursor-pointer mt-2">
                                                                            <DrInput id="showASINOnPackage" value="" checked={values.enableShowASINOnPackage} className="form-check-input" placeholder="" name="enableShowASINOnPackage" type="checkbox"
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'enableShowASINOnPackage': true }) }}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <small className="custom-switch-description">{t('enableShowASINOnPackageDescription')}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-3"></hr>
                                                        <div className="row row-md row-lg px-3">
                                                            <label htmlFor="insurance" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableInsurance')}
                                                                {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                            </label>
                                                            <div className="col-md-8 col-lg-8 mt-3">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <label className="custom-switch cursor-pointer mt-2">
                                                                            <DrInput id="insurance" value="" checked={values.enableInsurance} className="form-check-input" placeholder="" name="enableInsurance" type="checkbox"
                                                                                onChange={handleChange}
                                                                                onBlur={() => { setTouched({ ...touched, 'enableInsurance': true }) }}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <small className="custom-switch-description">{t('enableInsuranceDescription')}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-3"></hr>

                                                        <div className="row row-md row-lg">
                                                            <div className="col-sm-12">
                                                                <h3>{t('contentTitle')}</h3>
                                                            </div>
                                                        </div>

                                                        <div className="row row-md row-lg border m-1 flex-nowrap overflow-auto">
                                                            <Field target={document.body}
                                                                onBlur={() => { setTouched({ ...touched, 'costUnitList': true }) }} label={'costUnitList'} name={'costUnitList'}
                                                                component={DrWarehousePricing}
                                                            // costUnitList={values.costUnitList ? values.costUnitList : []}
                                                            />
                                                            {/* {(touched.costUnitList && errors.costUnitList) && <label type="invalid" className="form-control is-invalid">
                                                            {errors.costUnitList}
                                                        </label>} */}
                                                            {/* <DrWarehousePricing costUnitList={values.costUnitList ? values.costUnitList : []} onAutoPriceCallBack={onAutoPriceCallBack}>

                                                        </DrWarehousePricing> */}
                                                        </div>
                                                        <div className="row row-md row-lg justify-content-center my-3 px-1">
                                                            <div className="col-lg-auto col-md-2">
                                                                <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (isValid) {
                                                                            handleSubmit(values);
                                                                        }
                                                                    }}
                                                                >{t('saveChanges')}</button>
                                                            </div>
                                                        </div>

                                                    </section>
                                                }

                                                {selectedStore && (!warehouseMarketOptions || warehouseMarketOptions?.length === 0) &&
                                                    <div className="row row-md row-lg justify-content-center my-3 px-1">
                                                        <div className="col-lg-auto col-md-2">
                                                            <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate(getRouteByKey("GENERAL"));
                                                                }}>
                                                                <i className="me-2 fe fe-chevrons-right"></i>
                                                                {t('gotoWareHouseSelection')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                }

                                                {!selectedStore && <div className="row row-md row-lg justify-content-center my-3 px-1">
                                                    <div className="col-lg-auto col-md-2">
                                                        <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                Promise.all([
                                                                    dispatch(withActionPromise(clearCreateStoreInformationState()))
                                                                ]).then((e) => {
                                                                    navigate(getRouteByKey("MARKET_SELECTION"));
                                                                });;

                                                            }}>
                                                            <i className="me-2 fe fe-chevrons-right"></i>
                                                            {t('gotoMarketSelection')}
                                                        </button>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            )
            }
        </Formik>
    )
}

export default StorePricingPage;