const types = {
    CREATE_ACCOUNT_WITH_EMAIL_REQUESTED: "CREATE_ACCOUNT_WITH_EMAIL_REQUESTED",
    CREATE_ACCOUNT_WITH_EMAIL_FAILED: "CREATE_ACCOUNT_WITH_EMAIL_FAILED",
    CLEAR_CREATE_ACCOUNT_EMAIL_SENT_STATE: "CLEAR_CREATE_ACCOUNT_EMAIL_SENT_STATE",
    LOGIN_WITH_EMAIL_REQUESTED: "LOGIN_WITH_EMAIL_REQUESTED",
    LOGIN_WITH_EXTERNAL_TOKEN_REQUESTED: "LOGIN_WITH_EXTERNAL_TOKEN_REQUESTED",
    LOGIN_WITH_AMAZON_REQUESTED: "LOGIN_WITH_AMAZON_REQUESTED",
    VALIDATE_TOKEN_REQUEST: "VALIDATE_TOKEN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    FORGOT_PASSWORD_REQUESTED: "FORGOT_PASSWORD_REQUESTED",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_REQUESTED: "CHANGE_PASSWORD_REQUESTED",
    LOGOUT_USER: "LOGOUT_USER",
    UPDATE_CUSTOMER_INFO_REQUESTED: "UPDATE_CUSTOMER_INFO_REQUESTED",
    GET_CUSTOMER_INFO_REQUESTED: "GET_CUSTOMER_INFO_REQUESTED",
    SET_CUSTOMER_INFO: "SET_CUSTOMER_INFO",

    GET_CUSTOMER_PROFILE_INFO_REQUESTED: "GET_CUSTOMER_PROFILE_INFO_REQUESTED",
    SET_CUSTOMER_PROFILE_INFO_SUCCESS: "SET_CUSTOMER_PROFILE_INFO_SUCCESS",
    UPDATE_CUSTOMER_PROFILE_INFO_REQUESTED: "UPDATE_CUSTOMER_PROFILE_INFO_REQUESTED",

    SET_CUSTOMER_INFO_ROLE: "SET_CUSTOMER_INFO_ROLE",
    SET_CUSTOMER_INFO_AUTHORIZATION: "SET_CUSTOMER_INFO_AUTHORIZATION",
    GET_ROLES_REQUESTED: "GET_ROLES_REQUESTED",
    GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
    GET_ROLES_FAILED: "GET_ROLES_FAILED",
    GET_SUBUSERS_REQUESTED: "GET_SUBUSERS_REQUESTED",
    SET_SUBUSERS: "SET_SUBUSERS",
    CREATE_SUBUSER_REQUESTED: "CREATE_SUBUSER_REQUESTED",
    UPDATE_SUBUSER_REQUESTED: "UPDATE_SUBUSER_REQUESTED",
    RESET_SUBUSER_PASSWORD_REQUESTED: "RESET_SUBUSER_PASSWORD_REQUESTED",
    SET_IS_EXTENSION: "SET_IS_EXTENSION",

    GET_CUSTOMER_PACKAGE_INFO_REQUESTED: "GET_CUSTOMER_PACKAGE_INFO_REQUESTED",
    GET_CUSTOMER_PACKAGE_INFO_SUCCESS: "GET_CUSTOMER_PACKAGE_INFO_SUCCESS"

}

export default types;

export const createAccountWithEmail = (payload) => {
    return { type: types.CREATE_ACCOUNT_WITH_EMAIL_REQUESTED, payload };
};

export const createAccountWithEmailFailed = (payload) => {
    return { type: types.CREATE_ACCOUNT_WITH_EMAIL_FAILED, payload };
};

export const clearCreateAccountEmailSentState = () => {
    return { type: types.CLEAR_CREATE_ACCOUNT_EMAIL_SENT_STATE };
}

export const loginWithEmail = (payload) => {
    return { type: types.LOGIN_WITH_EMAIL_REQUESTED, payload };
}

export const loginWithExternalToken = (payload) => {
    return { type: types.LOGIN_WITH_EXTERNAL_TOKEN_REQUESTED, payload };
}

export const loginSuccess = (payload) => {
    return { type: types.LOGIN_SUCCESS, payload };
}

export const validateToken = () => {
    return { type: types.VALIDATE_TOKEN_REQUEST }
}

export const forgotPassword = (payload) => {
    return { type: types.FORGOT_PASSWORD_REQUESTED, payload }
}

export const forgotPasswordSuccess = (payload) => {
    return { type: types.FORGOT_PASSWORD_SUCCESS, payload }
}

export const changePassword = (payload) => {
    return { type: types.CHANGE_PASSWORD_REQUESTED, payload }
}

export const logoutUser = () => {
    return { type: types.LOGOUT_USER };
}

export const updateCustomerInfo = (payload) => {
    return { type: types.UPDATE_CUSTOMER_INFO_REQUESTED, payload }
}

export const getCustomerInfo = (payload) => {
    return { type: types.GET_CUSTOMER_INFO_REQUESTED, payload }
}

export const setCustomerInfo = (payload) => {
    return { type: types.SET_CUSTOMER_INFO, payload }
}

export const setCustomerInfoRole = (payload) => {
    return { type: types.SET_CUSTOMER_INFO_ROLE, payload }
}

export const setCustomerInfoAuthorization = (payload) => {
    return { type: types.SET_CUSTOMER_INFO_AUTHORIZATION, payload }
}

export const getRoles = () => {
    return { type: types.GET_ROLES_REQUESTED };
};

export const getRolesSuccess = (payload) => {
    return { type: types.GET_ROLES_SUCCESS, payload };
};

export const setSubUsers = (payload) => {
    return { type: types.SET_SUBUSERS, payload }
}

export const getSubUsers = (payload) => {
    return { type: types.GET_SUBUSERS_REQUESTED, payload }
}

export const createSubUser = (payload) => {
    return { type: types.CREATE_SUBUSER_REQUESTED, payload };
};

export const updateSubUser = (payload) => {
    return { type: types.UPDATE_SUBUSER_REQUESTED, payload };
};

export const resetSubSellerPassword = (payload) => {
    return { type: types.RESET_SUBUSER_PASSWORD_REQUESTED, payload };
};

export const setIsExtension = (payload) => {
    return { type: types.SET_IS_EXTENSION, payload }
}

export const getCustomerProfileInfo = (payload) => {
    return { type: types.GET_CUSTOMER_PROFILE_INFO_REQUESTED, payload }
}

export const setCustomerProfileInfoSuccess = (payload) => {
    return { type: types.SET_CUSTOMER_PROFILE_INFO_SUCCESS, payload }
}

export const upsertCustomerProfileInfo = (payload) => {
    return { type: types.UPDATE_CUSTOMER_PROFILE_INFO_REQUESTED, payload }
}

export const getCustomerPackageInfo = () => {
    return { type: types.GET_CUSTOMER_PACKAGE_INFO_REQUESTED };
};

export const getCustomerPackageInfoSuccess = (payload) => {
    return { type: types.GET_CUSTOMER_PACKAGE_INFO_SUCCESS, payload };
};
