import SweetAlert2 from 'react-sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { buttonTypes, buttonVariants, sweetAlertVariants } from '../../../utils/constants'
import classNames from "classnames";
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

function DrSweetAlertButton({ buttonText, buttonIcon, buttonType = buttonTypes.button, buttonVariant = buttonVariants.primary, selectedValue, disabled = false, isButtonIconLeft = true,
    sweetAlertVariant = sweetAlertVariants.basicAlert, text, title, icon, html, value,
    onCloseConfirmCallBack, ...rest }) {
    const [swalProps, setSwalProps] = useState({});
    const { t } = useTranslation('drSweetAlertButton');

    var btnClass = classNames({
        'btn': true,
        "btn-wave": true,
        "waves-effect": true,
        "waves-light": true,
        [`${buttonVariant}`]: true,
        "shadow": true
    }, rest.className);

    delete rest.className;

    // const showSwal = () => {
    //     withReactContent(Swal).fire({
    //       title: <i>Input something</i>,
    //       input: 'text',
    //       inputValue,
    //       preConfirm: () => {
    //         setInputValue(Swal.getInput()?.value || '')
    //       },
    //     })
    //   }
    const autoCloseAlert = () => {
        let timerInterval
        Swal.fire({
            title: 'Auto close alert!',
            html: 'I will close in <b></b> milliseconds.',
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
            }
        })
    }

    const basicAlert = () => {
        Swal.fire(text || t('confirmButtonText'));
    }

    const basicAlertError = () => {
        Swal.fire({
            title: title || t('dangerTitle'),
            text: text || t('errorTexts.errorSomethingWentWrong'),
            icon: 'error',
            showCloseButton: true,
            confirmButtonText: t('confirmButtonText')
        });
    }

    const basicAlertSuccess = () => {
        Swal.fire({
            title: title || t('successTitle'),
            text: text || t('infoTexts.infoDefaultMessage'),
            icon: 'success',
            showCloseButton: true,
            confirmButtonText: t('confirmButtonText')
        });
    }


    const areYouSureConfirm = () => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success ms-2',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: title || t('areYouSureTitle'),
            text: text || t('infoTexts.infoThereIsNoReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('yesButtonText'),
            cancelButtonText: t('noButtonText'),
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                onCloseConfirmCallBack(true);
                // swalWithBootstrapButtons.fire(
                //     'Deleted!',
                //     'Your file has been deleted.',
                //     'success'
                // )

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // swalWithBootstrapButtons.fire(
                //     'Cancelled',
                //     'Your imaginary file is safe :)',
                //     'error'
                // )
            }
        })

    }

    const customHtmlAlert = () => {
        Swal.fire({
            title: "",
            text: "",
            // icon: 'info',
            html: html || "<i class='fe fe-home'></i>",
            showCloseButton: true,
            confirmButtonText: t('confirmButtonText'),
            // height: "100%",
            width: "calc(100vw - 30px)"
        });
    }

    const saveChangesConfirm = () => {
        Swal.fire({
            title: title || t('saveChangesTitle'),
            icon: 'question',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: t('saveButtonText'),
            denyButtonText: t('dontSaveButtonText'),
            cancelButtonText: t('cancelButtonText'),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(t('successTitle'), text || t('infoTexts.infoDefaultMessage'), 'success')
            } else if (result.isDenied) {
                Swal.fire(t('infoTitle'), t('infoTexts.infoCancelledMessage'), 'info')
            }
        })
    }

    const inputTextAlert = (e) => {
        Swal.fire({
            title: title || t('enterInputTitle'),
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonText: t('cancelButtonText'),
            confirmButtonText: t('saveButtonText'),
            showLoaderOnConfirm: true,
            // preConfirm: (login) => {
            //     return fetch(`https://jsonplaceholder.typicode.com/posts`)
            //         .then(response => {
            //             if (!response.ok) {
            //                 throw new Error(response.statusText)
            //             }
            //             return response.json()
            //         })
            //         .catch(error => {
            //             Swal.showValidationMessage(
            //                 `Request failed: ${error}`
            //             )
            //         })
            // },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                e.target.checked = true;
                // $(e.target).prop("checked", true);
                onCloseConfirmCallBack({ checked: e.target.checked, value: result.value });
            }
        })
    }


    if (rest.disabled && rest.disabled === "") {
        delete rest.disabled;
    }

    const onChange = (e) => {
        // setSwalProps({
        //     show: true,
        //     title: 'Example',
        //     text: 'Hello World',
        //     className: "swal2-icon-warning"
        // });

        if (sweetAlertVariant === sweetAlertVariants.areYouSureConfirm) {
            areYouSureConfirm(e);
        }
        else if (sweetAlertVariant === sweetAlertVariants.basicAlert) {
            basicAlert(e);
        }
        else if (sweetAlertVariant === sweetAlertVariants.basicAlertError) {
            basicAlertError(e);
        }
        else if (sweetAlertVariant === sweetAlertVariants.basicAlertSuccess) {
            basicAlertSuccess(e);
        }
        else if (sweetAlertVariant === sweetAlertVariants.autoCloseAlert) {
            autoCloseAlert(e);
        }
        else if (sweetAlertVariant === sweetAlertVariants.customHtmlAlert) {
            customHtmlAlert(e);
        }
        else if (sweetAlertVariant === sweetAlertVariants.saveChangesConfirm) {
            saveChangesConfirm(e);
        }
        else if (sweetAlertVariant === sweetAlertVariants.inputTextAlert) {
            if (buttonType === buttonTypes.checkBox) {
                if (e.target && e.target.checked) {
                    inputTextAlert(e);
                }
                else {
                    areYouSureConfirm(e);
                }
            }
            else {
                inputTextAlert(e);
            }

        }
    }

    return (
        <>
            {buttonType === buttonTypes.button &&
                <button
                    className={btnClass}
                    disabled={disabled}
                    value={selectedValue}
                    {...rest}
                    onClick={onChange}
                >
                    {isButtonIconLeft && buttonIcon}
                    {buttonText}
                    {!isButtonIconLeft && buttonIcon}

                </button>
            }

            {buttonType === buttonTypes.checkBox &&
                <input
                    id={rest.id}
                    type="checkbox"
                    name={rest.name}
                    value={value}
                    className="form-check-input"
                    placeholder={rest.placeholder}
                    {...rest}
                    onChange={(e) => {
                        e.preventDefault();
                        onChange(e);
                    }}
                />
            }
            {/* <SweetAlert2 {...swalProps}
                didOpen={() => {
                    // run when swal is opened...
                }}
                didClose={() => {
                    if (onCloseConfirmCallBack) {
                        console.log("cancelled");
                        onCloseConfirmCallBack(false);

                    }
                    setSwalProps({});
                    // run when swal is closed...
                }}
                onConfirm={result => {

                    if (onCloseConfirmCallBack) {
                        
                        onCloseConfirmCallBack(true);

                    }
                    setSwalProps({});
                    // run when clieked in confirm and promise is resolved...
                }}
                onError={error => {
                    // run when promise rejected...
                }}
                onResolve={result => {
                    // run when promise is resolved...
                }}
            ></SweetAlert2> */}
        </>

    )
}

export default DrSweetAlertButton
