
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrOrdersFilter, DrPageFilter } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { buttonDropdownDirection, buttonVariants, modals, pageFilterTypes, siteExtensions } from '../../utils/constants';
import { clearActiveBuyoutCarrierSelectionModal, setActiveBuyoutCarrierSelectionModal, setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getOrders } from '../../state-management/actions/orders.actions';
import * as moment from 'moment';
import i18next from 'i18next';
import { flagOptions, orderStatusOptions } from '../../components/hoc/Constants/dr-constants.component';
import noImage from '../../assets/img/pngs/no-image.png'
import { setOrderPageSelections } from '../../state-management/actions/settings.actions';
import { toastr } from 'react-redux-toastr';
import { getPageFilters } from '../../state-management/actions/page-filters.actions';
// import { ordersMockData as  orderList} from '../../utils/mocks-data';

function OrdersPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('ordersPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const ordersPageFilters = useSelector(state => state.pageFilter?.ordersPageFilters);
    const storeOrderStatusCounts = useSelector(state => state.stores.storeOrderStatusCounts);
    const orderList = useSelector(state => state.orders.orderList);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.ordersPageSelections);
    const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);
    const [orderStatusOptionsWithCounts, setOrderStatusOptionsWithCounts] = useState(orderStatusOptions);
    const [detailedFilterData, setDetailedFilterData] = useState(null);

    useEffect(() => {
        if (!(pageSelections?.dateInterval) || !(pageSelections?.orderStatus)) {
            dispatch(withActionPromise(setOrderPageSelections({ dateInterval: [], orderStatus: 0 })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var newOptions = orderStatusOptions?.map(x => ({ value: x.value, label: x.label + "(" + (storeOrderStatusCounts?.find(y => y.status === x.value)?.orderCount || 0) + ")", isCritical: x.isCritical }))

        setOrderStatusOptionsWithCounts(newOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeOrderStatusCounts, orderStatusOptions]);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {

            dispatch(withActionPromise(getPageFilters(
                {
                    customerMainSellerId: selectedStore?.customerMainSellerId,
                    pageNumber: pageFilterTypes.orders,
                    pageName: Object.keys(pageFilterTypes)[Object.values(pageFilterTypes).indexOf(1)]
                })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId]);

    useEffect(() => {
        if (selectedStore && (pageSelections?.orderStatus)) {

            var localDate = new Date();
            var compareDateStart = pageSelections?.dateInterval?.length > 0 ? (pageSelections.dateInterval[0] && moment(pageSelections.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD") : null;
            var compareDateEnd = pageSelections?.dateInterval?.length > 1 ? (pageSelections.dateInterval[1] && moment(pageSelections.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD") : null;

            if (detailedFilterData) {
                dispatch(getOrders(detailedFilterData));
            }
            else {
                dispatch(getOrders({
                    customerMainSellerId: selectedStore?.customerMainSellerId,
                    orderStatus: pageSelections?.orderStatus,
                    startOrderDate: compareDateStart,
                    endOrderDate: compareDateEnd
                }));
            }
            // if (detailedFilterData) {
            //     detailedFilterData.isApplied = true;
            //     setDetailedFilterData(detailedFilterData);
            // }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId, pageSelections?.orderStatus, pageSelections?.dateInterval, detailedFilterData, detailedFilterData?.isApplied]);

    const columns = [
        { data: "orderFlag", title: "", width: "3%", minWidth: "10px", searchable: false, checkall: true, checkData: "id", searchDropdownSource: { flagOptions } }, //image
        { data: "orderNumber", name: "orderNumber", title: t('dataTable.orderNo'), width: "20%", minWidth: "130px", searchable: true },
        { data: "buyerSellerOrderNumber", title: t('dataTable.amazonOrderNumber'), width: "15%", minWidth: "120px", searchable: true },
        // { data: "shipmentStatus", title: t('dataTable.status'), width: "5%", minWidth: "70px", searchable: true },
        { data: "ordertotalAmount", title: t('dataTable.price'), width: "5%", minWidth: "55px", searchable: true },
        { data: "profitPrice", title: t('dataTable.profit'), width: "5%", minWidth: "20px", searchable: true },
        // { data: null, title: "Kar%", width: "10%", searchable: true },
        { data: "orderStatus", name: "orderStatus", title: t('dataTable.orderStatus'), width: "20%", searchable: true, visible: false },
        { data: "purchaseDate", title: t('dataTable.date'), width: "20%", minWidth: "80px", searchable: true },
        { data: null, title: "", width: "10%", minWidth: "100px", searchable: false },
        { data: null, title: "", width: "3%", searchable: false }
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: true,
            className: "text-start position-relative",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <input type="checkbox" className="ckbox position-absolute form-check-input form-checked-info " name="name" value={rowData.id} style={{ top: '1px', left: '10px' }}
                            onChange={(e) => {
                                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                            }}
                        />
                        <div className="card-aside-img cursor-pointer border border-gray-300 rounded container mt-2" style={{ textAlign: 'start', minWidth: '64px', position: 'relative' }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (rowData.products?.length > 0) {
                                    dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: rowData.products?.map(x => x.imageUrl) || [noImage] }))
                                }
                            }} >
                            <div className="row justify-content-start">
                                {rowData.products?.length > 0 && rowData.products.slice(0, (rowData.products.length > 1 ? 2 : rowData.products.length)).map((product, index) => {

                                    return (
                                        <div key={"product-pic-" + index} className={`px-0 rounded-0 ${rowData.products.length > 1 ? "col-6 " : "col-12 "}`} >
                                            <img src={product.imageUrl || noImage} className="img-thumbnail" alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                            ></img>
                                        </div>
                                    )
                                })}

                            </div>
                            {rowData.products?.length > 2 &&
                                <div className="row justify-content-start">
                                    {rowData.products.slice(2, (rowData.products.length > 3 ? 4 : rowData.products.length)).map((product, index) => {

                                        return (
                                            <div key={"product-pic-" + (index + 2)} className="col-6 px-0 rounded-0">
                                                <img src={product.imageUrl || noImage} alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                                ></img>
                                            </div>
                                        )
                                    })}

                                </div>
                            }
                            {rowData.products.length > 1 && <span className="badge bg-danger tx-bold" style={{ position: 'absolute', top: '-8px', right: '-8px' }}>{rowData.products.length}</span>}
                            {flagOptions.find(x => x.value === rowData.orderFlag)?.icon &&

                                <span style={{ position: 'absolute', bottom: '-8px', right: '-8px' }}>{flagOptions.find(x => x.value === rowData.orderFlag)?.icon}</span>

                            }
                        </div>
                        {/* <div className="row justify-content-center p-0 m-0 mt-1">
                            <div className="col-auto px-0">
                                <button className="btn btn-primary btn-icon btn-sm btn-wave waves-effect waves-light shadow"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/order-detail/' + rowData.id);
                                    }}
                                >
                                    <i className="fe fe-info" style={{ width: "12px" }}></i>
                                </button>
                            </div>
                           
                        </div> */}
                    </>);
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            width: "20%",
            createdCell: (td, cellData, rowData) => {

                ReactDOM.createRoot(td).render(
                    <>
                        <div className="row justify-content-center">
                            <div className="col-auto px-0">
                                <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/order-detail/' + rowData.id);
                                    }}
                                >
                                    {rowData.orderNumber}
                                </button>
                            </div>
                            <div className="col-auto px-0 my-auto cursor-pointer">
                                {/* <button className="btn btn-icon btn-outline-light rounded-pill btn-sm btn-wave waves-effect waves-light"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(rowData.orderNumber)
                                }}
                            >
                                <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-primary cursor-pointer"></i>
                            </button> */}
                                <span onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(rowData.orderNumber);
                                    toastr.info("Info", rowData.orderNumber + " is copied to clipboard", { timeOut: 1000 })
                                }}>
                                    <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                </span>

                            </div>

                        </div >
                        <div className="row justify-content-center">
                            <div className="col-auto px-0">
                                <span> {rowData.orderStatus}</span>
                            </div>
                        </div>
                    </>

                )
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            width: "20%",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.buyerSellerOrderNumber}</span><br />
                    </>)
            }
        },
        // {
        //     targets: 2,
        //     orderable: true,
        //     className: "text-center",
        //     createdCell: (td, cellData, rowData) => {
        //         ReactDOM.createRoot(td).render(
        //             <>
        //                 <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.shipmentStatus}</span><br />
        //             </>);
        //     }
        // },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {/* <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.orderTotal?.currencyCode} {rowData.orderTotal?.amount}</span><br /> */}
                        <span className="badge bg-info">
                            {/* {rowData.orderTotal?.currencyCode} {rowData.orderTotal?.amount} */}
                            {selectedStoreCurrencyType.currencyCode} {rowData.orderItemPriceAmount}
                        </span>
                    </>);
            }
        },
        {
            targets: 4,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div className="d-flex flex-wrap">
                        <div className="row justify-content-start w-90" >
                            <div className="col-12 px-0">
                                <span className="text-end badge rounded-pill bg-success fs-11 ms-2">Profit: {selectedStoreCurrencyType.currencyCode} {Number(rowData.profitPrice || 0.00).toFixed(2)}</span>
                            </div>
                        </div>
                        <div className="row justify-content-start w-90 mt-1" >
                            <div className="col-12 px-0">
                                <span className="text-end badge rounded-pill bg-success fs-11 ms-2">Profit %: {Number(rowData.profitPercent || 0.00).toFixed(2)}%</span>
                            </div>
                        </div>
                    </div>
                );
            }
        },
        // {
        //     targets: 5,
        //     orderable: true,
        //     className: "text-center",
        //     createdCell: (td, cellData, rowData) => {
        //         ReactDOM.createRoot(td).render(
        //             <>
        //                 <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.earning2}</span><br />
        //             </>);
        //     }
        // },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">
                            {/* {orderStatus.find(x => x.value === rowData.orderStatus)?.label} */}
                            {rowData.orderStatus}
                        </span><br />
                    </>);
            }
        },
        {
            targets: 6,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <span style={{ marginBottom: '85px' }}>{moment(rowData.purchaseDate).format('L LT')}</span>

                    </div>);
            }
        },
        {
            targets: 7,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(


                    // <a className="ms-2 cursor-pointer btn ripple btn-sm btn-teal" target="_blank" rel="noreferrer"
                    //     href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedStore.countryCode)?.extension + "/gp/aws/cart/add.html?AmazonOrderId=" + rowData.id + "&sendType=1&ASIN.1=" + rowData.firstASIN + "&Quantity.1=" + rowData.firstQuantityOrdered}>
                    //     {t('dataTable.placeOrder')}
                    // </a>
                    <>
                        {rowData.products?.length > 0 &&
                            // <a className={`ms-2 cursor-pointer btn ripple btn-sm btn-teal ${!rowData.isOrderable ? "disabled" : ""}`} target="_blank" rel="noreferrer"
                            //     href={"https://www.amazon.com/gp/aws/cart/add.html?AmazonOrderId=" + rowData.id + "&sendType=1&ASIN.1=" + rowData.products[0].asin + "&Quantity.1=" + rowData.products[0].quantityOrdered}>
                            //     {t('dataTable.placeOrder')}
                            // </a>
                            <button className={`ms-2 cursor-pointer btn ripple btn-sm btn-teal ${!rowData.isOrderable ? "disabled" : ""}`}
                                onClick={(e) => {

                                    e.preventDefault();
                                    dispatch(setActiveBuyoutCarrierSelectionModal({
                                        name: modals.buyoutCarrierSelection, isGlobal: true, title: "", source: rowData.id,
                                        carrierSelectionCallback: (response) => {
                                            dispatch(clearActiveBuyoutCarrierSelectionModal());
                                        }
                                    }));
                                }}
                            >
                                {t('dataTable.placeOrder')}
                            </button>
                        }
                    </>
                );
            }
        },
        {
            targets: 8,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <span id="droprightMenuButton" className="text-muted" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18 cursor-pointer"></i></span>
                        <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/order-detail/' + rowData.id);
                                }}>
                                {t('dataTable.seeDetails')}
                            </span>
                            <a className="dropdown-item d-flex align-items-center cursor-pointer" target="_blank" rel="noreferrer"
                                href={"https://sellercentral.amazon." + siteExtensions.find(x => x.countryCode === selectedStore.countryCode)?.extension + "/orders-v3/order/" + rowData.orderNumber}>
                                {t('dataTable.seeAtAmazon')}
                            </a>
                            {rowData.products?.length > 0 && rowData.isOrderable &&
                                // <a className="dropdown-item d-flex align-items-center cursor-pointer" target="_blank" rel="noreferrer"
                                //     href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === (rowData.products[0].sourceMarketCountryCode || (selectedStore?.countryCode || "US")))?.extension + "/gp/aws/cart/add.html?AmazonOrderId=" + rowData.orderNumber + "&sendType=1&ASIN.1=" + rowData.products[0].asin + "&Quantity.1=" + rowData.products[0].quantityOrdered}>
                                //     {t('dataTable.placeOrder')}
                                // </a>
                                <span className="dropdown-item d-flex align-items-center cursor-pointer"
                                    onClick={(e) => {

                                        e.preventDefault();
                                        dispatch(setActiveBuyoutCarrierSelectionModal({
                                            name: modals.buyoutCarrierSelection, isGlobal: true, title: "", source: rowData.id,
                                            carrierSelectionCallback: (response) => {
                                                dispatch(clearActiveBuyoutCarrierSelectionModal());
                                            }
                                        }));
                                    }}
                                >
                                    {t('dataTable.placeOrder')}
                                </span>
                            }

                        </div>
                    </div>)
            }
        }
    ];

    const orderPageOptionClickCallBack = (value) => {
        dispatch(withActionPromise(setOrderPageSelections({ orderStatus: value, dateInterval: pageSelections?.dateInterval })));
    };
    const timeIntervalOptionCallBack = (dateValues) => {
        dispatch(withActionPromise(setOrderPageSelections({ orderStatus: pageSelections?.orderStatus, dateInterval: dateValues })));
    };

    const refreshClickedCallBack = (value) => {
        setDetailedFilterData(null);
        navigate("/orders")
        if (selectedStore && pageSelections?.orderStatus) {
            var localDate = new Date();

            if (pageSelections?.dateInterval && pageSelections?.dateInterval.length > 0 && (pageSelections.dateInterval[0] || pageSelections.dateInterval[1])) {

                var localDate = new Date();
                var compareDateStart = (pageSelections.dateInterval[0] && moment(pageSelections.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD");
                var compareDateEnd = (pageSelections.dateInterval[1] && moment(pageSelections.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD");

                dispatch(getOrders({
                    customerMainSellerId: selectedStore?.customerMainSellerId,
                    orderStatus: pageSelections?.orderStatus,
                    startOrderDate: compareDateStart,
                    endOrderDate: compareDateEnd
                }));
            }
        }
    };

    const detailedSearchClick = (e, searchModel) => {
        var localDate = new Date();
        var compareDateStart = (searchModel?.startOrderDate && moment(searchModel?.startOrderDate).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD");
        var compareDateEnd = (searchModel?.endOrderDate && moment(searchModel?.endOrderDate).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD");

        dispatch(withActionPromise(setOrderPageSelections({ orderStatus: searchModel?.orderStatus || pageSelections?.orderStatus, dateInterval: [compareDateStart, compareDateEnd] || pageSelections?.dateInterval })));

        var searchData = Object.assign({}, searchModel);
        searchData.orderFlag = searchModel.orderFlag?.map(a => a.value) || null
        setDetailedFilterData(searchData);
    };

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.orders'), navigationPath: "" }]}>
            </DrBreadCrump>

            <div className="row justify-content-center justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end mb-2">

                <div className="col-auto my-1 ps-0">
                    <DrButtonDropdown className="ms-2 shadow" options={orderStatusOptionsWithCounts}
                        selectedValue={pageSelections?.orderStatus}
                        optionClickCallBack={orderPageOptionClickCallBack}
                        direction={buttonDropdownDirection.horizontal}
                        variant={buttonVariants.light}
                        selectedVariant={buttonVariants.teal}
                    >
                    </DrButtonDropdown>
                </div>
            </div>
            {detailedFilterData &&
                <div className="row">
                    <div className="col-12">
                        <div className="card rounded-10 mb-2">
                            <div className="card-body rounded p-2 bg-teal">
                                <div className="row justify-content-between">
                                    <div className="col-auto my-auto">
                                        <label className="text-white my-auto">
                                            Detaylı Arama Uygulanmıştır!
                                        </label>
                                    </div>
                                    <div className="col-auto my-auto">
                                        <button className="btn btn-danger btn-wave waves-effect waves-light shadow btn-sm rounded-pill"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setDetailedFilterData(null);
                                                navigate("/orders")
                                            }}
                                        >
                                            <i className="fe fe-trash-2 me-2"></i>
                                            Filtreyi Kaldır</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div id="orderTable" className="row mb-5">
                <div className="col-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            {orderList && <>
                                <DrDataTable
                                    data={orderList}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    search={true}
                                    minWidth="850px"
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            refreshClickedCallBack={refreshClickedCallBack}
                                            isSearchSavingActive={true}
                                            isSearchActive={false}
                                            timeIntervalOptionCallBack={timeIntervalOptionCallBack}
                                            selectedDates={pageSelections?.dateInterval}
                                            isMultiSelectEnabled={true}
                                            filterType={pageFilterTypes.orders}

                                            savedFilters={ordersPageFilters}
                                            searchDetail={<DrOrdersFilter detailedSearchClickCallBack={detailedSearchClick} />}
                                        >
                                        </DrPageFilter>
                                    }

                                    isMultiSelectEnabled={true}

                                />
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* </div>
            </div> */}
        </>
    )
}
export default OrdersPage