import React from 'react'
import { DrInput, DrNavigator } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { setConnectToAmazonCriteria, setSnapshotStorePageSubmission } from '../../state-management/actions/create-store.actions';
import { generateRandomUniqueId, getURLParams2 } from '../../utils/utils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { siteExtensions } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

function ConnectAmazonPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation('connectAmazonPage');
    const amazonCode = getURLParams2('spapi_oauth_code', location.search);
    const amazonState = getURLParams2('state', location.search);
    const marketSelection = useSelector(state => state.createStoreSnapshot.marketSelection);

    const getRefreshToken = async () => {
        await Promise.all([
            dispatch(withActionPromise(setConnectToAmazonCriteria({
                code: "",
                state: "",
                redirectUrl: window.location.origin,
                redirectPath: "/connect-amazon"
            })))
        ]).then((e) => {
            let uniqueId = generateRandomUniqueId(parseInt(50));
            let appId = "amzn1.sp.solution.1b75e99b-e5f6-4070-8ca1-2dc5e45a703b"
            let url = `${siteExtensions.find(x => x.countryCode === marketSelection?.countryCode)?.url}/apps/authorize/consent?application_id=${appId}&state=${uniqueId}&redirect_uri=${window.location.origin}&version=beta`
            window.location = url;
        });
    }

    const connectToAmazonSchema = Yup.object().shape({
        code: Yup.string()
            .min(20, 'Too Short!')
            .max(20, 'Too Long!')
            .required('Code is required'),
        state: Yup.string()
            .min(50, 'Too Short!')
            .required('State is required'),
    });

    const initialValues = {
        code: amazonCode ? amazonCode : "",
        state: amazonState ? amazonState : "",
        redirectUrl: window.location.origin
    }

    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            //onSubmit={handleSubmit}
            validationSchema={connectToAmazonSchema}
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                setTouched,
                setFieldValue
            }) => (
                <div className="content clearfix">
                    <form name="market-selection-form" className="needs-validation" autoComplete="off">
                        <section>
                            <div className="row row-md d-flex justify-content-center">
                                <div className="col-sm-8 col-lg-12 col-md-8">
                                    <h3 className="text-center">{t("title")}</h3>
                                </div>
                            </div>
                            {(!values.code || values.code?.length === 0) && <div className="row row-md row-lg">
                                <div className="col-12 text-center">
                                    <div className="alert alert-info" role="alert">
                                        {t("please")}
                                        <label className="cursor-pointer">
                                            <img htmlFor="lwa" border="0" alt="Login with Amazon"
                                                src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                                                width="156" height="32" />
                                            <DrInput id="lwa" value="0"
                                                className="custom-control-input"
                                                placeholder="" name="companySellerSerialNumber" type="button" onClick={getRefreshToken} />
                                        </label>
                                        {/* and allow SellerDoping and press <strong>Confirm</strong> to continue */}
                                        {t("allowSellerDoping")}
                                    </div>
                                </div>
                            </div>}
                            {/* <section className="alert alert-success" role="alert">
                                <div className="row row-md row-lg">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <h6>Code</h6>
                                            <DrInput value={values.code} className={`form-control text-muted ${(touched.code && errors.code) ? " is-invalid" : ""}`} disabled="disabled" placeholder="SKU şöyle görünecektir: AMZR-XXXX-123456" name="code" type="text"
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'code': true }) }} />
                                            {(touched.code && errors.code) &&
                                                <small className="is-invalid-description">{errors.code}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-md row-lg">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <h6>State</h6>
                                            <DrInput value={values.state} className={`form-control text-muted ${(touched.state && errors.state) ? " is-invalid" : ""}`} disabled="disabled" placeholder="SKU şöyle görünecektir: AMZR-XXXX-123456" name="state" type="text"
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'state': true }) }} />
                                            {(touched.state && errors.state) &&
                                                <small className="is-invalid-description">{errors.state}</small>}
                                        </div>
                                    </div>
                                </div>
                            </section> */}
                            {values.code && values.code.length > 0 &&
                                <section className="alert alert-success" role="alert">
                                    <div className="row row-md row-lg justify-content-center">
                                        <div className="col-auto">
                                            <label >{t("succesfullyConnected")}</label>
                                        </div>
                                    </div>
                                </section>
                            }
                        </section>
                        {<DrNavigator backAddress="WAREHOUSE_SELECTION" nextAddress="COMPLETE_CREATESTORE" disabled={!isValid} showCompleteButton={true}
                            callback={() => {
                                return Promise.all([
                                    dispatch(withActionPromise(setConnectToAmazonCriteria(values))),
                                    dispatch(withActionPromise(setSnapshotStorePageSubmission({ connectAmazonPage: true })))
                                ]);
                            }}
                        />}
                    </form>
                </div>
            )
            }
        </Formik>
    )
}

export default ConnectAmazonPage;