import { useDispatch, useSelector } from "react-redux";
import DrNavigator from "../../components/hoc/Navigator/dr-navigator.component";
import { Formik } from "formik";
import { withActionPromise } from "../../state-management/actions/app.actions";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { setCreatePackageSnapshotPageSubmission, setPackagesSnapshotMainPackage, setPackagesSnapshotPackageFeatures } from "../../state-management/actions/create-customer-packages.actions";
import { getPackages } from "../../state-management/actions/packages.action";
import { useEffect } from "react";
import classNames from "classnames";


function PackageFeatures() {

    const dispatch = useDispatch();
    const { t } = useTranslation('subscriptionPlanPage');

    const createPackagesSnapshot = useSelector(state => state.createPackagesSnapshot);
    const allPackages = useSelector(state => state.packages.allPackages);
    const customerPackageInfo = useSelector(state => state.customer.customerPackageInfo);

    useEffect(() => {
        if (!(allPackages?.length > 0)) {
            dispatch(getPackages())
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const initialValues = {
        mainPackageId: createPackagesSnapshot.mainPackageId,
        packageFeaturesIds: createPackagesSnapshot.packageFeaturesIds || []
    };

    const mainPackagePlanSchema = Yup.object().shape({
        // mainPackageId: Yup.string().required('Main Package plan is required')
    });

    const findMaxProfitPackage = (arr) => {
        let maxValue = Number.MIN_VALUE;
        let packageId = null;
        arr.forEach(subPackage => {
            if ((subPackage.price * subPackage.discount / 100) > maxValue) {
                maxValue = (subPackage.price * subPackage.discount / 100);
                packageId = subPackage.id;
            }

        });
        return packageId;
    }

    return (
        <Formik
            initialValues={initialValues}
            // validateOnBlur={true}
            enableReinitialize={true}
            validateOnMount={true}
        // validationSchema={mainPackagePlanSchema}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleBlur,
                setFieldValue
            }) => (
                <div className="content clearfix">
                    <form>
                        <section>
                            <div className="row">
                                {allPackages && allPackages.filter(x => !x.mainPlan && !x.isDisable && x.isVisible === true)?.sort((a, b) => a.planName.localeCompare(b.planName)).map((subPackage, index) => {

                                    let cardTopClass = classNames({
                                        'card custom-card': true,
                                        'ribbone-card': true,
                                        'overflow-hidden': false,
                                        'border border-success shadow': values.packageFeaturesIds.includes(subPackage.id)

                                    });

                                    let discountedPrice = subPackage.discount && subPackage.discount > 0 ? Number((subPackage.price - (subPackage.price * subPackage.discount / 100))).toFixed(0) : null;

                                    // let isAddablePackage = ((subPackage.mustPlan?.length > 0 && subPackage.mustPlan.split(',').includes(values.mainPackageId)) || (subPackage.mustPlan?.length === 0));
                                    let isAddablePackage = true;

                                    let notAddableText = "This Feature is already exists in your main package";

                                    if (customerPackageInfo?.customerPackages?.length > 0) {
                                        customerPackageInfo?.customerPackages.forEach(p => {
                                            if (isAddablePackage === true) {
                                                if (p.id === subPackage.id) {
                                                    isAddablePackage = false;
                                                }
                                                if (p.isDropAmazon2Amazon && subPackage.isDropAmazon2Amazon) {
                                                    isAddablePackage = false;
                                                }
                                                if (p.isFBA && subPackage.isFBA) {
                                                    isAddablePackage = false;
                                                }
                                                if (p.isSmartPricer && subPackage.isSmartPricer) {
                                                    isAddablePackage = false;
                                                }
                                                if (p.isWarehouse && subPackage.isWarehouse) {
                                                    isAddablePackage = false;
                                                }
                                            }

                                        });
                                    }

                                    if (values.mainPackageId) {
                                        let mainPackage = allPackages?.find(x => x.id === values.mainPackageId);
                                        if (mainPackage) {

                                            if (isAddablePackage === true) {
                                                if (mainPackage.id === subPackage.id) {
                                                    isAddablePackage = false;
                                                }
                                                if (mainPackage.isDropAmazon2Amazon && subPackage.isDropAmazon2Amazon) {
                                                    isAddablePackage = false;
                                                }
                                                if (mainPackage.isFBA && subPackage.isFBA) {
                                                    isAddablePackage = false;
                                                }
                                                if (mainPackage.isSmartPricer && subPackage.isSmartPricer) {
                                                    isAddablePackage = false;
                                                }
                                                if (mainPackage.isWarehouse && subPackage.isWarehouse) {
                                                    isAddablePackage = false;
                                                }
                                            }

                                        }
                                    }
                                    if (subPackage.mustPlan?.length > 0) {
                                        let mustPlanIds = subPackage.mustPlan.split(',').map(x => x.toString());

                                        let mustPlanNames = "";
                                        allPackages.filter(x => !x.isDisable && x.isVisible === true)?.forEach(p => {
                                            if (mustPlanIds.includes(p.id)) {
                                                if (mustPlanNames?.length > 0) {
                                                    mustPlanNames += ", ";
                                                }
                                                mustPlanNames += ("'" + p.planName + "'");
                                            }
                                        });

                                        if (isAddablePackage === true) {
                                            let hasMustPlanId = false;

                                            if (customerPackageInfo?.customerPackages?.length > 0) {
                                                customerPackageInfo?.customerPackages.forEach(p => {
                                                    if (mustPlanIds.includes(p.id)) {
                                                        hasMustPlanId = true;
                                                    }
                                                });
                                            }

                                            if (values.mainPackageId && hasMustPlanId === false) {
                                                if (mustPlanIds.includes(values.mainPackageId)) {
                                                    hasMustPlanId = true;
                                                }
                                            }

                                            isAddablePackage = hasMustPlanId;
                                            notAddableText = !hasMustPlanId ? ("This feature requires one of the main plans as follows " + mustPlanNames) : notAddableText;
                                        }
                                    }

                                    if (!(customerPackageInfo?.customerPackages?.length > 0) && !(values.mainPackageId?.length > 0)) {
                                        isAddablePackage = false;
                                        notAddableText = "This feature requires one of the main plans!";
                                    }
                                    // if (subPackage.mustPlan?.length > 0) {
                                    //     let mustPlanIds = subPackage.mustPlan.split(',');
                                    //     if (customerPackageInfo?.customerPackages?.length > 0) {
                                    //         customerPackageInfo?.customerPackages.forEach(p => {
                                    //             if (mustPlanIds.includes(p.id)) {
                                    //                 isAddablePackage = false;
                                    //             }
                                    //         });
                                    //     }
                                    //     if (values.mainPackageId && isAddablePackage === true) {
                                    //         if (mustPlanIds.includes(values.mainPackageId)) {
                                    //             isAddablePackage = false;
                                    //         }
                                    //     }
                                    // }
                                    // let bestSeller = allPackages.reduce((max, current) => Math.max(max.discount, current[0].discount), -Infinity);
                                    // let bestSellerPackageId = findMaxProfitPackage(allPackages.filter(x => x.mainPlan === true && !x.isDisable && x.isVisible === true && x.discount > 0) || []);
                                    return (
                                        <>
                                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 d-flex">
                                                <div className={cardTopClass}>


                                                    {false && <div className="ribbone ribbone-top-left text-danger">
                                                        <span className="bg-danger">
                                                            Best Seller
                                                        </span>
                                                    </div>}
                                                    {subPackage.discount > 0 && <div className="arrow-ribbone-right bg-teal">Save {subPackage.discount}%</div>}

                                                    <div className="card-body p-0">
                                                        <div className={`px-1 py-2 op-3 bg-info`} />
                                                        <div className="py-2 px-4 h-100 d-flex flex-column">
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <div className="fs-16 fw-semibold" style={{ minHeight: '40px' }}>{subPackage.planName}</div>
                                                            </div>
                                                            <div className="fs-18 fw-bold mb-1 text-center">
                                                                <span>
                                                                    {discountedPrice ? ("$" + discountedPrice) : ("$" + Number(subPackage.price).toFixed(0))}
                                                                    {discountedPrice &&
                                                                        <span className="fs-14 text-muted text-decoration-line-through ms-1 d-inline-block op-6">
                                                                            {("$" + Number(subPackage.price).toFixed(2))}
                                                                        </span>}
                                                                </span>

                                                                {/* {discountedPrice && <span className="badge rounded-pill bg-warning fs-11">Save {subPackage.discount}%</span>} */}
                                                            </div>
                                                            <div className="mb-1 text-muted">{subPackage.description}</div>
                                                            <div className="flex-grow-shrink row">
                                                                <div className="col mt-auto">
                                                                    <ul className="list-unstyled mb-0">
                                                                        {subPackage.productCount > 0 &&
                                                                            <li className="d-flex align-items-center mb-1">
                                                                                <span className="me-2">
                                                                                    <i className={`ri-checkbox-circle-line fs-15 ${subPackage.productCount > 0 ? "text-success" : " text-muted op-3"}`} />
                                                                                </span>
                                                                                <span>
                                                                                    <strong className="me-1">{subPackage.productCount} Product</strong> count
                                                                                </span>
                                                                            </li>}
                                                                        {subPackage.scanningCount > 0 &&
                                                                            <li className="d-flex align-items-center mb-1">
                                                                                <span className="me-2">
                                                                                    <i className={`ri-checkbox-circle-line fs-15 ${subPackage.scanningCount > 0 ? "text-success" : " text-muted op-3"}`} />
                                                                                </span>
                                                                                <span>
                                                                                    <strong className="me-1">{subPackage.scanningCount} Scanning</strong> count
                                                                                </span>
                                                                            </li>}
                                                                        {subPackage.sellerCount > 0 &&
                                                                            <li className="d-flex align-items-center mb-1">
                                                                                <span className="me-2">
                                                                                    <i className={`ri-checkbox-circle-line fs-15 ${subPackage.sellerCount > 0 ? "text-success" : " text-muted op-3"}`} />
                                                                                </span>
                                                                                <span>
                                                                                    <strong className="me-1">{subPackage.sellerCount} Seller</strong> count
                                                                                </span>
                                                                            </li>}
                                                                        {subPackage.userCount > 0 &&
                                                                            <li className="d-flex align-items-center mb-1">
                                                                                <span className="me-2">
                                                                                    <i className={`ri-checkbox-circle-line fs-15 ${subPackage.userCount > 0 ? "text-success" : " text-muted op-3"}`} />
                                                                                </span>
                                                                                <span>
                                                                                    <strong className="me-1">{subPackage.userCount} User</strong> count
                                                                                </span>
                                                                            </li>}

                                                                        {subPackage.isFBA &&
                                                                            <li className="d-flex align-items-center mb-1">
                                                                                <span className="me-2">
                                                                                    <i className={`ri-checkbox-circle-line fs-15 ${subPackage.isFBA ? "text-success" : " text-muted op-3"}`} />
                                                                                </span>
                                                                                <span className={`${subPackage.isFBA ? "fw-bold" : ""}`}>
                                                                                    FBA Option
                                                                                </span>
                                                                            </li>}
                                                                        {subPackage.isWarehouse &&
                                                                            <li className="d-flex align-items-center mb-1">
                                                                                <span className="me-2">
                                                                                    <i className={`ri-checkbox-circle-line fs-15 ${subPackage.isWarehouse ? "text-success" : " text-muted op-3"}`} />
                                                                                </span>
                                                                                <span className={`${subPackage.isWarehouse ? "fw-bold" : ""}`}>
                                                                                    Warehouse Option
                                                                                </span>
                                                                            </li>}
                                                                        {subPackage.isDropAmazon2Amazon &&
                                                                            <li className="d-flex align-items-center mb-1">
                                                                                <span className="me-2">
                                                                                    <i className={`ri-checkbox-circle-line fs-15 ${subPackage.isDropAmazon2Amazon ? "text-success" : " text-muted op-3"}`} />
                                                                                </span>
                                                                                <span className={`${subPackage.isDropAmazon2Amazon ? "fw-bold" : ""}`}>
                                                                                    Drop Amazon to Amazon
                                                                                </span>
                                                                            </li>}
                                                                        {subPackage.isSmartPricer && <li className="d-flex align-items-center mb-1">
                                                                            <span className="me-2">
                                                                                <i className={`ri-checkbox-circle-line fs-15 ${subPackage.isSmartPricer ? "text-success" : " text-muted op-3"}`} />
                                                                            </span>
                                                                            <span className={`${subPackage.isSmartPricer ? "fw-bold" : ""}`}>
                                                                                SmartPricer
                                                                            </span>
                                                                        </li>}
                                                                    </ul>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="card-footer p-0 border border-0">
                                                        <div className="row justify-content-center mb-2 mx-2">
                                                            <div className="col-auto">
                                                                {isAddablePackage &&
                                                                    <button className={`btn btn-wave btn-sm waves-effect waves-light shadow ${(values.packageFeaturesIds.includes(subPackage.id)) ? "btn-teal" : "btn-info"}`} type="button" value={subPackage.id}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (!values.packageFeaturesIds.includes(e.target.value)) {
                                                                                // values.packageFeaturesIds.push(e.target.value);
                                                                                var newArr = Object.assign([], values.packageFeaturesIds);
                                                                                newArr.push(e.target.value);
                                                                                setFieldValue("packageFeaturesIds", newArr);
                                                                            }
                                                                            else {
                                                                                var newArr = Object.assign([], values.packageFeaturesIds.filter(x => x !== e.target.value));
                                                                                setFieldValue("packageFeaturesIds", newArr);
                                                                            }


                                                                        }}
                                                                    >
                                                                        {values.packageFeaturesIds.includes(subPackage.id) ? "Remove Feature" : "Choose Feature"}
                                                                    </button>}
                                                                {!isAddablePackage &&
                                                                    <span className={`fw-bold fs-12 text-danger`}>
                                                                        {notAddableText}
                                                                    </span>}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                )}
                            </div>
                        </section>
                        {<DrNavigator backAddress="MAIN_PACKAGES" nextAddress="PAYMENT" disabled={!isValid}
                            callback={() => {


                                // var mainPackageId = values.mainPackageId || values.packageFeaturesIds[0];

                                // var requestPackageFeaturesIds = Object.assign([], values.mainPackageId?.length > 0 ? values.packageFeaturesIds : values.packageFeaturesIds.splice(1))

                                return Promise.all([
                                    // dispatch(withActionPromise(dispatch(setPackagesSnapshotMainPackage(mainPackageId)))),
                                    dispatch(withActionPromise(dispatch(setPackagesSnapshotPackageFeatures(values.packageFeaturesIds)))),
                                    dispatch(withActionPromise(dispatch(setCreatePackageSnapshotPageSubmission({ packageFeaturesPage: true }))))
                                ]);
                            }}
                        />}
                    </form>
                </div>
            )
            }
        </Formik>
    )
}

export default PackageFeatures
