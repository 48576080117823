const types = {
    GET_ALL_SMARTPRICER_STRATEGIES_REQUESTED: "GET_ALL_SMARTPRICER_STRATEGIES_REQUESTED",
    GET_ALL_SMARTPRICER_STRATEGIES_SUCCESS: "GET_ALL_SMARTPRICER_STRATEGIES_SUCCESS",
    GET_SMARTPRICER_STRATEGY_REQUESTED: "GET_SMARTPRICER_STRATEGY_REQUESTED",
    GET_SMARTPRICER_STRATEGY_SUCCESS: "GET_SMARTPRICER_STRATEGY_SUCCESS",

    GET_ALL_SMARTPRICER_STRATEGY_QUESTIONS_REQUESTED: "GET_ALL_SMARTPRICER_STRATEGY_QUESTIONS_REQUESTED",
    GET_ALL_SMARTPRICER_STRATEGY_QUESTIONS_SUCCESS: "GET_ALL_SMARTPRICER_STRATEGY_QUESTIONS_SUCCESS",

    CREATE_SMARTPRICER_STRATEGY_REQUESTED: "CREATE_SMARTPRICER_STRATEGY_REQUESTED",
    UPDATE_SMARTPRICER_STRATEGY_REQUESTED: "UPDATE_SMARTPRICER_STRATEGY_REQUESTED",
    DELETE_SMARTPRICER_STRATEGY_REQUESTED: "DELETE_SMARTPRICER_STRATEGY_REQUESTED",

    ADD_REMOVE_ASINS_SMARTPRICER_STRATEGY_REQUESTED: "ADD_REMOVE_ASINS_SMARTPRICER_STRATEGY_REQUESTED",

    UPDATE_SMARTPRICER_DEFAULT_REQUESTED: "UPDATE_SMARTPRICER_DEFAULT_REQUESTED",

    CLEAR_ALL_SMARTPRICER_DATA: "CLEAR_ALL_SMARTPRICER_DATA",
}

export default types;

export const getAllSmartPricerStrategies = (payload) => {
    return { type: types.GET_ALL_SMARTPRICER_STRATEGIES_REQUESTED, payload }
}

export const getAllSmartPricerStrategiesSuccess = (payload) => {
    return { type: types.GET_ALL_SMARTPRICER_STRATEGIES_SUCCESS, payload };
};

export const getSmartPricerStrategyById = (payload) => {
    return { type: types.GET_SMARTPRICER_STRATEGY_REQUESTED, payload }
}

export const getSmartPricerStrategyByIdSuccess = (payload) => {
    return { type: types.GET_SMARTPRICER_STRATEGY_SUCCESS, payload }
}

export const getAllSmartPricerStrategyQuestions = (payload) => {
    return { type: types.GET_ALL_SMARTPRICER_STRATEGY_QUESTIONS_REQUESTED, payload }
}

export const getAllSmartPricerStrategyQuestionsSuccess = (payload) => {
    return { type: types.GET_ALL_SMARTPRICER_STRATEGY_QUESTIONS_SUCCESS, payload }
}

export const createSmartPricerStrategy = (payload) => {
    return { type: types.CREATE_SMARTPRICER_STRATEGY_REQUESTED, payload }
}

export const updateSmartPricerStrategy = (payload) => {
    return { type: types.UPDATE_SMARTPRICER_STRATEGY_REQUESTED, payload }
}

export const deleteSmartPricerStrategy = (payload) => {
    return { type: types.DELETE_SMARTPRICER_STRATEGY_REQUESTED, payload }
}

export const addRemoveAsinsFromSmartPricerStrategy = (payload) => {
    return { type: types.ADD_REMOVE_ASINS_SMARTPRICER_STRATEGY_REQUESTED, payload }
}

export const updateSmartPricerStrategyDefault = (payload) => {
    return { type: types.UPDATE_SMARTPRICER_DEFAULT_REQUESTED, payload }
}

export const clearAllSmartPricerData = () => {
    return { type: types.CLEAR_ALL_SMARTPRICER_DATA }
}