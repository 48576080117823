import { applyMiddleware, combineReducers, legacy_createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/root.saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk'
import { reducer as toastrReducer } from 'react-redux-toastr'

import {
    AppReducer,
    CreateCustomerReducer,
    CreateStoreReducer,
    CustomerReducer,
    InventoryReducer,
    MarketReducer,
    OrdersReducer,
    RepricerReducer,
    SettingsReducer,
    StoreReducer,
    PageFilterReducer,
    WarehousesReducer,
    CreateSmartPricerReducer,
    KeepaReducer,
    SmartPricerReducer,
    ShipStationReducer,
    WalletReducer,
    NotificationReducer,
    ReportsReducer,
    TrademarkReducer,
    CreatePackagesReducer,
    PackagesReducer,
    AgreementReducer

} from './reducers/reducer-index'

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware, thunk];

    // only localStorageReducer will be persisted
    const persistConfig = {
        key: "auth",
        whitelist: ['localStorage', 'inventory', 'createCustomerSnapshot', 'createPackagesSnapshot', 'createStoreSnapshot', 'customer', 'settings', 'markets', 'stores', 'pageFilter', 'warehouses', 'smartPricerSnapshot', 'shipstation', 'wallet', 'notifications'],
        storage
    }

    const reducers = combineReducers({
        app: AppReducer,
        customer: CustomerReducer,
        createCustomerSnapshot: CreateCustomerReducer,
        settings: SettingsReducer,
        createStoreSnapshot: CreateStoreReducer,
        markets: MarketReducer,
        stores: StoreReducer,
        inventory: InventoryReducer,
        orders: OrdersReducer,
        reprizer: RepricerReducer,
        pageFilter: PageFilterReducer,
        warehouses: WarehousesReducer,
        smartPricerSnapshot: CreateSmartPricerReducer,
        smartPricer: SmartPricerReducer,
        keepa: KeepaReducer,
        toastr: toastrReducer,
        shipstation: ShipStationReducer,
        wallet: WalletReducer,
        notifications: NotificationReducer,
        reports: ReportsReducer,
        trademark: TrademarkReducer,
        createPackagesSnapshot: CreatePackagesReducer,
        packages: PackagesReducer,
        agreement: AgreementReducer
    });

    const persistedReducer = persistReducer(persistConfig, reducers);

    const store = legacy_createStore(
        persistedReducer,
        composeWithDevTools(applyMiddleware(...middlewares))
    )

    sagaMiddleware.run(rootSaga);
    const persistor = persistStore(store);
    return { store, persistor };
}

const { store, persistor } = configureStore();

export { configureStore, store, persistor };