import '../../../i18n';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRouteByKey } from '../../../routes';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

function DrBreadCrump({ breadCrumps = [] }) {
    const { REACT_APP_ROLES_STORE, REACT_APP_ROLES_GUEST, REACT_APP_ROLES_USER } = process.env;
    const navigate = useNavigate();
    const { t } = useTranslation('constants');
    const customerInfo = useSelector(state => state.customer.customerInfo);
    const isLoggedin = useSelector(state => state.customer?.isLoggedin);

    var logoNavigateKey = classNames({
        'HOME': isLoggedin && !(customerInfo?.role?.includes(REACT_APP_ROLES_USER) || customerInfo?.role?.includes(REACT_APP_ROLES_GUEST) || customerInfo?.role?.includes(REACT_APP_ROLES_STORE)),
        'LOGIN': !isLoggedin,
        'MARKET_SELECTION': isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_USER), // user
        'MAIN_PACKAGES': isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_GUEST), //guest
        'WAREHOUSE_HOME': isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_STORE) // store
    });
    return (
        <>
            <div className="page-header">
                <h1 className="page-title my-auto">{breadCrumps?.length > 0 ? breadCrumps[breadCrumps.length - 1].navigationText : t(('breadCrumps.home'))}</h1>
                <div>
                    <ol className="breadcrumb breadcrumb-style1 mb-0">
                        <li className={`breadcrumb-item cursor-pointer ${breadCrumps.length === 0 ? "active" : ""}`}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(getRouteByKey(logoNavigateKey))
                            }}>
                            <span>{t(('breadCrumps.home'))}</span>
                        </li>
                        {breadCrumps && breadCrumps.map((breadCrump, index) => {
                            return (
                                <li key={`bc-${index}`} className={`breadcrumb-item cursor-pointer no-select ${breadCrumps.length - 1 !== index ? "" : "active"}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(breadCrump.navigationPath)
                                    }}>

                                    {breadCrumps.length - 1 !== index && <span>{breadCrump.navigationText}</span>}
                                    {breadCrumps.length - 1 === index && breadCrump.navigationText}
                                </li>
                            )
                        })
                        }
                    </ol>
                </div>
            </div>



            {/* <ol className="breadcrumb">
                <li className={`breadcrumb-item cursor-pointer ${breadCrumps.length === 0 ? "active" : ""}`}
                    onClick={(e) => {
                        e.preventDefault();
                        navigate("/home")
                    }}>
                    <span className="no-select">{t(('breadCrumps.home'))}</span>
                </li>
                {breadCrumps && breadCrumps.map((breadCrump, index) => {
                    return (
                        <li key={`bc-${index}`} className={`breadcrumb-item cursor-pointer ${breadCrumps.length - 1 !== index ? "" : "active"}`}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(breadCrump.navigationPath)
                            }}>

                            {breadCrumps.length - 1 !== index && <span className="no-select">{breadCrump.navigationText}</span>}
                            {breadCrumps.length - 1 === index && <span className="no-select">{breadCrump.navigationText}</span>}
                        </li>
                    )
                })
                }
            </ol > */}
        </>
    );
}
export default DrBreadCrump