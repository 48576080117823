import 'vanilla-cookieconsent/dist/cookieconsent.js';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { useEffect } from "react";
import './dr-cookieconsent.component.css';

function DrCookieConsent() {
    useEffect(() => {
        const cc = window.initCookieConsent();

        cc.run({
            autorun: true,
            current_lang: 'en',
            autoclear_cookies: true,
            page_scripts: true,
            gui_options: {
                consent_modal: {
                    layout: 'box',
                    position: 'bottom left',
                    transition: 'slide',
                    swap_buttons: false
                }
            },
            onFirstAction: function (user_preferences, cookie) {
                // callback triggered only once
            },
            onAccept: function (cookie) {
                // ... cookieconsent accepted
            },
            onChange: function (cookie, changed_preferences) {
                // ... cookieconsent preferences were changed
            },
            languages: {
                en: {
                    consent_modal: {
                        title: '',
                        description: 'By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.',
                        primary_btn: {
                            text: 'Accept',
                            role: 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Settings',
                            role: 'settings'
                        }
                    },
                    settings_modal: {
                        title: 'Cookie preferences',
                        save_settings_btn: 'Save settings',
                        accept_all_btn: 'Accept all',
                        blocks: [
                            {
                                title: 'Strictly necessary cookies',
                                description: 'These cookies are essential for the proper functioning of DrivrzLane. Without these cookies, the website would not work properly.',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true
                                }
                            },
                            {
                                title: 'Analytics cookies',
                                description: 'These cookies collect information about how you use the website. All of the data is anonymized and cannot be used to identify you.',
                                toggle: {
                                    value: 'analytics',
                                    enabled: true,
                                    readonly: false
                                }
                            }
                        ]
                    }
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}

export default DrCookieConsent