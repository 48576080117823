import React, { useEffect, useState } from 'react'
import { DrBreadCrump, DrDualDatePicker, DrEmptyMailTemplate, DrFileUploadInput, DrSelectBox } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import i18next from 'i18next';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import noImage from '../../assets/img/pngs/no-image.png'
import avatar from '../../assets/img/users/profile3.png'
import { getOrderItem } from '../../state-management/actions/orders.actions';
import { setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { useTranslation } from 'react-i18next';
import { modals, notificationMessagesTypes } from '../../utils/constants';
import { defaultMailTemplatesBodyTextOptions, notificationMessageTypesOptions } from '../../components/hoc/Constants/dr-constants.component';
import { getCreateNegativeFeedbackRemoval, getMessagingActionsForOrder, getNotificationsByOrderNo, sendNotificationMessage } from '../../state-management/actions/notification.actions';

function MessageDetailPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('messageDetailPage');
    const customerInfo = useSelector(state => state.customer.customerInfo);
    const { orderNo, messageId } = useParams();
    const selectedOrderDetail = useSelector(state => state.orders.selectedOrder);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const notificationMessages = useSelector(state => state.notifications.notificationMessages);
    const messagingActionsForOrder = useSelector(state => state.notifications.messagingActionsForOrder);
    const orderTrackingStatuses = useSelector(state => state.orders.orderTrackingStatuses);
    const [orderDetailData, setOrderDetailData] = useState(null);
    const [selectedTemplateOption, setSelectedTemplateOption] = useState("-1");
    const language = useSelector(state => state.settings.language);

    useEffect(() => {
        if (orderNo) {
            dispatch(withActionPromise(getOrderItem({ orderId: orderNo })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId, orderNo, messageId]);

    useEffect(() => {
        if (selectedOrderDetail) {
            var data = {
                productPicture: selectedOrderDetail?.productPicture,
                orderNo: selectedOrderDetail?.orderNumber,
                orderStatus: selectedOrderDetail?.orderStatus,
                totalPrice: selectedOrderDetail?.ordertotalCurrencyCode + " " + selectedOrderDetail?.ordertotalAmount,

                orderHistory: {
                    purchaseDate: selectedOrderDetail?.purchaseDate,
                    salesChannel: selectedOrderDetail?.salesChannel,
                    shipmentServiceLevelCategory: selectedOrderDetail?.shipmentServiceLevelCategory,
                    earliestShipDate: selectedOrderDetail?.earliestShipDate,
                    latestShipDate: selectedOrderDetail?.latestShipDate,
                    earliestDeliveryDate: selectedOrderDetail?.earliestDeliveryDate,
                    latestDeliveryDate: selectedOrderDetail?.latestDeliveryDate
                },
                shippingDetails: {
                    carrierName: selectedOrderDetail.shipServiceLevel,
                    trackingNo: selectedOrderDetail.trackingNo,
                    buyerName: selectedOrderDetail.buyerName,
                    phone: selectedOrderDetail?.shippingAddress?.phone,
                    addressLine1: selectedOrderDetail?.shippingAddress?.addressLine1,
                    addressLine2: selectedOrderDetail?.shippingAddress?.addressLine2,
                    city: selectedOrderDetail?.shippingAddress?.city,
                    stateOrRegion: selectedOrderDetail?.shippingAddress?.stateOrRegion,
                    countryCode: selectedOrderDetail?.shippingAddress?.countryCode,
                    postalCode: selectedOrderDetail?.shippingAddress?.postalCode
                },
                orderDetailList: selectedOrderDetail.orderDetailList,
                buyerSellerOrderNumber: selectedOrderDetail.buyerSellerOrderNumber
            }

            if (selectedOrderDetail.orderDetailList && selectedOrderDetail.orderDetailList.length > 0) {
                data.title = selectedOrderDetail.orderDetailList[0].title;
                data.asin = selectedOrderDetail.orderDetailList[0].asin;
                data.sellerSKU = selectedOrderDetail.orderDetailList[0].sellerSKU;
                data.price = selectedOrderDetail.orderDetailList[0].itemPriceCurrencyCode + " " + selectedOrderDetail.orderDetailList[0].itemPriceAmount;;
                data.quantity = selectedOrderDetail.orderDetailList[0].quantityOrdered;
            }

            setOrderDetailData(data);

            dispatch(withActionPromise(getNotificationsByOrderNo(selectedOrderDetail?.orderNumber)));
            dispatch(withActionPromise(getMessagingActionsForOrder({ orderNo: selectedOrderDetail?.orderNumber, customerMainSellerId: selectedStore?.customerMainSellerId })));
            // dispatch(withActionPromise(getCreateNegativeFeedbackRemoval({ orderNo: selectedOrderDetail?.orderNumber, customerMainSellerId: selectedStore?.customerMainSellerId })));
            // dispatch(withActionPromise(getNotificationAttributes({ orderNo: selectedOrderDetail?.orderNumber, customerMainSellerId: selectedStore?.customerMainSellerId })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrderDetail?.orderNumber]);

    // useEffect(() => {
    //     if (selectedTemplateOption && selectedTemplateOption !== "-1") {
    //         setTextareaValue(i18next.t('drMailTemplates:' + selectedTemplateOption + '.text', { customerName: orderDetailData?.shippingDetails?.buyerName || "NoName", storeName: selectedStore?.mainSellerName }))
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [language]);

    const initialNotificationValues = {
        customerMainSellerId: selectedStore?.customerMainSellerId,
        amazonOrderNumber: selectedOrderDetail?.orderNumber,

        allowedNotificationMessageTypeOptions: notificationMessageTypesOptions.filter(item1 =>
            !!messagingActionsForOrder.find(item2 => (item1.value === item2 || item1.value === notificationMessagesTypes.unexpectedProblem))),

        selectedMessageTypeOption: null,
        selectedMessageTemplateOption: null,
        messageText: "",
        isTextAreaDisabled: true,
        isAttachmentDisabled: true,
        coverageDateInterval: null,
        fileContent: []
    }


    function getLatestUpdateDate(sourceData) {
        if (sourceData && sourceData.length > 0) {
            var sortedData = sourceData?.sort((b, a) => moment(a.sendDate, "YYYY-MM-DDThh:mm:ss").valueOf() - moment(b.sendDate, "YYYY-MM-DDThh:mm:ss").valueOf());
            return sortedData[0].sendDate;
        }
        else {
            return null;
        }
    };

    const handleSubmit = async (values) => {
        var requestData = {
            selectedMessageTypeOption: values.selectedMessageTypeOption,
            notificationRequestData: {
                customerMainSellerId: values.customerMainSellerId,
                amazonOrderNumber: values.amazonOrderNumber
            }
        }

        if (values.selectedMessageTypeOption === notificationMessagesTypes.confirmOrderDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.confirmServiceDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.unexpectedProblem
            || values.selectedMessageTypeOption === notificationMessagesTypes.confirmCustomizationDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.confirmDeliveryDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.digitalAccessKey) {

            requestData.notificationRequestData.messageText = values.messageText;
        }

        if (values.selectedMessageTypeOption === notificationMessagesTypes.confirmCustomizationDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.amazonMotors
            || values.selectedMessageTypeOption === notificationMessagesTypes.confirmDeliveryDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.digitalAccessKey
            || values.selectedMessageTypeOption === notificationMessagesTypes.legalDisclose
            || values.selectedMessageTypeOption === notificationMessagesTypes.warranty
            || values.selectedMessageTypeOption === notificationMessagesTypes.sendInvoice) {

            requestData.notificationRequestData.fileContent = values.fileContent;
        }

        if (values.selectedMessageTypeOption === notificationMessagesTypes.warranty) {

            requestData.notificationRequestData.coverageStartDate = values.coverageDateInterval && values.coverageDateInterval.length > 0 ? values.coverageDateInterval[0] : null;
            requestData.notificationRequestData.coverageEndDate = values.coverageDateInterval && values.coverageDateInterval.length > 0 ? values.coverageDateInterval[1] : null;
        }

  
        dispatch(sendNotificationMessage(requestData))
    }

    return (
        <>
            <div className="page-header mb-0 mt-4 mb-2 row row-md row-lg justify-content-between w-100">
                <div className="col-lg-auto col-md-auto col-sm-auto col-xs-12">
                    <DrBreadCrump
                        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.messages'), navigationPath: "/messages" },
                        { navigationText: i18next.t('constants:breadCrumps.messageDetail'), navigationPath: "" }]} >
                    </DrBreadCrump>
                </div>
            </div>
            <div className="row row-md row-lg d-flex justify-content-center bg-light">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            {orderDetailData &&
                                <>
                                    <form>
                                        <div className="row row-md row-lg justify-content-sm-center justify-content-md-center justify-content-center">
                                            <div className="custom-card bg-gray-300 col-xl-3 col-lg-3 col-md-10 col-sm-10 col-10 cursor-pointer"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (orderDetailData.orderDetailList?.length > 0) {
                                                        dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: orderDetailData.orderDetailList?.map(x => x.imageUrl) || [noImage] }))
                                                    }
                                                }}
                                            >
                                                <div className="row justify-content-start">
                                                    {orderDetailData.orderDetailList?.length > 0 && orderDetailData.orderDetailList.slice(0, (orderDetailData.orderDetailList.length > 1 ? 2 : orderDetailData.orderDetailList.length)).map((product, index) => {

                                                        return (
                                                            <div key={"product-pic-" + index} className={`px-0 rounded-0 border ${orderDetailData.orderDetailList.length > 1 ? "col-6 " : "col-12 "}`} >
                                                                <img src={product.imageUrl || noImage} alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                                                ></img>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                {orderDetailData.orderDetailList?.length > 2 &&
                                                    <div className="row justify-content-start">
                                                        {orderDetailData.orderDetailList.slice(2, (orderDetailData.orderDetailList.length > 3 ? 4 : orderDetailData.orderDetailList.length)).map((product, index) => {

                                                            return (
                                                                <div key={"product-pic-" + (index + 2)} className="col-6 px-0 rounded-0 border">
                                                                    <img src={product.imageUrl || noImage} alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                                                    ></img>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                }
                                                {orderDetailData.orderDetailList.length > 1 && <span className="badge bg-danger tx-bold" style={{ position: 'absolute', top: '-8px', right: '-8px' }}>{orderDetailData.orderDetailList.length}</span>}

                                            </div>

                                            <div className="col-xl-9 col-lg-9 col-md-10 col-sm-10 col-10">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h4 className="mb-3">Order Summary</h4>
                                                    </div>
                                                    <div className="col-auto">
                                                        <span className="badge ripple btn-sm btn-teal btn-rounded tx-bold"
                                                            onClick={(e) => {
                                                                navigate('/order-detail/' + orderNo);
                                                            }}
                                                        >{orderDetailData.orderNo}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3 tx-bold">Gönderim Tarihi:</div>
                                                    <div className="col-sm-9 px-0">{moment(orderDetailData.orderHistory.earliestShipDate).format('LLLL')} to  {moment(orderDetailData.orderHistory.latestShipDate).format('LLLL')}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3 tx-bold">Teslim Edilme Tarihi:</div>
                                                    <div className="col-sm-9 px-0">{moment(orderDetailData.orderHistory.earliestDeliveryDate).format('LLLL')} to {moment(orderDetailData.orderHistory.latestDeliveryDate).format('LLLL')}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3 tx-bold">Satın Alma Tarihi:</div>
                                                    <div className="col-sm-9 px-0">{moment(orderDetailData.orderHistory.purchaseDate).format('LLLL')}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3 tx-bold">Kargo Hizmeti:</div>
                                                    <div className="col-sm-9 px-0">{orderDetailData.orderHistory.shipmentServiceLevelCategory}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3 tx-bold">Fulfillment:</div>
                                                    <div className="col-sm-9 px-0">Seller</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3 tx-bold">Satış Mağazası:</div>
                                                    <div className="col-sm-9 px-0">{orderDetailData.orderHistory.salesChannel}</div>
                                                </div>

                                            </div>
                                        </div>

                                    </form>
                                    <hr className="my-3" />
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className="mb-3 text-center">Shipment Status</h4>
                                            <div className="vtimeline">
                                                {orderTrackingStatuses?.length > 0 &&
                                                    orderTrackingStatuses.map((trackingObject, index) => {
                                                        var colorName = (trackingObject.groupName === "0" ? "bg-info-transparent" : "")
                                                            || (trackingObject.groupName === "1" ? "bg-success-transparent" : "")
                                                            || (trackingObject.groupName === "2" ? "bg-success" : "")
                                                            || "bg-info-transparent";
                                                        return (
                                                            <div key={"trackingObject_" + index} className={`timeline-wrapper timeline-wrapper-dark ${index % 2 === 0 ? "timeline-inverted" : ""}`} >
                                                                <div className="timeline-badge"></div>
                                                                <div className={`timeline-panel ${colorName}`} >
                                                                    <div className="timeline-heading ">
                                                                        <h6 className="timeline-title">{trackingObject.buyerSellerOrderStatus}</h6>
                                                                    </div>

                                                                    <div className="timeline-footer d-flex align-items-center flex-wrap">
                                                                        <span className="ms-md-auto ms-2">{moment(trackingObject.updatedDate).format('LLLL')}</span>
                                                                    </div>
                                                                    <div className="timeline-body">
                                                                        {trackingObject.CarrierName?.length > 0 &&
                                                                            <p>
                                                                                {trackingObject.carrierName}
                                                                            </p>
                                                                        }
                                                                        {trackingObject.trackingNumber?.length > 0 &&
                                                                            <p>
                                                                                {trackingObject.trackingNumber}
                                                                            </p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {!orderTrackingStatuses?.length > 0 &&

                                                <h6 className="mb-3 text-center">NO TRACKING INFO</h6>
                                            }
                                        </div>
                                    </div>

                                </>}
                        </div>
                    </div>
                    {notificationMessages && notificationMessages.length > 0 &&
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row" style={{ maxHeight: '500px', overflowY: "auto" }}>
                                    <div className="col">
                                        <div className="main-chat-body" id="ChatBody">
                                            <div className="content-inner">
                                                {notificationMessages && notificationMessages.length > 0 && <label className="main-chat-time"><span> {moment(getLatestUpdateDate(notificationMessages), "YYYY-MM-DDThh:mm:ss").fromNow()}</span></label>}

                                                {notificationMessages && notificationMessages.length > 0
                                                    && notificationMessages?.sort((a, b) => moment(a.sendDate, "YYYY-MM-DDThh:mm:ss").valueOf() - moment(b.sendDate, "YYYY-MM-DDThh:mm:ss").valueOf())?.map((message, index) => {
                                                        var newSendDate = "";
                                                        // var dayDifference = moment().diff(moment(message.sendDate, "YYYY-MM-DDThh:mm:ss"), 'days')
                                                        // if(dayDifference === 0){
                                                        //     newSendDate = moment(message.sendDate, "YYYY-MM-DDThh:mm:ss").format('LT');
                                                        // }
                                                        // else if (dayDifference === 1){
                                                        //     newSendDate = moment(message.sendDate, "YYYY-MM-DDThh:mm:ss").format("LT");
                                                        // }
                                                        // var fromNow = moment(message.sendDate, "YYYY-MM-DDThh:mm:ss").fromNow();
                                                        newSendDate = moment(message.sendDate, "YYYY-MM-DDThh:mm:ss").calendar(null, {
                                                            // when the date is closer, specify custom values
                                                            lastWeek: '[Last Week]',
                                                            lastDay: '[Yesterday At] LT',
                                                            sameDay: '[Today At] LT',
                                                            nextDay: '[Tomorrow At]',
                                                            nextWeek: 'dddd',
                                                            // when the date is further away, use from-now functionality    
                                                            sameElse: "LLLL"
                                                            // sameElse: function () {
                                                            //     return "[" + fromNow + "]";
                                                            // }
                                                        })

                                                        return (
                                                            <div key={"media_row_" + index} className={`media ${message.isSendMe ? "" : "flex-row-reverse"}`}>
                                                                {message.isSendMe && <div className="main-img-user online border bg-gray-200"><img alt="avatar" src={avatar} /></div>}
                                                                <div className="media-body">
                                                                    <DrEmptyMailTemplate templateKey={index} htmlText={message.messageText}></DrEmptyMailTemplate>
                                                                    <div>
                                                                        <span>{newSendDate}</span>
                                                                    </div>
                                                                </div>
                                                                {!message.isSendMe && <div className="main-img-user online border bg-gray-200"><img alt="avatar" src={customerInfo?.profilePicture || avatar} /></div>}
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        {messagingActionsForOrder && messagingActionsForOrder?.find(item2 => notificationMessagesTypes.negativeFeedbackRemoval === item2) &&
                                            <button className="btn btn-warning-ghost btn-wave waves-effect waves-light shadow"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    dispatch(withActionPromise(getCreateNegativeFeedbackRemoval({ orderNo: selectedOrderDetail?.orderNumber, customerMainSellerId: selectedStore?.customerMainSellerId })));
                                                }}>
                                                <i className="fe fe-x-circle me-1"></i>
                                                Remove Negative Feedbacks
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <Formik
                        initialValues={initialNotificationValues}
                        validateOnBlur={true}
                        onSubmit={handleSubmit}
                        // validationSchema={storePreferencesSchema}
                        enableReinitialize={true}
                        validateOnMount={true}
                    >
                        {({
                            isValid,
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setTouched,
                            setFieldValue,

                        }) => (
                            // <form className="was-validated">
                            <form >
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div className="row justify-content-start mb-3">
                                            <div className="col-auto">
                                                <Field name={'selectedMessageTypeOption'}
                                                    onBlur={() => { setTouched({ ...touched.selectedMessageTypeOption, 'selectedMessageTypeOption': true }) }}
                                                    component={DrSelectBox}
                                                    placeholder="Select Message Type"
                                                    // disabled={!values.enableAutoDeleteOutCriteria}
                                                    options={values.allowedNotificationMessageTypeOptions}
                                                    selectedValue={values?.selectedMessageTypeOption}
                                                    onChange={(e) => {
                                                        setFieldValue("selectedMessageTypeOption", e.value);
                                                        setFieldValue("isTextAreaDisabled", (e.value === notificationMessagesTypes.amazonMotors
                                                            || e.value === notificationMessagesTypes.legalDisclose
                                                            || e.value === notificationMessagesTypes.warranty
                                                            || e.value === notificationMessagesTypes.sendInvoice));

                                                        setFieldValue("isAttachmentDisabled", (e.value === notificationMessagesTypes.confirmServiceDetails
                                                            || e.value === notificationMessagesTypes.confirmOrderDetails
                                                            || e.value === notificationMessagesTypes.unexpectedProblem));

                                                        setFieldValue("fileContent", []);
                                                        setFieldValue("messageText", "");
                                                        setFieldValue("selectedMessageTemplateOption", "-1");
                                                    }} />
                                                {(touched.selectedMessageTypeOption && errors.selectedMessageTypeOption) && <label type="invalid" className="form-control is-invalid">
                                                    {errors.selectedMessageTypeOption}
                                                </label>}
                                            </div>
                                            <div className="col-auto">
                                                <Field name={'selectedMessageTemplateOption'}
                                                    onBlur={() => { setTouched({ ...touched.selectedMessageTemplateOption, 'selectedMessageTemplateOption': true }) }}
                                                    component={DrSelectBox}
                                                    placeholder="Select Template"
                                                    disabled={values?.isTextAreaDisabled}
                                                    options={defaultMailTemplatesBodyTextOptions}
                                                    onChange={(e) => {

                                                        if (e.value && e.value !== "-1") {
                                                            var templateData = {
                                                                customerName: orderDetailData?.shippingDetails?.buyerName || "NoName",
                                                                storeName: selectedStore?.mainSellerName,
                                                                orderNo: orderDetailData?.orderNo,
                                                                carrierName: orderDetailData?.shippingDetails?.carrierName || "---",
                                                                trackingNo: orderDetailData?.shippingDetails?.trackingNo || "---"
                                                            }
                                                            setFieldValue("messageText", i18next.t('drMailTemplates:' + e.value + '.text', templateData))
                                                        }
                                                        else {
                                                            setFieldValue("messageText", "");
                                                        }

                                                    }}
                                                />
                                                {(touched.selectedMessageTemplateOption && errors.selectedMessageTemplateOption) && <label type="invalid" className="form-control is-invalid">
                                                    {errors.selectedMessageTemplateOption}
                                                </label>}
                                            </div>
                                            {values?.selectedMessageTypeOption === notificationMessagesTypes.warranty && <div className="col-auto">
                                                <DrDualDatePicker
                                                    selectedDates={values?.coverageDateInterval}
                                                    onCloseCallBack={(dateValues) => {
                                                        var dates = [(dateValues[0] ? moment.utc(dateValues[0].toString(), "YYYY/MM/DD").startOf('day').format() : null), (dateValues[1] ? moment.utc(dateValues[1].toString(), "YYYY/MM/DD").startOf('day').format() : null)];
                                                        setFieldValue("coverageDateInterval", dates);
                                                    }}>
                                                </DrDualDatePicker>
                                            </div>}
                                        </div>
                                        <div className="row w-100">
                                            <div className="col">
                                                <textarea id="message-textarea" className={`form-control w-100`} placeholder="Type your message here..." type="html"
                                                    style={{ minHeight: '200px', height: "auto" }}
                                                    value={values?.messageText}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        var textarea = document.getElementById("message-textarea");
                                                        textarea.style.height = "";
                                                        textarea.style.height = Math.min(textarea.scrollHeight, 500) + "px";

                                                        setFieldValue("messageText", e.currentTarget.value)
                                                    }}
                                                    onBlur={() => { setTouched({ ...touched.messageText, 'messageText': true }) }}
                                                    disabled={values?.isTextAreaDisabled}
                                                    required=""
                                                />
                                                <div className="invalid-feedback">Example invalid form file feedback</div>
                                            </div>
                                        </div>
                                        <div className="row w-100 justify-content-end">
                                            <div className="col-auto">
                                                <div className="row">

                                                    {/* <div className="col-auto px-0">
                                                        <nav className="nav">
                                                            <button type="file" accept="image/png, image/gif, image/jpeg" className="btn nav-link" data-bs-toggle="tooltip" title="Add Photo"
                                                                onChange={onFileUploaded} >
                                                                <i className="fe fe-image" />
                                                            </button>
                                                            <button className="btn nav-link" data-bs-toggle="tooltip" title="Attach a File"><i className="fe fe-paperclip" /></button>
                                                        </nav>
                                                    </div> */}
                                                    {!values?.isAttachmentDisabled &&
                                                        <div className="col-auto">
                                                            <Field onBlur={() => { setTouched({ ...touched.fileContent, 'fileContent': true }) }}
                                                                name={'fileContent'}
                                                                component={DrFileUploadInput}
                                                                placeholder="select files"
                                                                accept="application/pdf, image/png, image/jpeg, image/gif,image/bmp, image/webp,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/rdf+xml"
                                                                maxFiles={5}
                                                                maxFileSizeInBytes={10000000}
                                                                multiple
                                                            />
                                                        </div>
                                                    }
                                                    <div className="col-auto px-0">
                                                        <button className="btn btn-info btn-icon btn-wave waves-effect waves-light shadow main-msg-send" data-bs-toggle="tooltip" title="Send Message"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (isValid) {
                                                                    handleSubmit(values);
                                                                }

                                                                // var textarea = document.querySelector("#message-textarea");
                                                                // if (textarea) {
                                                                
                                                                //     let body = <DrEmptyMailTemplate templateKey={"notification"} htmlText={textarea.value} ></DrEmptyMailTemplate>;

                                                                //     var emailData = {
                                                                //         emailFrom: "sellerzeynepyucel@gmail.com",
                                                                //         emailTo: "koray65d3@gmail.com",
                                                                //         emailSubject: "Notification",
                                                                //         emailBody: EmailRender(body),
                                                                //         emailDate: moment.utc()
                                                                //     }
                                                                //     dispatch(withActionPromise(sendNotification(emailData)));
                                                                // }
                                                                // if (selectedMessageType === notificationMessagesTypes.sendInvoice) {
                                                                // var requestData = {
                                                                //     customerMainSellerId: selectedStore?.customerMainSellerId,
                                                                //     amazonOrderNumber: selectedOrderDetail?.orderNumber,
                                                                //     fileContent: []
                                                                // }
                                                                // dispatch(sendInvoiceMessage(requestData));
                                                                // }


                                                            }}><i className="fe fe-send" /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        )
                        }
                    </Formik>
                </div>
            </div>
        </>
    )
}
export default MessageDetailPage