import { useTranslation } from 'react-i18next';
import { DrBreadCrump, DrInput } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import transfer from '../../assets/img/pngs/arrow-right.png'
import logo from '../../assets/img/brand/logo.png'
import { getCardNumber, getCreditCardIcon, getNumbersFromString } from '../../utils/utils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makePaymentToWallet, setSelectedCreditCard } from '../../state-management/actions/wallet.actions';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { ReactSVG } from 'react-svg';

function WalletPaymentPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('walletPage');
    const selectedCreditCard = useSelector(state => state.wallet.selectedCreditCard);

    const initialValues = {
        currency: 0,
        currencyCode: "usd",
        cardId: selectedCreditCard?.cardId || null,
        creaditCardInfo: selectedCreditCard?.cardId ? null
            : {
                cardHolderName: selectedCreditCard?.cardHolderName || "",
                cardNumber: (selectedCreditCard?.cardNumber && getNumbersFromString(selectedCreditCard?.cardNumber)) || "",
                cvc: selectedCreditCard?.cvc || "",
                expirationDateMonth: selectedCreditCard?.expirationDateMonth || 0,
                expirationDateYear: selectedCreditCard?.expirationDateYear || 0,
                isSaveCard: selectedCreditCard?.isSaveCard || false
            },
        description: "Deposit Wallet"
    }

    const paymentSchema = Yup.object().shape({
        currency: Yup.string().required("Maximum stock is required")
            .matches(/^[0-9]+$/, "Must be only digits"),
    });


    return (
        <div >
            <div className="page-header mb-0 mt-4 row row-md row-lg mb-2 d-flex justify-content-between">
                <div className="col-auto">
                    <DrBreadCrump
                        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.myWallet'), navigationPath: "/mywallet" },
                        { navigationText: i18next.t('constants:breadCrumps.cardSelection'), navigationPath: "/card-selection" },
                        { navigationText: i18next.t('constants:breadCrumps.payment'), navigationPath: "" }]} >
                    </DrBreadCrump>
                </div>
            </div>
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            {!selectedCreditCard &&
                                <div className="row row-md row-lg justify-content-center mb-2">
                                    <div className="col-auto">
                                        <button className={`btn btn-info-ghost btn-wave waves-effect waves-light shadow btn-block `}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate(getRouteByKey("MY_WALLET_CARD_SELECTION"));
                                            }}>
                                            <i className="me-2 fe fe-chevrons-right"></i>
                                            {t('walletPaymentPage.goToCardSelection')}
                                        </button>
                                    </div>
                                </div>

                            }
                            {selectedCreditCard &&

                                <Formik
                                    initialValues={initialValues}
                                    validateOnBlur={true}
                                    // onSubmit={handleSubmit}
                                    validationSchema={paymentSchema}
                                    enableReinitialize={true}
                                    validateOnMount={true}
                                >
                                    {({
                                        isValid,
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        setTouched,
                                        setFieldValue
                                    }) => (
                                        <form >
                                            <div className="row row-md row-lg justify-content-center mb-2">
                                                <div className="col-12 col-md-10 col-lg-8 col-xl-8">
                                                    <h3 className="mb-3 mt-3 text-center">
                                                        {t('walletPaymentPage.title')}
                                                    </h3>
                                                    <div className="row justify-content-between mb-3">
                                                        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5  my-auto">
                                                            <div className="row justify-content-center">
                                                                <div className="col-auto px-0">
                                                                    <img src={getCreditCardIcon(selectedCreditCard?.cardNumber)} alt="img" style={{ height: '72px' }} ></img>
                                                                </div>
                                                                <div className="col-auto my-auto">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-auto">
                                                                            <span>{selectedCreditCard.cardHolderName}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-auto">
                                                                            <span>{getCardNumber(selectedCreditCard.cardNumber)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 my-auto px-0">
                                                            <div className="row justify-content-center align-items-center">
                                                                <div className="col-auto px-0 my-auto">
                                                                    {/* <i className="fa fa-ellipsis-h fa-lg my-auto"></i>
                                                                    <i className="fa fa-minus fa-lg my-auto"></i>
                                                                    <i className="fa fa-chevron-right fa-lg my-auto"></i> */}
                                                                    <img src={transfer} alt="img" style={{ height: '64px' }} ></img>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 my-2">
                                                            <div className="row bg-gray-200 rounded-10 border justify-content-center" >
                                                                <div className="col-auto px-0 py-auto my-auto" style={{ minWidth: '190px', height: '72px' }}>
                                                                    <div className="row h-100">
                                                                        <div className="col-12 px-0 my-auto" >
                                                                            <ReactSVG src="../../../assets/img/brand/logo.svg" />
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center mb-3">
                                                        <div className="col-auto">
                                                            <h4 className="mb-3 mt-3 text-center">
                                                                {t('walletPaymentPage.currencyAmountTitle')}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center mb-5">
                                                        <div className="col-auto border-top-0 border-start-0 border-end-0 borber-bottom border-primary">
                                                            <DrInput className="form-control-lg tx-44"
                                                                placeholder="" name="currency" type="number" step="1" min="0"
                                                                style={{ width: '175px' }}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'currency': true }) }}
                                                                value={values.currency || ""}
                                                                iconPosition={"left"}
                                                                icon={<i className="fe fe-dollar-sign" style={{ fontSize: '32px' }}></i>}
                                                                isInvalid={(touched.currency && errors.currency)}
                                                                isValid={touched.currency && !errors.currency}
                                                                validationText={errors.currency}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center mb-3">
                                                        <div className="col-12">
                                                            <div className="row justify-content-between">
                                                                <div className="col-6">
                                                                    <div className="row justify-content-end">
                                                                        <div className="col-12">
                                                                            <button className={`btn btn-info-ghost btn-wave waves-effect waves-light shadow btn-block `}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    dispatch(withActionPromise(setSelectedCreditCard(null)));
                                                                                    navigate(getRouteByKey("MY_WALLET"));
                                                                                }}
                                                                            >
                                                                                <i className="me-2 fe fe-chevrons-right"></i>
                                                                                {t('walletPaymentPage.cancel')}</button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="row justify-content-start">
                                                                        <div className="col-12">
                                                                            <button className={`btn btn-success btn-wave waves-effect waves-light shadow btn-block ${isValid ? "" : "disabled"}`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    

                                                                                    if (isValid) {
                                                                                        dispatch(withActionPromise(makePaymentToWallet(values)))
                                                                                    }
                                                                                }}
                                                                            >{t('walletPaymentPage.confrmPayment')}</button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                    }
                                </Formik>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}
export default WalletPaymentPage

