import { useTranslation } from 'react-i18next';
import { DrBreadCrump } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import paypal from '../../assets/img/cards/PayPal.png'
import stripe from '../../assets/img/cards/Stripe.png'
import googlepay from '../../assets/img/cards/GooglePay.png'

function PaymentMethodPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('walletPage');

    return (
        <div >
            <div className="page-header mb-0 mt-4 row row-md row-lg mb-2 d-flex justify-content-between">
                <div className="col-auto">
                    <DrBreadCrump
                        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.myWallet'), navigationPath: "/mywallet" },
                        { navigationText: i18next.t('constants:breadCrumps.paymentMethod'), navigationPath: "" }]} >
                    </DrBreadCrump>
                </div>
            </div>
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row row-md row-lg justify-content-center">
                                <div className="col-12 col-md-10 col-lg-8 col-xl-8">
                                    <h3 className="mb-3 mt-3 text-center">
                                        {t('walletPaymentMethodPage.title')}
                                    </h3>
                                    <div className="row row-md row-lg border border-gray-300 rounded m-1">
                                        <div className="col-12 px-0">
                                            <label className="row my-0 ms-0 me-0 bg-gray-200 px-0 py-3 cursor-pointer"
                                                onClick={(e) => {
                                                    // e.preventDefault();
                                                    navigate(getRouteByKey("MY_WALLET_CARD_SELECTION"))
                                                }}  >
                                                <div className="col-auto my-auto">
                                                    {/* <i className="w-6 me-2 fa fa-cc-stripe" style={{ fontSize: '1.4em', color: 'var(--primary-bg-color)' }}></i> */}
                                                    <img src={stripe} alt="img" style={{ height: '36px' }} ></img>
                                                </div>
                                                <div className="col my-auto px-0">
                                                    <span>{t('walletPaymentMethodPage.payWithCard')}</span>
                                                </div>
                                                <div className="col-auto my-auto">
                                                    <i className="w-6 me-2 fa fa-chevron-right"></i>
                                                </div>

                                            </label>
                                        </div>
                                    </div>
                                    <div className="row row-md row-lg border border-gray-300 rounded m-1">
                                        <div className="col-12 px-0">
                                            <label className="row my-0 ms-0 me-0 bg-gray-200 px-0 py-3 cursor-pointer ripple">
                                                <div className="col-auto my-auto">
                                                    {/* <i className="w-6 me-2 fa fa-cc-paypal" style={{ fontSize: '1.4em', color: 'var(--blue)' }}></i> */}
                                                    <img src={paypal} alt="img" style={{ height: '36px' }} ></img>
                                                </div>
                                                <div className="col my-auto px-0">
                                                    <span>{t('walletPaymentMethodPage.payViaPaypal')}</span>
                                                </div>
                                                <div className="col-auto my-auto">
                                                    <i className="w-6 me-2 fa fa-chevron-right"></i>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row row-md row-lg border border-gray-300 rounded m-1">
                                        <div className="col-12 px-0">
                                            <label className="row my-0 ms-0 me-0 bg-gray-200 px-0 py-3 cursor-pointer ripple">
                                                <div className="col-auto my-auto">
                                                    {/* <i className="w-6 me-2 fa fa-cc-paypal" style={{ fontSize: '1.4em', color: 'var(--blue)' }}></i> */}
                                                    <img src={googlepay} alt="img" style={{ height: '36px' }} ></img>
                                                </div>
                                                <div className="col my-auto px-0">
                                                    <span>{t('walletPaymentMethodPage.payViaGoogle')}</span>
                                                </div>
                                                <div className="col-auto my-auto">
                                                    <i className="w-6 me-2 fa fa-chevron-right"></i>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default PaymentMethodPage

