import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DrBreadCrump, DrInput, DrPasswordInput } from "../../components/component-index";
import * as Yup from 'yup';
import { getCustomerInfo, updateCustomerInfo } from "../../state-management/actions/customer.actions";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import './account.scss'
import avatar from '../../assets/img/users/profile3.png'
import Swal from "sweetalert2";

function AccountPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('accountPage');
    const customerInfo = useSelector(state => state.customer.customerInfo);

    useEffect(() => {
        if (customerInfo?.customerId) {
            dispatch(getCustomerInfo(customerInfo?.customerId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = {
        firstName: customerInfo?.firstName,
        lastName: customerInfo?.lastName,
        email: customerInfo?.email,
        oldPassword: null,
        newPassword: null,
        newPasswordAgain: null
    };

    const customerInfoSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name is required'),
        lastName: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Last name is required'),
        newPassword: Yup.string().nullable()
            .matches(/^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/i, 'Your password should be a minimum of 8 characters. Should have at least one letter and one number.'),

        newPasswordAgain: Yup.string().nullable().when('newPassword', {
            is: (value) => value && value !== '',
            then: () => Yup.string().nullable(true).required('Confirm Password is required').test(
                'empty-check',
                'You need to enter password',
                oldPassword => !oldPassword || oldPassword.length > 0)
                .ensure().required('Confirm Password is required')
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
            otherwise: () => Yup.string().nullable(),
        }),

        // oldPassword: Yup.string().nullable().when('newPassword', {
        //     is: (value) => value && value !== '',
        //     then: () => Yup.string().nullable(true).required('Old Password is required').test(
        //         'empty-check',
        //         'You need to enter password',
        //         oldPassword => !oldPassword || oldPassword.length > 0)
        //         .notOneOf([Yup.ref('newPassword'), null], 'New password needs to be different')
        //         .matches(/^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/i, 'Your password should be a minimum of 8 characters. Should have at least one letter and one number.'),
        //     otherwise: () => Yup.string().nullable(),
        // })
    });

    const handleSubmit = async (values) => {
        var customer = {
            id: customerInfo?.customerId,
            firstName: values.firstName,
            lastName: values.lastName,
            profilePicture: customerInfo?.profilePicture,
            newPassword: values.newPassword ? values.newPassword : "",
            oldPassword: values.oldPassword ? values.oldPassword : ""
        }
        dispatch(updateCustomerInfo(customer));
    }

    const ImgUpload = ({
        onChange,
        src
    }) =>
        <label htmlFor="photo-upload" className="custom-file-upload fas" style={{ height: '200px', width: '200px' }}>
            <div className="img-wrap img-upload" >
                <img htmlFor="photo-upload" src={src} alt="" />
            </div>
            <input id="photo-upload" type="file" accept="image/png, image/gif, image/jpeg" onChange={onChange} />
        </label>

    const photoUpload = async (e) => {
        e.preventDefault();
        if (e.target.files[0].size > 512000) {
            Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error61'), 'error')
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {

            var customer = {
                id: customerInfo?.customerId,
                firstName: customerInfo?.firstName,
                lastName: customerInfo?.lastName,
                profilePicture: reader.result || "",
                newPassword: "",
                oldPassword: ""
            }
            dispatch(updateCustomerInfo(customer));
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            onSubmit={handleSubmit}
            validationSchema={customerInfoSchema}
            enableReinitialize={true}
            validateOnMount={true}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                setTouched
            }) => (
                <div>
                    <DrBreadCrump
                        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.navigatorText1'), navigationPath: "" },
                        { navigationText: i18next.t('constants:breadCrumps.navigatorText2'), navigationPath: "" }]} >
                    </DrBreadCrump>
                    <div className="row row-md row-lg d-flex justify-content-center">
                        <div className="col-md-8 col-lg-12">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <form>
                                        <div className="row row-md row-lg justify-content-center justify-content-lg-start justify-content-xl-start">

                                            <div id="profile-picture" className="col-8 col-sm-5 col-md-6 col-lg-auto col-xl-auto">
                                                <ImgUpload onChange={photoUpload} src={customerInfo?.profilePicture || avatar}></ImgUpload>
                                            </div>
                                            <div className="col-12 col-sm-7 col-md-6 col-lg-7 col-xl-7">
                                                <div className="row">
                                                    <div className="col-md-12 col-lg-5">
                                                        <h5>{t('firstName')}</h5>
                                                        <DrInput value={values.firstName} className="form-control" placeholder={t('firstName')} name="firstName" type="text"
                                                            onChange={handleChange}
                                                            onBlur={() => { setTouched({ ...touched, 'firstName': true }) }}
                                                            isInvalid={(touched.firstName && errors.firstName)}
                                                            isValid={touched.firstName && !errors.firstName}
                                                            validationText={errors.firstName}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 col-lg-5">
                                                        <h5>{t('lastName')}</h5>
                                                        <DrInput value={values.lastName} className={`form-control`}
                                                            placeholder={t('lastName')}
                                                            name="lastName"
                                                            type="text"
                                                            onChange={handleChange}
                                                            onBlur={() => { setTouched({ ...touched, 'lastName': true }) }}
                                                            isInvalid={(touched.lastName && errors.lastName)}
                                                            isValid={touched.lastName && !errors.lastName}
                                                            validationText={errors.lastName}
                                                        />
                                                    </div>
                                                    <div className="col-md-12 col-lg-7 mt-1">
                                                        <h5>{t('email')}</h5>
                                                        <DrInput value={values.email} className={`form-control disabled text-muted`} disabled="true" placeholder={t('email')} name="email" type="text" onChange={handleChange}
                                                            onBlur={() => { setTouched({ ...touched, 'email': true }) }} />

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <hr className="mt-4 mb-5" />
                                        <div className="row row-md row-lg">
                                            <div className="col-mg-5 col-lg-5">
                                                <div className="form-group">
                                                    <label>{t('oldPassword')}</label>
                                                    <DrPasswordInput className="form-control" name="oldPassword" type="password" placeholder={t('oldPassword')} value={values.oldPassword} showRevealButton={true} onChange={handleChange}
                                                        onBlur={() => { setTouched({ ...touched, 'oldPassword': true }) }}
                                                        isInvalid={(touched.oldPassword && errors.oldPassword)}
                                                        isValid={touched.oldPassword && !errors.oldPassword}
                                                        validationText={errors.oldPassword}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-mg-5 col-lg-5">
                                                <div className="form-group">
                                                    <label>{t('newPassword')}</label>
                                                    <DrPasswordInput className="form-control" name="newPassword" type="password" placeholder={t('newPassword')} value={values.newPassword} showRevealButton={true} onChange={handleChange}
                                                        onBlur={() => { setTouched({ ...touched, 'newPassword': true }) }}
                                                        isInvalid={(touched.newPassword && errors.newPassword)}
                                                        isValid={touched.newPassword && !errors.newPassword}
                                                        validationText={errors.newPassword}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-mg-5 col-lg-5">
                                                <div className="form-group">
                                                    <label>{t('confirmPassword')}</label>
                                                    <DrPasswordInput name="newPasswordAgain" type="password" className="form-control" placeholder={t('confirmPassword')} value={values.newPasswordAgain} showRevealButton={true} onChange={handleChange}
                                                        onBlur={() => { setTouched({ ...touched, 'newPasswordAgain': true }) }}
                                                        isInvalid={(touched.newPasswordAgain && errors.newPasswordAgain)}
                                                        isValid={touched.newPasswordAgain && !errors.newPasswordAgain}
                                                        validationText={errors.newPasswordAgain} />

                                                </div>
                                            </div>
                                            <div className="col-mg-5 col-lg-5" ></div>
                                        </div>
                                        <hr className="mt-4 mb-5" />
                                        <div className="row row-md row-lg justify-content-center">
                                            <div className="col-lg-auto col-md-2">
                                                <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (isValid) {
                                                            handleSubmit(values);
                                                        }
                                                    }}
                                                >{t('saveChanges')}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </Formik>
    )
}

export default AccountPage;