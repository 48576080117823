import types from "../actions/agreement.action";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CLEAR_ALL_AGREEMENTS:
            return initialState;
        case types.GET_AGREEMENT_SUCCESS:
            return {
                ...state, "agreements": action.payload
            }
        default:
            return state;
    }
}

const initialState = {
    "agreements": null
}

export default reducer;