import types from "../actions/trademark.action";

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case types.GET_TRADEMARK_RESULT_SUCCESS:
            return { ...state, trademarkData: action.payload };
        case types.CLEAR_ALL_TRADEMARK_DATA:
            return initialState;
        default:
            return state;
    }
}

const initialState = {
    "trademarkData": []
}

export default reducer;