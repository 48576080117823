import { loginWithEmail } from "../../../../state-management/actions/customer.actions";
import * as Yup from 'yup';
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DrInput } from "../../../component-index";
import { getRouteByKey } from "../../../../routes";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { clearActiveModal, setActiveModal, withActionPromise } from "../../../../state-management/actions/app.actions";
import { modals } from "../../../../utils/constants";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import Card from "react-credit-cards-2";
import { getCardNumber } from "../../../../utils/utils";
import { deleteSavedCard, saveCardIsFavourite } from "../../../../state-management/actions/wallet.actions";
import "./dr-credit-card.component.scss";

function DrCreditCardModal() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('walletPage');
    const selectedCreditCard = useSelector(state => state.wallet.selectedCreditCard);

    return (
        <div >
            <div className="row justify-content-center py-4">
            
                <div className="col-auto">
                    {selectedCreditCard?.isDefault && <i className="fa fa-star tx-20 tx-light-yellow" style={{ position: "absolute", top: "2px", left: "17px", zIndex: "1500" }}></i>}
                    <Card
                        preview={true}
                        number={getCardNumber(selectedCreditCard?.cardNumber)}
                        name={selectedCreditCard?.cardHolderName}
                        expiry=""
                        cvc=""
                        locale={{valid:"valid thru"}}
                    />
                </div>
            </div>
            <div className="row justify-content-center pt-4">
                <div className="col-12">
                    <button className="btn btn-info btn-lg btn-block btn-wave waves-effect waves-light shadow"
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(withActionPromise(clearActiveModal()));
                            navigate(getRouteByKey("MY_WALLET_PAYMENT_PAGE"));

                        }}>
                        {t("myWalletPage.creditCardModal.payWithThisCard")}
                    </button>
                </div>
            </div>
            <div className="row justify-content-center pt-2">
                <div className="col-12">
                    <button className={`btn btn-purple btn-block btn-wave waves-effect waves-light shadow ${!selectedCreditCard?.isDefault ? "" : "disabled"}`}
                        onClick={(e) => {
                            e.preventDefault();
                            if (!selectedCreditCard?.isDefault) {
                                dispatch(withActionPromise(saveCardIsFavourite(selectedCreditCard?.cardId)));
                            }
                        }}>
                        {t("myWalletPage.creditCardModal.setFavaurite")}
                    </button>
                </div>
            </div>
            <div className="row justify-content-center pt-2">
                <div className="col-12">
                    <button className="btn btn-danger btn-block btn-wave waves-effect waves-light shadow"
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(withActionPromise(deleteSavedCard(selectedCreditCard?.cardId)))
                            dispatch(withActionPromise(clearActiveModal()));
                        }}>
                        {t("myWalletPage.creditCardModal.deleteThisCard")}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DrCreditCardModal