import { call, takeLatest, all } from 'redux-saga/effects'
import { CONTROLLERS, apiGet } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types from '../actions/create-store.actions';

function* runGetAmazonRefreshToken(action) {
    const payload = action.payload;

    const path = `/AmazonLogin?code=${payload.code}&state=${payload.state}`;

    const response = yield call(apiGet(path, CONTROLLERS.LOGIN, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        
    }
}

export default function* createStoreSaga() {
    yield all([
        takeLatest(types.GET_AMAZON_REFRESH_TOKEN_REQUESTED, safe(runGetAmazonRefreshToken))
    ]);
}