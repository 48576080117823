const datepicker_en = {
    name: "en",
    months: [
      ["January", "Jan"],
      ["February", "Feb"],
      ["March", "Mar"],
      ["April", "Apr"],
      ["May", "May"],
      ["June", "Jun"],
      ["July", "Jul"],
      ["August", "Aug"],
      ["September", "Sep"],
      ["October", "Oct"],
      ["November", "Nov"],
      ["December", "Dec"],
    ],
    weekDays: [
      ["Saturday", "Sat"],
      ["Sunday", "Sun"],
      ["Monday", "Mon"],
      ["Tuesday", "Tue"],
      ["Wednesday", "Wed"],
      ["Thursday", "Thu"],
      ["Friday", "Fri"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["AM", "Am"],
      ["PM", "Pm"],
    ],
  };

  export default datepicker_en;