import { DrAppLayout, DrToastr } from "./components/component-index";
import { RenderRoutes } from "./routes";
// import Cookies from 'js-cookie'
import { useDispatch, useSelector } from "react-redux";
import './i18n';
import PermissionProvider from "./security/PermissionProvider";
import { useEffect, useState } from "react";
import { setCustomerInfoRole } from "./state-management/actions/customer.actions";
import { SetThemeColor } from "./utils/theme-change";

function useLocationHash() {
    const [hash, setHash] = useState(window.location.hash);
    window.addEventListener("hashchange", () => setHash(window.location.hash));
  
    return hash;
  }

function App() {
    // const cookie = Cookies.get("AuthToken");
    const hash = useLocationHash();
    const dispatch = useDispatch();
    const customerAuthorization = useSelector(state => state.customer.customerInfo?.authorization);
    const customerRole = useSelector(state => state.customer.customerInfo?.role);
    const selectedStore = useSelector(state => state.stores.selectedStore);

    useEffect(() => {
        if (customerAuthorization && customerAuthorization.length > 0) {
            var roleIds = [];

            if (selectedStore?.customerMainSellerId) {
                roleIds = customerAuthorization.find(x => x.customerMainSellerId === selectedStore?.customerMainSellerId)?.roleIds;
            }
            else {
                roleIds = customerAuthorization?.[0].roleIds;
            }

            dispatch(setCustomerInfoRole(roleIds));
        }
        else {
            dispatch(setCustomerInfoRole([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerAuthorization, selectedStore])

    // useEffect(() => {
    //     if (cookie) {
    //         dispatch(validateToken())
    //     }
    //     else {
    //         dispatch(logoutUser());
    //     }
    // }, [cookie, dispatch])

    return (
        <PermissionProvider userRole={customerRole}>
            <DrAppLayout>
                <RenderRoutes />

            </DrAppLayout>
            {/* <DrToastr /> */}
        </PermissionProvider>

    );
}

export default App;
