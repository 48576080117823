import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { DrInput, DrMaskedInput } from "../../components/component-index";
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { createExtensionCustomer } from "../../state-management/actions/create-customer.actions";
import loginImg from '../../assets/img/login-img.png'
import { phoneNumberMask } from "../../utils/masks";
import { ReactSVG } from "react-svg";

function ExtensionRegisterPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('registerPage');


    const initialValuesRegister = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    };

    const userInfoSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name is required'),
        lastName: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Last name is required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phone: Yup.string().min(14, 'Too Short!').max(20, 'Too Long!').required('Required')
            .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/, 'Your phone number should match the format!')
    });

    return (
        <div className="container-fluid" >
            <div className="row justify-content-center mt-5">
                <div className="col-auto">
                    <ReactSVG src="../../../../assets/img/brand/logo.svg" />
                </div>
            </div>
            <div className="row justify-content-center mt-5 h-100">
                <div className="col-md-6 col-lg-6 col-sm-10 col-xl-5 col-12 h-100" >
                    <div className="card custom-card" >
                        <div className="card-body">
                            <Formik
                                initialValues={initialValuesRegister}
                                validateOnBlur={true}
                                // onSubmit={handleSubmit}
                                validationSchema={userInfoSchema}
                                enableReinitialize={true}
                                validateOnMount={true}
                            >
                                {({
                                    isValid,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    setTouched,
                                    handleBlur,
                                    setFieldValue
                                }) => (
                                    <form name="register-form" className="needs-validation" autoComplete="off" >
                                        <section>
                                            <div className="row justify-content-center" style={{ height: "450px" }}>
                                                <div className="col-10">
                                                    <h1 className="text-center">{t('welcome')}</h1>
                                                    <h5 className="text-center">{t('worldEf')}</h5>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-group">
                                                                <label>{t('firstName')}</label>
                                                                <DrInput value={values.firstName} className="form-control" placeholder={t('firstName')} name="firstName" type="text"
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'firstName': true }) }} />
                                                                {(touched.firstName && errors.firstName) && <label type="invalid" className="form-control is-invalid">
                                                                    {errors.firstName}
                                                                </label>}
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group has-validation">
                                                                <label>{t('lastName')}</label>
                                                                <DrInput value={values.lastName} className="form-control" placeholder={t('lastName')} name="lastName" type="text" onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'lastName': true }) }} />
                                                                {(touched.lastName && errors.lastName) && <div type="text" className="form-control is-invalid">
                                                                    {errors.lastName}
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-group has-validation">
                                                                <label>{t('email')}</label>
                                                                <DrInput value={values.email} className="form-control" placeholder={t('email')} name="email" type="text" onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'email': true }) }} />
                                                                {(touched.email && errors.email) && <div type="invalid" className="form-control is-invalid">
                                                                    {errors.email}
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-group has-validation">
                                                                <label>{t('phone')}</label>
                                                                <DrMaskedInput className="form-control" name="phone" placeholder="(5xx) 000-0000"
                                                                    type="text"
                                                                    mask={phoneNumberMask}
                                                                    value={values.phone}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'phone': true }) }} />
                                                                {(touched.phone && errors.phone) && <div type="invalid" className="form-control is-invalid"> {errors.phone} </div>}
                                                                <small className="custom-switch-description">{t('phoneTip')}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center mt-3">
                                                        <div className="col-11 col-lg-6 col-xl-6">
                                                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow btn-block ${isValid ? "" : "disabled"}`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (true) {
                                                                        var user = {
                                                                            firstName: values.firstName,
                                                                            lastName: values.lastName,
                                                                            email: values.email,
                                                                            phone: values.phone ? values.phone : ""
                                                                        }
                                                                        
                                                                        dispatch(createExtensionCustomer(user));
                                                                    }
                                                                }}
                                                            >{t('sendForm')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </form>
                                )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6 col-sm-10 col-xl-5 col-12 h-100" >
                    <div className="card custom-card" >
                        <div className="card-body">
                            <div className="row justify-content-center" style={{ height: "450px" }}>
                                <div className="col-10">
                                    <div className="row justify-content-center mb-4">
                                        <img src={loginImg} className="img-lg" alt="dashleadlogo" style={{ maxHeight: "300px" }} />
                                    </div>
                                    <h4 className="text-center">{t('welcome')}</h4>
                                    <h5 className="text-center">{t('info')}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ExtensionRegisterPage;