import { forgotPassword, loginWithEmail } from "../../../../state-management/actions/customer.actions";
import * as Yup from 'yup';
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DrForgotPasswordTemplate, DrInput } from "../../../component-index";
import { getRouteByKey } from "../../../../routes";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setActiveModal } from "../../../../state-management/actions/app.actions";
import { modals } from "../../../../utils/constants";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { render as EmailRender } from '@react-email/render';

function DrForgotPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('drForgotPassword');
    const isLoggedin = useSelector(state => state.customer?.isLoggedin);
    const createCustomerSnapshot = useSelector(state => state.createCustomerSnapshot);

    const handleForgotPassword = async (value) => {

        let body = <DrForgotPasswordTemplate siteUrl={window.location.origin} ></DrForgotPasswordTemplate>;
        var request = {
            emailTo: value,
            emailToName: "",
            emailSubject: "Password Change",
            emailBody: EmailRender(body)
        }

        dispatch(forgotPassword(request));
    }
    useEffect(() => {
        if (isLoggedin) {
            navigate(getRouteByKey("HOME"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedin]);


    const loginAccountSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required')
    });

    return (
        <Formik
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize={true}
            validateOnMount={true}
            initialValues={{
                email: createCustomerSnapshot.email || '',
                password: ''
            }}
            validationSchema={loginAccountSchema}
        >
            {({
                errors,
                values,
                touched,
                dirty,
                handleChange,
                handleBlur,
                isValid
            }) => (<div>
                <form className="needs-validation mt-5 mb-1 me-4 ms-4">
                    <div className="form-group has-validation">
                        <label>{t('email')}</label>
                        <DrInput value={values.email} className={`form-control`} placeholder={t('email')} name="email" type="text" onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={(touched.email && errors.email)}
                            isValid={touched.email && !errors.email}
                            validationText={errors.email} />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-warning btn-wave waves-effect waves-light shadow btn-block mt-4"
                            onClick={(e) => {
                                e.preventDefault();
                                handleForgotPassword(values.email);
                            }}
                        >{t('requestresetlik')}</button>
                    </div>
                    <div className="mt-3 text-center">
                        <p className="mb-0">{t('doyouremember')} </p>
                    </div>
                    <div className="mt-3 text-center">
                        <span className="text-primary ms-2 cursor-pointer"
                            onClick={() => dispatch(setActiveModal({ name: modals.login, isGlobal: true, title: i18next.t('drLogin:title') }))}
                        >{t('trysignin')}</span>
                    </div>
                </form>
            </div>
            )
            }
        </Formik>
    )
}

export default DrForgotPassword