import types from '../actions/page-filters.actions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CLEAR_PAGE_FILTERS:
            return { ...initialState };
        case types.SET_PAGE_FILTERS_INVENTORY:
            return { ...state, inventoryPageFilters: action.payload };
        case types.SET_PAGE_FILTERS_ORDERS:
            return { ...state, ordersPageFilters: action.payload };
            case types.SET_PAGE_FILTERS_PRODUCY_VARIATIONS:
            return { ...state, productVariationsPageFilters: action.payload };
        case types.SET_PAGE_FILTERS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default reducer;

const initialState = {
    "inventoryPageFilters": [],
    "ordersPageFilters": [],
    "productVariationsPageFilters": []
};