import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiDelete, apiGet, apiPost, apiPut, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getCoupons, getCouponsSuccess, getPackageByIdSuccess, getPackages, getPackagesSuccess } from '../actions/packages.action';
import i18next from 'i18next';
import Swal from 'sweetalert2';

function* runGetAllPackages() {
    const response = yield call(apiGet(``, CONTROLLERS.PACKAGE, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getPackagesSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetPackageById(action) {
    const payload = action.payload;
    var path = `/GetId?Id=${payload}`;
    const response = yield call(apiGet(path, CONTROLLERS.PACKAGE, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield all([put(getPackageByIdSuccess(response.data)),

        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runCreatePackage(action) {
    const payload = action.payload;
    const response = yield call(apiPost(``, payload, false, CONTROLLERS.PACKAGE, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getPackages()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdatePackage(action) {
    const payload = action.payload;
    const response = yield call(apiPut(``, payload, false, CONTROLLERS.PACKAGE, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getPackages()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeletePackage(action) {
    const payload = action.payload;
    var path = `?id=${payload}`;
    const response = yield call(apiDelete(path, CONTROLLERS.PACKAGE, true, true));
    if (response && response.isSuccess) {
        yield all([
            put(getPackages()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))


        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'));
    }
}

function* runCreateCouponPackage(action) {
    const payload = action.payload;
    const response = yield call(apiPost(`/CreateCouponPackage`, payload, false, CONTROLLERS.PACKAGE, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getPackages()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteCreateCouponPackage(action) {
    const payload = action.payload;
    const response = yield call(apiPost(`/DeleteCouponPackage`, payload, false, CONTROLLERS.PACKAGE, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getPackages()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetAllCoupons() {
    const response = yield call(apiGet(``, CONTROLLERS.COUPON, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getCouponsSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runCreateCoupon(action) {
    const payload = action.payload;
    const response = yield call(apiPost(``, payload, false, CONTROLLERS.COUPON, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getCoupons()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateCoupon(action) {
    const payload = action.payload;
    const response = yield call(apiPut(``, payload, false, CONTROLLERS.COUPON, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getCoupons()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteCoupon(action) {
    const payload = action.payload;
    var path = `?id=${payload}`;
    const response = yield call(apiDelete(path, CONTROLLERS.COUPON, true, true));
    if (response && response.isSuccess) {
        yield all([
            put(getCoupons()),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))


        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage'), 'error'));
    }
}

export default function* packagesSaga() {
    yield all([
        takeLatest(types.GET_PACKAGES_REQUESTED, safe(runGetAllPackages)),
        takeLatest(types.GET_PACKAGE_BY_ID_REQUESTED, safe(runGetPackageById)),
        takeLatest(types.CREATE_PACKAGE_REQUESTED, safe(runCreatePackage)),
        takeLatest(types.UPDATE_PACKAGE_REQUESTED, safe(runUpdatePackage)),
        takeLatest(types.DELETE_PACKAGE_REQUESTED, safe(runDeletePackage)),
        takeLatest(types.CREATE_PACKAGE_COUPON_REQUESTED, safe(runCreateCouponPackage)),
        takeLatest(types.DELETE_PACKAGE_COUPON_REQUESTED, safe(runDeleteCreateCouponPackage)),
        takeLatest(types.GET_COUPONS_REQUESTED, safe(runGetAllCoupons)),
        takeLatest(types.CREATE_COUPON_REQUESTED, safe(runCreateCoupon)),
        takeLatest(types.UPDATE_COUPON_REQUESTED, safe(runUpdateCoupon)),
        takeLatest(types.DELETE_COUPON_REQUESTED, safe(runDeleteCoupon)),
    ]);
}