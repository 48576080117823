import React from "react";
// import { Line } from "react-chartjs-2";
import Chart from "react-apexcharts";
import './chart.scss'

function DrLineChart({ chartData }) {

    var options = {
        series: [{
            name: chartData?.peakText || "",
            data: chartData?.series || []
        }],
        chart: {
            // height: 200,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                }
            }
        },
        colors: chartData?.colors || ['var(--info-color)', 'var(--danger-color)', 'var(--success-color)'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: 3,
        },
        grid: {
            borderColor: '#f2f5f7',
        },
        title: {
            text: chartData?.title || "",
            align: 'left',
            style: {
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#8c9097'
            },
        },
        xaxis: {
            categories: chartData?.categories || [],
            labels: {
                show: true,
                style: {
                    colors: "#8c9097",
                    fontSize: '11px',
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-label',
                },
            }
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    colors: "#8c9097",
                    fontSize: '11px',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-label',
                },
            }
        }
    };

    return (

        < >
            {/* <Line
                data={chartData}
                options={options}
            /> */}
            <Chart
                options={options}
                series={options.series}
                type="line"
                height="200px"
                width="100%"
                className="apexcharts-canvas apexcharts7ngldghr apexcharts-theme-light"
            />
        </>
    );
}

export default DrLineChart;

