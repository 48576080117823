function DrEmptyMailTemplate({ templateKey, htmlText }) {

    return (
        <div className="main-msg-wrapper" key={templateKey} id={"message_div_" + templateKey}>
            {htmlText}
        </div>
    )
}

export default DrEmptyMailTemplate

