import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DrBreadCrump, DrTagifyInput } from '../../components/component-index';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { stringAsinsSplitRegex } from '../../utils/constants';
import { useParams } from 'react-router-dom';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { clearProductsDetails, getProductsDetailsByAsins, saveProductIsFavourite } from '../../state-management/actions/inventory.actions';
import noImage from '../../assets/img/pngs/no-image.png'
import './product-compare.page.scss'
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper/modules';




function ProductComparePage() {
    const dispatch = useDispatch();
    const { asins } = useParams();
    const { t } = useTranslation('productDetailPage');
    const selectedProducts = useSelector(state => state.inventory.selectedProducts);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const [selectedAsins, setSelectedAsins] = useState([]);
    const [compareProducts, setCompareProducts] = useState([]);

    useEffect(() => {
        dispatch(withActionPromise(clearProductsDetails()));
        if (asins && asins.length > 0) {
            setSelectedAsins(asins.split(stringAsinsSplitRegex));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCompareProducts(selectedProducts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProducts]);


    useEffect(() => {
        if (selectedAsins?.length > 0 && selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getProductsDetailsByAsins({
                asinList: selectedAsins,
                customerMainSellerId: selectedStore?.customerMainSellerId
            })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAsins, selectedStore?.customerMainSellerId]);

    const setIsFavourite = (customerMainSellerProductId, isFavourite) => {
        let savedCompareProducts = Object.assign([], compareProducts);
        savedCompareProducts.find(x => x.customerMainSellerProductId === customerMainSellerProductId).isFavourite = isFavourite;

        dispatch(saveProductIsFavourite({
            customerMainSellerProductId: customerMainSellerProductId,
            isFavourite: isFavourite
        }));
        setCompareProducts(savedCompareProducts);
    }

    var colClass = classNames({
        'col-12 h-100 my-0 px-0': compareProducts?.length === 1,
        'col-12 col-xl-6 col-lg-6 col-md-9 col-sm-12 h-100 my-0 me-2 px-0': compareProducts?.length === 2,
        'col-12 col-xl-4 col-lg-5 col-md-7 col-sm-10 h-100 my-0 me-2 px-0': compareProducts?.length === 3,
        'col-12 col-xl-3 col-lg-3 col-md-5 col-sm-8 h-100 my-0 me-2 px-0': compareProducts?.length === 4,
        'col-12 col-xl-3 col-lg-4 col-md-6 col-sm-9 h-100 my-0 me-2 px-0': compareProducts?.length > 4,
    });

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
      };

    return (
        <>

            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
                { navigationText: i18next.t('constants:breadCrumps.productCompare'), navigationPath: "" }]} >
            </DrBreadCrump>







            <div className="row flex-nowrap overflow-x justify-content-center">
                <div className="col-12 px-0">
                    <div className="card custom-card">
                        <div className="card-body">

                            <div className="row row-md row-lg justify-content-center mb-4">
                                <div className="col-4 col-md-5 col-lg-2 col-xl-2 px-0 mx-0 my-auto no-select text-end">
                                    <label className="fw-semibold me-2">ASIN :</label>
                                </div>
                                <div className="col-8 col-md-7 col-lg-10 col-xl-10 px-0 mx-0 px-0">


                                    <DrTagifyInput value={selectedAsins || ""}
                                        settings={{
                                            pattern: /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{10}$/,
                                            delimiters: ",| |:|[\\n\\r]"
                                        }}
                                        className="tags-input-control"
                                        onBlur={(e) => {
                                            setSelectedAsins(e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                        }}
                                        onRemove={(e) => {
                                            setSelectedAsins(e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                        }}
                                        onEditUpdated={(e) => {
                                            setSelectedAsins(e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                        }}
                                    >
                                    </DrTagifyInput>

                                </div>
                            </div>

                            {compareProducts && compareProducts.length > 0 &&
                                <div className="row fs-13">
                                    <div className="col-4 col-md-5 col-lg-2 col-xl-2 px-0 mx-0 no-select">
                                        <div className="row p-0 m-0 border-bottom" style={{ height: '230px' }}>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("sourceMarket")}</label>
                                            </div>
                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("buyPrice")}</label>
                                            </div>
                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("estimatedShippingPrice")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("totalPrice")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("sellPrice")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("amazonCommission")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("listingPrice")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isBuyboxSeller")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isLowestSeller")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("stock")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("minPrice")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("maxPrice")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isAmazonSeller")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("primeSellerCount")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("amazonSellerBrandCount")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("review")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("buyboxPrice")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("lowestPrice")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isPrimeSellerExist")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("sellerCount")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("buyboxOffersCount")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("brand")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("handlingTime")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("category1")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("category2")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isShippingAvailableForBuyersLocation")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("totalCustomsDutyPrice")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("totalShippingPrice")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isUnavailable")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isFBAproduct")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("priceShownAtBasket")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isAddonProduct")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isDiscounted")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isPrimeExclusive")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="fw-semibold me-2">{t("isChineseSeller")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="me-2">{t("isParentAsin")}</label>
                                            </div>

                                        </div>
                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                            <div className="col-12 text-end">
                                                <label className="me-2">{t("maxDeliveryTime")}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div id="product-compare" className="col-8 col-md-7 col-lg-10 col-xl-10 px-0 mx-0 container testimonial-group no-select" >
                                        <div className="row p-0 m-0 h-100 w-100 justify-content-between">


                                            <Swiper
                                                slidesPerView={4}
                                                spaceBetween={5}
                                                freeMode={true}
                                                pagination={pagination}
                                                grabCursor={true}
                                                modules={[FreeMode, Pagination]}
                                                className="primary-pagination"
                                            >
                                                {/* <SwiperSlide>Slide 1</SwiperSlide> */}

                                                {compareProducts?.map((product, index) => {
                                                    var colorClass = index % 2 === 0 ? "bg-info bg-opacity-10" : "bg-success bg-opacity-10";
                                                    return (
                                                        <SwiperSlide>
                                                            <div key={(index + 1)} className={"col-12"}>
                                                                <div className={`card custom-card border border-gray-300 rounded bg-gray-100 row h-100 p-0 m-0 ${colorClass}`} >
                                                                    <div className="col-12 px-0 mx-0">


                                                                        <div className="row p-0 m-0 border-bottom" style={{ height: '230px' }}>
                                                                            <div className="col-12 px-2 pt-2 mx-0 text-center">
                                                                                <i className={`cursor-pointer fs-28  ${product.isFavourite ? "bi-star-fill text-warning" : "bi-star"}`} aria-hidden="true" style={{ position: "absolute", right: "4px", top: "4px" }}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        setIsFavourite(product.customerMainSellerProductId, !product.isFavourite);
                                                                                    }}>
                                                                                </i>
                                                                                <img alt="" className="m-auto" style={{ maxWidth: '210px', maxHeight: '210px' }} src={compareProducts[index].productPicture || noImage} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].sourceMarketCountryCode} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2">{compareProducts[index].shippingPriceCurrencyCode} {compareProducts[index].shippingPrice}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                {/* <span className="mx-2">{compareProducts[index].shippingPriceCurrencyCode} {Number(compareProducts[index].shippingPrice + compareProducts[index].listPrice).toFixed(2) }</span> */}
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2">USD {Number(compareProducts[index].price).toFixed(2)}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2">{compareProducts[index].listPriceCurrencyCode} {compareProducts[index].listPrice}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2">{compareProducts[index].isBuybox ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2">{compareProducts[index].isLowest ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2">{compareProducts[index].stock}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2">{compareProducts[index].isSoldByAmazon ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].primeSellerCount} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].totalSellerCount} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].review} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].buyboxPriceCurrencyCode} {compareProducts[index].buyboxPrice} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].lowestPriceCurrencyCode} {compareProducts[index].lowestPrice} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].primeSellerCount && compareProducts[index].primeSellerCount > 0 ? <i className="fa fa-check text-success"></i> : <i className="fa fa-check text-close"></i>}  </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].totalSellerCount}  </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].brandName}  </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].displayGroupRankTitle} ({compareProducts[index].displayGroupRank})</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].classificationRankTitle} ({compareProducts[index].classificationRank})</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].customsDutyPriceCurrencyCode} {Number(compareProducts[index].customsDutyPrice).toFixed(2)} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].shippingPriceCurrencyCode} {Number(compareProducts[index].shippingPrice).toFixed(2)} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].stock && compareProducts[index].stock > 0 ? <i className="fa fa-close text-danger"></i> : <i className="fa fa-check text-success"></i>}  </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].isSoldByAmazon ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].price && compareProducts[index].price > 0 ? <i className="fa fa-close text-danger"></i> : <i className="fa fa-check text-success"></i>} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].isDiscounted ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].isPrimeExclusive ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> {compareProducts[index].isChineseSellerExists ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row border-bottom" style={{ height: '30px' }}>
                                                                            <div className="col-12">
                                                                                <span className="mx-2"> ?? </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    )
                                                })}

                                            </Swiper>
                                        </div>

                                    </div>

                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProductComparePage