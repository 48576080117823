const types = {
    SET_ACTIVE_MODAL: "SET_ACTIVE_MODAL",
    CLEAR_ACTIVE_MODAL: "CLEAR_ACTIVE_MODAL",
    SET_ACTIVE_PICTUREMODAL: "SET_ACTIVE_PICTUREMODAL",
    CLEAR_ACTIVE_PICTUREMODAL: "CLEAR_ACTIVE_PICTUREMODAL",
    SET_ACTIVE_PAGEFILTERS_MODAL: "SET_ACTIVE_PAGEFILTERS_MODAL",
    CLEAR_ACTIVE_PAGEFILTERS_MODAL: "CLEAR_ACTIVE_PAGEFILTERS_MODAL",
    SET_ACTIVE_CAPTCHA_MODAL: "SET_ACTIVE_CAPTCHA_MODAL",
    CLEAR_ACTIVE_CAPTCHA_MODAL: "CLEAR_ACTIVE_CAPTCHA_MODAL",
    SET_ACTIVE_SEARCH_TASK_PRODUCTS_MODAL: "SET_ACTIVE_SEARCH_TASK_PRODUCTS_MODAL",
    CLEAR_ACTIVE_SEARCH_TASK_PRODUCTS_MODAL: "CLEAR_ACTIVE_SEARCH_TASK_PRODUCTS_MODAL",
    SET_ACTIVE_ADD_MERCHANT_LIST_MODAL: "SET_ACTIVE_ADD_MERCHANT_LIST_MODAL",
    CLEAR_ACTIVE_ADD_MERCHANT_LIST_MODAL: "CLEAR_ACTIVE_ADD_MERCHANT_LIST_MODAL",

    SET_ACTIVE_CREATE_LABEL_MODAL: "SET_ACTIVE_CREATE_LABEL_MODAL",
    CLEAR_ACTIVE_CREATE_LABEL_MODAL: "CLEAR_ACTIVE_CREATE_LABEL_MODAL",

    SET_ACTIVE_SAVE_PAGEFILTER_MODAL: "SET_ACTIVE_SAVE_PAGEFILTER_MODAL",
    CLEAR_ACTIVE_SAVE_PAGEFILTER_MODAL: "CLEAR_ACTIVE_SAVE_PAGEFILTER_MODAL",

    SET_ACTIVE_BUYOUT_CARRIER_SELECTION_MODAL: "SET_ACTIVE_BUYOUT_CARRIER_SELECTION_MODAL",
    CLEAR_ACTIVE_BUYOUT_CARRIER_SELECTION_MODAL: "CLEAR_ACTIVE_BUYOUT_CARRIER_SELECTION_MODAL",

    SET_ACTIVE_TERMS_CONDITIONS_MODAL: "SET_ACTIVE_TERMS_CONDITIONS_MODAL",
    CLEAR_ACTIVE_TERMS_CONDITIONS_MODAL: "CLEAR_ACTIVE_TERMS_CONDITIONS_MODAL",

    SET_LOADER_SPINNER: "SET_LOADER_SPINNER",
    SET_APP_ERROR: "SET_APP_ERROR"
}

export default types;

export const setActiveModal = (modal) => {
    return { type: types.SET_ACTIVE_MODAL, payload: modal };
};

export const clearActiveModal = () => {
    return { type: types.CLEAR_ACTIVE_MODAL };
};

export const setActivePictureModal = (modal) => {
    return { type: types.SET_ACTIVE_PICTUREMODAL, payload: modal };
};

export const clearActivePictureModal = () => {
    return { type: types.CLEAR_ACTIVE_PICTUREMODAL };
};

export const setActivePageFiltersModal = (modal) => {
    return { type: types.SET_ACTIVE_PAGEFILTERS_MODAL, payload: modal };
};

export const clearActivePageFiltersModal = () => {
    return { type: types.CLEAR_ACTIVE_PAGEFILTERS_MODAL };
};

export const setActiveCaptchaModal = (modal) => {
    return { type: types.SET_ACTIVE_CAPTCHA_MODAL, payload: modal };
};

export const clearActiveCaptchaModal = () => {
    return { type: types.CLEAR_ACTIVE_CAPTCHA_MODAL };
};

export const setActiveCreateLabelModal = (modal) => {
    return { type: types.SET_ACTIVE_CREATE_LABEL_MODAL, payload: modal };
};

export const clearActiveCreateLabelModal = () => {
    return { type: types.CLEAR_ACTIVE_CREATE_LABEL_MODAL };
};

export const setActiveSearchTaskProductsModal = (modal) => {
    return { type: types.SET_ACTIVE_SEARCH_TASK_PRODUCTS_MODAL, payload: modal };
};

export const clearActiveSearchTaskProductsModal = () => {
    return { type: types.CLEAR_ACTIVE_SEARCH_TASK_PRODUCTS_MODAL };
};

export const setActiveAddMerchantListModal = (modal) => {
    return { type: types.SET_ACTIVE_ADD_MERCHANT_LIST_MODAL, payload: modal };
};

export const clearActiveAddMerchantListModal = () => {
    return { type: types.CLEAR_ACTIVE_ADD_MERCHANT_LIST_MODAL };
};

export const setActiveSavePagefilterModal = (modal) => {
    return { type: types.SET_ACTIVE_SAVE_PAGEFILTER_MODAL, payload: modal };
};

export const clearActiveSavePagefilterModal = () => {
    return { type: types.CLEAR_ACTIVE_SAVE_PAGEFILTER_MODAL };
};

export const setActiveBuyoutCarrierSelectionModal = (modal) => {
    return { type: types.SET_ACTIVE_BUYOUT_CARRIER_SELECTION_MODAL, payload: modal };
};

export const clearActiveBuyoutCarrierSelectionModal = () => {
    return { type: types.CLEAR_ACTIVE_BUYOUT_CARRIER_SELECTION_MODAL };
};

export const setActiveTermAndConditionsModal = (modal) => {
    return { type: types.SET_ACTIVE_TERMS_CONDITIONS_MODAL, payload: modal };
};

export const clearActiveTermAndConditionsModal = () => {
    return { type: types.CLEAR_ACTIVE_TERMS_CONDITIONS_MODAL };
};

export const setLoadIndicator = (payload) => {
    return { type: types.SET_LOADER_SPINNER, payload };
};

export const setAppError = (payload) => {
    return { type: types.SET_APP_ERROR, payload };
}

export const withActionPromise = (action) => (dispatch, getState) => Promise.resolve().then(() => {
    return dispatch(action);
});

export const syncedState = (action) => (dispatch, getState) => Promise.resolve().then(() => {
    return getState();
});
