import React, { useEffect } from 'react'
import { DrBreadCrump, DrInput, DrSelectBox } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// import * as Yup from 'yup';
import { getCustomerStoreFilter, updateCustomerStoreFilter } from '../../state-management/actions/store.actions';
import i18next from 'i18next';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { getRouteByKey } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { filterBuyboxOrLowestOptions, filterValueOptions } from '../../components/hoc/Constants/dr-constants.component';

function StoreFiltersPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('storeFiltersPage');

    const selectedStore = useSelector(state => state.stores.selectedStore);
    const myStore = useSelector(state => state.stores.myStore);
    const selectedStoreFilter = useSelector(state => state.stores.selectedStoreFilter);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(getCustomerStoreFilter(selectedStore?.customerMainSellerId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId]);

    const initialValues = {
        customerMainSellerId: selectedStore?.customerMainSellerId || "", //magazam
        onlyAmazonProductsValue: selectedStoreFilter?.onlyAmazonProductsValue || 0, //remove/out of stock
        onlySHouseProductsValue: selectedStoreFilter?.onlySHouseProductsValue || 0,//remove/out of stock
        filterTrademarkProductsValue: selectedStoreFilter?.filterTrademarkProductsValue || 0, // only remove option
        checkFromUSPTO: selectedStoreFilter?.checkFromUSPTO || false,
        checkFromCIPO: selectedStoreFilter?.checkFromCIPO || false,
        filterDiscountedProductsValue: selectedStoreFilter?.filterDiscountedProductsValue || 0, //remove/out of stock
        filterNoShipProductsValue: selectedStoreFilter?.filterNoShipProductsValue || 0, //remove/out of stock
        filterMissingShippingProductsValue: selectedStoreFilter?.filterMissingShippingProductsValue || 0, //remove/out of stock
        filterUnAvailableProductsValue: selectedStoreFilter?.filterUnAvailableProductsValue || 0,//only remove option
        filterNoImportFeeProductsValue: selectedStoreFilter?.filterNoImportFeeProductsValue || 0, //remove/out of stock
        filterNoSalesRankProductsValue: selectedStoreFilter?.filterNoSalesRankProductsValue || 0, //remove/out of stock
        filterSameSellerBrandProductsValue: selectedStoreFilter?.filterSameSellerBrandProductsValue || 0, //remove/out of stock
        filterPrimeSellersInStoreProductsValue: selectedStoreFilter?.filterPrimeSellersInStoreProductsValue || 0, //remove/out of stock
        filterByMaxSellersInStoreProductsValue: selectedStoreFilter?.filterByMaxSellersInStoreProductsValue || null, //count
        filterByMinSellersInStoreProductsValue: selectedStoreFilter?.filterByMinSellersInStoreProductsValue || null, //count
        filterHaveCNSellersProductsValue: selectedStoreFilter?.filterHaveCNSellersProductsValue || 0, //remove/out of stock
        filterHaveAmazonSellersProductsValue: selectedStoreFilter?.filterHaveAmazonSellersProductsValue || 0, //remove/out of stock
        filterPrimeExclusiveProductsValue: selectedStoreFilter?.filterPrimeExclusiveProductsValue || 0, //remove/out of stock
        filterBuyboxOrLowestPriceValue: selectedStoreFilter?.filterBuyboxOrLowestPriceValue || 0, // lowest/buybox
    }

    const handleSubmit = async (values) => {
        dispatch(updateCustomerStoreFilter(values));
    }

    // const storeFiltersSchema = Yup.object().shape({

    //     enableOnlyAmazonProducts: Yup.bool().required('Required'),
    //     onlyAmazonProductsValue: Yup.string().nullable().when('enableOnlyAmazonProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableOnlySHouseProducts: Yup.bool().required('Required'),
    //     onlySHouseProductsValue: Yup.string().nullable().when('enableOnlySHouseProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterTrademarkProducts: Yup.bool().required('Required'),
    //     filterTrademarkProductsValue: Yup.string().nullable().when('enableFilterTrademarkProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterDiscountedProducts: Yup.bool().required('Required'),
    //     filterDiscountedProductsValue: Yup.string().nullable().when('enableFilterDiscountedProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterNoShipProducts: Yup.bool().required('Required'),
    //     filterNoShipProductsValue: Yup.string().nullable().when('enableFilterNoShipProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterMissingShippingProducts: Yup.bool().required('Required'),
    //     filterMissingShippingProductsValue: Yup.string().nullable().when('enableFilterMissingShippingProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterUnavailableProducts: Yup.bool().required('Required'),
    //     filterUnAvailableProductsValue: Yup.string().nullable().when('enableFilterUnavailableProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterNoImportFeeProducts: Yup.bool().required('Required'),
    //     filterNoImportFeeProductsValue: Yup.string().nullable().when('enableFilterNoImportFeeProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterNoSalesRankProducts: Yup.bool().required('Required'),
    //     filterNoSalesRankProductsValue: Yup.string().nullable().when('enableFilterNoSalesRankProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterSameSellerBrandProducts: Yup.bool().required('Required'),
    //     filterSameSellerBrandProductsValue: Yup.string().nullable().when('enableFilterSameSellerBrandProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterPrimeSellersInStoreProducts: Yup.bool().required('Required'),
    //     filterPrimeSellersInStoreProductsValue: Yup.string().nullable().when('enableFilterPrimeSellersInStoreProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterByMaxSellersInStoreProducts: Yup.bool().required('Required'),
    //     filterByMaxSellersInStoreProductsValue: Yup.string().nullable().when('enableFilterByMaxSellersInStoreProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterByMinSellersInStoreProducts: Yup.bool().required('Required'),
    //     filterByMinSellersInStoreProductsValue: Yup.string().nullable().when('enableFilterByMinSellersInStoreProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterHaveCNSellersProducts: Yup.bool().required('Required'),
    //     filterHaveCNSellersProductsValue: Yup.string().nullable().when('enableFilterHaveCNSellersProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterHaveAmazonSellersProducts: Yup.bool().required('Required'),
    //     filterHaveAmazonSellersProductsValue: Yup.string().nullable().when('enableFilterHaveAmazonSellersProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     }),

    //     enableFilterPrimeExclusiveProducts: Yup.bool().required('Required'),
    //     filterPrimeExclusiveProductsValue: Yup.string().nullable().when('enableFilterPrimeExclusiveProducts', {
    //         is: (value) => value && value !== false,
    //         then: () => Yup.string().required('Selection is required'),
    //         otherwise: () => Yup.string().nullable(),
    //     })

    // });

    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            onSubmit={handleSubmit}
            // validationSchema={storeFiltersSchema}
            enableReinitialize={true}
            validateOnMount={true}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setTouched,
                setFieldValue
            }) => (

                <div>
                    <div className="page-header">
                        <div>
                            <DrBreadCrump
                                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.filter'), navigationPath: "" }]} >
                            </DrBreadCrump>
                        </div>
                    </div>
                    <div className="row row-md row-lg d-flex justify-content-center">
                        <div className="col-md-8 col-lg-12">
                            <form>
                                <div className="row row-md row-lg justify-content-center">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                <div className="row row-md row-lg justify-content-center">
                                                    <div className="col-lg-auto col-md-6 col-sm-6">
                                                        <h3 className="text-center">{t('contentTitle')}</h3>
                                                    </div>
                                                </div>
                                                {selectedStore &&
                                                    <div className="border rounded px-3 pb-3">
                                                        <div className="row row-md row-lg m-1">
                                                            <div className="col-sm-12">
                                                                <div className="row px-3 py-3 my-3">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterBuyboxOrLowestPrice')}
                                                                                    <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterBuyboxOrLowestPriceValue, 'filterBuyboxOrLowestPriceValue': true }) }}
                                                                                    name={'filterBuyboxOrLowestPriceValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterBuyboxOrLowestOptions} />
                                                                                {(touched.filterBuyboxOrLowestPriceValue && errors.filterBuyboxOrLowestPriceValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterBuyboxOrLowestPriceValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterBuyboxOrLowestPriceDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableOnlyAmazonProducts')}
                                                                                    <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.onlyAmazonProductsValue, 'onlyAmazonProductsValue': true }) }}
                                                                                    name={'onlyAmazonProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.onlyAmazonProductsValue && errors.onlyAmazonProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.onlyAmazonProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableOnlyAmazonProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableOnlySHouseProducts')}
                                                                                    <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.onlySHouseProductsValue, 'onlySHouseProductsValue': true }) }}
                                                                                    name={'onlySHouseProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.onlySHouseProductsValue && errors.onlySHouseProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.onlySHouseProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableOnlySHouseProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label htmlFor="filterTrademarkProducts" className="noselect mt-3 text-start cursor-pointer">
                                                                                    {t('enableFilterTrademarkProducts')}
                                                                                    {/* <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span> */}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterTrademarkProductsValue, 'filterTrademarkProductsValue': true }) }}
                                                                                    name={'filterTrademarkProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions}
                                                                                    onChange={(e) => {
                                                                                        if (e.value === '' || e.value === 0) {
                                                                                            setFieldValue("checkFromUSPTO", false);
                                                                                            setFieldValue("checkFromCIPO", false);
                                                                                        }
                                                                                    }} />
                                                                                {(touched.filterTrademarkProductsValue && errors.filterTrademarkProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterTrademarkProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterTrademarkProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <label className="custom-switch cursor-pointer">
                                                                                    <DrInput id="checkFromUSPTOInput" className="form-check-input" placeholder="" name="checkFromUSPTO" type="checkbox"
                                                                                        value=""
                                                                                        disabled={`${values.filterTrademarkProductsValue !== '' && values.filterTrademarkProductsValue !== 0 ? "" : "disabled"}`}
                                                                                        checked={values.checkFromUSPTO}
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'checkFromUSPTO': true }) }}
                                                                                    />
                                                                                    {/* <span className="custom-switch-indicator" style={{ minWidth: '36px' }} /> */}
                                                                                    <label htmlFor="checkFromUSPTOInput" className={`noselect ms-2 mt-2 text-start cursor-pointer ${values.filterTrademarkProductsValue !== '' && values.filterTrademarkProductsValue !== 0 ? "" : "text-muted"}`}>
                                                                                        {t('enableFilterTrademarkProductsOption1')}
                                                                                    </label>
                                                                                </label>

                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <label className="custom-switch cursor-pointer">
                                                                                    <DrInput id="checkFromCIPOInput" className="form-check-input" placeholder="" name="checkFromCIPO" type="checkbox"
                                                                                        value=""
                                                                                        disabled={`${values.filterTrademarkProductsValue !== '' && values.filterTrademarkProductsValue !== 0 ? "" : "disabled"}`}
                                                                                        checked={values.checkFromCIPO}
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'checkFromCIPO': true }) }}
                                                                                    />
                                                                                    {/* <span className="custom-switch-indicator" style={{ minWidth: '36px' }} /> */}
                                                                                    <label htmlFor="checkFromCIPOInput" className={`noselect ms-2 mt-2 text-start cursor-pointer ${values.filterTrademarkProductsValue !== '' && values.filterTrademarkProductsValue !== 0 ? "" : "text-muted"}`}>
                                                                                        {t('enableFilterTrademarkProductsOption2')}
                                                                                    </label>
                                                                                </label>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterDiscountedProducts')}
                                                                                    <span className="ms-2 badge bg-warning header-badge">{i18next.t('constants:badges.notRecommended')}</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterDiscountedProductsValue, 'filterDiscountedProductsValue': true }) }}
                                                                                    name={'filterDiscountedProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterDiscountedProductsValue && errors.filterDiscountedProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterDiscountedProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterDiscountedProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterNoShipProducts')}
                                                                                    <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterNoShipProductsValue, 'filterNoShipProductsValue': true }) }}
                                                                                    name={'filterNoShipProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterNoShipProductsValue && errors.filterNoShipProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterNoShipProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterNoShipProductsDescription', { countryName: myStore?.countryCode })}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterMissingShippingProducts')}
                                                                                    <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterMissingShippingProductsValue, 'filterMissingShippingProductsValue': true }) }}
                                                                                    name={'filterMissingShippingProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterMissingShippingProductsValue && errors.filterMissingShippingProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterMissingShippingProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterMissingShippingProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterUnavailableProducts')}
                                                                                    <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterUnAvailableProductsValue, 'filterUnAvailableProductsValue': true }) }}
                                                                                    name={'filterUnAvailableProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterUnAvailableProductsValue && errors.filterUnAvailableProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterUnAvailableProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterUnavailableProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterNoImportFeeProducts')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterNoImportFeeProductsValue, 'filterNoImportFeeProductsValue': true }) }}
                                                                                    name={'filterNoImportFeeProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterNoImportFeeProductsValue && errors.filterNoImportFeeProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterNoImportFeeProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterNoImportFeeProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterNoSalesRankProducts')}
                                                                                    <span className="ms-2 badge bg-warning header-badge">{i18next.t('constants:badges.notRecommended')}</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterNoSalesRankProductsValue, 'filterNoSalesRankProductsValue': true }) }}
                                                                                    name={'filterNoSalesRankProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterNoSalesRankProductsValue && errors.filterNoSalesRankProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterNoSalesRankProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterNoSalesRankProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterSameSellerBrandProducts')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterSameSellerBrandProductsValue, 'filterSameSellerBrandProductsValue': true }) }}
                                                                                    name={'filterSameSellerBrandProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterSameSellerBrandProductsValue && errors.filterSameSellerBrandProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterSameSellerBrandProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterSameSellerBrandProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterPrimeSellersInStoreProducts')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterPrimeSellersInStoreProductsValue, 'filterPrimeSellersInStoreProductsValue': true }) }}
                                                                                    name={'filterPrimeSellersInStoreProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterPrimeSellersInStoreProductsValue && errors.filterPrimeSellersInStoreProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterPrimeSellersInStoreProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterPrimeSellersInStoreProductsDescription', { countryCode: myStore?.countryCode })}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterByMaxSellersInStoreProducts')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <DrInput className="form-control" placeholder="" name="filterByMaxSellersInStoreProductsValue" type="number" min="0"
                                                                                    value={values.filterByMaxSellersInStoreProductsValue || ""}
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'filterByMaxSellersInStoreProductsValue': true }) }}
                                                                                    isInvalid={(touched.filterByMaxSellersInStoreProductsValue && errors.filterByMaxSellersInStoreProductsValue)}
                                                                                    isValid={touched.filterByMaxSellersInStoreProductsValue && !errors.filterByMaxSellersInStoreProductsValue}
                                                                                    validationText={errors.filterByMaxSellersInStoreProductsValue}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterByMaxSellersInStoreProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterByMinSellersInStoreProducts')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <DrInput className="form-control" placeholder="" name="filterByMinSellersInStoreProductsValue" type="number" min="0"
                                                                                    value={values.filterByMinSellersInStoreProductsValue || ""}
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'filterByMinSellersInStoreProductsValue': true }) }}
                                                                                    isInvalid={(touched.filterByMinSellersInStoreProductsValue && errors.filterByMinSellersInStoreProductsValue)}
                                                                                    isValid={touched.filterByMinSellersInStoreProductsValue && !errors.filterByMinSellersInStoreProductsValue}
                                                                                    validationText={errors.filterByMinSellersInStoreProductsValue}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterByMinSellersInStoreProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterHaveCNSellersProducts')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterHaveCNSellersProductsValue, 'filterHaveCNSellersProductsValue': true }) }}
                                                                                    name={'filterHaveCNSellersProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterHaveCNSellersProductsValue && errors.filterHaveCNSellersProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterHaveCNSellersProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterHaveCNSellersProductsDescription', { countryCode: myStore?.countryCode })}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border-top">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {t('enableFilterHaveAmazonSellersProducts')}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterHaveAmazonSellersProductsValue, 'filterHaveAmazonSellersProductsValue': true }) }}
                                                                                    name={'filterHaveAmazonSellersProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterHaveAmazonSellersProductsValue && errors.filterHaveAmazonSellersProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterHaveAmazonSellersProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterHaveAmazonSellersProductsDescription', { countryCode: myStore?.countryCode })}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row px-3 py-3 my-3 border border-gold bg-gold rounded">
                                                                    <div className="col-lg-12 col-sm-12 mt-3">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                <label className="noselect mt-3 text-start text-darkgray">
                                                                                    {t('enableFilterPrimeExclusiveProducts')}
                                                                                    <span className="ms-2 badge bg-dark-gold header-badge text-white">{i18next.t('constants:badges.onlyPrimeUser')}</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                <Field onBlur={() => { setTouched({ ...touched.filterPrimeExclusiveProductsValue, 'filterPrimeExclusiveProductsValue': true }) }}
                                                                                    name={'filterPrimeExclusiveProductsValue'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder="Select"
                                                                                    options={filterValueOptions} />
                                                                                {(touched.filterPrimeExclusiveProductsValue && errors.filterPrimeExclusiveProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.filterPrimeExclusiveProductsValue}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{t('enableFilterPrimeExclusiveProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {selectedStore && <div className="row row-md row-lg justify-content-center my-3 px-1">

                                                    <div className="col-lg-auto col-md-2">
                                                        <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (isValid) {
                                                                    handleSubmit(values);
                                                                }
                                                            }}
                                                        >{t('saveChanges')}</button>
                                                    </div>
                                                </div>}
                                                {!selectedStore && <div className="row row-md row-lg justify-content-center my-3 px-1">

                                                    <div className="col-lg-auto col-md-2">
                                                        <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                Promise.all([
                                                                    dispatch(withActionPromise(clearCreateStoreInformationState()))
                                                                ]).then((e) => {
                                                                    navigate(getRouteByKey("MARKET_SELECTION"));
                                                                });;

                                                            }}>
                                                            <i className="me-2 fe fe-chevrons-right"></i>
                                                            {t('gotoMarketSelection')}
                                                        </button>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            )
            }
        </Formik>
    )
}

export default StoreFiltersPage;