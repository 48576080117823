import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getRouteByKey } from '../../../routes';
import { clearCreateStoreInformationState } from '../../../state-management/actions/create-store.actions';
import { useEffect, useState } from 'react';
import './dr-store-dropdown.component.scss'
import { getCustomerStore, setLastSelectedStoreMarketId, setSelectedStore } from '../../../state-management/actions/store.actions';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { withActionPromise } from '../../../state-management/actions/app.actions';
import { setLastVisitedMarket } from '../../../state-management/actions/settings.actions';
import Restricted from '../../../security/Restricted';

function DrStoreDropdown({

    onChange,
    disableInputChange,
    disabled,
    className,
    ...rest
}) {
    const { REACT_APP_ROLES_ADMIN } = process.env;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customerStores = useSelector(state => state.stores.customerStores);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const myStore = useSelector(state => state.stores.myStore);
    const allMarkets = useSelector(state => state.markets.allMarkets);
    const { t } = useTranslation('drHeader');

    const [dropdownSource, setDropdownSource] = useState(null);

    var btnClass = classNames({
        'text-muted': disabled,
        'no-pointer-events': disabled,
        'cursor-pointer': true,
    }, rest.className, className);

    useEffect(() => {
        if (customerStores && Object.keys(customerStores).length > 0) {
            setDropdownSource(allMarkets.filter(x => customerStores.some(y => y.marketSerialNumber === x.marketSerialNumber)));
        }
    }, [customerStores, allMarkets]);

    const inputChange = (marketId) => {
        if (customerStores) {
            dispatch(setSelectedStore(customerStores.find(x => x.marketId === marketId)));
            dispatch(withActionPromise(setLastSelectedStoreMarketId(marketId)));
            dispatch(withActionPromise(setLastVisitedMarket(marketId)));
        }
    }

    useEffect(() => {
        if (selectedStore?.customerMainSellerId && myStore?.id !== selectedStore?.customerMainSellerId) {
            dispatch(getCustomerStore({
                marketId: selectedStore?.marketId,
                customerMainSellerId: selectedStore?.customerMainSellerId
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId]);

    return (
        <>
            {/* <div className="nav-link icon align-middle d-flex align-items-center float-left" data-bs-toggle="dropdown" >
                <i className="flag flag-ca" style={{ marginRight: '10px', marginBottom: '2px' }} />
                <span className="noselect mb-2" style={{ fontSize: '15px' }}>{i18next.t('constants:countries.CA')}</span>
            </div> */}
            <div id="storeDropDown" className={btnClass}>

                {dropdownSource &&
                    <div className="btn-group">
                        <button type="button" className="btn btn-primary-ghost btn-wave waves-effect waves-light shadow dropdown-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i className={`me-2 flag ${selectedStore && selectedStore.countryCode ? ("flag-" + (selectedStore.countryCode === 'UK' ? 'gb' : selectedStore.countryCode.toLowerCase())) : ""}`}></i>
                            <span className="noselect" style={{ fontSize: '13px', fontWeight: '500' }}>{selectedStore ? i18next.t(('constants:countries.' + selectedStore.countryCode)) : "Select"}</span>
                            <i className="fe fe-chevron-down ms-2" />
                        </button>
                        <ul className="dropdown-menu">

                            {dropdownSource.map((market, index) => {
                                let translateKey = 'constants:countries.' + market.countryCode;
                                let flagClass = 'w-6 me-2 flag flag-' + (market.countryCode === 'UK' ? 'gb' : market.countryCode.toLowerCase());

                                return (
                                    <li className="dropdown-item" key={(index + 1)}>
                                        <div className="d-flex w-100 no-select"
                                            onClick={(e) => {
                                                if (onChange)
                                                    onChange(market.id);
                                                if (!disableInputChange)
                                                    inputChange(market.id);
                                            }}>
                                            <i className={flagClass}></i>
                                            {i18next.t(translateKey)}
                                        </div>
                                    </li>


                                )
                            })}


                            <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                                <li>
                                    <hr className="dropdown-divider"></hr>
                                </li>
                                <li className="dropdown-item text-success">
                                    <div className="d-flex w-100 no-select"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(clearCreateStoreInformationState());
                                            navigate(getRouteByKey("MARKET_SELECTION"));
                                        }}>
                                        <i className="fe fe-plus text-success me-2"></i>
                                        {t('addnewcountry')}
                                    </div>
                                </li>


                            </Restricted>
                        </ul>
                    </div>
                }
                {!dropdownSource &&
                    <button className="btn btn-success-ghost btn-wave waves-effect waves-light shadow"
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(withActionPromise(setLastSelectedStoreMarketId(selectedStore?.marketId)));
                            dispatch(withActionPromise(clearCreateStoreInformationState()));
                            navigate(getRouteByKey("MARKET_SELECTION"));
                        }
                        }>
                        <i className="fe fe-plus text-success me-2"></i>
                        {t('addnewcountry')}
                    </button>
                }


            </div>
        </>
    )
}
export default DrStoreDropdown