import { useDispatch } from "react-redux";
import './dr-search-task-products.scss'
import { useTranslation } from "react-i18next";

import { clearActiveSearchTaskProductsModal } from "../../../../state-management/actions/app.actions";
import { useEffect, useState } from "react";
import { generatePdfReport } from "../../../../utils/utils";
import $ from 'jquery';

function DrSearchTaskProducts({ display, source, persistModal = false }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drSearchTaskProducts');
    // {"Asins":["B0B1X18BXZ"],"CountryCode":"US"}  searchType 1
    //  [{ASIN:"sdsds" , SourceMarketCountryCode : "US", CountryCode:"US",...} , {}]
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (source && source.data) {
            var dataArr = [];
            var parsedData = JSON.parse(source.data);
            if (source.searchType === 0) {
                parsedData.forEach(element => {
                    dataArr.push({ asin: element.ASIN, sourceMarketCountryCode: element.SourceMarketCountryCode, marketCountryCode: element.CountryCode });
                });
            }
            else if (source.searchType === 1) {
                parsedData.forEach(element => {
                    dataArr.push({ asin: element.Asin, sourceMarketCountryCode: element.SourceMarketCountryCode, marketCountryCode: element.MarketCountryCode });
                });
            }
            setTableData(dataArr);
        }
        else {
            setTableData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    const data = [{
        "ASIN": "B001BKWXOY",
        "SourceMarketCountryCode": "US",
        "CountryCode": "CA",
        "CustomerMainSellerId": "e1afa174-bde1-48ae-b643-ae0b57164295",
        "ProductMarketId": "be9b2989-2710-4681-adff-9bab944c9bfc",
        "Detail": {
            "Asin": "B0BXWL5M67",
            "ProductMarketId": "fd21f8aa-edba-4128-94d0-0c18525021af",
            "Review": 853,
            "Rating": 2853,
            "Stars": 46,
            "Stock": 999,
            "BuyboxOwnerSeller": "",
            "ShippingPrice": 16.78,
            "ShippingPriceCurrencyCode": "USD",
            "DeliveryTime": "6",
            "CustomsDutyPrice": 3.89,
            "CustomsDutyPriceCurrencyCode": "USD",
            "PrimeSellerCount": 0,
            "TotalSellerCount": 7,
            "IsSoldByAmazon": false,
            "IsFBASellerExists": false,
            "IsChineseSellerExists": false,
            "IsPrimeExclusive": false,
            "IsDiscounted": true,
            "DiscountType": "percent",
            "DiscountValue": "10",
            "CouponType": "",
            "CouponValue": "",
            "CouponTypeDestinationMarket": "",
            "CouponValueDestinationMarket": "",
            "IsLiquidContent": false,
            "SellerDisplayGroupRankTitle": "",
            "SellerDisplayGroupRank": 49672,
            "SellerClassificationRankTitle": "",
            "SellerClassificationRank": 155,
            "SellerDisplayGroupRankAtDestinationMarket": 1530,
            "SellerClassificationRankAtDestinationMarket": 19,

            "NoShipProduct": true,
            "OnlySellerDoppingHouse": false,
            "UnAvailable": true
        }
    }]
    const exportToExcelProductDetail = () => {
        if (source && source.data) {
            var parsedData = JSON.parse(source.data);
            if (source.searchType === 0) {
                const tableColumn = ["ASIN", "Review", "Rating", "Stars", "Stock",
                    "BuyboxOwnerSeller", "ShippingPrice", "CustomsDutyPrice",
                    "PrimeSellerCount", "TotalSellerCount", "IsSoldByAmazon",
                    "IsFBASellerExists", "IsChineseSellerExists", "IsPrimeExclusive",
                    "IsLiquidContent", "Discount", "Coupon", "Coupon-SalesMarket", "MainCategoryRank-SubCategoryRank-SourceMarket", "MainCategoryRank-SubCategoryRank-SalesMarket",
                    "UnDeliverable", "UnAvailable", "SameSellerBrandProductValue", "DeliveryTime"
                ];
                // define an empty array of rows
                const tableRows = [];

                // for each ticket pass all its data into an array
                parsedData.forEach(product => {
                    if (product.Detail) {
                        const productData = [
                            product.Detail?.Asin,
                            product.Detail?.Review,
                            product.Detail?.Rating,
                            product.Detail?.Stars,
                            product.Detail?.Stock,
                            product.Detail?.BuyboxOwnerSeller,
                            product.Detail?.ShippingPriceCurrencyCode + " " + Number(product.Detail?.ShippingPrice).toFixed(3),
                            product.Detail?.CustomsDutyPriceCurrencyCode + " " + Number(product.Detail?.CustomsDutyPrice).toFixed(3),
                            product.Detail?.PrimeSellerCount,
                            product.Detail?.TotalSellerCount,
                            product.Detail?.IsSoldByAmazon,
                            product.Detail?.IsFBASellerExists,
                            product.Detail?.IsChineseSellerExists,
                            product.Detail?.IsPrimeExclusive,
                            product.Detail?.IsLiquidContent,
                            product.Detail?.DiscountType + " " + product.Detail?.DiscountValue,
                            product.Detail?.CouponType + " " + product.Detail?.CouponValue,
                            product.Detail?.CouponTypeDestinationMarket + " " + product.Detail?.CouponValueDestinationMarket,
                            product.Detail?.SellerDisplayGroupRank + " - " + product.Detail?.SellerClassificationRank,
                            product.Detail?.SellerDisplayGroupRankAtDestinationMarket + " - " + product.Detail?.SellerClassificationRankAtDestinationMarket,
                            product.Detail?.UnDeliverable,
                            product.Detail?.UnAvailable,
                            product.Detail?.SameSellerBrandProductValue,
                            product.Detail?.DeliveryTime
                        ];
                        // push each tickcet's info into a row
                        tableRows.push(productData);
                    }

                });
                generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Search Task Product Details", orientation: 'l', format: "a1", reportName: "products_details" });
            }
            else if (source.searchType === 1) {
                const tableColumn = ["ASIN", "Stock"];
                // define an empty array of rows
                const tableRows = [];

                // for each ticket pass all its data into an array
                parsedData.forEach(product => {
                    if (product.Detail) {
                        const productData = [
                            product.Detail?.Asin,
                            product.Detail?.StockCount
                        ];
                        // push each tickcet's info into a row
                        tableRows.push(productData);
                    }

                });
                generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Search Task Product Stocks", orientation: 'p', format: "a4", reportName: "products_stocks" });
            }

        }
    }
    return (
        <div className={`modal fade modal-background ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="searchTaskModal" aria-modal="true" aria-hidden="true"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActiveSearchTaskProductsModal());
                }
            }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header" style={{ minHeight: '48px' }}>
                        <h6 className="modal-title" id="exampleModalLongTitle">{t('title')}</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => dispatch(clearActiveSearchTaskProductsModal())} >
                        </button>
                    </div>
                    <div className="modal-body justify-contents-center">
                        {display &&
                            <div id="searchTaskProducts" className="table-responsive border p-0">

                                <table className="table mg-b-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center">{t('datatable.asin')}</th>
                                            <th className="text-center">{t('datatable.countryCode')}</th>
                                            <th className="text-center">{t('datatable.sourceCountryCode')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData && tableData.map((item, index) => {
                                            return (
                                                <tr key={"search-task-products_" + (index + 1)} style={{ backgroundColor: "var(--default-border) !important" }}>
                                                    <td className="text-start" width={"30%"}>{item.asin}</td>
                                                    <td className="text-center" width={"35%"}> {item.marketCountryCode}</td>
                                                    <td className="text-center" width={"35%"}> {item.sourceMarketCountryCode}</td>
                                                </tr>
                                            )
                                        }
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="row justify-content-end">
                            <div className="col-auto">
                                <button type="button" className="btn btn-success btn-wave waves-effect waves-light shadow" data-dismiss="modal"
                                    onClick={() => {
                                        exportToExcelProductDetail();
                                    }}>
                                    <i className="me-2 fe fe-download"></i>
                                    {t('Excel')}
                                </button>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-secondary btn-wave waves-effect waves-light shadow" data-dismiss="modal"
                                    onClick={() => dispatch(clearActiveSearchTaskProductsModal())}>{t('close')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DrSearchTaskProducts