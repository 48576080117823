import types from "../actions/shipstation.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SHIPSTATION_SUCCESS:
            return { ...state, selectedOrderShipstationData: action.payload };
        case types.CLEAR_SHIPSTATION_DATA:
            return { ...state, selectedOrderShipstationData: null };
        case types.GET_SHIPSTATION_WAREHOUSES_SUCCESS:
            return { ...state, shipstationWarehouses: action.payload };
        case types.GET_SHIPSTATION_CARRIERS_SUCCESS:
            return { ...state, shipstationCarriers: action.payload };
        case types.GET_SHIPSTATION_CARRIER_SERVICES_SUCCESS:
            return {
                ...state, "shipstationCarrierServices": state.shipstationCarrierServices.filter(x => x.carrierCode !== action.payload.carrierCode).concat(action.payload)
            };
        // case types.GET_SHIPSTATION_CARRIER_PACKAGES_SUCCESS:
        //     return { ...state, selectedCarrierPackages: action.payload };
        case types.GET_SHIPSTATION_CARRIER_PACKAGES_SUCCESS:
            return { ...state, shipstationCarrierPackages: state.shipstationCarrierPackages.filter(x => x.carrierCode !== action.payload.carrierCode).concat(action.payload) };
        case types.CLEAR_ALL_SHIPSTATION_DATA:
            return initialState;;
        default:
            return state;
    }
}

const initialState = {
    "selectedOrderShipstationData": null,
    "shipstationWarehouses": [],
    "shipstationCarriers": [],
    "shipstationCarrierServices": [],
    "shipstationCarrierPackages": []
}

export default reducer;