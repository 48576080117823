import { useDispatch } from 'react-redux'
import { clearActivePictureModal } from '../../../state-management/actions/app.actions';
import { GrFormClose } from 'react-icons/gr'
import './dr-picturemodal.component.scss'
import { useState } from 'react';
import noImage from '../../../assets/img/pngs/no-image.png'
import { useEffect } from 'react';

function DrPictureModal({ display, source }) {
    const dispatch = useDispatch();
    const [selectedPictureIndex, setSelectedPictureIndex] = useState(0);

    useEffect(() => {
        if (source?.length > 0) {
            setSelectedPictureIndex(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`modal fade ${display ? "effect-scale show " : ""}`} id="pic-modal-background" style={{ display: display ? "block" : "none" }}
                tabIndex="-1" role="dialog" aria-labelledby="baseModal" aria-modal="true" aria-hidden="true"
                onClick={(e) => {
                    if (e.target.id === "pic-modal-background") {
                        dispatch(clearActivePictureModal());
                        setSelectedPictureIndex(0)
                    }

                }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content pic-content" data-tag="pic-content">
                        <div className="modal-body justify-contents-center">
                            <button type="button" className="btn-close modal-close-button" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => {
                                    dispatch(clearActivePictureModal());
                                    setSelectedPictureIndex(0);
                                }}>
                            </button>

                            {source?.length > 1 &&
                                <>
                                    {/* <button className="btn btn-outline-icon btn-wave waves-effect waves-light shadow" onClick={() => setSelectedPictureIndex(selectedPictureIndex > 0 ? selectedPictureIndex - 1 : (source.length - 1))}
                                    style={{ position: 'absolute', left: '-8px', top: '47%' }}>
                                    <i className={`fe fe-chevron-left`}></i>
                                </button> */}
                                    <div className="swiper-button-prev cursor-pointer shadow"
                                        onClick={() => setSelectedPictureIndex(selectedPictureIndex > 0 ? selectedPictureIndex - 1 : (source.length - 1))}
                                    >
                                    </div>
                                </>

                            }

                            {display && <img src={source[selectedPictureIndex]} alt="img" className="img-xl p-0 m-0"></img>}

                            {source?.length > 1 &&
                                <>
                                    {/* <button className="btn btn-outline-icon btn-wave waves-effect waves-light shadow" onClick={() => setSelectedPictureIndex(selectedPictureIndex < (source.length - 1) ? (selectedPictureIndex + 1) : 0)}
                                        style={{ position: 'absolute', right: '-8px', top: '47%' }}                          >
                                        <i className={`fe fe-chevron-right`}></i>
                                    </button> */}
                                    <div className="swiper-button-next cursor-pointer shadow"
                                        onClick={() => setSelectedPictureIndex(selectedPictureIndex < (source.length - 1) ? (selectedPictureIndex + 1) : 0)}
                                    >
                                    </div>
                                </>


                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DrPictureModal


