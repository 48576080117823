import React from 'react';
import { BlobProvider, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 30,
    },
    section: {
        margin: 10,
        padding: 10,
    },
    title: {
        fontSize: 18,
        marginBottom: 4,
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 16,
        marginBottom: 10,
    },
    date: {
        fontSize: 12,
        marginBottom: 10,
        textAlign: 'right',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        marginTop: 20,
    },
    sectionLeft: {
        textAlign: 'left',
    },
    sectionRight: {
        textAlign: 'right',
    },
    text: {
        fontSize: 10,
        marginBottom: 5,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCol: {
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',

    },
    tableCell: {
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5,
        padding: 5,
    },
    priceDivider: {
        marginBottom: 30,
    },
    rowPrice: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    priceText: {
        fontSize: 14,
        textAlign: 'left',
    },
    priceNumber: {
        fontSize: 14,
        textAlign: 'right',
    },
    priceTotal: {
        fontSize: 16,
        fontWeight: 'light',
    },
});

const DrOrderInvoice = ({ data }) => {
    const { invoicedFrom, amazonOrderNumber, invoicedTo, date, tax, total, currencyCode, products } = data;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.title}>Invoice</Text>
                    <Text style={styles.title}>{amazonOrderNumber}</Text>
                    <View style={{ borderBottom: '1 solid black', marginBottom: 10 }} />
                    <Text style={styles.date}>Date: {new Date(date).toLocaleDateString()}</Text>
                    <View style={styles.row}>
                        <View style={styles.sectionLeft}>
                            <Text style={styles.subtitle}>Invoiced From</Text>
                            <Text style={styles.text}>{invoicedFrom}</Text>
                        </View>
                        <View style={styles.sectionRight}>
                            <Text style={styles.subtitle}>Invoiced To</Text>
                            <Text style={styles.text}>{invoicedTo}</Text>
                        </View>
                    </View>
                    <Text style={styles.subtitle}>Products</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Description</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Unit</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Price</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Total</Text>
                            </View>
                        </View>
                        {products?.map((product, index) => (
                            <View style={styles.tableRow} key={index}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{product.description}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{product.unit}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{`${product.price} ${product.currencyCode}`}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{`${product.price * product.unit} ${product.currencyCode}`}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <View style={styles.priceDivider} />
                    <View style={styles.rowPrice}>
                        <View style={styles.sectionLeft}>
                            <Text style={styles.priceText}>Tax:</Text>
                        </View>

                        <View style={styles.sectionRight}>
                            <Text style={styles.priceNumber}>{tax} {currencyCode}</Text>
                        </View>
                    </View>
                    <View style={{ borderBottom: '0.5 solid black', marginVertical: 10 }} />
                    <View style={styles.rowPrice}>
                        <View style={styles.sectionLeft}>
                            <Text style={styles.priceText}>Total:</Text>
                        </View>

                        <View style={styles.sectionRight}>
                            <Text style={[styles.priceNumber, styles.priceTotal]}>{total} {currencyCode}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};



const DrPDFViewer = ({ pdfData }) => {
    return (
        <BlobProvider document={<DrOrderInvoice data={pdfData} />}>
            {({ url, loading, error }) => {
                if (loading) return <div>Loading document...</div>;
                if (error) return <div>Something went wrong: {error.message}</div>;
                if (url) {
                    // Open PDF in a new tab
                    window.open(url, '_blank');
                }
                return null;
            }}
        </BlobProvider>
    );
};

export default DrPDFViewer;