import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrInput, DrPageFilter, DrTagifyInput } from '../../components/component-index';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { getProductsBrands, saveProductBrandBlock } from '../../state-management/actions/inventory.actions';
import { useDispatch, useSelector } from 'react-redux';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { buttonVariants, pageFilterTypes } from '../../utils/constants';
import { generatePdfReport } from '../../utils/utils';
import { ExportToExcel } from '../../excelexport';
// import { copyrightBrandsMockData as trademarkData } from '../../utils/mocks-data';
import { getRouteByKey } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { trademarkOfficeOptions } from '../../components/hoc/Constants/dr-constants.component';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getTrademarkResult } from '../../state-management/actions/trademark.action';

function CopyrightBrandListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('copyrightBrandsPage');
  const trademarkData = useSelector(state => state.trademark.trademarkData);
  const selectedStore = useSelector(state => state.stores.selectedStore);


  useEffect(() => {
    // if (selectedStore?.customerMainSellerId) {
    //   dispatch(withActionPromise(getProductsBrands(selectedStore?.customerMainSellerId)));

    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    brandList: [],
    serialNumber: null,
    countryCode: "USA"
  }

  const columns = [
    { data: "serialNumber", title: "", minWidth: "50px", maxWidth: "50px", searchable: false, checkall: true, checkData: "serialNumber" },
    { data: "serialNumber", title: t('dataTable.serialNumber'), minWidth: "120px", maxWidth: "120px", searchable: true, },
    { data: "brandName", title: t('dataTable.brand'), width: "15%", searchable: true },
    { data: "trademarkCountry", title: t('dataTable.trademarkOffice'), minWidth: "120px", maxWidth: "120px", searchable: true },
    { data: "codes", title: t('dataTable.codes'), width: "15%", searchable: true },
    { data: null, title: "", minWidth: "80px", maxWidth: "80px", searchable: false },
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        if (rowData.isBlocked) {
          td.closest("tr").classList.toggle("greyed-out");
        }

        ReactDOM.createRoot(td).render(
          <>
            {!rowData.isBlocked &&
              <input type="checkbox" className="ckbox" name="name" value={rowData.serialNumber}
                onChange={(e) => {
                  e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                }}
              />
            }
          </>
        )
      }
    },
    {
      targets: 1,
      orderable: true,
      className: "text-center",
      width: "20%",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-14 px-1 py-1">{rowData.serialNumber}</span><br />
          </>)
      }
    },
    {
      targets: 2,
      orderable: true,
      className: "text-center",
      width: "20%",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-14 px-1 py-1">{rowData.brandName}</span><br />
          </>)
      }
    },
    {
      targets: 3,
      orderable: true,
      className: "text-center",
      width: "20%",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-14 px-1 py-1">{rowData.trademarkCountry}</span><br />
          </>)
      }
    },
    {
      targets: 4,
      orderable: true,
      className: "text-center",
      width: "20%",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-14 px-1 py-1">{rowData.codes}</span><br />
          </>)
      }
    },
    {
      targets: 5,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            {/* <button className={`ms-2 btn btn-wave waves-effect waves-light shadow btn-sm  ${rowData.isBlocked ? "btn-warning" : "btn-danger"}`} rel="noreferrer" value=""
            onClick={(e) => {
              e.preventDefault();
              // dispatch(withActionPromise(saveProductBrandBlock({
              //   customerMainSellerId: selectedStore.customerMainSellerId,
              //   productBrandId: rowData.id,
              //   isBlocked: !rowData.isBlocked
              // })));
            }}>
            {rowData.isBlocked ? "UnBlock" : "Block"}

          </button> */}
          </>

        );
      }
    }
  ];

  const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {

    if (selectedOperation === "0" || selectedOperation === "1") //export
    {
      var data = trademarkData.filter(item1 =>
        !!checkedValues.find(item2 => item1.serialNumber === item2));

      if (selectedOperation === "0" && data?.length > 0) {
        ExportToExcel({ excelData: data, fileName: "copyright_brands" });
      }
      else if (selectedOperation === "1" && data?.length > 0) {

        const tableColumn = ["BRAND NAME", "SERIAL NUMBER", "TRADEMARK COUNTRY", "CODES"];
        // define an empty array of rows
        const tableRows = [];

        // for each ticket pass all its data into an array
        data.forEach(brand => {
          const brandData = [
            brand.brandName,
            brand.serialNumber,
            brand.trademarkCountry,
            brand.codes
          ];
          // push each tickcet's info into a row
          tableRows.push(brandData);
        });
        generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Product Brands", orientation: 'l', reportName: "copyright_brands" });

      }
    }

  }

  const trademarkSchema = Yup.object().shape({

    serialNumber: Yup.string().nullable().matches(/^[0-9]+$/, "Must be only digits")
      .min(8, 'Serial Number needs to be 8 character')
      .max(8, 'Serial Number needs to be 8 character'),
    // serialNumber: Yup.number()
    //   .nullable()
    //   .notRequired()
    //   .min(10000000)
    //   .max(99999999)
    //   .test(
    //     "noEOrSign", // type of the validator (should be unique)
    //     "Number had an 'e' or sign.", // error message
    //     (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
    //   ),
    brandList: Yup.array().nullable().when('serialNumber', {
      is: (value) => value && value !== '',
      then: () => Yup.array().nullable(),
      otherwise: () => Yup.array().required('You need to enter keywords if no Serial Number entered!').test({
        message: 'At least one brand name is required',
        test: arr => arr?.length !== 0,
      }).ensure().required('Brand names are required')
    })

  });
  return (
    <>
      <DrBreadCrump
        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
        { navigationText: i18next.t('constants:breadCrumps.copyrightBrandsList'), navigationPath: "" }]} >
      </DrBreadCrump>

      <div id="inventoryTable" className="row mb-5">
        <div className="col-lg-12 col-xl-12 col-md-12">

          <div className="card custom-card">
            <div className="card-body horizontal-scrollable">
              <Formik
                initialValues={initialValues}
                validateOnBlur={true}
                // onSubmit={handleSubmit}
                validationSchema={trademarkSchema}
                enableReinitialize={true}
                validateOnMount={true}
                validateOnChange={true}

              >
                {({
                  isValid,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setTouched,
                  setFieldValue,
                  validateForm
                }) => (
                  <form>
                    <div className="row my-2 justify-content-end">
                      <div className="col-auto my-auto">
                        <DrButtonDropdown options={trademarkOfficeOptions}
                          optionClickCallBack={(value) => {

                          }}
                          variant={buttonVariants.orange}
                          selectedValue={values.countryCode}>
                        </DrButtonDropdown>
                      </div>
                    </div>
                    <div className="row my-2">

                      <div className="col-auto">
                        <h6>{t('serialNumber')}</h6>
                        <DrInput value={values.serialNumber || ""} className={`form-control`} type="text"

                          placeholder={t('serialNumber')}
                          name="serialNumber"
                          onChange={handleChange}
                          onBlur={() => { setTouched({ ...touched, 'serialNumber': true }) }}
                          isInvalid={(touched.serialNumber && errors.serialNumber)}
                          isValid={touched.serialNumber && !errors.serialNumber}
                          validationText={errors.serialNumber}
                        />
                      </div>
                      <div className="col">
                        <h6>{t('brandNames')}</h6>
                        <DrTagifyInput value={values.brandList || []}
                          settings={{
                            // pattern: /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{10}$/,
                            delimiters: ",|:|[\\n\\r]"
                          }}
                          isvalid={((touched.serialNumber || touched.brandList) && !errors.brandList)}
                          isinvalid={((touched.serialNumber || touched.brandList) && errors.brandList)}
                          className={`tags-input-control`}

                          onBlur={(e) => {
                            setTouched({ ...touched, 'brandList': true });
                            setFieldValue("brandList", e.detail.tagify.value?.map(a => a.value.trim()));
                            setTouched({ ...touched, 'serialNumber': true });
                            setFieldValue("serialNumber", "");
                            validateForm();
                          }}
                          onRemove={(e) => {
                            setTouched({ ...touched, 'brandList': true });
                            setFieldValue("brandList", e.detail.tagify.value?.map(a => a.value.trim()));
                            setTouched({ ...touched, 'serialNumber': true });
                            setFieldValue("serialNumber", "");
                            validateForm();
                          }}
                          onEditUpdated={(e) => {
                            setTouched({ ...touched, 'brandList': true });
                            setFieldValue("brandList", e.detail.tagify.value?.map(a => a.value.trim()));
                            setTouched({ ...touched, 'serialNumber': true });
                            setFieldValue("serialNumber", "");
                            validateForm();

                          }}>
                        </DrTagifyInput>
                        <div type="text" className={`invalid-feedback ${((touched.serialNumber || touched.brandList) && errors.brandList) ? "d-block" : "d-none"}`} >
                          {errors.brandList}
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-end my-2">
                      <div className="col-auto px-0">
                        <button className={`btn btn-teal btn-wave waves-effect waves-light shadow ${(!isValid) ? "disabled" : ""}`}
                          onClick={(e) => {
                            e.preventDefault();
                            var request = {

                              brandList: values.serialNumber?.length > 0 ? null : values.brandList,
                              serialNumber: values.serialNumber === "" ? null : values.serialNumber,
                              countryCode: values.countryCode

                            }

                            dispatch(getTrademarkResult(request))
                          }}
                        >
                          <i className="fe fe-search me-2"></i>
                          {t('copyrightSearch')}</button>
                      </div>
                    </div>
                  </form>
                )
                }
              </Formik>
              <DrDataTable
                data={trademarkData}
                columns={columns}
                columnDefs={columnDefs}
                doesHaveBorder={false}
                headerSearch={true}
                // headerSelectbox={true}
                minWidth="850px"
                search={true}
                order={[[1, 'asc']]}
                isMultiSelectEnabled={true}
                detailedSearchChildren={
                  <DrPageFilter
                    filterType={pageFilterTypes.copyrightBrands}
                    selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                  >
                  </DrPageFilter>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default CopyrightBrandListPage