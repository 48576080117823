
import './dr-filepond-upload.component.scss'
import { FilePond, File, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

import { useEffect, useState } from 'react';
import { setActivePictureModal } from '../../../state-management/actions/app.actions';
import { modals } from '../../../utils/constants';
import { useDispatch } from 'react-redux';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginFileEncode);

function DrFilePondUploadInput({
    name,
    field,
    form,
    onChange,
    disabled,
    className,
    selectedFiles,
    maxFileSizeInBytes,
    removeFileTypeStringFromContent = true,

    disableInputChange,
    accept,
    allowMultiple = false,
    maxFiles = 1,
    maxFileSize,
    labelIdle = 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
    ...rest }) {

    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (form) {
            form.setFieldValue(field.name, files);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    if (rest.disabled && rest.disabled === "") {
        delete rest.disabled;
    }

    function onFileUploaded(files) {

        if (files && files.length > maxFiles) {
            if (form) {
                form.setFieldValue(field.name, []);
            }
            onChange({ filePondItems: [], returnValueItems: [] });
            return;
        }

        var isFileSizeExceedsLimit = false;
        files.forEach(file => {
            if (file.fileSize > maxFileSizeInBytes) {
                isFileSizeExceedsLimit = true;
            }
        });

        if (isFileSizeExceedsLimit) {
            if (form) {
                form.setFieldValue(field.name, []);
            }
            onChange({ filePondItems: [], returnValueItems: [] });
            return;
        }

        var isFileTypeNotValid = false;
        if (accept?.length > 0) {
            files.forEach(file => {
                if (!accept.includes(file.fileType)) {
                    isFileTypeNotValid = true;
                }
            });
        }

        if (isFileTypeNotValid) {
            if (form) {
                form.setFieldValue(field.name, []);
            }
            onChange({ filePondItems: [], returnValueItems: [] });
            return;
        }

        const promises = []
        for (const file of files) {
            promises.push(readFile(file.file));
        }

        Promise.all(promises).then((result) => {
            if (form) {
                form.setFieldValue(field.name, files);
            }
            onChange({ filePondItems: files, returnValueItems: result });
        });
    }

    function readFile(f) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(f)
            reader.onload = event => resolve({ attachment: removeFileTypeStringFromContent ? event.target.result.split('base64,')[1] : event.target.result, contentType: f.type, name: f.name })
            reader.onerror = reject
        })
    }

    return (
        <>
            <FilePond
                allowFileEncode={true}
                files={form ? selectedFiles : files}
                acceptedFileTypes={accept}
                onupdatefiles={fileItems => {
                    setFiles(fileItems);

                    if (onChange) {
                        onFileUploaded(fileItems);
                    }
                }}
                onaddfile={(e) => {

                }}
                onaddfilestart={(e) => {

                }}
                onaddfileprogress={(e) => {

                }}
                onprocessfileprogress={(e) => {


                }}
                onprocessfile={(e) => {

                }}

                onactivatefile={(e) => {
                    var base64String = "data:" + e.fileType + ";base64," + e.getFileEncodeBase64String();
                    dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [base64String] }))
                }}

                allowMultiple={allowMultiple}
                maxFiles={maxFiles}
                // server="/api"
                name="files"
                labelIdle={labelIdle}

                allowFileSizeValidation={true}
                allowFileTypeValidation={accept?.length > 0 ? true : false}
                maxFileSize={maxFileSize}
                maxTotalFileSize={maxFileSize * maxFiles}
            />
        </>
    )
}

export default DrFilePondUploadInput;
