import React from 'react'
import Restricted from './Restricted'
import { MainPackages, MarketSelectionPage } from '../pages/pages-index';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createPackagesSteps, createStoreSteps, findCurrentStep } from '../utils/steps';
import { DrCardContainer, DrStepTracker } from '../components/component-index';

function SecureRoute({ allowedTo, children }) {
    const location = useLocation();
    const customerInfo = useSelector(state => state.customer.customerInfo);

    const currentCreatePackagesStep = findCurrentStep(location, createPackagesSteps);
    const currentStoreStep = findCurrentStep(location, createStoreSteps);

    const createPackagesPageSubmissions = useSelector(state => state.createPackagesSnapshot.pageSubmissions);
    const createStorePageSubmissions = useSelector(state => state.createStoreSnapshot.pageSubmissions);

    return (
        <>
            <Restricted
                allowedTo={allowedTo}
                // fallback={<h2>Insufficient Permission</h2>}
                fallback={
                    // (customerInfo.role?.length > 0 && customerInfo.role?.includes(REACT_APP_ROLES_GUEST) //guest
                    //     && <>
                    //         <DrCardContainer backgroundClass="bg-transparent p-0" className={"pt-4 my-5"} shadow={false}>
                    //             <DrStepTracker steps={createStoreSteps} currentStep={currentStoreStep} pageSubmissions={createStorePageSubmissions} />
                    //             <MarketSelectionPage></MarketSelectionPage>
                    //         </DrCardContainer>
                    //     </>)
                    // || (customerInfo.role?.length > 0 && customerInfo.role?.includes(REACT_APP_ROLES_USER) //guest
                    //     && <>
                    //         <DrCardContainer backgroundClass="bg-transparent p-0" className={"pt-4 my-5"} shadow={false}>
                    //             <DrStepTracker stepsClass="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-8" steps={createPackagesSteps} currentStep={currentCreatePackagesStep} pageSubmissions={createPackagesPageSubmissions} />
                    //             <MainPackages></MainPackages>
                    //         </DrCardContainer>
                    //     </>)
                    // || 
                    <h2>Insufficient Permission</h2>

                }
            >
                {children}
            </Restricted>
        </>
    )
}

export default SecureRoute
