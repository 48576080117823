import types from "../actions/market.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_MARKETS_SUCCESS:
            return { ...state, allMarkets: action.payload };
        case types.CLEAR_ALL_MARKETS:
            return initialState;;
        default:
            return state;
    }
}

const initialState = {
    "allMarkets": []
}

export default reducer;