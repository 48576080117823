import { useDispatch, useSelector } from "react-redux";
import { clearActivePageFiltersModal, clearActiveSavePagefilterModal, setActiveSavePagefilterModal, withActionPromise } from "../../../state-management/actions/app.actions";
import './dr-saved-pagefilters.scss'
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import { Formik } from 'formik';
import { DrInput } from "../../component-index";
import { modals, pageFilterTypes } from "../../../utils/constants";
import { useEffect } from "react";
import { deletePageFilter, setInventoryPageFilters, setOrdersPageFilters, setProductVariationsPageFilters, updatePageFilter } from "../../../state-management/actions/page-filters.actions";

function DrSavedPageFilters({ display, source, persistModal = false, filterType }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drPageFilter');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const customerInfo = useSelector(state => state.customer.customerInfo);

    useEffect(() => {
        if (source && source.length > 0) {
            var selectedFilter = source.find(x => x.isSelected);
            if (selectedFilter) {
                setSelectedFilter(selectedFilter.id)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    const deleteFilter = (value) => {
        var selectedFilter = source.find(x => x.id === value);
        var data = {
            filterId: value,
            customerSubUserId: customerInfo?.customerId,
            customerMainSellerId: selectedStore?.customerMainSellerId,
            pageNumber: selectedFilter?.pageNumber,
        }
       
        dispatch(withActionPromise(deletePageFilter(data)));
    };

    const updateFilter = () => {
        var selectedFilter = source.find(x => x.isSelected);
        dispatch(withActionPromise(updatePageFilter(selectedFilter)));
    };

    const setSelectedFilter = (id) => {
        checkCustomSwitchInput(id);

        source.forEach(function (item) {
            item.isSelected = item.id === id ? true : false;
        });

        if (filterType === pageFilterTypes.inventory) {
            dispatch(withActionPromise(setInventoryPageFilters(source)));
        }
        if (filterType === pageFilterTypes.orders) {
            dispatch(withActionPromise(setOrdersPageFilters(source)));
        }

        if (filterType === pageFilterTypes.productVariations) {
            dispatch(withActionPromise(setProductVariationsPageFilters(source)));
        }
    }

    const checkCustomSwitchInput = (value) => {
        var allCustomSwitchInput = $("#pageFilters").find(".form-check-input");
        $("#pageFilters").find("tr").removeClass('selected');

        [].forEach.call(allCustomSwitchInput, function (el) {

            if (el.value === value) {
                el.checked = true;
                el.closest("tr").classList.toggle("selected");
            }
            else {
                el.checked = false;
            }
        });
    }

    return (
        <div className={`modal fade modal-background ${display ? "effect-scale show" : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="pagefilterModal" aria-modal="true" aria-hidden="true"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActivePageFiltersModal());
                }
            }}>
            <div className="modal-dialog modal-dialog">
                <div className="modal-content">
                    <div className="modal-header" >
                        <h6 className="modal-title" id="exampleModalLongTitle">{t('title')}</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                dispatch(clearActivePageFiltersModal())
                            }}>
                        </button>
                    </div>
                    <div className="modal-body justify-contents-center">
                        {display &&
                            <Formik
                                initialValues={source}
                                validateOnBlur={true}
                                // onSubmit={handleSubmit}
                                enableReinitialize={true}
                                validateOnMount={true}
                            >
                                {({
                                    isValid,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    setTouched,
                                    setFieldValue
                                }) => (
                                    <form>
                                        <div id="pageFilters" className="table-responsive border p-0">
                                            <table className="table mg-b-0">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center"></th>
                                                        <th className="text-center">{t('datatable.filterName')}</th>
                                                        <th className="text-center">{t('datatable.description')}</th>
                                                        <th className="text-center" />
                                                        <th className="text-center" />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {source && source.map((item, index) => {
                                                        return (
                                                            <tr key={"filter_" + (index + 1)} className={`bg-teal bg-opacity-10 ${item.isSelected ? "selected" : ""}`}>
                                                                <td className="text-center" width={"5%"}>
                                                                    <label htmlFor={"filter_" + (index + 1)} className="custom-switch cursor-pointer">
                                                                        {/* <input type="radio" className="ckbox" name="name" value={item.id} checked={item.isSelected}
                                                                            onChange={(e) => {
                                                                                setSelectedFilter(e.currentTarget.value);
                                                                            }}
                                                                        /> */}
                                                                        <DrInput id={"filter_" + (index + 1)} key={"filter_" + (index + 1)} value={item.id} className="form-check-input" placeholder="" name="item" type="radio"
                                                                            onChange={(e) => {
                                                                                setSelectedFilter(e.currentTarget.value);
                                                                            }}
                                                                            onBlur={handleBlur}>
                                                                        </DrInput>

                                                                    </label>
                                                                </td>
                                                                <td className="text-center" width={"30%"}>
                                                                    <label htmlFor={"filter_" + (index + 1)} className="noselect my-auto text-start cursor-pointer">
                                                                        {item.filterName}
                                                                    </label>
                                                                </td>
                                                                <td className="text-center" width={"40%"}> {item.description}</td>
                                                                <td className="text-center" width={"5%"}>
                                                                    <button className="btn btn-secondary btn-icon btn-sm btn-wave waves-effect waves-light shadow" value={item.id}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            dispatch(clearActivePageFiltersModal());
                                                                            var selectedFilter = source.find(x => x.id === e.currentTarget.value);
                                                                            if (selectedFilter) {
                                                                                dispatch(setActiveSavePagefilterModal({
                                                                                    name: modals.pageFilterSave, isGlobal: true, source: selectedFilter,
                                                                                    pagefilterSavedCallBack: (response) => {
                                                                                        dispatch(clearActiveSavePagefilterModal());
                                                                                    }
                                                                                }));
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className="fe fe-edit" ></i>
                                                                    </button>
                                                                </td>
                                                                <td className="text-center" width={"5%"}>
                                                                    <button className="btn btn-danger btn-icon btn-sm btn-wave waves-effect waves-light shadow" value={item.id}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            deleteFilter(e.currentTarget.value);
                                                                        }}
                                                                    >
                                                                        <i className="fe fe-trash" ></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    )
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                )
                                }
                            </Formik>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                {source && source.length > 0 &&
                                    <button type="button" className="btn btn-primary btn-wave waves-effect waves-light shadow"
                                        onClick={updateFilter}>{t('save')}</button>
                                }
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-secondary btn-wave waves-effect waves-light shadow" data-dismiss="modal"
                                    onClick={() => dispatch(clearActivePageFiltersModal())}>{t('close')}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default DrSavedPageFilters