import { useDispatch, useSelector } from "react-redux";
import { withActionPromise } from "../../../state-management/actions/app.actions";
import { setMyStorePageSelections } from "../../../state-management/actions/settings.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearCreatePackagesState } from "../../../state-management/actions/create-customer-packages.actions";

function DrSubscriptionSuspended() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation('drPackageWarnings');


    return (
        <>
            <div className="alert alert-danger alert-dismissible fade show custom-alert-icon shadow-sm bg-danger bg-opacity-10" role="alert">
                <svg className="svg-danger" xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 0 24 24" width="1.5rem" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM12 17.3c-.72 0-1.3-.58-1.3-1.3 0-.72.58-1.3 1.3-1.3.72 0 1.3.58 1.3 1.3 0 .72-.58 1.3-1.3 1.3zm1-4.3h-2V7h2v6z"></path></svg>

                <div className="row justify-content-left ms-2 ps-3">
                    <div className="col-auto">

                        <div className="row mb-2">
                            <div className="col-auto">
                                <span className="fw-bold">
                                    Your subscription is suspended.
                                </span>

                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-auto">
                                <span className="fw-bold">
                                    Due to your subscription is suspended your account is limited to do actions on Inventory and Order pages!
                                </span>

                            </div>
                        </div>
                        <div className="row justify-content-start" >
                            <div className="col-auto">

                                <button type="button" className="btn btn-outline-danger btn-wave waves-effect waves-light"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(clearCreatePackagesState());
                                        navigate("/main-packages")
                                    }}>
                                    Go To Package Selection Page
                                </button>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default DrSubscriptionSuspended
