import { Link, useLocation } from 'react-router-dom';
import Restricted from '../../../security/Restricted';
import { useEffect } from 'react';
import $ from 'jquery';

function DrSideBarElements(props) {
    const location = useLocation();

    // useEffect(() => {
    //     closeAllSlideMenus();
    //     closeAllSlideExpanded();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [location?.pathname]);

    useEffect(() => {
        closeAllSlideMenus();
        closeAllSlideExpanded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeAllSlideMenus = () => {
        var openedElements = document.querySelectorAll(".slide-menu.child1.openx");
        [].forEach.call(openedElements, function (el) {
            $(el).slideUp(200, function () {
                $(el).removeClass('openx');

                // $(el).css('display','none');
                // $(el).hide();
                // el.style.display = "none"
                if (!$(el).closest(".slide").hasClass("open")) {
                    el.style.removeProperty("display");
                    el.style.display = "none";
                }

            });
        });
        // closeAllSubSlideMenus();
    }

    const closeAllSlideExpanded = () => {
        var allActiveSlideElements = document.querySelectorAll(".slide.open");
        [].forEach.call(allActiveSlideElements, function (el) {
            el.classList.remove("open");
        });
        // closeAllSubSlideExpanded();
    }

    // const closeAllSubSlideMenus = () => {
    //     var openedElements = document.querySelectorAll(".slide-menu.open");
    //     [].forEach.call(openedElements, function (el) {
    //         $(el).slideUp(200, function () {
    //             $(el).removeClass('open');
    //             $(el).removeAttribute("display");
    //         });
    //     });
    // }

    // const closeAllSubSlideExpanded = () => {
    //     var allActiveSlideElements = document.querySelectorAll(".slide.open");
    //     [].forEach.call(allActiveSlideElements, function (el) {
    //         el.classList.remove("open");
    //     });
    // }

    // const toggleActive = (e, item) => {
    //     let currentEl = e.currentTarget;
    //     const hasUl = currentEl.lastChild;

    //     var isOpened = $(hasUl).hasClass('openx');
    //     var hasSubMenuOpened = $(currentEl).find('.slide.open');
    //     // if (!hasSubMenuOpened || !isOpened) {
    //     closeAllSlideMenus();
    //     closeAllSlideExpanded();
    //     // }

    //     if (item.haveChildren) {
    //         if (!hasSubMenuOpened || !isOpened) {
    //             $(hasUl).slideDown(200, function () {
    //                 $(hasUl).addClass('openx');
    //             });
    //             $(currentEl).addClass("open");
    //         }
    //     }
    // };


    const toggleActive = (e, item) => {
        let currentEl = e.currentTarget.parentElement;
        const hasUl = currentEl.lastChild;

        var isOpened = $(currentEl).hasClass('open');
        var hasSubMenuOpened = $(currentEl).find('.slide.open');
        // if (!hasSubMenuOpened || !isOpened) {
        // closeAllSlideMenus();
        // closeAllSlideExpanded();
        // }
        if (item.haveChildren) {
            if (!isOpened) {
                $(hasUl).slideDown(200, function () {
                    $(hasUl).addClass('openx');
                });
                $(currentEl).addClass("open");
                closeAllSlideMenus();
                closeAllSlideExpanded();
            }
            else {
                $(hasUl).slideUp(200, function () {
                    $(hasUl).removeClass('openx');
                    hasUl.style.removeProperty("display");
                    hasUl.style.display = "none";
                });
                $(currentEl).removeClass("open");
            }
        }
    };

    const toggleSubActive = (e, item) => {
        let currentEl = e.currentTarget;
        const hasUl = currentEl.lastChild;
        var isOpened = $(hasUl).hasClass('openx');
        if (item.haveChildren) {
            if (!isOpened) {
                $(hasUl).slideDown(200, function () {
                    $(hasUl).addClass('openx');
                });
                $(currentEl).addClass("open");
            }
            else {
                $(hasUl).slideUp(200, function () {
                    $(hasUl).removeClass('openx');
                    hasUl.style.removeProperty("display");
                    hasUl.style.display = "none";
                });
            }
        }
    };

    const toggleChildActive = (e) => {
        e.stopPropagation();
        e.currentTarget.classList.add("active");

        const sideMenuItems = document.querySelectorAll('.side-menu__item.active');
        [].forEach.call(sideMenuItems, function (el) {
            if (!$(el).parent().hasClass("has-sub")) {
                el.classList.remove("active");
            }

        });

        closeAllSlideMenus();
        closeAllSlideExpanded();
    }

    return (

        <nav className="main-menu-container nav nav-pills flex-column sub-open">
            <div className="slide-left" id="slide-left">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width={24} height={24} viewBox="0 0 24 24"> <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" /> </svg>
            </div>
            <ul className="main-menu">
                {props.elements.map((item, index) => {
                    let isExpanded1 = item.haveChildren && item.childElements.find(el => {
                        return location.pathname.includes(el.navigateTo);
                    });

                    let isSubActive = false;
                    if (item.haveChildren) {
                        item.childElements?.forEach(childsChildElement => {
                            let tempActive = childsChildElement.haveChildren ?
                                (childsChildElement.childElements.find(el => { return location.pathname.includes(el.navigateTo); }))
                                : location.pathname !== "/" && location.pathname.includes(childsChildElement.navigateTo);

                            if (tempActive) {
                                isSubActive = true;
                            }
                        });
                    }

                    let isActive1 = isSubActive || (item.haveChildren ?
                        (item.childElements.find(el => { return location.pathname.includes(el.navigateTo); }))
                        : location.pathname !== "/" && location.pathname.includes(item.navigateTo));



                    let hasChildNavigated1 = item.haveChildren && item.childElements.some(e => location.pathname !== "/" && location.pathname.includes(e.navigateTo));
                    return (

                        <li key={`slide-${index}`} className={`slide ${isExpanded1 ? "open" : ""} ${item.haveChildren ? "has-sub" : ""}`}
                        // onClick={(e) => {
                        //     e.preventDefault();
                        //     if (item.haveChildren) {
                        //         toggleActive(e, item)
                        //     }
                        // }
                        // }
                        >
                            <Restricted allowedTo={item.allowedTo}>
                                <Link exact={"false"} to={item.navigateTo}
                                    className={`side-menu__item ${isActive1 ? "active" : ""}`}
                                    onClick={(e) => {
                                        if (item.navigateTo === "none")
                                            e.preventDefault();
                                        toggleActive(e, item)

                                    }}>

                                    {item.icon ? item.icon : <></>}
                                    <span className="side-menu__label my-auto">{item.name}</span>
                                    {item.haveChildren && <i className="fe fe-chevron-right side-menu__angle"></i>}
                                </Link>
                            </Restricted>
                            {item.haveChildren &&
                                <ul className={`slide-menu child1 ${hasChildNavigated1 ? "openx" : ""}`}>
                                    {item.childElements.map((childItem, i) => {

                                        let isExpanded2 = childItem.haveChildren && childItem.childElements.find(el => {
                                            return location.pathname.includes(el.navigateTo);
                                        });
                                        let isActive2 = childItem.haveChildren && childItem.childElements.find(el => { return location.pathname.includes(el.navigateTo); }) ? true
                                            : location.pathname !== "/" && location.pathname.includes(childItem.navigateTo);

                                        let hasChildNavigated2 = childItem.haveChildren && childItem.childElements.some(e => location.pathname !== "/" && location.pathname.includes(e.navigateTo));

                                        return (
                                            <li key={`slide-item-${i}`}
                                                className={childItem.haveChildren ? (`slide ${isExpanded2 ? "open" : ""} ${childItem.haveChildren ? "has-sub" : ""}`)
                                                    : (`slide ${isActive2 ? "active" : ""}`)}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (childItem.haveChildren) {
                                                        toggleSubActive(e, childItem)
                                                    } else {
                                                        toggleChildActive(e);
                                                    }
                                                }}>
                                                <Restricted allowedTo={childItem.allowedTo}>
                                                    <Link exact={"false"} to={childItem.navigateTo} className={`side-menu__item ${isActive2 ? "active" : ""}`}
                                                        onClick={(e) => { if (childItem.navigateTo === "none") e.preventDefault(); }}>
                                                        {/* <childItem.icon size={18}></childItem.icon> */}
                                                        <span className={childItem.haveChildren ? "side-menu__label my-auto" : ""}>{childItem.name}</span>
                                                        {childItem.haveChildren && <i className="fe fe-chevron-right side-menu__angle"></i>}
                                                    </Link>
                                                </Restricted>
                                                {childItem.haveChildren &&
                                                    <ul className={`slide-menu child2 ${hasChildNavigated2 ? "openx" : ""}`}>
                                                        {childItem.childElements.map((childsChildItem, j) => {

                                                            let isActive3 = childsChildItem.haveChildren ? childsChildItem.childElements.find(el => { return location.pathname.includes(el.navigateTo); })
                                                                : location.pathname !== "/" && location.pathname.includes(childsChildItem.navigateTo);
                                                            return (
                                                                <li key={`slide-item-child-${j}`} className={`slide ${isActive3 ? "active" : ""}`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        toggleChildActive(e)
                                                                    }}>
                                                                    <Restricted allowedTo={childsChildItem.allowedTo}>
                                                                        <Link exact={"false"} to={childsChildItem.navigateTo}
                                                                            className={`side-menu__item ${isActive3 ? "active" : ""}`}
                                                                            onClick={(e) => { if (childsChildItem.navigateTo === "none") e.preventDefault(); }}>
                                                                            {/* <childItem.icon size={18}></childItem.icon> */}

                                                                            <span className={"side-menu__label my-auto"}>{childsChildItem.name}</span>
                                                                            {childsChildItem.haveChildren && <i className="fe fe-chevron-right side-menu__angle"></i>}
                                                                        </Link>
                                                                    </Restricted>
                                                                </li>
                                                            )
                                                        }
                                                        )}
                                                    </ul>
                                                }
                                            </li>
                                        )
                                    }
                                    )}
                                </ul>
                            }
                        </li>
                    )
                }

                )}
            </ul>

            <div className="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width={24} height={24} viewBox="0 0 24 24"> <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" /> </svg></div>
        </nav>


    );
}

export default DrSideBarElements;