import { useDispatch } from 'react-redux'
import { clearActiveModal } from '../../../state-management/actions/app.actions';
import i18next from 'i18next';
import $ from 'jquery';

function DrBaseModal({ display, children, persistModal = false, showCloseButton = true, showCloseButtonFooter = false, closeActionCallback, title }) {
    const dispatch = useDispatch();

    return (
        <>
            <div className={`modal fade modal-background ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
                tabIndex="-1" role="dialog" aria-labelledby="baseModal" aria-modal="true" aria-hidden="true"
                onClick={(e) => {
                    if (persistModal) {
                        return;
                    }
                    if ($(e.target).hasClass("modal-background")) {
                        dispatch(clearActiveModal());
                    }
                }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLongTitle">{title}</h6>
                            {showCloseButton &&
                                // <span onClick={() => closeActionCallback ? closeActionCallback() : dispatch(clearActiveModal())}
                                //     className="modal-close-button mt-1">
                                //     <GrFormClose size="25" />
                                // </span>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        if (closeActionCallback) {
                                            closeActionCallback()
                                        } else {
                                            dispatch(clearActiveModal())
                                        }
                                    }
                                    }
                                >
                                </button>
                            }
                        </div>
                        <div className="modal-body justify-contents-center">
                            {display && children}
                        </div>
                        <div className="modal-footer">
                            {showCloseButtonFooter &&
                                <button type="button" className="btn btn-secondary btn-wave waves-effect waves-light shadow" data-dismiss="modal"
                                    onClick={() => {
                                        if (closeActionCallback) {
                                            closeActionCallback()
                                        } else {
                                            dispatch(clearActiveModal())
                                        }
                                    }}>
                                    {i18next.t('constants:buttons.close')}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DrBaseModal


