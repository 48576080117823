const types = {
    GET_ALL_CUSTOMER_STORES_REQUESTED: "GET_ALL_CUSTOMER_STORES_REQUESTED",
    GET_ALL_CUSTOMER_STORES_SUCCESS: "GET_ALL_CUSTOMER_STORES_SUCCESS",
    GET_STORE_REQUESTED: "GET_STORE_REQUESTED",
    GET_STORE_REQUESTED_SUCCESS: "GET_STORE_REQUESTED_SUCCESS",
    CREATE_STORE_REQUESTED: "CREATE_STORE_REQUESTED",
    CREATE_STORE_SUCCESS: "CREATE_STORE_SUCCESS",
    CREATE_STORE_FAILED: "CREATE_STORE_FAILED",
    UPDATE_STORE_REQUESTED: "UPDATE_STORE_REQUESTED",
    UPDATE_STORE_SUCCESS: "UPDATE_STORE_SUCCESS",
    UPDATE_STORE_FAILED: "UPDATE_STORE_FAILED",
    DELETE_STORE_REQUESTED: "DELETE_STORE_REQUESTED",
    DELETE_STORE_SUCCESS: "DELETE_STORE_SUCCESS",
    DELETE_STORE_FAILED: "DELETE_STORE_FAILED",
    SET_CUSTOMER_STORES: "SET_CUSTOMER_STORES",
    SET_SELECTED_STORE: "SET_SELECTED_STORE",
    SET_LAST_SELECTED_STORE_MARKET_ID: "SET_LAST_SELECTED_STORE_MARKET_ID",
    CLEAR_SELECTED_STORE: "CLEAR_SELECTED_STORE",
    SET_MY_STORE: "SET_MY_STORE",
    CLEAR_MY_STORE: "CLEAR_MY_STORE",
    SET_MY_STORE_SHIPPINGS: "SET_MY_STORE_SHIPPINGS",
    UPSERT_STORE_TOKEN: "UPSERT_STORE_TOKEN",
    SET_MY_STORE_IS_REFRESH_TOKEN_VALID: "SET_MY_STORE_IS_REFRESH_TOKEN_VALID",

    GET_STORE_FILTER_REQUESTED: "GET_STORE_FILTER_REQUESTED",
    GET_STORE_FILTER_SUCCESS: "GET_STORE_FILTER_SUCCESS",
    UPDATE_STORE_FILTER_REQUESTED: "UPDATE_STORE_FILTER_REQUESTED",
    UPDATE_STORE_FILTER_SUCCESS: "UPDATE_STORE_FILTER_SUCCESS",
    UPDATE_STORE_FILTER_FAILED: "UPDATE_STORE_FILTER_FAILED",
    SET_SELECTED_STORE_FILTER: "SET_SELECTED_STORE_FILTER",
    CLEAR_STORE_FILTER: "CLEAR_STORE_FILTER",
    CLEAR_STORE_STATE: "CLEAR_STORE_STATE",

    GET_SELECTED_WAREHOUSE_SETTINGS_REQUESTED: "GET_SELECTED_WAREHOUSE_SETTINGS_REQUESTED",
    UPDATE_STORE_SETTINGS_REQUESTED: "UPDATE_STORE_SETTINGS_REQUESTED",
    UPDATE_STORE_SETTINGS_SUCCESS: "UPDATE_STORE_SETTINGS_SUCCESS",
    UPDATE_STORE_SETTINGS_FAILED: "UPDATE_STORE_SETTINGS_FAILED",
    SET_SELECTED_WAREHOUSE_SETTINGS: "SET_SELECTED_WAREHOUSE_SETTINGS",
    CLEAR_SELECTED_WAREHOUSE_SETTINGS: "CLEAR_SELECTED_WAREHOUSE_SETTINGS",

    GET_SELECTED_PRICING_DATA_REQUESTED: "GET_SELECTED_PRICING_DATA_REQUESTED",
    SET_SELECTED_PRICING_DATA: "SET_SELECTED_PRICING_DATA",
    UPDATE_PRICING_DATA_REQUESTED: "UPDATE_PRICING_DATA_REQUESTED",
    UPDATE_PRICING_DATA_SUCCESS: "UPDATE_PRICING_DATA_SUCCESS",
    UPDATE_PRICING_DATA_FAILED: "UPDATE_PRICING_DATA_FAILED",
    CLEAR_SELECTED_PRICING_DATA: "CLEAR_SELECTED_PRICING_DATA",

    UPDATE_STORE_WAREHOUSES_SETTINGS_SUCCESS: "UPDATE_STORE_WAREHOUSES_SETTINGS_SUCCESS",
    UPDATE_STORE_WAREHOUSES_SETTINGS_FAILED: "UPDATE_STORE_WAREHOUSES_SETTINGS_FAILED",

    GET_STORE_DASHBOARD_DATA_REQUESTED: "GET_STORE_DASHBOARD_DATA_REQUESTED",
    GET_STORE_DASHBOARD_DATA_SUCCESS: "GET_STORE_DASHBOARD_DATA_SUCCESS",

    GET_STORE_DASHBOARD_ORDER_STATUS_REQUESTED: "GET_STORE_DASHBOARD_ORDER_STATUS_REQUESTED",
    GET_STORE_DASHBOARD_ORDER_STATUS_SUCCESS: "GET_STORE_DASHBOARD_ORDER_STATUS_SUCCESS"
}
export default types;

export const getAllCustomerStores = (payload) => {
    return { type: types.GET_ALL_CUSTOMER_STORES_REQUESTED, payload }
}

export const getCustomerStore = (payload) => {
    return { type: types.GET_STORE_REQUESTED, payload }
}

export const createCustomerStore = (payload) => {
    return { type: types.CREATE_STORE_REQUESTED, payload }
}

export const updateCustomerStore = (payload) => {
    return { type: types.UPDATE_STORE_REQUESTED, payload }
}

export const deleteCustomerStore = (payload) => {
    return { type: types.DELETE_STORE_REQUESTED, payload }
}

export const setCustomerStores = (payload) => {
    return { type: types.SET_CUSTOMER_STORES, payload }
}

export const setSelectedStore = (payload) => {
    return { type: types.SET_SELECTED_STORE, payload };
}

export const setLastSelectedStoreMarketId = (payload) => {
    return { type: types.SET_LAST_SELECTED_STORE_MARKET_ID, payload };
}

export const clearSelectedStore = () => {
    return { type: types.CLEAR_SELECTED_STORE };
}

export const setMyStore = (payload) => {
    return { type: types.SET_MY_STORE, payload };
}

export const clearMyStore = () => {
    return { type: types.CLEAR_MY_STORE };
}

export const setMyStoresShippings = (payload) => {
    return { type: types.SET_MY_STORE_SHIPPINGS, payload }
}

export const setMyStoresIsRefreshTokenValid = (payload) => {
    return { type: types.SET_MY_STORE_IS_REFRESH_TOKEN_VALID, payload }
}

export const getCustomerStoreFilter = (payload) => {
    return { type: types.GET_STORE_FILTER_REQUESTED, payload }
}

export const updateCustomerStoreFilter = (payload) => {
    return { type: types.UPDATE_STORE_FILTER_REQUESTED, payload }
}

export const setSelectedStoreFilter = (payload) => {
    return { type: types.SET_SELECTED_STORE_FILTER, payload };
}

export const clearSelectedStoreFilter = () => {
    return { type: types.CLEAR_STORE_FILTER };
}

export const clearStoreState = () => {
    return { type: types.CLEAR_STORE_STATE };
}

export const getMarketWarehouseSettings = (payload) => {
    return { type: types.GET_SELECTED_WAREHOUSE_SETTINGS_REQUESTED, payload }
}

export const setSelectedMarketWarehouseSettings = (payload) => {
    return { type: types.SET_SELECTED_WAREHOUSE_SETTINGS, payload };
}

export const updateCustomerStoreSettings = (payload) => {
    return { type: types.UPDATE_STORE_SETTINGS_REQUESTED, payload }
}

export const clearSelectedMarketWarehouseSettings = () => {
    return { type: types.CLEAR_SELECTED_WAREHOUSE_SETTINGS };
}

export const getMarketPricingData = (payload) => {
    return { type: types.GET_SELECTED_PRICING_DATA_REQUESTED, payload }
}
export const setSelectedMarketPricingData = (payload) => {
    return { type: types.SET_SELECTED_PRICING_DATA, payload };
}

export const updateMarketPricingData = (payload) => {
    return { type: types.UPDATE_PRICING_DATA_REQUESTED, payload }
}

export const clearSelectedMarketPricingData = () => {
    return { type: types.CLEAR_SELECTED_PRICING_DATA };
}

export const upsertStoreToken = (payload) => {
    return { type: types.UPSERT_STORE_TOKEN, payload }
}

export const getStoreDashboardData = (payload) => {
    return { type: types.GET_STORE_DASHBOARD_DATA_REQUESTED, payload }
}

export const getStoreDashboardDataSuccess = (payload) => {
    return { type: types.GET_STORE_DASHBOARD_DATA_SUCCESS, payload }
}

export const getOrderStatusCount = (payload) => {
    return { type: types.GET_STORE_DASHBOARD_ORDER_STATUS_REQUESTED, payload }
}

export const getOrderStatusCountSuccess = (payload) => {
    return { type: types.GET_STORE_DASHBOARD_ORDER_STATUS_SUCCESS, payload }
}