import React from 'react';
import PermissionContext from "./PermissionContext";

// It should receive the users userRole as parameter
const PermissionProvider = ({ userRole, children }) => {

    // Creates a method that returns whether the requested userRole is available in the list of userRoles
    // passed as parameter
    const isAllowedTo = (permissions) => {
        if (!permissions || permissions.length === 0) {
            return true;
        }

        //return permissions.some(r => userRole.indexOf(r) >= 0); // arrray of permissions
        //return permissions.includes(userRole);// single permission
        if(userRole && userRole.length>0){
            return permissions.some(r => userRole.find(x => x === r)); // arrray of permissions
        }
        else{
            return false;
        }
        //return permissions.some(r => userRole.find(x => x === r)); // arrray of permissions
    }

    // This component will render its children wrapped around a PermissionContext's provider whose
    // value is set to the method defined above
    return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;