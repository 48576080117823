
import React from 'react'
import { DrBreadCrump} from '../../components/component-index';
import i18next from 'i18next';

function ServiceConfigPage() {
    
    return (
        <div style={{ marginBottom: '150px' }}>
            <div className="page-header mb-0 mt-4 mb-2 row row-md row-lg justify-content-between">
                <div className="col-lg-auto col-md-auto col-sm-auto col-xs-12 text-center">
                    <DrBreadCrump
                        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.administrator'), navigationPath: "" },
                        { navigationText: i18next.t('constants:breadCrumps.serviceConfigs'), navigationPath: "" }]} >
                    </DrBreadCrump>
                </div>
            </div>
            
        </div>
    )
}
export default ServiceConfigPage