import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable } from '../../components/component-index';
import { bestSellingMockData } from '../../utils/mocks-data';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { timeOptions } from '../../components/hoc/Constants/dr-constants.component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getBestSellingProducts } from '../../state-management/actions/reports.actions';
import { setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { getRouteByKey } from '../../routes';
import { modals } from '../../utils/constants';

function BestSellingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('bestsellingPage');
  const [tableData, setTableData] = useState([]);
  const selectedStore = useSelector(state => state.stores.selectedStore);
  const bestSellingProducts = useSelector(state => state.reports.bestSellingProducts);
  const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);

  useEffect(() => {
    if (selectedStore?.customerMainSellerId) {
      dispatch(withActionPromise(getBestSellingProducts({ customerMainSellerId: selectedStore?.customerMainSellerId, take: 10 })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore?.customerMainSellerId]);

  const columns = [
    { data: "productPicture", title: "", maxWidth: "80px", minWidth: "80px", searchable: false },
    { data: "productName", title: t('dataTable.productName'), minWidth: "300px", searchable: true },
    { data: "asin", title: t('dataTable.asin'), maxWidth: "130px", minWidth: "130px", searchable: true },
    { data: "categoryName", title: t('dataTable.categoryName'), maxWidth: "190px", minWidth: "190px", searchable: true },
    { data: "profitPercent", title: t('dataTable.prices'), maxWidth: "160px", minWidth: "160px", searchable: false },
    // { data: "shippingCost", title: t('dataTable.shippingCost'), maxWidth: "90px", minWidth: "90px", searchable: true },
    // { data: "profitPercent", title: t('dataTable.profitPercent'), maxWidth: "90px", minWidth: "90px", searchable: true },
    // { data: "customsCostPercent", title: t('dataTable.customsCostPercent'), maxWidth: "90px", minWidth: "90px", searchable: true },
    // { data: "customsCost", title: t('dataTable.customsCost'), maxWidth: "90px", minWidth: "90px", searchable: true },
    // { data: "progressPayment", title: t('dataTable.progressPayment'), maxWidth: "90px", minWidth: "90px", searchable: true },
    { data: "numberOfSales", title: t('dataTable.numberOfSales'), maxWidth: "90px", minWidth: "90px", searchable: true },
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: true,
      className: "text-start position-relative",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <img src={rowData.productPicture} alt="img" className="img-thumbnail" data-tag="pic-modal-opener"
            onClick={(e) => {
              e.preventDefault();
              if (rowData.productPicture) {
                dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [rowData.productPicture] }))
              }
            }}
          ></img>
        )
      }
    },
    {
      targets: 1,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1 text-wrap">{rowData.productName}</span><br />
          </>);
      }
    },
    {
      targets: 2,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.asin}</span><br />
          </>);
      }
    },
    {
      targets: 3,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="badge bg-info">{rowData.categoryName}</span><br />
          </>
        );
      }
    },
    {
      targets: 4,
      orderable: true,
      searchable: false,
      className: "text-start",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          // <div className="text-center" style={{ fontSize: '16px', minWidth: '70px' }}>
          <div className="d-flex flex-wrap">
            <div className="row justify-content-start w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-success fs-11">{t('dataTable.profitPercent')}(%): {Number(rowData.profitPercent).toFixed(2)}%</span>
              </div>
            </div>
            <div className="row justify-content-start w-90 mt-1 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-warning fs-11">{t('dataTable.shippingCost')}: {selectedStoreCurrencyType.currencyCode} {Number(rowData.shippingCost).toFixed(2)}</span>
              </div>
            </div>
            <div className="row justify-content-start mt-1 w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-warning fs-11">{t('dataTable.customsCostPercent')}(%): {Number(rowData.customsCostPercent).toFixed(2)}%</span>
              </div>
            </div>
            <div className="row justify-content-start mt-1 w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-warning fs-11">{t('dataTable.customsCost')}: {selectedStoreCurrencyType.currencyCode} {Number(rowData.customsCost).toFixed(2)}</span>
              </div>
            </div>
            <div className="row justify-content-start mt-1 w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-success fs-11">{t('dataTable.progressPayment')}(%): {Number(rowData.progressPayment).toFixed(2)}%</span>
              </div>
            </div>
          </div>
          // <span className="badge bg-info">CN$11.40</span><br />
          // <span className="badge bg-info">Kâr: 3.08%</span>
          // </div>
        );
      }
    },
    // {
    //   targets: 5,
    //   orderable: false,
    //   className: "text-center",
    //   createdCell: (td, cellData, rowData) => {
    //     ReactDOM.createRoot(td).render(
    //       <>
    //         <span className="badge rounded-pil bg-teal">{Number(rowData.profitPercent).toFixed(2)}%</span>
    //       </>);
    //   }
    // },
    // {
    //   targets: 6,
    //   orderable: false,
    //   className: "text-center",
    //   createdCell: (td, cellData, rowData) => {
    //     ReactDOM.createRoot(td).render(
    //       <>
    //         <span className="badge rounded-pil bg-warning">{Number(rowData.customsCostPercent).toFixed(2)}%</span><br />
    //       </>);
    //   }
    // },
    // {
    //   targets: 7,
    //   orderable: false,
    //   className: "text-center",
    //   createdCell: (td, cellData, rowData) => {
    //     ReactDOM.createRoot(td).render(
    //       <>
    //         <span className="badge rounded-pil bg-warning">{selectedStoreCurrencyType.currencyCode} {Number(rowData.customsCost).toFixed(2)}</span><br />
    //       </>);
    //   }
    // },
    // {
    //   targets: 8,
    //   orderable: false,
    //   className: "text-center",
    //   createdCell: (td, cellData, rowData) => {
    //     ReactDOM.createRoot(td).render(
    //       <>
    //         <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.progressPayment}</span><br />
    //       </>);
    //   }
    // },
    {
      targets: 5,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.numberOfSales}</span><br />
          </>);
      }
    },
  ];

  const optionClickCallBack = (value) => {

  };

  return (

    <div style={{ marginBottom: '150px' }}>
      <DrBreadCrump
        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
        { navigationText: i18next.t('constants:breadCrumps.bestSellingProducts'), navigationPath: "" }]} >
      </DrBreadCrump>
      {/* <div className="col-lg-auto col-md-auto col-sm-auto col-xs-12 px-0 pb-2">
          <DrButtonDropdown className="shadow" options={timeOptions} optionClickCallBack={optionClickCallBack}>
          </DrButtonDropdown>
        </div> */}
      <div id="inventoryTable" className="row mb-5">
        <div className="col-12">
          <div className="card custom-card">
            <div className="card-body">
              {selectedStore?.customerMainSellerId.length > 0 &&
                <DrDataTable
                  data={bestSellingProducts.sort((b, a) => a.numberOfSales - b.numberOfSales)}
                  columns={columns}
                  columnDefs={columnDefs}
                  doesHaveBorder={false}
                  headerSearch={true}
                  order={[[5, 'desc']]}
                  minWidth="850px"
                />
              }
              {!selectedStore &&
                <div className="row row-md row-lg justify-content-center">
                  <div className="col-lg-auto col-md-2">
                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                      onClick={(e) => {
                        e.preventDefault();
                        Promise.all([
                          dispatch(withActionPromise(clearCreateStoreInformationState()))
                        ]).then((e) => {
                          navigate(getRouteByKey("MARKET_SELECTION"));
                        });;

                      }}>
                      <i className="me-2 fe fe-chevrons-right"></i>
                      {t('gotoMarketSelection')}
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BestSellingPage