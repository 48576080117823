import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrPageFilter } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Restricted from '../../security/Restricted';
import { staticRoles } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { messagesMockData } from '../../utils/mocks-data';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { getNotifications } from '../../state-management/actions/notification.actions';

function MessagesPage() {
    const { REACT_APP_ROLES_ADMIN } = process.env;
    const dispatch = useDispatch();
    const { t } = useTranslation('notificationPages');
    const navigate = useNavigate();
    const selectedStore = useSelector(state => state.stores.selectedStore);
    //const [selectedUser, setSelectedUser] = useState(null);
    const allNotifications = useSelector(state => state.notifications.allNotifications);
    useEffect(() => {
        dispatch(withActionPromise(getNotifications(selectedStore?.customerMainSellerId)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { data: "emailTo", title: t("name"), width: "20%", minWidth: "150px", searchable: true },
        { data: "amazonOrderNumber", title: t("amazonOrderNumber"), minWidth: "110px", searchable: true },
        { data: "lastMessageDate", title: t("lastMessageDate"), width: "10%", minWidth: "130px", searchable: false },
        { data: "messageSource", title: t("messageSource"), width: "10%", minWidth: "80px", searchable: false },
        { data: null, title: "", width: "3%", searchable: false }
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: true,
            className: "text-start",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className={`fs-12 my-auto mx-2`}>{rowData.emailTo} {rowData.totalMessageCount}</span>
                    </>);
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            width: "20%",
            searchable: true,
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className={`fs-12 my-auto mx-2`}>{rowData.amazonOrderNumber}</span>
                );
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className={`fs-12 my-auto mx-2`}>{rowData.lastMessageDate}</span>
                );
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className={`fs-12 my-auto mx-2`}>{rowData.messageSource}</span>
                );
            }
        },
        {
            targets: 4,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <span id="droprightMenuButton" className="text-muted" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18"></i></span>
                        <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Mark As Read
                            </span>

                            <a className="dropdown-item d-flex align-items-center cursor-pointer" target="_blank" rel="noreferrer"
                                href="">
                                Test 2
                            </a>
                        </div>
                    </div>)
            }
        }
    ];

    const refreshClickedCallBack = (value) => {

    };

    const rowClickedCallBack = async (rowData) => {
        navigate('/message-detail/' + rowData.orderId + "/" + rowData.messageId);
    }

    return (
        <>
            <div>
                <DrBreadCrump
                    breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.messages'), navigationPath: "" }]} >
                </DrBreadCrump>
                <div className="row row-md row-lg d-flex justify-content-center">
                    <div className="col-md-8 col-lg-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                                    <div id="messagesTable" className="row mb-5">
                                        <div className="col-lg-12 col-xl-12 col-md-12">
                                            <div className="card custom-card">
                                                <div className="card-body horizontal-scrollable">
                                                    {allNotifications && <>
                                                        <DrDataTable
                                                            data={allNotifications}
                                                            columns={columns}
                                                            columnDefs={columnDefs}
                                                            doesHaveBorder={false}
                                                            headerSearch={true}
                                                            search={true}
                                                            minWidth="850px"
                                                            isRowClickEnabled={true}
                                                            rowClickedCallBack={rowClickedCallBack}
                                                            detailedSearchChildren={
                                                                <DrPageFilter
                                                                    refreshClickedCallBack={refreshClickedCallBack}
                                                                    isSearchSavingActive={false}
                                                                    isSearchActive={true}
                                                                >
                                                                </DrPageFilter>
                                                            }
                                                        />
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Restricted>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MessagesPage;