const datepicker_tr = {
    name: "tr",
    months: [
      ["Ocak", "Oca"],
      ["Şubat", "Şub"],
      ["Mart", "Mar"],
      ["Nisan", "Nis"],
      ["Mayıs", "May"],
      ["Haziran", "Haz"],
      ["Temmuz", "Tem"],
      ["Ağustos", "Ağu"],
      ["Eylül", "Eyl"],
      ["Ekim", "Eki"],
      ["Kasım", "Kas"],
      ["Aralık", "Ara"],
    ],
    weekDays: [
      ["Cumartesi", "Ctesi"],
      ["Pazar", "Paz"],
      ["Pazartesi", "Ptesi"],
      ["Salı", "Sal"],
      ["Çarşamba", "Çar"],
      ["Perşembe", "Per"],
      ["Cuma", "Cum"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["AM", "Am"],
      ["PM", "Pm"],
    ],
  };

  export default datepicker_tr;