import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearActiveModal } from "../../../../state-management/actions/app.actions";

function DrDescriptionModal({ header, text, navigationPath, navigationText }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-auto px-0">
                    {header && <p className="text-3xl text-center font-bold mb-4">{header}</p>}
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-auto px-0">
                    <p className="text-xs text-center main-msg-wrapper w-100">{text}</p>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-auto px-0">
                    {navigationPath && <button className="btn btn-secondary btn-wave waves-effect waves-light shadow"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(navigationPath);
                            dispatch(clearActiveModal());
                        }}>
                        <i className="w-6 me-2 fa fa-forward"></i>
                        {navigationText}
                    </button>}
                </div>
            </div>
            {/* <div className="w-90vw md:w-108">
                {header && <p className="text-3xl text-center font-bold mb-4">{header}</p>}
                <p className="text-xs text-center main-msg-wrapper w-100">{text}</p>
            </div> */}
        </>

    )
}

export default DrDescriptionModal