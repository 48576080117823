import i18next from 'i18next';
import { DrBreadCrump } from '../../components/component-index';
import { useTranslation } from 'react-i18next';

function CreditPage() {
  const { t } = useTranslation('creditpage');
  return (
    <div>
      <DrBreadCrump
        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.logisticsManagement'), navigationPath: "" },
        { navigationText: i18next.t('constants:breadCrumps.credit'), navigationPath: "" }]} >
      </DrBreadCrump>
      <div className="row">
        <div className="col-lg-12 col-xl-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body horizontal-scrollable">
              <div className="row my-1 mt-4">
                <div className="col-6 col-lg-6 col-xl">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-muted mb-2">
                            {t('youreMaingCredit')}
                          </h6>
                          <span className="h2 mb-0 text-success">$50.43</span>
                        </div>
                        <div className="col-auto">
                          <span className="h2 fe fe-dollar-sign text-muted mb-0" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-lg-6 col-xl">
                  <div className="card" style={{ marginBottom: 10 }}>
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-muted mb-2">
                            {t('howToLoadTheEstimate')}
                            <i
                              data-toggle="tooltip"
                              title=""
                              className="fe fe-help-circle"
                              data-original-title="Tahmini yüklemeniz gereken tutar, depoya göndermiş olduğunuz SellerRunning House siparişlerinizin tahmini toplam nakliye ücretini göstermektedir. Siparişlerinizin hızlı bir şekilde depodan çıkış yapması ve bekleyen ödemelere aktarılmaması için hesabınızda en az burada belirtilen tutar kadar bakiye olması gerekmektedir."
                            />
                          </h6>
                          <span className="h2 mb-0 text-danger">$0.00</span>
                        </div>
                        <div className="col-auto">
                          <span className="h2 fe fe-upload text-muted mb-0" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="my-3">
                <i className="fe fe-credit-card" />
                {t('paymentDetails')}
              </h3>
              <hr />
              <div className="row">
                <div className="col-auto pr-0">
                  <img
                    src="https://static.sellerrunning.com/assets/img/card-icons/MasterCard.png"
                    alt="MasterCard"
                    style={{ maxWidth: 45 }}
                  />
                </div>
                <div className="col-auto">
                  <div>**** **** **** 4322</div>
                  <div className="mt--3">
                    <small className="text-muted">Expires 6/2025</small>
                  </div>
                </div>
                <div className="col-auto">
                  <div aria-multiselectable="true" className="accordion" id="accordion" role="tablist">
                    <div className="card">
                      <div className="card-header" id="headingOne" role="tab">
                        <a aria-controls="collapseOne" aria-expanded="true"
                          data-bs-toggle="collapse" href="#collapseOne">{t('newAddToCart')} </a>
                      </div>
                      <div aria-labelledby="headingOne" className="collapse show"
                        data-bs-parent="#accordion" id="collapseOne" role="tabpanel">
                        <div className="card-body">
                          Kart Ekleme Alanı
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-auto pr-0">
                  <img
                    src="https://static.sellerrunning.com/assets/img/card-icons/MasterCard.png"
                    alt="MasterCard"
                    style={{ maxWidth: 45 }}
                  />
                </div>
                <div className="col-auto">
                  <div>**** **** **** 8665</div>
                  <div className="mt--3">
                    <small className="text-muted">Expires 6/2025</small>
                  </div>
                </div>
                <div className="col-auto">
                  <span></span>
                  <i
                    data-toggle="tooltip"
                    title=""
                    className="fe fe-check-circle text-primary"
                    data-original-title="Varsayılan kart"
                  />
                </div>
              </div>
              <div className="row my-1 mt-5 ml-1 mr-1">
                <div className="col-auto">
                  <h3>
                    <i className="fe fe-credit-card" /> {t('uploadCredits')}
                  </h3>
                  <hr />
                  <div className="alert alert-light">
                    <p>
                      <strong>Önemli:</strong> Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                      dolore magna aliqua
                    </p>
                  </div>
                  <form>
                    <div
                      className="btn-group-toggle d-flex mb-5 sh-balance-group"
                      data-toggle="buttons"
                    >
                      <label className="btn btn-white focus active firstbalance">
                        <input
                          type="radio"
                          name="Balance"
                          defaultValue={5}
                          defaultChecked="checked"
                          id="Balance5"
                        />{" "}
                        <i className="fe fe-dollar-sign mr-2" /> 5
                      </label>
                      <label className="btn btn-white ml-2">
                        <input type="radio" name="Balance" defaultValue={25} />{" "}
                        <i className="fe fe-dollar-sign mr-2" /> 25
                      </label>
                      <label className="btn btn-white ml-2">
                        <input type="radio" name="Balance" defaultValue={50} />{" "}
                        <i className="fe fe-dollar-sign mr-2" /> 50
                      </label>
                      <label className="btn btn-white ml-2">
                        <input type="radio" name="Balance" defaultValue={100} />{" "}
                        <i className="fe fe-dollar-sign mr-2" /> 100
                      </label>
                      <label className="btn btn-white ml-2">
                        <input type="radio" name="Balance" defaultValue={250} />{" "}
                        <i className="fe fe-dollar-sign mr-2" /> 250
                      </label>
                      <div className="input-group input-group-merge ml-2">
                        <input
                          type="number"
                          className="txt-sh-balance form-control form-control-prepended"
                          autoComplete="off"
                          name="TxtBalance"
                          id="TxtBalance"
                          size={8}
                          min={5}
                          max={1000}
                        />
                        <div className="input-group-prepend enable-import-fee-percentage">
                          <div className="input-group-text">
                            <span
                              className="fe fe-dollar-sign"
                              style={{ color: "#283e59" }}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary col ml-2"
                        name="btnAddBalance"
                        id="btnAddBalance"
                        data-submit-if-valid="true"
                        data-toggle="submit-confirm"
                        data-form-id="add-balance"
                        data-custom-message="Yüklemek istediğiniz kredi tutarına ödeme alt yapısı komisyonu eklenerek kredi kartınızdan <b>$5.51</b> tutarında ödeme çekilip hesabınıza <b>$5</b> aktarılacaktır. <br><br>Bu işlemi onaylıyor musunuz?"
                      >
                        {t('uploadcredits')}
                      </button>
                    </div>
                  </form>
                </div>

              </div>
              <h3>
                <i className="fe fe-file-minus" /> {t('creditUploadHistory')}
              </h3>
              <hr className="mb-0" />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr className="no-border">
                      <th className="px-0 bg-transparent">{t('date')}</th>
                      <th className="px-0 bg-transparent">{t('sumary')}</th>
                      <th className="px-0 bg-transparent">{t('state')}</th>
                      <th className="px-0 bg-transparent" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-0">28 Kas 2022</td>
                      <td className="px-0">$26.32</td>
                      <td className="px-0">
                        <div className="badge badge-success">Ödendi</div>
                      </td>
                      <td className="px-0 text-right">
                        <a
                          href="https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLWkdURUVmUXFIaWpoZnMoi9SEowYyBgr5Y3Wb2josFiHuvNX6J75qm_C_2afvwu19SoLk_DFK_he8AR7x044cJQ2hDEeQaqAOMxs"

                        >
                          <i className="fa fa-file-text-o" /> Detaylar
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-0">27 Kas 2022</td>
                      <td className="px-0">$31.53</td>
                      <td className="px-0">
                        <div className="badge badge-success">Ödendi</div>
                      </td>
                      <td className="px-0 text-right">
                        <a
                          href="https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLWkdURUVmUXFIaWpoZnMoi9SEowYyBmJYyUQfnTosFtVp4BQFtx3h7ut-kgXlu60S5oKBW3x2xv68fZwvVDOwG58bSNIvd23QZyE"
                          target="_blank" rel="noreferrer"
                        >
                          <i className="fa fa-file-text-o" /> Detaylar
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-0">24 Kas 2022</td>
                      <td className="px-0">$33.61</td>
                      <td className="px-0">
                        <div className="badge badge-success">Ödendi</div>
                      </td>
                      <td className="px-0 text-right">
                        <a
                          href="https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLWkdURUVmUXFIaWpoZnMoi9SEowYyBgzPVN2d4TosFkrFlvL3hUcGwUIAYoD7XYlczwpaZo4P7X8oTcueViA2JFguJ9-1lEXeg50"
                          target="_blank" rel="noreferrer"
                        >
                          <i className="fa fa-file-text-o" /> Detaylar
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-0">23 Kas 2022</td>
                      <td className="px-0">$15.92</td>
                      <td className="px-0">
                        <div className="badge badge-success">Ödendi</div>
                      </td>
                      <td className="px-0 text-right">
                        <a
                          href="https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLWkdURUVmUXFIaWpoZnMoi9SEowYyBgHOzsOXWTosFgKSwJ8WZqgglZpmUi2y5PpZYUcMM3ymOf6N6tSiTijAfTyVRFdicfN5M7k"
                          target="_blank" rel="noreferrer"
                        >
                          <i className="fa fa-file-text-o" /> Detaylar
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-0">22 Kas 2022</td>
                      <td className="px-0">$5.51</td>
                      <td className="px-0">
                        <div className="badge badge-success">Ödendi</div>
                      </td>
                      <td className="px-0 text-right">
                        <a
                          href="https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLWkdURUVmUXFIaWpoZnMoi9SEowYyBsqwIouHbTosFgOyL2wOE38Wxrzd7CQ2ale5aJuRkt0L9hby786z2w_QGkwX_eAiGrYskeQ"
                          target="_blank" rel="noreferrer"
                        >
                          <i className="fa fa-file-text-o" /> Detaylar
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-0">20 Kas 2022</td>
                      <td className="px-0">$15.92</td>
                      <td className="px-0">
                        <div className="badge badge-success">Ödendi</div>
                      </td>
                      <td className="px-0 text-right">
                        <a
                          href="https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLWkdURUVmUXFIaWpoZnMoi9SEowYyBpRiPLLnETosFtUk5qu6cU8N9c5spSWJfnux9OBkJ7TM_rajk2TwsV6IKU0lJt4cN3LNqF4"
                          target="_blank" rel="noreferrer"
                        >
                          <i className="fa fa-file-text-o" /> Detaylar
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-0">18 Kas 2022</td>
                      <td className="px-0">$105.40</td>
                      <td className="px-0">
                        <div className="badge badge-success">Ödendi</div>
                      </td>
                      <td className="px-0 text-right">
                        <a
                          href="https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLWkdURUVmUXFIaWpoZnMoi9SEowYyBnGcGl-gKzosFlvz4V57goOkYqrBN2IS9Anh9H8nNo2qIWKME7dNaEt2ff7Yt9V0cUHgczU"
                          target="_blank" rel="noreferrer"
                        >
                          <i className="fa fa-file-text-o" /> Detaylar
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-0">16 Kas 2022</td>
                      <td className="px-0">$38.81</td>
                      <td className="px-0">
                        <div className="badge badge-success">Ödendi</div>
                      </td>
                      <td className="px-0 text-right">
                        <a
                          href="https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLWkdURUVmUXFIaWpoZnMoi9SEowYyBtgw9EZ42zosFs2F15IUaT1Bs6bQDEqUgYgCBUENo8-yACMbHJy5e1PJb14DvgNDGm5ovZI"
                          target="_blank" rel="noreferrer"
                        >
                          <i className="fa fa-file-text-o" /> Detaylar
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-0">15 Kas 2022</td>
                      <td className="px-0">$52.34</td>
                      <td className="px-0">
                        <div className="badge badge-success">Ödendi</div>
                      </td>
                      <td className="px-0 text-right">
                        <a
                          href="https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLWkdURUVmUXFIaWpoZnMoi9SEowYyBlaxVHNHtTosFtz9joyJUwe1MF6ylvjwVVHdg3SXIW27am2-TKOcBkuuL4pdRwpLCJacbQI"
                          target="_blank" rel="noreferrer"
                        >
                          <i className="fa fa-file-text-o" /> Detaylar
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-0">14 Kas 2022</td>
                      <td className="px-0">$55.46</td>
                      <td className="px-0">
                        <div className="badge badge-success">Ödendi</div>
                      </td>
                      <td className="px-0 text-right">
                        <a
                          href="https://pay.stripe.com/receipts/payment/CAcQARoXChVhY2N0XzFLWkdURUVmUXFIaWpoZnMoi9SEowYyBhVLZh1UCDosFl8A-NEzOTTJNJRp-YYz8wVcDT8Uw3QGTCFfMafY7Gh7Sg3MuP2Yo8eYueI"
                          target="_blank" rel="noreferrer"
                        >
                          <i className="fa fa-file-text-o" /> Detaylar
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="mt-4 mb-5">
                  <div className="pagination-container">
                    <ul className="pagination pagination-sm justify-content-center">
                      <li className="page-item PagedList-skipToPrevious disabled">
                        <a
                          className="page-link"
                          href="/settings/sh/credit"
                          rel="prev"
                        >
                          İlk sayfa
                        </a>
                      </li>
                      <li className="page-item PagedList-skipToNext ">
                        <a
                          className="page-link"
                          href="/settings/sh/credit?startingAfter=ch_3M40YTEfQqHijhfs0tBARZ10"
                          rel="next"
                        >
                          Sonraki »
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <form>
                {" "}
                <div className="row">
                  <div className="col-sm-7">
                    <div className="amzr-daterangepicker-container">
                      <div className="amzr-daterangepicker">
                        <i className="fe fe-calendar" />
                        &nbsp;
                        <span>Nisan 14, 2023 - Mayıs 15, 2023</span>{" "}
                        <i className="fe fe-chevron-down" />
                      </div>
                    </div>
                  </div>
                  <div className="col-auto ">
                    <input name="SSStart" type="hidden" defaultValue="2023-04-14" />
                    <input name="SSEnd" type="hidden" defaultValue="2023-05-15" />
                    <input type="hidden" name="SHUserId" defaultValue={1} />
                    <button className="btn btn-success-ghost btn-wave waves-effect waves-light shadow btn-sm mt-1" id="buttonSSReportDownload" >
                      <i className="fe fe-download" />
                      Raporu İndir
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
export default CreditPage
