import React from 'react'
import { getRouteByKey } from '../../../routes'
import { useTranslation } from 'react-i18next';
import './dr-navigator.component.scss'
import { useNavigate } from 'react-router-dom';

function DrNavigator({ backAddress, nextAddress, callback, disabled, showCompleteButton = false }) {
    const navigate = useNavigate();
    const { t } = useTranslation('drNavigator');
    const navigateTo = async (key, direction) => {
        // this callback will be used to store the information before moving forward to next page.
        if (callback && direction === "next") {
            await callback();
        }
        navigate(getRouteByKey(key));
    }

    return (

        <div className="drnav">
            <div className="actions">
                <ul role="menu" aria-label="Pagination" className={`${backAddress ? "justify-content-between" : "justify-content-end"}`}>

                    {backAddress &&
                        <li>
                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigateTo(backAddress, "back")
                                }}>
                                {t('previous')}
                            </button>
                        </li>
                    }
                    {nextAddress &&
                        <li className={`${disabled ? "disabled" : ""}`} aria-hidden={!disabled} >
                            <button className={`btn btn-wave waves-effect waves-light shadow ${disabled ? "disabled" : ""} ${showCompleteButton ? "btn-success" : "btn-primary"}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!disabled) {
                                        navigateTo(nextAddress, "next");
                                    }
                                }}>
                                {!showCompleteButton && t('next')}
                                {showCompleteButton && t('finish')}
                            </button>

                        </li>
                    }
                    {/* {!nextAddress && backAddress && showCompleteButton &&
                        <li aria-hidden="true" // aria-disabled={!disabled}
                            onClick={(e) => {
                                e.preventDefault();
                                if (!disabled) {
                                    navigate(getRouteByKey("CONGRATULATIONS"));
                                }
                            }}>
                            <span role="menuitem">{t('finish')}</span>
                        </li>
                    } */}
                </ul>
            </div>
        </div>
    )
}

export default DrNavigator
