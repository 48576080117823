import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiDelete, apiGet, apiPost, apiPut, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getPageFilters, setInventoryPageFilters, setOrdersPageFilters, setProductVariationsPageFilters } from '../actions/page-filters.actions';
import { pageFilterTypes } from '../../utils/constants';
import i18next from 'i18next';
import Swal from 'sweetalert2';

function* runGetPageFilters(action) {
    const payload = action.payload;

    var path = `?customerMainSellerId=${payload.customerMainSellerId}`;
    path += `&pageNumber=${payload.pageNumber}`;

    const response = yield call(apiGet(path, CONTROLLERS.PAGEFILTER, true, false));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        if (payload.pageNumber === pageFilterTypes.inventory) {
            yield put(setInventoryPageFilters(response.data.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())));
        }

        if (payload.pageNumber === pageFilterTypes.orders) {
            yield put(setOrdersPageFilters(response.data.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())));
        }

        if (payload.pageNumber === pageFilterTypes.productVariations) {
            yield put(setProductVariationsPageFilters(response.data.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())));
        }
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error17') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdatePageFilter(action) {
    const payload = action.payload;

    const response = yield call(apiPut(``, payload, false, CONTROLLERS.PAGEFILTER, true));
    if (response && response.isSuccess) {

        yield all([
            put(getPageFilters({ customerMainSellerId: payload.customerMainSellerId, customerSubUserId: payload.customerSubUserId, pageNumber: payload.pageNumber }))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error19') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runCreatePageFilter(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload, false, CONTROLLERS.PAGEFILTER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getPageFilters({ customerMainSellerId: payload.customerMainSellerId, customerSubUserId: payload.customerSubUserId, pageNumber: payload.pageNumber }))
        ]);
    }
    else {

        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error18') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeletePageFilter(action) {
    const payload = action.payload;
    var path = `?id=${payload.filterId}`;

    const response = yield call(apiDelete(path, CONTROLLERS.PAGEFILTER, true, true));
    if (response && response.isSuccess) {
        yield all([
            put(getPageFilters({ customerMainSellerId: payload.customerMainSellerId, pageNumber: payload.pageNumber }))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error20') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* pageFilterSaga() {
    yield all([
        takeLatest(types.SAVE_PAGE_FILTER_REQUESTED, safe(runCreatePageFilter)),
        takeLatest(types.UPDATE_PAGE_FILTER_REQUESTED, safe(runUpdatePageFilter)),
        takeLatest(types.GET_PAGE_FILTERS_REQUESTED, safe(runGetPageFilters)),
        takeLatest(types.DELETE_PAGE_FILTER_REQUESTED, safe(runDeletePageFilter))
    ]);

}