import types from "../actions/packages.action";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PACKAGES_SUCCESS:
            return { ...state, allPackages: action.payload };
        case types.GET_PACKAGE_BY_ID_SUCCESS:
            return { ...state, selectedPackage: action.payload };
        case types.GET_COUPONS_SUCCESS:
            return { ...state, allCoupons: action.payload };
        case types.CLEAR_ALL_PACKAGES:
            return initialState;;
        default:
            return state;
    }
}

const initialState = {
    "allPackages": [],
    "allCoupons": [],
    "selectedPackage": null
}

export default reducer;