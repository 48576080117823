
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrPageFilter } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { buttonVariants, modals, pageFilterTypes } from '../../utils/constants';
import { setActiveCreateLabelModal, setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getWarehouseOrders } from '../../state-management/actions/orders.actions';
import * as moment from 'moment';
import i18next from 'i18next';
import { flaggedMarketOptions, flagOptions, orderStatusOptions, timeOptions } from '../../components/hoc/Constants/dr-constants.component';
import { clearShipStationData, getShipStationCarriers, getShipStationWarehouses } from '../../state-management/actions/shipstation.actions';
import noImage from '../../assets/img/pngs/no-image.png'
import { setWarehouseOrderPageSelections } from '../../state-management/actions/settings.actions';
import { getWarehouseShippings, getWarehouses } from '../../state-management/actions/warehouses.actions';
import { toastr } from 'react-redux-toastr';
import { generatePdfReport } from '../../utils/utils';
import { ExcelExport2 } from '../../excelexport';

function WarehouseOrdersPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('ordersPage');
    const [warehouseMarketOptions, setWarehouseMarketOptions] = useState(null);


    const warehouseOrderList = useSelector(state => state.orders.warehouseOrderList);

    const pageSelections = useSelector(state => state.settings?.pageSelections?.warehouseOrdersPageSelections);
    const shipstationCarriers = useSelector(state => state.shipstation.shipstationCarriers);


    useEffect(() => {
        if (!(pageSelections?.timeOption) || !(pageSelections?.orderStatus) || !(pageSelections?.marketOption)) {
            dispatch(withActionPromise(setWarehouseOrderPageSelections({ timeOption: pageSelections?.timeOption || "0", orderStatus: pageSelections?.orderStatus || 0, marketOption: pageSelections?.marketOption || flaggedMarketOptions[0].value })));
        }
        dispatch(withActionPromise(getShipStationWarehouses()));

        if (!(shipstationCarriers && shipstationCarriers?.length > 0)) {
            dispatch(withActionPromise(getShipStationCarriers()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (pageSelections?.marketOption && pageSelections?.marketOption !== "") {


            var localDate = new Date();

            if (pageSelections?.dateInterval && pageSelections?.dateInterval.length > 0 && (pageSelections.dateInterval[0] || pageSelections.dateInterval[1])) {

                var localDate = new Date();
                var compareDateStart = (pageSelections.dateInterval[0] && moment(pageSelections.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD");
                var compareDateEnd = (pageSelections.dateInterval[1] && moment(pageSelections.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD");

                dispatch(getWarehouseOrders({
                    orderStatus: pageSelections?.orderStatus,
                    startOrderDate: compareDateStart,
                    endOrderDate: compareDateEnd
                }));

            }

            // var data = {
            //     orderStatus: pageSelections?.orderStatus,
            //     createdAfter: moment.utc(localDate).subtract(pageSelections?.timeOption, 'days').startOf('day').format()
            // }

            // if (pageSelections?.marketOption !== "-1") {
            //     data.marketId = pageSelections?.marketOption;
            // }

            // dispatch(getWarehouseOrders(data))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSelections?.orderStatus, pageSelections?.dateInterval, pageSelections?.marketOption]);

    const columns = [
        { data: "id", title: "", width: "10%", minWidth: "80px", searchable: false, checkall: true, checkData: "id	" },
        { data: "orderNumber", title: "Order No", width: "15%", minWidth: "150px", searchable: true },
        { data: "warehouseSuitName", title: "Suit Name", width: "15%", minWidth: "120px", searchable: true },
        { data: "orderType", title: "Order Type", width: "15%", minWidth: "100px", searchable: true },
        { data: "sellerMailAddress", title: "Seller", width: "15%", minWidth: "110px", searchable: true },
        { data: "trackingNumber", title: "Tracking No", width: "15%", minWidth: "110px", searchable: true },
        { data: "labelData", title: "Label", width: "15%", minWidth: "110px", searchable: false },
        { data: null, title: "", width: "10%", minWidth: "100px", searchable: false }
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center position-relative",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <input type="checkbox" className="ckbox position-absolute form-check-input form-checked-info " name="name" value={rowData.id} style={{ top: '1px', left: '10px' }}
                            onChange={(e) => {
                                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                            }}
                        />

                        <div className="card-aside-img cursor-pointer border border-gray-300 rounded container mt-2" style={{ textAlign: 'start', minWidth: '64px', position: 'relative' }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (rowData.products?.length > 0) {
                                    dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: rowData.products?.map(x => x.imageUrl) || [noImage] }))
                                }
                            }} >
                            <div className="row justify-content-start">
                                {rowData.products?.length > 0 && rowData.products.slice(0, (rowData.products.length > 1 ? 2 : rowData.products.length)).map((product, index) => {

                                    return (
                                        <div key={"product-pic-" + index} className={`px-0 rounded-0 ${rowData.products.length > 1 ? "col-6 " : "col-12 "}`} >
                                            <img src={product.imageUrl || noImage} className="img-thumbnail" alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                            ></img>
                                        </div>
                                    )
                                })}

                            </div>
                            {rowData.products?.length > 2 &&
                                <div className="row justify-content-start">
                                    {rowData.products.slice(2, (rowData.products.length > 3 ? 4 : rowData.products.length)).map((product, index) => {

                                        return (
                                            <div key={"product-pic-" + (index + 2)} className="col-6 px-0 rounded-0">
                                                <img src={product.imageUrl || noImage} alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                                ></img>
                                            </div>
                                        )
                                    })}

                                </div>
                            }
                            {rowData.products.length > 1 && <span className="badge bg-danger tx-bold" style={{ position: 'absolute', top: '-8px', right: '-8px' }}>{rowData.products.length}</span>}
                            {flagOptions.find(x => x.value === rowData.orderFlag)?.icon &&

                                <span style={{ position: 'absolute', bottom: '-8px', right: '-8px' }}>{flagOptions.find(x => x.value === rowData.orderFlag)?.icon}</span>

                            }
                        </div>
                        {/* <div className="card-aside-img cursor-pointer border border-gray-300 rounded container mt-2" style={{ textAlign: 'start', minWidth: '64px', position: 'relative' }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (rowData.products?.length > 0) {
                                    dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: rowData.products?.map(x => x.imageUrl) || [noImage] }))
                                }
                            }} >
                            <div className="row justify-content-start">
                                {rowData.products?.length > 0 && rowData.products.slice(0, (rowData.products.length > 1 ? 2 : rowData.products.length)).map((product, index) => {

                                    return (
                                        <div key={"product-pic-" + index} className={`px-0 rounded-0 ${rowData.products.length > 1 ? "col-6 " : "col-12 "}`} >
                                            <img src={product.imageUrl || noImage} alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                            ></img>
                                        </div>
                                    )
                                })}

                            </div>
                            {rowData.products?.length > 2 &&
                                <div className="row justify-content-start">
                                    {rowData.products.slice(2, (rowData.products.length > 3 ? 4 : rowData.products.length)).map((product, index) => {

                                        return (
                                            <div key={"product-pic-" + (index + 2)} className="col-6 px-0 rounded-0">
                                                <img src={product.imageUrl || noImage} alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                                ></img>
                                            </div>
                                        )
                                    })}

                                </div>
                            }
                            {rowData.products.length > 1 && <span className="badge bg-danger tx-bold" style={{ position: 'absolute', top: '-8px', right: '-8px' }}>{rowData.products.length}</span>}

                        </div> */}
                    </>);
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            width: "20%",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <div className="row justify-content-center">
                            <div className="col-auto px-0">
                                <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/order-detail/' + rowData.id);
                                    }}
                                >
                                    {rowData.orderNumber}
                                </button>
                            </div>
                            <div className="col-auto px-0 my-auto cursor-pointer">
                                {/* <button className="btn btn-icon btn-outline-light rounded-pill btn-sm btn-wave waves-effect waves-light"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(rowData.orderNumber)
                                }}
                            >
                                <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-primary cursor-pointer"></i>
                            </button> */}
                                <span onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(rowData.orderNumber);
                                    toastr.info("Info", rowData.orderNumber + " is copied to clipboard", { timeOut: 1000 })
                                }}>
                                    <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                </span>

                            </div>

                        </div >
                    </>)
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.warehouseSuitName}</span><br />
                    </>);
            }
        },
        {
            targets: 3,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">
                            {rowData.orderType}
                        </span><br />
                    </>);
            }
        },
        {
            targets: 4,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">
                            {rowData.sellerMailAddress}
                        </span><br />

                    </div>);
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            width: "20%",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.trackingNumber}</span><br />
                    </>)
            }
        },
        {
            targets: 6,
            orderable: true,
            className: "text-center",
            width: "20%",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>

                        {rowData.labelData?.length > 0 &&
                            <label>
                                <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                    href={rowData.labelData}>
                                    Show Label
                                </a>
                            </label>}
                    </>)
            }
        },
        {
            targets: 7,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <button className={`ms-2 btn btn-wave waves-effect waves-light shadow btn-sm  ${rowData.trackingNumber ? "btn-warning" : "btn-teal"}`} rel="noreferrer" value=""
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(clearShipStationData());
                            dispatch(setActiveCreateLabelModal({
                                name: modals.createLabel, isGlobal: true,
                                source: rowData.id
                            }));

                        }}>
                        {rowData.trackingNumber ? "Re-Create Label" : "Create Label"}

                    </button>
                );
            }
        }
    ];



    const orderPageOptionClickCallBack = (value) => {
        dispatch(withActionPromise(setWarehouseOrderPageSelections({ dateInterval: pageSelections?.dateInterval, orderStatus: value, marketOption: pageSelections?.marketOption })));
    };

    const timeIntervalOptionCallBack = (dateValues) => {
        dispatch(withActionPromise(setWarehouseOrderPageSelections({ dateInterval: dateValues, orderStatus: pageSelections?.orderStatus, marketOption: pageSelections?.marketOption })));
    };

    const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {
        if (checkedValues && checkedValues.length > 0) {
            if (selectedOperation === "0" || selectedOperation === "1") //export
            {
                var data = warehouseOrderList.filter(item1 =>
                    !!checkedValues.find(item2 => item1.id === item2));

                let tableRows = [];

                // for each ticket pass all its data into an array
                data.forEach(orderItem => {
                    const orderData = {
                        orderNumber: orderItem.orderNumber || "",
                        warehouseSuitName: orderItem.warehouseSuitName || "",
                        orderType: orderItem.orderType || "",
                        sellerMailAddress: orderItem.sellerMailAddress || "",
                        trackingNumber: orderItem.trackingNumber || ""
                    };
                    // push each tickcet's info into a row
                    tableRows.push(orderData);
                });

                if (selectedOperation === "0") {

                    let tableColumn = [
                        {
                            header: "ORDER NUMBER",
                            key: "orderNumber",
                            width: "30"
                        },
                        {
                            header: "SUIT NAME",
                            key: "warehouseSuitName",
                            width: "40"
                        },
                        {
                            header: "ORDER TYPE",
                            key: "orderType",
                            width: "20"
                        },
                        {
                            header: "SELLER EMAIL",
                            key: "sellerMailAddress",
                            width: "30"
                        },
                        {
                            header: "TRACKING NUMBER",
                            key: "trackingNumber",
                            width: "50"
                        }
                    ];



                    ExcelExport2({ excelData: tableRows, columns: tableColumn, fileName: "order_list" });

                }
                else if (selectedOperation === "1") {
                    let tableColumn = ["ORDER NUMBER", "SUIT NAME", "ORDER TYPE", "SELLER EMAIL", "TRACKING NUMBER"];
                    // define an empty array of rows

                    generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Order List", orientation: 'l', reportName: "order_list" });

                }
            }

        }
    }

    return (

        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.warehouseManagement'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.warehouseOrders'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div style={{ marginBottom: '150px' }}>
                <div className="mb-2 row row-md row-lg justify-content-end">

                    <div className="col-auto">
                        <DrButtonDropdown className="ms-2 shadow" options={orderStatusOptions.filter(x => x.value === 2 || x.value === 3 || x.value === 4)}
                            selectedValue={pageSelections?.orderStatus}
                            optionClickCallBack={orderPageOptionClickCallBack}
                            variant={buttonVariants.teal}>
                        </DrButtonDropdown>
                    </div>
                </div>
                <div id="orderTable" className="row mb-5">
                    <div className="col-lg-12 col-xl-12 col-md-12">
                        <div className="card custom-card">
                            <div className="card-body horizontal-scrollable">
                                {warehouseOrderList && <>
                                    <DrDataTable
                                        data={warehouseOrderList}
                                        columns={columns}
                                        columnDefs={columnDefs}
                                        doesHaveBorder={false}
                                        headerSearch={true}
                                        //headerSelectbox={true}
                                        search={true}
                                        minWidth="850px"
                                        detailedSearchChildren={
                                            <DrPageFilter
                                                isSearchSavingActive={false}
                                                isSearchActive={false}
                                                timeIntervalOptionCallBack={timeIntervalOptionCallBack}
                                                filterType={pageFilterTypes.warehouseOrders}
                                                selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                            >
                                            </DrPageFilter>
                                        }
                                    />
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>


    )
}
export default WarehouseOrdersPage