import { call, put } from 'redux-saga/effects'
import { store } from "../state-management/configureStore";
import { clearActiveModal, setAppError, setLoadIndicator } from '../state-management/actions/app.actions';
import { logoutUser } from './actions/customer.actions';
import Cookies from 'js-cookie';
import i18next from 'i18next';
import Swal from 'sweetalert2';

const safe = (saga, componentFailed = null, ...args) => function* (action) {
    try {
        yield call(saga, ...args, action)
    } catch (error) {
        // call failed action

        if (error.response && error.response.status === 401) {
            Cookies.remove('AuthToken', { domain: process.env.REACT_APP_HOST_URL });
            store.dispatch(logoutUser());
            store.dispatch(setLoadIndicator(0));
            //store.dispatch(setActiveModal({ name: modals.login, isGlobal: true, title: i18next.t('drLogin:title') }))

            Swal.fire({
                title: i18next.t('drSweetAlertButton:errorTitle'),
                text: error.response.data.message,
                icon: 'error',
                showCloseButton: true,
                confirmButtonText: 'Ok'
            })
            return;
        }
        if (error.response && error.response.status === 400) {
            store.dispatch(setLoadIndicator(0));
            Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (error && error.message ? error.message : "") + " " + (error?.response?.data?.data || ""), 'error');
            return;
        }
        if (error.response && error.response.status === 404) {
            store.dispatch(setLoadIndicator(0));
            Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (error && error.message ? error.message : "") + " " + (error?.response?.data?.data?.detail || ""), 'error');
            return;
        }
        if (error.code === "ERR_NETWORK") {

            Swal.fire({
                title: i18next.t('drSweetAlertButton:errorTitle'),
                text: error.message,
                icon: 'error',
                showCloseButton: true,
                confirmButtonText: i18next.t('drSweetAlertButton:confirmButtonText'),
            })
            store.dispatch(setLoadIndicator(0));
            return;
        }
        if (componentFailed)
            yield put({ type: componentFailed })
        store.dispatch(setLoadIndicator(0));
        yield call(displaySagaErrorMessageOnConsole, ...args, error)
    }
}

const displaySagaErrorMessageOnConsole = (err) => {

    let errorMessage = err.message;
    if (err.response && err.response.data) {
        if (err.response.data.errors && err.response.data.errors.length > 0) {
            errorMessage = "";
            err.response.data.errors.forEach(element => {
                if (errorMessage !== "") {
                    errorMessage += " \r\n ";
                }
                errorMessage += element;
            });
        }
        else if (err.response.data.Message) {
            errorMessage = err.response.data.Message;
        }
    }

    if (err.response) {
        err.response.userFriendlyErrorMessage = errorMessage;
        store.dispatch(setAppError(err.response));
    }
    else {
        store.dispatch(setAppError(err));
    }


    // redirect page if error status code defined below
    if (err.response && err.response.status >= 500) {
        store.dispatch(clearActiveModal());
        // history.push("/error");
        // store.dispatch(setActiveModal({ name: modals.error, isGlobal: true }))

        Swal.fire({
            title: err?.response?.status || 'Error',
            text: "Unexpected error occured!",
            icon: 'error',
            showCloseButton: true,
            confirmButtonText: 'Ok'
        })

    } else {
        // set error message application state

        // store.dispatch(setActiveModal({ name: modals.error, isGlobal: true }))
    }
}

export default safe;
