import types from "../actions/notification.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_NOTIFICATIONS_SUCCESS:
            return { ...state, allNotifications: action.payload };
        case types.GET_NOTIFICATION_BY_ORDER_NO_SUCCESS:
            return { ...state, notificationMessages: action.payload };
        case types.GET_SUPPORTED_MESSAGES_LIST_SUCCESS:
            return { ...state, supportedMessagesList: action.payload };
        case types.GET_MESSAGING_ACTIONS_LIST_SUCCESS:
            return { ...state, messagingActionsForOrder: action.payload };
        case types.GET_NOTIFICATION_ATTRIBUTES_SUCCESS:
            return { ...state, notificationAttributes: action.payload };
        case types.CLEAR_ALL_NOTIFICATIONS:
            return initialState;;
        default:
            return state;
    }
}

const initialState = {
    "allNotifications": [],
    "notificationMessages": [],
    "supportedMessagesList": [],
    "messagingActionsForOrder": [],
    "notificationAttributes": null
}

export default reducer;