const types = {
    CLEAR_ALL_TRADEMARK_DATA: "CLEAR_ALL_TRADEMARK_DATA",
    GET_TRADEMARK_RESULT_REQUESTED: "GET_TRADEMARK_RESULT_REQUESTED",
    GET_TRADEMARK_RESULT_SUCCESS: "GET_TRADEMARK_RESULT_SUCCESS",
}

export default types;


export const clearAllTrademarkData = () => {
    return { type: types.CLEAR_ALL_TRADEMARK_DATA };
};

export const getTrademarkResult = (payload) => {
    return { type: types.GET_TRADEMARK_RESULT_REQUESTED, payload }
}

export const getTrademarkResultSuccess = (payload) => {
    return { type: types.GET_TRADEMARK_RESULT_SUCCESS, payload }
}