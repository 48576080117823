
export const createPackagesSteps = [
    { id: 1, position: "30%", progress: "30%", title: "CHOOSE A PLAN", pathname: "/main-packages", key: "MAIN_PACKAGES", previousstep: "", nextstep: "SUB_FEATURES", icon:<i className="m-auto fs-16 text-bold ri-home-gear-line"></i> },
    { id: 2, position: "60%", progress: "60%", title: "SUB FEATURES", pathname: "/package-features", key: "SUB_FEATURES", previousstep: "MAIN_PACKAGES", nextstep: "PAYMENT" , icon:<i className="m-auto fs-16 text-bold ri-git-merge-fill"></i>},
    { id: 3, position: "100%", progress: "100%", title: "PAYMENT", pathname: "/customer-payment", key: "PAYMENT", previousstep: "SUB_FEATURES", nextstep: "CONGRATULATIONS" , icon:<i className="m-auto fs-16 text-bold ri-bank-card-2-line"></i>},
    // { id: 5, position: "100%", progress: "100%", title: "FINAL STEP", pathname: "/congratulations", key:"CONGRATULATIONS", previousstep:"PAYMENT", nextstep:""}
]

export const createStoreSteps = [
    { id: 1, position: "25%", progress: "25%", title: "SELECT A MARKET", pathname: "/market-selection", key: "MARKET_SELECTION", previousstep: "", nextstep: "STORE_PREFERENCES" },
    { id: 2, position: "50%", progress: "50%", title: "STORE PREFERENCES", pathname: "/store-preferences", key: "STORE_PREFERENCES", previousstep: "MARKET_SELECTION", nextstep: "WAREHOUSE_SELECTION" },
    { id: 3, position: "75%", progress: "75%", title: "WAREHOUSE SELECTION", pathname: "/warehouse-selection", key: "WAREHOUSE_SELECTION", previousstep: "STORE_PREFERENCES", nextstep: "CONNECT_AMAZON" },
    { id: 4, position: "100%", progress: "100%", title: "CONNECT TO AMAZON", pathname: "/connect-amazon", key: "CONNECT_AMAZON", previousstep: "WAREHOUSE_SELECTION", nextstep: "COMPLETE_CREATESTORE" },
    //{ id: 5, position: "100%", progress: "100%", title: "FINAL STEP", pathname: "/complete-createstore", key:"COMPLETE_CREATESTORE", previousstep:"CONNECT_AMAZON", nextstep:""}
]

export const createSmartPricerSteps = [
    { id: 1, position: "25%", progress: "25%", title: "SELECT SMART OPTIONS", pathname: "/smartpricer-options", key: "SMARTPRICER_OPTIONS", previousstep: "", nextstep: "SMARTPRICER_FILTERS" },
    { id: 2, position: "50%", progress: "50%", title: "PRICING FILTERS", pathname: "/smartpricer-filters", key: "SMARTPRICER_FILTERS", previousstep: "SMARTPRICER_OPTIONS", nextstep: "SMARTPRICER_TARGET" },
    { id: 3, position: "75%", progress: "75%", title: "TARGET PRODUCTS", pathname: "/smartpricer-target", key: "SMARTPRICER_TARGET", previousstep: "SMARTPRICER_FILTERS", nextstep: "SMARTPRICER_COMPLETE" },
    { id: 4, position: "100%", progress: "100%", title: "COMPLETE", pathname: "/smartpricer-complete", key: "SMARTPRICER_COMPLETE", previousstep: "SMARTPRICER_TARGET", nextstep: "" },
    // { id: 5, position: "100%", progress: "100%", title: "COMPLETED", pathname: "/smartpricer-completed", key:"SMARTPRICER_COMPLETED", previousstep:"SMARTPRICER_COMPLETE", nextstep:""}
]

// export const findCurrentStepByKey = (key) => {
//     if (key) {
//         let step = steps.find(s => s.key === key);
//         if (step) {
//             return step;
//         }
//     }
//     else {
//         let step = steps.find(s => s.key === "MAIN_PACKAGES");
//         if (step) {
//             return step;
//         }
//     }
//     return null;
// }

export const findCurrentStep = (location, steps) => {
    let path = steps.find(s => s.pathname === location.pathname);
    if (path) {
        return path;
    }
    return null;
}