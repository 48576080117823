
import { useTranslation } from "react-i18next";
import { Formik } from 'formik';
import { clearActiveAddMerchantListModal } from "../../../../state-management/actions/app.actions";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { DrInput, DrTagifyInput } from "../../../component-index";
import { generateRandomUniqueId } from "../../../../utils/utils";
import $ from 'jquery';

function DrAddMerchantListModal({ display, source, persistModal = false, addMerchantListCallback }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drAddMerhant');

    const initialValues = {
        id: generateRandomUniqueId(10),
        listName: "",
        merchantIds: "",
        isSelected: false
    };

    const handleSubmit = (values) => {
        if (addMerchantListCallback) {
            var newSource = [];
            if (!source || (source && source.length === 0)) {
                newSource = [];
            }
            else {
                try {
                    newSource = JSON.parse(source);
                } catch (error) {
                    newSource = []
                }
            }

            newSource.push(values);

            addMerchantListCallback(JSON.stringify(newSource));
        }
    };

    const addMerhantListSchema = Yup.object().shape({
        listName: Yup.string().required('Captcha text is required'),
    });

    return (

        <div className={`modal fade modal-background ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="addMerchantModal" aria-modal="true" aria-hidden="true"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActiveAddMerchantListModal());
                }
            }}>
            <Formik
                initialValues={initialValues}
                validateOnBlur={true}
                // onSubmit={handleSubmit}
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={addMerhantListSchema}
            >
                {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setTouched,
                    setFieldValue
                }) => (

                    <div className="form needs-validation modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header" >
                                <h6 className="modal-title" >{t('title')}</h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => dispatch(clearActiveAddMerchantListModal())} >
                                </button>

                            </div>
                            <div className="modal-body justify-contents-center">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <h6>{t('listName')}</h6>
                                            <DrInput value={values.listName} className={`form-control ${(touched.listName && errors.listName) ? " is-invalid" : ""}`} placeholder="List Name" name="listName" type="text"
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'listName': true }) }}
                                                isInvalid={(touched.listName && errors.listName)}
                                                isValid={touched.listName && !errors.listName}
                                                validationText={errors.listName} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group has-validation">
                                            <h6>{t('merchantIdList')}</h6>
                                            <DrTagifyInput value={values.merchantIds}
                                                settings={{
                                                    pattern: /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{14}$/,
                                                    delimiters: ",| |:|[\\n\\r]"
                                                }}
                                                className="tags-input-control w-100"
                                                onBlur={(e) => {
                                                    setTouched({ ...touched, 'merchantIds': true });
                                                    setFieldValue("merchantIds", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                }}
                                                onRemove={(e) => {
                                                    setTouched({ ...touched, 'merchantIds': true });
                                                    setFieldValue("merchantIds", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                }}
                                                onEditUpdated={(e) => {
                                                    setTouched({ ...touched, 'merchantIds': true });
                                                    setFieldValue("merchantIds", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                }}>
                                            </DrTagifyInput>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row justify-content-end w-100">
                                    <div className="col-auto">
                                        <button type="submit" className="btn btn-primary btn-wave waves-effect waves-light shadow"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (isValid) {
                                                    handleSubmit(values);
                                                }
                                            }}>
                                            {t('save')}
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                )
                }
            </Formik>
        </div>

    )
}

export default DrAddMerchantListModal