import { call, takeLatest, all, put } from 'redux-saga/effects'
import { CONTROLLERS, apiGet, apiPost } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getAllSearchTasks, setSearchTasks } from '../actions/reprizer.action';

function* runGetAllSearchTasks(action) {
    const payload = action.payload;

    const path = `/GetTasks?taskstatus=${payload.taskStatus}&searchtype=${payload.taskType}`;

    const response = yield call(apiGet(path, CONTROLLERS.REPRIZER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(setSearchTasks(response.data));
    }
}

function* runGetSearchTask(action) {
    const payload = action.payload;

    const path = `/GetTaskById?id=${payload.id}`;

    const response = yield call(apiGet(path, CONTROLLERS.REPRIZER, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {

    }
}

function* runSaveSearchTasks(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/CreateOrUpdateTasks`, payload.searchTasks, false, CONTROLLERS.REPRIZER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield put(getAllSearchTasks({ taskStatus: payload.taskStatus, taskType: payload.taskType }));
    }
}

function* runDeleteSearchTasks(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/DeleteTask`, payload.searchTasks, false, CONTROLLERS.REPRIZER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield put(getAllSearchTasks({ taskStatus: payload.taskStatus, taskType: payload.taskType }));
    }
}

export default function* reprizerSaga() {
    yield all([
        takeLatest(types.GET_ALL_SEARCH_TASKS_REQUESTED, safe(runGetAllSearchTasks)),
        takeLatest(types.GET_SEARCH_TASK_REQUESTED, safe(runGetSearchTask)),
        takeLatest(types.SAVE_SEARCH_TASKS_REQUESTED, safe(runSaveSearchTasks)),
        takeLatest(types.DELETE_SEARCH_TASKS_REQUESTED, safe(runDeleteSearchTasks))
    ]);
}