const types = {
    GET_NOTIFICATIONS_REQUESTED: "GET_NOTIFICATIONS_REQUESTED",
    GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
    GET_NOTIFICATION_BY_ORDER_NO_REQUESTED: "GET_NOTIFICATION_BY_ORDER_NO_REQUESTED",
    GET_NOTIFICATION_BY_ORDER_NO_SUCCESS: "GET_NOTIFICATION_BY_ORDER_NO_SUCCESS",

    GET_SUPPORTED_MESSAGES_LIST_REQUESTED: "GET_SUPPORTED_MESSAGES_LIST_REQUESTED",
    GET_SUPPORTED_MESSAGES_LIST_SUCCESS: "GET_SUPPORTED_MESSAGES_LIST_SUCCESS",
    GET_MESSAGING_ACTIONS_LIST_REQUESTED: "GET_MESSAGING_ACTIONS_LIST_REQUESTED",
    GET_MESSAGING_ACTIONS_LIST_SUCCESS: "GET_MESSAGING_ACTIONS_LIST_SUCCESS",
    GET_CREATE_NEGATIVE_FEEDBACK_REMOVAL_REQUESTED: "GET_CREATE_NEGATIVE_FEEDBACK_REMOVAL_REQUESTED",
    GET_CREATE_NEGATIVE_FEEDBACK_REMOVAL_SUCCESS: "GET_CREATE_NEGATIVE_FEEDBACK_REMOVALACTIONS_LIST_SUCCESS",
    GET_NOTIFICATION_ATTRIBUTES_REQUESTED: "GET_NOTIFICATION_ATTRIBUTES_REQUESTED",
    GET_NOTIFICATION_ATTRIBUTES_SUCCESS: "GET_NOTIFICATION_ATTRIBUTES_SUCCESS",

    SEND_NOTIFICATION_REQUESTED: "SEND_NOTIFICATION_REQUESTED",
    SEND_NOTIFICATION_SUCCESS: "SEND_NOTIFICATION_SUCCESS",

    SEND_NOTIFICATION_MESSAGE_REQUESTED: "SEND_NOTIFICATION_MESSAGE_REQUESTED",
    SEND_NOTIFICATION_MESSAGE_SUCCESS: "SEND_NOTIFICATION_MESSAGE_SUCCESS",

    CLEAR_ALL_NOTIFICATIONS: "CLEAR_ALL_NOTIFICATIONS"
}

export default types;

export const getNotifications = (payload) => {
    return { type: types.GET_NOTIFICATIONS_REQUESTED, payload }
}

export const getNotificationsSuccess = (payload) => {
    return { type: types.GET_NOTIFICATIONS_SUCCESS, payload };
};

export const getNotificationsByOrderNo = (payload) => {
    return { type: types.GET_NOTIFICATION_BY_ORDER_NO_REQUESTED, payload }
}

export const getNotificationsByOrderNoSuccess = (payload) => {
    return { type: types.GET_NOTIFICATION_BY_ORDER_NO_SUCCESS, payload };
};

export const getSupportedMessagesList = () => {
    return { type: types.GET_SUPPORTED_MESSAGES_LIST_REQUESTED }
}

export const getSupportedMessagesListSuccess = (payload) => {
    return { type: types.GET_SUPPORTED_MESSAGES_LIST_SUCCESS, payload };
};

export const getMessagingActionsForOrder = (payload) => {
    return { type: types.GET_MESSAGING_ACTIONS_LIST_REQUESTED, payload }
}

export const getMessagingActionsForOrderSuccess = (payload) => {
    return { type: types.GET_MESSAGING_ACTIONS_LIST_SUCCESS, payload };
};

export const getCreateNegativeFeedbackRemoval = (payload) => {
    return { type: types.GET_CREATE_NEGATIVE_FEEDBACK_REMOVAL_REQUESTED, payload }
}

export const getCreateNegativeFeedbackRemovalSuccess = (payload) => {
    return { type: types.GET_CREATE_NEGATIVE_FEEDBACK_REMOVAL_SUCCESS, payload };
};

export const getNotificationAttributes = (payload) => {
    return { type: types.GET_NOTIFICATION_ATTRIBUTES_REQUESTED, payload }
}

export const getNotificationAttributesSuccess = (payload) => {
    return { type: types.GET_NOTIFICATION_ATTRIBUTES_SUCCESS, payload };
};




export const sendNotification = (payload) => {
    return { type: types.SEND_NOTIFICATION_REQUESTED, payload };
};

export const sendNotificationSuccess = (payload) => {
    return { type: types.SEND_NOTIFICATION_SUCCESS, payload };
};

export const sendNotificationMessage = (payload) => {
    return { type: types.SEND_NOTIFICATION_MESSAGE_REQUESTED, payload };
};

export const sendNotificationMessageSuccess = (payload) => {
    return { type: types.SEND_NOTIFICATION_MESSAGE_SUCCESS, payload };
};

export const clearAllNotifications = () => {
    return { type: types.CLEAR_ALL_NOTIFICATIONS }
}