import * as Yup from 'yup';
import { Field, Formik } from "formik";
import { DrButtonDropdown, DrInput, DrSelectBox } from "../../component-index";
import { useDispatch, useSelector } from 'react-redux';
import { flaggedMarketOptions } from '../Constants/dr-constants.component';
import { buttonVariants, countries } from '../../../utils/constants';
import { createWarehouse, updateWarehouse } from '../../../state-management/actions/warehouses.actions';
import classNames from 'classnames';

function DrNewWarehouse({ form, field, warehouse }) {
    const dispatch = useDispatch();
    const selectedStore = useSelector(state => state.stores.selectedStore);

    var warehouseSaveEditClass = classNames({
        'border border-gray-300 rounded mb-1 bg-gray-100 mt-2': warehouse && warehouse.id?.length > 0,
        'row m-0 justify-content-end collapse': true,
    });


    const initialValues = {
        id: warehouse?.id || "",
        marketId: warehouse?.marketId || "",
        wareHouseName: warehouse?.wareHouseName || "",
        wareHouseShortName: warehouse?.wareHouseShortName || "",
        logo: warehouse?.logo || "",

        addressLine1: warehouse?.addressLine1 || "",
        addressLine2: warehouse?.addressLine2 || "",
        addressLine3: warehouse?.addressLine3 || "",
        city: warehouse?.city || "",
        district: warehouse?.district || "",
        stateOrRegion: warehouse?.stateOrRegion || "",
        municipality: warehouse?.municipality || "",
        postalCode: warehouse?.postalCode || "",
        phone: warehouse?.phone || "",
        sellMarginDefault: warehouse?.sellMarginDefault || "",
    };

    // const [warehouse, setWarehouse] = useState(defaultWarehouse);

    // useEffect(() => {
    //     setWarehouse(defaultWarehouse);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // useEffect(() => {
    //     setWarehouse(defaultWarehouse);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [warehouses]);

    const handleSubmit = async (values) => {
        //form.setFieldValue(`${field.name}`, values);
        var data = {
            customerMainSellerId: selectedStore?.customerMainSellerId,
            warehouseData: values
        }

        if (data.warehouseData.id && data.warehouseData.id.length > 0) {
            dispatch(updateWarehouse(data));
        }
        else {
            dispatch(createWarehouse(data));
        }
    };

    const warehouseSchema = Yup.object().shape({
        wareHouseName: Yup.string().required('Required'),

    });

    return (
        <>
            <Formik
                validateOnChange={true}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={initialValues}
                validationSchema={warehouseSchema}
                onSubmit={handleSubmit}>
                {({
                    errors,
                    values,
                    touched,
                    dirty,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setTouched,
                    isValid
                }) => (
                    <div className={warehouseSaveEditClass} id={("warehouseDetail_" + (warehouse?.id || ""))}>
                        <form className="m-3 w-100 needs-validation">
                            <div className="row justify-content-center">
                                <div className="col-11">
                                    <div className="form-group has-validation">
                                        <label>Country Code</label>
                                        <DrButtonDropdown buttonDropdownKey={"shippingsOptions_" + values.id} variant={buttonVariants.light} options={flaggedMarketOptions?.filter(x => x.value !== "-1")}
                                            optionClickCallBack={(value) => {
                                                setFieldValue('marketId', value);
                                            }}
                                            selectedValue={values.marketId}
                                            disabled={values?.id ? "disabled" : ""}
                                        >
                                        </DrButtonDropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-11 col-lg-7 col-xl-7">
                                    <div className="form-group has-validation">
                                        <label>Warehouse Name</label>
                                        <DrInput value={values.wareHouseName} className={`form-control`} placeholder={"Warehouse Name"} name="wareHouseName" type="text" onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={(touched.wareHouseName && errors.wareHouseName)}
                                            isValid={touched.wareHouseName && !errors.wareHouseName}
                                            validationText={errors.wareHouseName} />
                                    </div>
                                </div>
                                <div className="col-11 col-lg-4 col-xl-4">
                                    <div className="form-group has-validation">
                                        <label>Warehouse Short Name</label>
                                        <DrInput value={values.wareHouseShortName} className={`form-control`} placeholder={"Warehouse Short Name"} name="wareHouseShortName" type="text" onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={(touched.wareHouseShortName && errors.wareHouseShortName)}
                                            isValid={touched.wareHouseShortName && !errors.wareHouseShortName}
                                            validationText={errors.wareHouseShortName} />
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-11">
                                    <div className="form-group has-validation">
                                        <label>Logo Url</label>
                                        <DrInput value={values.logo} className={`form-control`} name="icon" type="text" placeholder={"Logo Url"} onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={(touched.logo && errors.logo)}
                                            isValid={touched.logo && !errors.logo}
                                            validationText={errors.logo} />
                                    </div>
                                </div>
                            </div>
                            {warehouse?.id &&
                                <>
                                    <div className="row justify-content-center">
                                        <div className="col-11">
                                            <div className="form-group has-validation">
                                                <label>Address Line 1</label>
                                                <DrInput value={values.addressLine1} className={`form-control`}
                                                    placeholder={""}
                                                    name="addressLine1" type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.addressLine1 && errors.addressLine1)}
                                                    isValid={touched.addressLine1 && !errors.addressLine1}
                                                    validationText={errors.addressLine1} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-11">
                                            <div className="form-group has-validation">
                                                <label>Address Line 2</label>
                                                <DrInput value={values.addressLine2} className={`form-control`}
                                                    placeholder={""}
                                                    name="addressLine2" type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.addressLine2 && errors.addressLine2)}
                                                    isValid={touched.addressLine2 && !errors.addressLine2}
                                                    validationText={errors.addressLine2} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-11">
                                            <div className="form-group has-validation">
                                                <label>Address Line 3</label>
                                                <DrInput value={values.addressLine3} className={`form-control`}
                                                    placeholder={""}
                                                    name="addressLine3" type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.addressLine3 && errors.addressLine3)}
                                                    isValid={touched.addressLine3 && !errors.addressLine3}
                                                    validationText={errors.addressLine3} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-11 col-lg-5 col-xl-5">
                                            <div className="form-group has-validation">
                                                <label>StateOrRegion</label>
                                                <Field onBlur={() => { setTouched({ ...touched.stateOrRegion, 'stateOrRegion': true }) }}
                                                    name={'stateOrRegion'}
                                                    component={DrSelectBox}
                                                    placeholder="stateOrRegion"
                                                    disabled={false}
                                                    options={countries} />
                                                {(touched.stateOrRegion && errors.stateOrRegion) && <div type="invalid" className="form-control is-invalid">
                                                    {errors.stateOrRegion}
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="col-11 col-lg-6 col-xl-6"></div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-11 col-lg-5 col-xl-5">
                                            <div className="form-group has-validation">
                                                <label>City</label>
                                                <DrInput value={values.city} className={`form-control`}
                                                    placeholder={"city"}
                                                    name="city" type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.city && errors.city)}
                                                    isValid={touched.city && !errors.city}
                                                    validationText={errors.city} />
                                            </div>
                                        </div>
                                        <div className="col-11 col-lg-6 col-xl-6">
                                            <div className="form-group has-validation">
                                                <label>District</label>
                                                <DrInput value={values.district} className={`form-control`}
                                                    placeholder={"district"}
                                                    name="district" type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.district && errors.district)}
                                                    isValid={touched.district && !errors.district}
                                                    validationText={errors.district} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-11 col-lg-5 col-xl-5">
                                            <div className="form-group has-validation">
                                                <label>postalCode</label>
                                                <DrInput value={values.postalCode} className={`form-control`}
                                                    placeholder={"postalCode"}
                                                    name="postalCode" type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.postalCode && errors.postalCode)}
                                                    isValid={touched.postalCode && !errors.postalCode}
                                                    validationText={errors.postalCode} />
                                            </div>
                                        </div>
                                        <div className="col-11 col-lg-6 col-xl-6">
                                            <div className="form-group has-validation">
                                                <label>phone</label>
                                                <DrInput value={values.phone} className={`form-control`}
                                                    placeholder={"phone"}
                                                    name="phone" type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.phone && errors.phone)}
                                                    isValid={touched.phone && !errors.phone}
                                                    validationText={errors.phone} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-11 col-lg-5 col-xl-5">
                                            <div className="form-group has-validation">
                                                <label>sellMarginDefault</label>
                                                <DrInput value={values.sellMarginDefault} className={`form-control`}
                                                    placeholder={"sellMarginDefault"}
                                                    name="sellMarginDefault" type="text"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.sellMarginDefault && errors.sellMarginDefault)}
                                                    isValid={touched.sellMarginDefault && !errors.sellMarginDefault}
                                                    validationText={errors.sellMarginDefault} />
                                            </div>
                                        </div>
                                        <div className="col-11 col-lg-6 col-xl-6">
                                            <div className="form-group has-validation">
                                                <label>municipality</label>
                                                <DrInput value={values.municipality} className={`form-control`}
                                                    placeholder={"municipality"}
                                                    name="municipality" type="text" onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.municipality && errors.municipality)}
                                                    isValid={touched.municipality && !errors.municipality}
                                                    validationText={errors.municipality} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-wave waves-effect waves-light shadow btn-block mt-4" type="submit"
                                            onClick={handleSubmit}
                                        >Kaydet</button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                )
                }
            </Formik>
        </>
    )
}
export default DrNewWarehouse