import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { syncedState, withActionPromise } from '../../state-management/actions/app.actions';
import { getRouteByKey } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { setSmartPricerSnapshotMeta } from '../../state-management/actions/create-smartpricer-filter.actions';
import { useTranslation } from 'react-i18next';
import { createSmartPricerStrategy } from '../../state-management/actions/smartpricer.actions';

function SmartPricerCompletedPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('smartPricerPage');
    const isSmartpricerFilterSubmittedBefore = useSelector(state => state.smartPricerSnapshot.smartpricerFilterSubmitted);
    const selectedStore = useSelector(state => state.stores.selectedStore);

    useEffect(() => {
        if (!isSmartpricerFilterSubmittedBefore) {
            const submitSmartPricerSnapshot = async () => {
                // await Promise.all([
                //     dispatch(withActionPromise(setSmartPricerSnapshotMeta({ "smartpricerFilterSubmitted": true })))
                // ]);

                const lastState = await dispatch(syncedState());

                var data = {
                    smartPricerName: lastState.smartPricerSnapshot.smartpricerName,
                    smartPricerStrategy: lastState.smartPricerSnapshot.targetType,
                    customerMainSellerId: selectedStore?.customerMainSellerId,
                    productCategoryId: lastState.smartPricerSnapshot.targetCategoryId,
                    answerResults: Object.assign([], lastState.smartPricerSnapshot.smartpricerQuestions),
                    asins: Object.assign([], lastState.smartPricerSnapshot.targetProductsAsins),
                    isBuyBoxGroup: lastState.smartPricerSnapshot.filterTypeSelection === "2" || false,
                    isLowestGroup: lastState.smartPricerSnapshot.filterTypeSelection === "1" || false,
                }


                dispatch(createSmartPricerStrategy(data));
            }
            submitSmartPricerSnapshot();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmartpricerFilterSubmittedBefore, dispatch]);

    return (
        <div className="row row-md row-lg d-flex justify-content-center">
            <div className="col-md-8 col-lg-12">
                <div className="card custom-card">
                    <div className="card-body">
                        <div className="row row-md row-lg justify-content-center">
                            <h3 className="text-3xl text-center tx-bold mb-5">{t('completedPage.contentTitle')}</h3>
                        </div>
                        <div className="row row-md row-lg justify-content-center">
                            <div className="col-auto">
                                <button className="btn btn-info btn-wave waves-effect waves-light shadow" onClick={(e) => {
                                    e.preventDefault();
                                    navigate(getRouteByKey("SMARTPRICER"));
                                }}
                                >
                                    <i className="me-2 fe fe-chevrons-right"></i>
                                    {t('completedPage.goToStrategies')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SmartPricerCompletedPage;