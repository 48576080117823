import types from '../actions/create-store.actions';

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case types.SET_MARKET_SNAPSHOT_META:
            return { ...state, ...action.payload };

        case types.SET_MARKET_SELECTION:
            return {
                ...state, "marketSelection": {
                    ...state.marketSelection,
                    ...action.payload
                }
            };

        case types.SET_MARKET_PREFERENCES:
            return {
                ...state, "marketPreferences": {
                    ...state.marketPreferences,
                    ...action.payload
                }
            };

        case types.SET_CONNECT_TO_AMAZON_CRITERIA:
            return {
                ...state, "connectToAmazonCriteria": {
                    ...state.connectToAmazonCriteria,
                    ...action.payload
                }
            };
        case types.SET_SHIPPING_IDS:
            return {
                ...state,
                "warehouseShippingIds": action.payload
            };
        case types.CLEAR_CREATE_STORE_INFORMATION:
            return initialState;

        case types.SET_SNAPSHOT_STORE_PAGE_SUBMISSION:
            return {
                ...state, "pageSubmissions": {
                    ...state.pageSubmissions,
                    ...action.payload
                }
            };

        default:
            return state;
    }
};

export default reducer;

const initialState = {
    "customerMainSellersId": "",
    "marketSelection": {
        "countryCode": "",
        "marketSerialNumber": ""
    },
    "marketPreferences": {
        "customerSubUserId": "",
        "marketId": "",
        "name": "",
        "companySellerSerialNumber": "",
        "maxStock": "",
        "primePercentage": "",
        "currencyConversion": 1,
        "conversionRate": "",
        "enableTaxPayer": false,
        "enableSalesTaxRates": false,
        "aboveSalePrice": "",
        "aboveSalePriceRate": "",
        "aboveSalePriceOffset": "",
        "belowSalePrice": "",
        "belowSalePriceRate": "",
        "belowSalePriceOffset": "",
        "enableImportFee": false,
        "importFeeRate": "",
        "enableCommunalPoolForbiddenProducts": false,
        "enableCommunalPoolForbiddenProductsBrands": false,
        "enableCommunalPoolBlockedBrands": false,
        "enableCommunalPoolAutoAddBrands": false,
        "enableCommunalPoolAutoAddProducts": false,
        "enableHandlingTime": false,
        "handlingTime": "",
        "enableMaxDelivery": false,
        "maxDeliveryTime": "",
        "enableAutoDeleteOutCriteria": false,
        "autoDeleteTimePeriods": "",
        "enableAutoCarrierChange": false,
        "presentCardSender": "",
        "presentNote": "",
        "enableAutoOrderConfirm": false,
        "enableAutoOrderPreConfirm": false,
        "enableAfterOrderPlaced": false,
        "afterOrderPlacedTime": "",
        "enableAutoPriceUpdate": false,
        "enableAutoStockUpdate": false,
        "enableAutoProductDeletion": false,
        "enableAutoProductAdd": false,
        "askConfirmationForNewProducts": false,
        "sourceMarkets": []
    },
    "connectToAmazonCriteria": {
        "code": "",
        "state": "",
        "redirectUrl": "",
        "redirectPath": ""
    },
    "warehouseShippingIds": [],
    "pageSubmissions":
    {
        "marketSelectionPage": false,
        "storePreferencesPage": false,
        "wareHouseSelectionPage": false,
        "connectAmazonPage": false
    },
    "customerStoreSubmitted": false

}
