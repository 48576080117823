import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiGet, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getAllMarketsSuccess } from '../actions/market.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';

function* runGetAllMarkets() {
    const response = yield call(apiGet(``,CONTROLLERS.MARKETPLACE, true, true));
    if(response && response.isSuccess && response.resultType === 1 && response.data ) {

        yield put(getAllMarketsSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error14') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* marketSaga() {
    yield all([
        takeLatest(types.GET_ALL_MARKETS_REQUESTED, safe(runGetAllMarkets))
    ]);
}