const types = {
    CREATE_PACKAGE_REQUESTED: "CREATE_PACKAGE_REQUESTED",
    UPDATE_PACKAGE_REQUESTED: "UPDATE_PACKAGE_REQUESTED",
    DELETE_PACKAGE_REQUESTED: "DELETE_PACKAGE_REQUESTED",

    GET_PACKAGES_REQUESTED: "GET_PACKAGES_REQUESTED",
    GET_PACKAGES_SUCCESS: "GET_PACKAGES_SUCCESS",
    GET_PACKAGE_BY_ID_REQUESTED: "GET_PACKAGE_BY_ID_REQUESTED",
    GET_PACKAGE_BY_ID_SUCCESS: "GET_PACKAGE_BY_ID_SUCCESS",

    CREATE_PACKAGE_COUPON_REQUESTED: "CREATE_PACKAGE_COUPON_REQUESTED",
    CREATE_PACKAGE_COUPON_SUCCESS: "CREATE_PACKAGE_COUPON_SUCCESS",
    DELETE_PACKAGE_COUPON_REQUESTED: "DELETE_PACKAGE_COUPON_REQUESTED",
    DELETE_PACKAGE_COUPON_SUCCESS: "DELETE_PACKAGE_COUPON_SUCCESS",


    GET_COUPONS_REQUESTED: "GET_COUPONS_REQUESTED",
    GET_COUPONS_SUCCESS: "GET_COUPONS_SUCCESS",
    CREATE_COUPON_REQUESTED: "CREATE_COUPON_REQUESTED",
    UPDATE_COUPON_REQUESTED: "UPDATE_COUPON_REQUESTED",
    DELETE_COUPON_REQUESTED: "DELETE_COUPON_REQUESTED",

    CLEAR_ALL_PACKAGES: "CLEAR_ALL_PACKAGES"
}

export default types;

export const clearAllPackages = () => {
    return { type: types.CLEAR_ALL_PACKAGES }
}

export const createPackage = (payload) => {
    return { type: types.CREATE_PACKAGE_REQUESTED, payload };
};

export const updatePackage = (payload) => {
    return { type: types.UPDATE_PACKAGE_REQUESTED, payload };
};

export const deletePackage = (payload) => {
    return { type: types.DELETE_PACKAGE_REQUESTED, payload };
};

export const getPackages = () => {
    return { type: types.GET_PACKAGES_REQUESTED };
};

export const getPackagesSuccess = (payload) => {
    return { type: types.GET_PACKAGES_SUCCESS, payload };
};

export const getPackageById = (payload) => {
    return { type: types.GET_PACKAGE_BY_ID_REQUESTED, payload };
};

export const getPackageByIdSuccess = (payload) => {
    return { type: types.GET_PACKAGE_BY_ID_SUCCESS, payload };
};

export const createPackageCoupon = (payload) => {
    return { type: types.CREATE_PACKAGE_COUPON_REQUESTED, payload };
};

export const createPackageCouponSuccess = (payload) => {
    return { type: types.CREATE_PACKAGE_COUPON_SUCCESS, payload };
};

export const deletePackageCoupon = (payload) => {
    return { type: types.DELETE_PACKAGE_COUPON_REQUESTED, payload };
};

export const deletePackageCouponSuccess = (payload) => {
    return { type: types.DELETE_PACKAGE_COUPON_SUCCESS, payload };
};

export const getCoupons = () => {
    return { type: types.GET_COUPONS_REQUESTED };
};
export const getCouponsSuccess = (payload) => {
    return { type: types.GET_COUPONS_SUCCESS, payload };
};

export const createCoupon = (payload) => {
    return { type: types.CREATE_COUPON_REQUESTED, payload };
};

export const updateCoupon = (payload) => {
    return { type: types.UPDATE_COUPON_REQUESTED, payload };
};

export const deleteCoupon = (payload) => {
    return { type: types.DELETE_COUPON_REQUESTED, payload };
};
