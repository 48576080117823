import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { syncedState, withActionPromise } from '../../state-management/actions/app.actions';
import { getRouteByKey } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { loginWithEmail } from '../../state-management/actions/customer.actions';
import { useTranslation } from 'react-i18next';

function Congratulation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('congratulationPage');
    const createPackagesSnapshot = useSelector(state => state.createPackagesSnapshot);
    const isSubmittedBefore = useSelector(state => state.createPackagesSnapshot.packagesCreateSubmitted);
    const isCustomerSuccessfullyCreated = useSelector(state => state.createPackagesSnapshot.packagesSuccessfullyCreated);
    const isLoggedin = useSelector(state => state.customer?.isLoggedin);

    useEffect(() => {
        if (!isSubmittedBefore && !isLoggedin) {
            const submitCreatePackageSnapshot = async () => {
                const lastState = await dispatch(syncedState());
                // dispatch(createCustomer({
                //     createCustomerSnapshot: lastState.createCustomerSnapshot
                // }));

            }
            submitCreatePackageSnapshot();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmittedBefore, dispatch]);


    useEffect(() => {
        if (isCustomerSuccessfullyCreated && !isLoggedin) {
            // if (createCustomerSnapshot?.customerInformation?.customerSetup?.email && createCustomerSnapshot?.customerInformation?.customerSetup?.password)
            //     dispatch(withActionPromise(loginWithEmail({
            //         email: createCustomerSnapshot?.customerInformation.customerSetup.email,
            //         password: createCustomerSnapshot?.customerInformation.customerSetup.password
            //     })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCustomerSuccessfullyCreated]);

    return (
        <>
            <div className="page-header">

            </div>
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row row-md row-lg justify-content-center">
                                <h3 className="text-3xl text-center tx-bold mb-5">{t('header')}</h3>
                            </div>
                            <hr className="mt-2 mb-4" />
                            <div className="row row-md row-lg justify-content-center">
                                <h3 className="text-3xl text-center tx-bold mb-5">{t('headerDescription')}</h3>
                            </div>
                            <div className="row row-md row-lg justify-content-center">
                                <h4 className="text-xs text-center mb-5">{t('description')}</h4>
                            </div>
                            <div className="row row-md row-lg justify-content-center">
                                <div className="col-lg-auto col-md-2">
                                    <button className="btn btn-info btn-wave waves-effect waves-light shadow" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(getRouteByKey("HOME"));
                                    }}
                                    >
                                        <i className="me-2 fe fe-chevrons-right"></i>
                                        {t('goToHome')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Congratulation;