const types = {
    GET_PAGE_FILTERS_REQUESTED: "GET_PAGE_FILTERS_REQUESTED",
    SAVE_PAGE_FILTER_REQUESTED: "SAVE_PAGE_FILTER_REQUESTED",
    UPDATE_PAGE_FILTER_REQUESTED: "UPDATE_PAGE_FILTER_REQUESTED",
    DELETE_PAGE_FILTER_REQUESTED: "DELETE_PAGE_FILTER_REQUESTED",
    SET_PAGE_FILTERS: "SET_PAGE_FILTERS",
    SET_PAGE_FILTERS_INVENTORY: "SET_PAGE_FILTERS_INVENTORY",
    SET_PAGE_FILTERS_ORDERS: "SET_PAGE_FILTERS_ORDERS",
    SET_PAGE_FILTERS_PRODUCY_VARIATIONS: "SET_PAGE_FILTERS_PRODUCY_VARIATIONS",
    CLEAR_PAGE_FILTERS: "CLEAR_PAGE_FILTERS"
}

export default types;

export const clearPageFilters = () => {
    return { type: types.CLEAR_PAGE_FILTERS }
}

export const getPageFilters = (payload) => {
    return { type: types.GET_PAGE_FILTERS_REQUESTED, payload }
}

export const savePageFilter = (payload) => {
    return { type: types.SAVE_PAGE_FILTER_REQUESTED, payload }
}

export const updatePageFilter = (payload) => {
    return { type: types.UPDATE_PAGE_FILTER_REQUESTED, payload }
}

export const deletePageFilter = (payload) => {
    return { type: types.DELETE_PAGE_FILTER_REQUESTED, payload }
}

export const setPageFilters = (payload) => {
    return { type: types.SET_PAGE_FILTERS, payload };
};

export const setInventoryPageFilters = (payload) => {
    return { type: types.SET_PAGE_FILTERS_INVENTORY, payload };
};

export const setOrdersPageFilters = (payload) => {
    return { type: types.SET_PAGE_FILTERS_ORDERS, payload };
};

export const setProductVariationsPageFilters = (payload) => {
    return { type: types.SET_PAGE_FILTERS_PRODUCY_VARIATIONS, payload };
};