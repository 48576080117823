import ReduxToastr from "react-redux-toastr";
import "./dr-toastr.component.scss";

function DrToastr() {
    return (
        <ReduxToastr
           
            timeOut={4000}
            position="top-right"
            getState={(state) => state.toastr}
            transitionIn="bounceIn"
            transitionOut="bounceOut"
            progressBar
            closeOnToastrClick
        // preventDuplicates
        />
    );
}

export default DrToastr