import classNames from 'classnames';
import './dr-input.component.css'

function DrFileUploadInput({
    name,
    field,
    form,
    onChange,
    disabled,
    className,
    selectedValue,
    disableInputChange,
    accept,
    maxFiles,
    maxFileSizeInBytes,
    removeFileTypeStringFromContent = true,
    ...rest }) {


    if (rest.disabled && rest.disabled === "") {
        delete rest.disabled;
    }

    var inputClass = classNames({
        'form-control': true,
    }, className);

    function onFileUploaded(e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > maxFiles) {
            e.currentTarget.value = null;
            form.setFieldValue(field.name, []);
            return;
        }

        var isFileSizeExceedsLimit = false;
        e.target.files.forEach(file => {
            if (file.size > maxFileSizeInBytes) {
                isFileSizeExceedsLimit = true;
            }
        });

        if (isFileSizeExceedsLimit) {
            e.currentTarget.value = null;
            form.setFieldValue(field.name, []);
            return;
        }

        const promises = []
        for (const file of e.target.files) {
            promises.push(readFile(file));
        }

        Promise.all(promises).then((result) => {
            form.setFieldValue(field.name, result);
        });
    }

    function readFile(f) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(f)
            reader.onload = event => resolve({ attachment: removeFileTypeStringFromContent ? event.target.result.split('base64,')[1] : event.target.result, contentType: f.type, name: f.name })
            reader.onerror = reject
        })
    }

    return (
        <div className={`dr-input-content input-group`}>
            <input
                id={name}
                type="file"
                name={name}
                accept={accept}
                className={inputClass}
                {...rest}
                onChange={(e) => {
                    if (onChange)
                        onChange(e);
                    if (!disableInputChange)
                        onFileUploaded(e);
                }}
            />
        </div>
    )
}

export default DrFileUploadInput;
