import types from "../actions/orders.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ORDERS_SUCCESS:
            return { ...state, orderList: action.payload };
        case types.GET_ORDERS_FAILED:
            return { ...state, orderList: [] };
        case types.GET_WAREHOUSE_ORDERS_SUCCESS:
            return { ...state, warehouseOrderList: action.payload };
        case types.GET_WAREHOUSE_ORDERS_FAILED:
            return { ...state, warehouseOrderList: [] };
        case types.GET_LATEST_ORDERS_SUCCESS:
            return { ...state, latestOrderList: action.payload };
        case types.GET_LATEST_ORDERS_FAILED:
            return { ...state, latestOrderList: [] };
        case types.GET_ORDER_ITEM_SUCCESS:
            return { ...state, selectedOrder: action.payload };
        case types.GET_ORDER_ITEM_FAILED:
            return { ...state, selectedOrder: null };
        case types.GET_ORDER_ITEM_SUMMARY_SUCCESS:
            return { ...state, orderDetailSaleSummary: action.payload };
        case types.GET_ORDER_ITEM_SUMMARY_FAILED:
            return { ...state, orderDetailSaleSummary: null };
        case types.GET_ORDER_TRACKING_STATUS_SUCCESS:
            return { ...state, orderTrackingStatuses: action.payload };
        case types.GET_ORDER_TRACKING_STATUS_FAILED:
            return { ...state, orderTrackingStatuses: [] };
        case types.GET_ORDER_NOTES_SUCCESS:
            return { ...state, orderNotes: action.payload };
        case types.GET_INVOICE_SUCCESS:
            return { ...state, invoice: action.payload };
        case types.GET_ORDER_SHIPPING_CALCULATE_SUCCESS:
            return { ...state, orderShippingCalculate: action.payload };
        case types.CLEAR_ALL_ORDERS:
            return initialState;
        default:
            return state;
    }
}

const initialState = {
    "orderList": [],
    "warehouseOrderList": [],
    "latestOrderList": [],
    "selectedOrder": null,
    "orderDetailSaleSummary": null,
    "orderTrackingStatuses": [],
    "orderNotes": [],
    "invoice": null,
    "orderShippingCalculate": null
}

export default reducer;