import './dr-warehouse-pricing.component.scss'
import { DrInput } from '../../component-index';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function DrWarehousePricing({ form, field , source}) {
    const [autoPriceValues, setAutoPriceValues] = useState([]);
    const { t } = useTranslation('drWarehousePricing');

    useEffect(() => {
        if (form.values[field.name] && form.values[field.name].length > 0) {
            let autopricevalues = Object.assign([], form.values[field.name])
            setAutoPriceValues(autopricevalues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values, field.name]);


    const onChangeAll = (fieldName, value) => {
        for (let index = 0; index < autoPriceValues.length; index++) {
            form.setFieldValue(`${field.name}[${index}].${fieldName}`, value);
        }
    }

    const onChange = (id, fieldName, value) => {
        var index = form.values[field.name].findIndex(x => x.costUnitId === id);
        form.setFieldValue(`${field.name}[${index}].${fieldName}`, value);
    }

    return (

        <>
            {form.values[field.name] && form.values[field.name].length > 0 && <div id="pricingTable" className="col-12">
                {/* <form> */}
                <table className="table table-nowrap card-table price-rules table-striped w-100">
                    <thead>
                        <tr className="table-danger">
                            <th>{t('cost')} (USD)</th>
                            <th data-toggle="tooltip" className="text-center" data-original-title="MINIMUM PROFIT PERCENTAGE">{t('min')}</th>
                            <th data-toggle="tooltip" className="text-center standart-profit-margin-header" data-original-title="STANDARD PROFIT PERCENTAGE">{t('standard')}</th>
                            <th data-toggle="tooltip" className="text-center" data-original-title="MAXIMUM PROFIT PERCENTAGE">{t('max')}</th>
                            <th data-toggle="tooltip" className="text-center max-price-only-seller-header" data-original-title="MAXIMUM PROFIT PERCENTAGE">
                                {t('max2')}
                                {/* <i data-toggle="tooltip" className="fe fe-help-circle" title data-original-title="Set a maximum profit rate for the products that you are the only seller. (This feature can only be used by those who have the Repricer plan.)" /> */}
                            </th>
                            <th data-toggle="tooltip" className="text-center" data-original-title="CRITICAL STOCK: WHEN THE STOCK QUANTITY FALLS BELOW THE NUMBER YOU SPECIFY, IT WILL BE UPDATED TO 0.">{t('stock')}</th>
                            <th className="text-center default-shipping-cost-header">
                                {t('carrier')}
                                {/* <i data-toggle="tooltip" className="fe fe-help-circle" title data-original-title="Default shipping cost: Enter a default shipping cost for your products with unclear shipping fees (USD). This is only used when you disable the 'Filter missing-shipping info products' filter and used in cases where the shipping cost on Amazon.com is uncertain." /> */}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="list">
                        <tr className="bulkupdate_pricingrules">
                            <td><label /></td>
                            <td className="text-start">
                                <DrInput className="form-control autoPriceMinAll" placeholder="" name="autoPriceMinAll" type="number" step="0.1"
                                    onChange={(e) => {
                                        onChangeAll("min", e.currentTarget.value);
                                    }}
                                    icon={<i className="fe fe-percent tx-muted"></i>}
                                    iconPosition="left"
                                />
                            </td>
                            <td className="text-center standart-profit-margin">
                                <DrInput className="form-control autoPriceStandartAll" placeholder="" name="autoPriceStandartAll" type="number" step="0.1"
                                    onChange={(e) => {
                                        onChangeAll("standart", e.currentTarget.value);
                                    }}
                                    icon={<i className="fe fe-percent tx-muted"></i>}
                                    iconPosition="left"
                                />
                            </td>
                            <td>
                                <DrInput className="form-control autoPriceMaxAll" placeholder="" name="autoPriceMaxAll" type="number" step="0.1"
                                    onChange={(e) => {
                                        onChangeAll("max", e.currentTarget.value);
                                    }}
                                    icon={<i className="fe fe-percent tx-muted"></i>}
                                    iconPosition="left"
                                />
                            </td>
                            <td className="text-center max-price-only-seller">
                                <DrInput className="form-control autoPriceMax2All" placeholder="" name="autoPriceMax2All" type="number" step="0.1"
                                    onChange={(e) => {
                                        onChangeAll("max2", e.currentTarget.value);
                                    }}
                                    icon={<i className="fe fe-percent tx-muted"></i>}
                                    iconPosition="left"
                                />
                            </td>
                            <td>
                                <DrInput className="form-control autoPriceStockAll" placeholder="" name="autoPriceStockAll" type="number" step="1"
                                    onChange={(e) => {
                                        onChangeAll("stock", e.currentTarget.value);
                                    }}
                                    icon={<i className="fe fe-percent tx-muted"></i>}
                                    iconPosition="left"
                                />
                            </td>
                            <td className="text-center default-shipping-cost">
                                <DrInput className="form-control autoPriceCargoAll" placeholder="" name="autoPriceCargoAll" type="number" step="0.1"
                                    onChange={(e) => {
                                        onChangeAll("cargo", e.currentTarget.value);
                                    }}
                                    icon={<i className="fe fe-percent tx-muted"></i>}
                                    iconPosition="left"
                                />
                            </td>
                        </tr>

                        {form.values[field.name].sort((a, b) => a.sortOrder - b.sortOrder).map((costUnit, index) => {
                            // let minFieldTouched = `${field.name}[${index}].min`;
                            return (
                                <tr key={('costUnit_' + index)}>
                                    <td className="text-start">
                                        <label htmlFor="commissionMin-6497900" className="tx-bold">{costUnit.costUnitName}</label>
                                        <input data-val="true" data-val-number="The field Id must be a number." data-val-required="The Id field is required." id="UserPriceRulesForItemDoesntShip_0__Id" name="UserPriceRulesForItemDoesntShip[0].Id" type="hidden" defaultValue={6497900} />
                                        <input data-val="true" data-val-number="The field PriceRange1 must be a number." data-val-required="The PriceRange1 field is required." id="UserPriceRulesForItemDoesntShip_0__PriceRange1" name="UserPriceRulesForItemDoesntShip[0].PriceRange1" type="hidden" defaultValue={0.00} />
                                        <input data-val="true" data-val-number="The field PriceRange2 must be a number." data-val-required="The PriceRange2 field is required." id="UserPriceRulesForItemDoesntShip_0__PriceRange2" name="UserPriceRulesForItemDoesntShip[0].PriceRange2" type="hidden" defaultValue={10.00} />
                                    </td>
                                    <td className="text-center bulkupdate_autopricemin">
                                        <DrInput className="form-control autoPriceMin" placeholder="" name="autoPriceMin" type="number" step="0.1"
                                            value={costUnit.min || ""}
                                            // defaultValue={2.00}
                                            onChange={(e) => {
                                                onChange(costUnit.costUnitId, "min", e.currentTarget.value);
                                            }}
                                            onBlur={field.onBlur}
                                            // onBlur={() => { form.setTouched({ ...form.touched, minFieldTouched : true }) }}
                                            icon={<i className="fe fe-percent tx-muted"></i>}
                                            iconPosition="left"
                                        />
                                    </td>
                                    <td className="text-center standart-profit-margin bulkupdate_comission">
                                        <DrInput className="form-control autoPriceStandart" placeholder="" name="autoPriceStandart" type="number" step="0.1"
                                            value={costUnit.standart || ""}
                                            // defaultValue={3.00}
                                            onChange={(e) => {
                                                onChange(costUnit.costUnitId, "standart", e.currentTarget.value);
                                            }}
                                            onBlur={field.onBlur}
                                            icon={<i className="fe fe-percent tx-muted"></i>}
                                            iconPosition="left"
                                        />
                                    </td>
                                    <td className="text-center bulkupdate_autopricemax">
                                        <DrInput className="form-control autoPriceMax" placeholder="" name="autoPriceMax" type="number" step="0.1"
                                            value={costUnit.max || ""}
                                            // defaultValue={4.00}
                                            onChange={(e) => {
                                                onChange(costUnit.costUnitId, "max", e.currentTarget.value);
                                            }}
                                            onBlur={field.onBlur}
                                            icon={<i className="fe fe-percent tx-muted"></i>}
                                            iconPosition="left"
                                        />
                                    </td>
                                    <td className="text-center max-price-only-seller bulkupdate_autopricemaxsh">
                                        <DrInput className="form-control autoPriceMax2" placeholder="" name="autoPriceMax2" type="number" step="0.1"
                                            value={costUnit.max2 || ""}
                                            // defaultValue={3.00}
                                            onChange={(e) => {
                                                onChange(costUnit.costUnitId, "max2", e.currentTarget.value);
                                            }}
                                            onBlur={field.onBlur}
                                            icon={<i className="fe fe-percent tx-muted"></i>}
                                            iconPosition="left"
                                        />
                                    </td>
                                    <td className="text-center bulkupdate_minstockquantity">
                                        <DrInput className="form-control autoPriceStock" placeholder="" name="autoPriceStock" type="number" step="1"
                                            value={costUnit.stock || ""}
                                            // defaultValue={"3"}
                                            onChange={(e) => {
                                                onChange(costUnit.costUnitId, "stock", e.currentTarget.value);
                                            }}
                                            onBlur={field.onBlur}
                                            icon={<i className="fe fe-package tx-muted"></i>}
                                            iconPosition="left"
                                        />
                                        <small className="invalid-feedback">Stock Value Needed</small>
                                    </td>
                                    <td className="text-center default-shipping-cost bulkupdate_defaultshippingcostinusd">
                                        <DrInput className="form-control autoPriceCargo" placeholder="" name="autoPriceCargo" type="number" step="0.1"
                                            value={costUnit.cargo || ""}
                                            // defaultValue={20.00}
                                            onChange={(e) => {
                                                onChange(costUnit.costUnitId, "cargo", e.currentTarget.value);
                                            }}
                                            onBlur={field.onBlur}
                                            icon={<i className="fe fe-percent tx-muted"></i>}
                                            iconPosition="left"
                                        />
                                    </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
                {/* </form> */}
            </div>}
        </>

    )
}

export default DrWarehousePricing