import React, { useEffect, useState } from 'react'
import { DrBreadCrump, DrButtonDropdown } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { getNotifications } from '../../state-management/actions/notification.actions';
import { buttonVariants } from '../../utils/constants';
import outlookImage from '../../assets/img/pngs/outlook.png'
import gmailImage from '../../assets/img/pngs/gmail.png'
import gmailstep1 from '../../assets/img/media/gmailstep1.png'
import gmailstep2 from '../../assets/img/media/gmailstep2.png'
import gmailstep3 from '../../assets/img/media/gmailstep3.png'
import gmailstep4 from '../../assets/img/media/gmailstep4.png'
import gmailstep5 from '../../assets/img/media/gmailstep5.png'
import gmailstep1_en from '../../assets/img/media/gmailstep1-en.png'
import gmailstep2_en from '../../assets/img/media/gmailstep2-en.png'
import gmailstep3_en from '../../assets/img/media/gmailstep3-en.png'
import gmailstep4_en from '../../assets/img/media/gmailstep4-en.png'
import gmailstep5_en from '../../assets/img/media/gmailstep5-en.png'
import outlookstep2 from '../../assets/img/media/outlookstep2.jpg'
import outlookstep2_en from '../../assets/img/media/outlookstep2-en.jpg'

import gmail1tr from '../../assets/img/media/gmail1tr.png'
import gmail2tr from '../../assets/img/media/gmail2tr.png'
import gmail3tr from '../../assets/img/media/gmail3tr.png'
import gmail4tr from '../../assets/img/media/gmail4tr.png'
import gmail5tr from '../../assets/img/media/gmail5tr.png'

import { getCustomerStore } from '../../state-management/actions/store.actions';

function EmailForwardingPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('emailForwardingPage');
    const language = useSelector(state => state.settings.language);
    const [selectedMailType, setSelectedMailType] = useState("0");
    const myStore = useSelector(state => state.stores.myStore);
    const selectedStore = useSelector(state => state.stores.selectedStore);

    useEffect(() => {
        dispatch(withActionPromise(getNotifications()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(getCustomerStore({
                marketId: selectedStore?.marketId,
                customerMainSellerId: selectedStore?.customerMainSellerId
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId]);

    return (
        <>
            <div>
                <DrBreadCrump
                    breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.forwardingEmails'), navigationPath: "" }]} >
                </DrBreadCrump>
                <div className="row row-md row-lg d-flex justify-content-center">
                    <div className="col-md-8 col-lg-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <ul>
                                    <li>
                                        <i className="fa fa-angle-double-right mb-2 me-2"></i>
                                        {t('infoTextOne')}
                                    </li>
                                    <li>
                                        <i className="fa fa-angle-double-right mb-2 me-2"></i>
                                        <Trans
                                            i18nKey={t('infoTextTwo')}
                                            components={{
                                                bold: <strong />,
                                                underline: <u />
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <i className="fa fa-angle-double-right mb-2 me-2"></i>
                                        <Trans
                                            i18nKey={t('infoTextThree')}
                                            components={{
                                                bold: <strong />,
                                                underline: <u />
                                            }}
                                        />
                                    </li>
                                </ul>

                                <div className="row px-3 py-3 my-3 border-top">
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="row justify-content-start">
                                            <div className="col-auto my-auto">
                                                <label className="noselect text-start tx-bold">
                                                    {t('selectMailType')}
                                                </label>
                                            </div>
                                            <div className="col-auto my-auto">
                                                <DrButtonDropdown variant={buttonVariants.light} className="ms-2"
                                                    options={[
                                                        { value: "0", label: " Outlook", icon: <img src={outlookImage} height="20px"></img> },
                                                        { value: "1", label: " Gmail", icon: <img src={gmailImage} height="20px"></img> },
                                                        // { value: "2", label: "Yandex", icon: <img src={yandexImage} height="20px"></img> }
                                                    ]}
                                                    optionClickCallBack={(value) => { setSelectedMailType(value) }}
                                                    selectedValue={selectedMailType}
                                                >
                                                </DrButtonDropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="mb-3 mt-3 text-center tx-danger tx-bold">
                                    {t('infoTextFour')}
                                </h4>
                                {selectedMailType === "0" &&
                                    <div className="row border m-2">
                                        <div className="col-12">
                                            <div className="row p-4">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <ul>
                                                                <li>
                                                                    <label>
                                                                        {t('outlookStepOne')}
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row p-4">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <ul>
                                                                <li>
                                                                    <label>
                                                                        <Trans
                                                                            i18nKey={t('outlookStepTwo')}
                                                                            components={{
                                                                                iconSettings: <i className="ms-1 fe fe-settings tx-bold"></i>,
                                                                                iconArrow: <i className="fe fe-arrow-right"></i>,
                                                                                strong: <strong />
                                                                            }}
                                                                        />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <button
                                                            className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={"#hotmail-forwarding-step-2"}
                                                        >
                                                            <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                        </button>

                                                    </div>

                                                    <div id="hotmail-forwarding-step-2" className="col-12 py-3 collapse">
                                                        <img src={language === "tr" ? outlookstep2 : outlookstep2_en} alt="" style={{ width: '100%' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row p-4">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <ul>
                                                                <li>
                                                                    <label>
                                                                        {t('outlookStepThree')}
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {selectedMailType === "1" &&
                                    <div className="row border m-2">
                                        <div className="col-12">
                                            <div className="row p-4">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <ul>
                                                                <li>
                                                                    <label>
                                                                        <Trans
                                                                            i18nKey={t('gmailStepOne')}
                                                                            components={{
                                                                                iconSettings: <i className="fe fe-settings tx-bold" />,
                                                                                iconArrow: <i className="fe fe-arrow-right" />,
                                                                                strong: <strong />
                                                                            }}
                                                                        />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <p className="row">
                                                        <div >
                                                            <button
                                                                className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={"#gmail-forwarding-step-1"}
                                                            >
                                                                <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                            </button>

                                                        </div>
                                                    </p>
                                                    <div id="gmail-forwarding-step-1" className="col-12 py-3 collapse">
                                                        <img src={language === "tr" ? gmail1tr : gmail1tr} alt="" style={{ width: '100%' }}></img>
                                                    </div>
                                                    <div className="row justify-content-center p-1">
                                                        <strong className="col-auto bg-secondary border rounded-10 ">{myStore?.customerNotificationEmail}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row p-4">
                                                <div className="col-12">
                                                    <div className="row">

                                                        <div className="col">
                                                            <ul>
                                                                <li>
                                                                    <label>
                                                                        <Trans
                                                                            i18nKey={t('gmailStepTwo')}
                                                                            components={{
                                                                                strong: <strong />,
                                                                                br: <br />,
                                                                                badge: <span className="badge bg-warning" />,
                                                                                iconAlert: <i className="fe fe-alert-triangle" />
                                                                            }}
                                                                        />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <p className="row">
                                                        <div >
                                                            <button
                                                                className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={"#gmail-forwarding-step-2"}
                                                            >
                                                                <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                            </button>
                                                        </div>
                                                    </p>
                                                    <div id="gmail-forwarding-step-2" className="col-12 py-3 collapse">
                                                        <img src={language === "tr" ? gmail2tr : gmail2tr} alt="" style={{ width: '100%' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row p-4">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <ul>
                                                                <li>
                                                                    <label>
                                                                        <Trans
                                                                            i18nKey={t('gmailStepThree')}
                                                                            components={{
                                                                                strong: <strong />,
                                                                                iconSettings: <i className="ms-1 fe fe-settings font-weight-bold" />,
                                                                                iconArrow: <i className="fe fe-arrow-right" />
                                                                            }}
                                                                        />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <button
                                                            className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={"#gmail-forwarding-step-3"}
                                                        >
                                                            <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                        </button>
                                                    </div>
                                                    <div id="gmail-forwarding-step-3" className="col-12 py-3 collapse">
                                                        <img src={language === "tr" ? gmail3tr : gmail3tr} alt="" style={{ width: '100%' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row p-4">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <ul>
                                                                <li>
                                                                    <label>
                                                                        <Trans
                                                                            i18nKey={t('gmailStepFour')}
                                                                            components={{
                                                                                strong: <strong />
                                                                            }}
                                                                        />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <p className="row">
                                                        <div >
                                                            <button
                                                                className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={"#gmail-forwarding-step-4"}
                                                            >
                                                                <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                            </button>
                                                        </div>
                                                    </p>
                                                    <div id="gmail-forwarding-step-4" className="col-12 py-3 collapse">
                                                        <img src={language === "tr" ? gmail4tr : gmail4tr} alt="" style={{ width: '100%' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row p-4">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <ul>
                                                                <li>
                                                                    <label>
                                                                        <Trans
                                                                            i18nKey={t('gmailStepFive')}
                                                                            components={{
                                                                                strong: <strong />
                                                                            }}
                                                                        />
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <p className="row">
                                                        <div >
                                                            <button
                                                                className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={"#gmail-forwarding-step-5"}
                                                            >
                                                                <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                            </button>
                                                        </div>
                                                    </p>
                                                    <div id="gmail-forwarding-step-5" className="col-12 py-3 collapse">
                                                        <img src={language === "tr" ? gmail5tr : gmail5tr} alt="" style={{ width: '100%' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EmailForwardingPage;


