"use strict";
import $ from 'jquery';

const ANIMATION_DURATION = 300;




const slideHasSub = document.querySelectorAll(".nav > ul > .slide.has-sub");

const firstLevelItems = document.querySelectorAll(
    ".nav > ul > .slide.has-sub > a"
);

const innerLevelItems = document.querySelectorAll(
    ".nav > ul > .slide.has-sub .slide.has-sub > a"
);


const slideUp = (target, duration = ANIMATION_DURATION) => {
    const { parentElement } = target;
    parentElement.classList.remove("open");
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = `${duration}ms`;
    target.style.boxSizing = "border-box";
    target.style.height = `${target.offsetHeight}px`;
    // target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = "none";
        target.style.removeProperty("height");
        target.style.removeProperty("padding-top");
        target.style.removeProperty("padding-bottom");
        target.style.removeProperty("margin-top");
        target.style.removeProperty("margin-bottom");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
    }, duration);
};
const slideDown = (target, duration = ANIMATION_DURATION) => {
    const { parentElement } = target;
    parentElement.classList.add("open");
    target.style.removeProperty("display");
    let { display } = window.getComputedStyle(target);
    if (display === "none") display = "block";
    target.style.display = display;
    const height = target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    // target.offsetHeight;
    target.style.boxSizing = "border-box";
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = `${duration}ms`;
    target.style.height = `${height}px`;
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    window.setTimeout(() => {
        target.style.removeProperty("height");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-property");
        target.style.removeProperty("transition-duration");
    }, duration);
};
function iconOverayFn() {
    let html = document.querySelector("html");
    html.setAttribute("data-nav-layout", "vertical");
    html.setAttribute("data-vertical-style", "overlay");
    toggleSidemenu();
    // setNavActive();
}
function menuClose() {
    let html = document.querySelector("html");
    html.setAttribute("data-toggled", "close");
    document.querySelector("#responsive-overlay").classList.remove("active");
}
export function toggleSidemenu() {
    let html = document.querySelector("html");
    let sidemenuType = html.getAttribute("data-nav-layout");
    let mainContentDiv = document.querySelector(".main-content");
    let sidebar = document.getElementById("sidebar");
    // console.log("toggled")
    if (window.innerWidth >= 992) {
        if (sidemenuType === "vertical") {
            sidebar.removeEventListener("mouseenter", mouseEntered);
            sidebar.removeEventListener("mouseleave", mouseLeave);
            sidebar.removeEventListener("click", icontextOpen);
            mainContentDiv.removeEventListener("click", icontextClose);
            let sidemenulink = document.querySelectorAll(
                ".main-menu li > .side-menu__item"
            );
            sidemenulink.forEach((ele) =>
                ele.removeEventListener("click", doubleClickFn)
            );

            let verticalStyle = html.getAttribute("data-vertical-style");
            switch (verticalStyle) {
                // closed
                case "closed":
                    html.removeAttribute("data-nav-style");
                    if (html.getAttribute("data-toggled") === "close-menu-close") {
                        html.removeAttribute("data-toggled");
                    } else {
                        html.setAttribute("data-toggled", "close-menu-close");
                    }
                    break;
                // icon-overlay
                case "overlay":
                    html.removeAttribute("data-nav-style");
                    if (html.getAttribute("data-toggled") === "icon-overlay-close") {
                        html.removeAttribute("data-toggled", "icon-overlay-close");
                        sidebar.removeEventListener("mouseenter", mouseEntered);
                        sidebar.removeEventListener("mouseleave", mouseLeave);
                    } else {
                        if (window.innerWidth >= 992) {
                            if (!localStorage.getItem("sashlayout")) {
                                html.setAttribute("data-toggled", "icon-overlay-close");
                            }
                            sidebar.addEventListener("mouseenter", mouseEntered);
                            sidebar.addEventListener("mouseleave", mouseLeave);
                        } else {
                            sidebar.removeEventListener("mouseenter", mouseEntered);
                            sidebar.removeEventListener("mouseleave", mouseLeave);
                        }
                    }
                    break;
                // icon-text
                case "icontext":
                    html.removeAttribute("data-nav-style");
                    if (html.getAttribute("data-toggled") === "icon-text-close") {
                        html.removeAttribute("data-toggled", "icon-text-close");
                        sidebar.removeEventListener("click", icontextOpen);
                        mainContentDiv.removeEventListener("click", icontextClose);
                    } else {
                        html.setAttribute("data-toggled", "icon-text-close");
                        if (window.innerWidth >= 992) {
                            sidebar.addEventListener("click", icontextOpen);
                            mainContentDiv.addEventListener("click", icontextClose);
                        } else {
                            sidebar.removeEventListener("click", icontextOpen);
                            mainContentDiv.removeEventListener("click", icontextClose);
                        }
                    }
                    break;
                // doublemenu
                case "doublemenu":
                    html.removeAttribute("data-nav-style");
                    if (html.getAttribute("data-toggled") === "double-menu-open") {
                        html.setAttribute("data-toggled", "double-menu-close");
                        if (document.querySelector(".slide-menu")) {
                            let slidemenu = document.querySelectorAll(".slide-menu");
                            slidemenu.forEach((e) => {
                                if (e.classList.contains("double-menu-active")) {
                                    e.classList.remove("double-menu-active");
                                }
                            });
                        }
                    } else {
                        let sidemenu = document.querySelector(".side-menu__item.active");
                        if (sidemenu) {
                            html.setAttribute("data-toggled", "double-menu-open");
                            if (sidemenu.nextElementSibling) {
                                sidemenu.nextElementSibling.classList.add("double-menu-active");
                            } else {
                                document
                                    .querySelector("html")
                                    .setAttribute("data-toggled", "double-menu-close");
                            }
                        }
                    }

                    doublemenu();
                    break;
                // detached
                case "detached":
                    html.removeAttribute("data-nav-style");
                    if (html.getAttribute("data-toggled") === "detached-close") {
                        html.removeAttribute("data-toggled", "detached-close");
                        sidebar.removeEventListener("mouseenter", mouseEntered);
                        sidebar.removeEventListener("mouseleave", mouseLeave);
                    } else {
                        html.setAttribute("data-toggled", "detached-close");
                        if (window.innerWidth >= 992) {
                            sidebar.addEventListener("mouseenter", mouseEntered);
                            sidebar.addEventListener("mouseleave", mouseLeave);
                        } else {
                            sidebar.removeEventListener("mouseenter", mouseEntered);
                            sidebar.removeEventListener("mouseleave", mouseLeave);
                        }
                    }
                    break;
                // default
                case "default":
                    iconOverayFn();
                    html.removeAttribute("data-toggled");

                // for making any vertical style as default
                // default:
                // iconOverayFn();
                // html.removeAttribute('data-toggled');
            }
            let menuclickStyle = html.getAttribute("data-nav-style");
            switch (menuclickStyle) {
                case "menu-click":
                    if (html.getAttribute("data-toggled") === "menu-click-closed") {
                        html.removeAttribute("data-toggled");
                    } else {
                        html.setAttribute("data-toggled", "menu-click-closed");
                    }
                    break;
                case "menu-hover":
                    if (html.getAttribute("data-toggled") === "menu-hover-closed") {
                        html.removeAttribute("data-toggled");
                        // setNavActive();
                    } else {
                        html.setAttribute("data-toggled", "menu-hover-closed");
                        clearNavDropdown();
                    }
                    break;
                case "icon-click":
                    if (html.getAttribute("data-toggled") === "icon-click-closed") {
                        html.removeAttribute("data-toggled");
                    } else {
                        html.setAttribute("data-toggled", "icon-click-closed");
                    }
                    break;
                case "icon-hover":
                    if (html.getAttribute("data-toggled") === "icon-hover-closed") {
                        html.removeAttribute("data-toggled");
                        // setNavActive();
                    } else {
                        html.setAttribute("data-toggled", "icon-hover-closed");
                        clearNavDropdown();
                    }
                    break;
                //for making any horizontal style as default
                default:
            }
        }
    } else {
        if (html.getAttribute("data-toggled") === "close") {
            html.setAttribute("data-toggled", "open");
            let i = document.createElement("div");
            i.id = "responsive-overlay";
            setTimeout(() => {
                if (
                    document.querySelector("html").getAttribute("data-toggled") === "open"
                ) {
                    document.querySelector("#responsive-overlay").classList.add("active");
                    document
                        .querySelector("#responsive-overlay")
                        .addEventListener("click", () => {
                            document
                                .querySelector("#responsive-overlay")
                                .classList.remove("active");
                            menuClose();
                        });
                }
                window.addEventListener("resize", () => {
                    if (window.screen.width >= 992) {
                        document
                            .querySelector("#responsive-overlay")
                            .classList.remove("active");
                    }
                });
            }, 100);
        } else {
            html.setAttribute("data-toggled", "close");
        }
    }
}

function mouseEntered() {
    let html = document.querySelector("html");
    html.setAttribute("data-icon-overlay", "open");
}
function mouseLeave() {
    let html = document.querySelector("html");
    html.removeAttribute("data-icon-overlay");
}
function icontextOpen() {
    let html = document.querySelector("html");
    html.setAttribute("data-icon-text", "open");
}
function icontextClose() {
    let html = document.querySelector("html");
    html.removeAttribute("data-icon-text");
}
// function setNavActive() {
//     let currentPath = window.location.pathname.split("/")[0];

//     currentPath =
//         location.pathname == "/" ? "index.html" : location.pathname.substring(1);
//     currentPath = currentPath.substring(currentPath.lastIndexOf("/") + 1);
//     let sidemenuItems = document.querySelectorAll(".side-menu__item");
//     sidemenuItems.forEach((e) => {
//         if (currentPath === "/") {
//             currentPath = "index.html";
//         }
//         if (e.getAttribute("href") === currentPath) {
//             e.classList.add("active");
//             e.parentElement.classList.add("active");
//             let parent = e.closest("ul");
//             let parentNotMain = e.closest("ul:not(.main-menu)");
//             let hasParent = true;
//             // while (hasParent) {
//             if (parent) {
//                 parent.classList.add("active");
//                 parent.previousElementSibling.classList.add("active");
//                 parent.parentElement.classList.add("active");

//                 if (parent.parentElement.classList.contains("has-sub")) {
//                     let elemrntRef = parent.parentElement.parentElement.parentElement;
//                     elemrntRef.classList.add("open", "active");
//                     elemrntRef.firstElementChild.classList.add("active");
//                     elemrntRef.children[1].style.display = "block";
//                     Array.from(elemrntRef.children[1].children).map((element) => {
//                         if (element.classList.contains("active")) {
//                             element.children[1].style.display = "block";
//                         }
//                     });
//                 }

//                 if (parent.classList.contains("child1")) {
//                     slideDown(parent);
//                 }
//                 parent = parent.parentElement.closest("ul");
//                 //
//                 if (parent && parent.closest("ul:not(.main-menu)")) {
//                     parentNotMain = parent.closest("ul:not(.main-menu)");
//                 }
//                 if (!parentNotMain) hasParent = false;
//             } else {
//                 hasParent = false;
//             }
//         }
//         // }
//     });
// }
function clearNavDropdown() {
    let sidemenus = document.querySelectorAll("ul.slide-menu");
    sidemenus.forEach((e) => {
        let parent = e.closest("ul");
        let parentNotMain = e.closest("ul:not(.main-menu)");
        if (parent) {
            let hasParent = parent.closest("ul.main-menu");
            while (hasParent) {
                parent.classList.add("active");
                slideUp(parent);
                //
                parent = parent.parentElement.closest("ul");
                parentNotMain = parent.closest("ul:not(.main-menu)");
                if (!parentNotMain) hasParent = false;
            }
        }
    });
}

function doublemenu() {
    if (window.innerWidth >= 992) {
        let html = document.querySelector("html");
        let sidemenulink = document.querySelectorAll(
            ".main-menu > li > .side-menu__item"
        );
        sidemenulink.forEach((ele) => {
            ele.addEventListener("click", doubleClickFn);
        });
    }
}
function doubleClickFn() {
    var $this = this;
    let html = document.querySelector("html");
    var checkElement = $this.nextElementSibling;
    if (checkElement) {
        if (!checkElement.classList.contains("double-menu-active")) {
            if (document.querySelector(".slide-menu")) {
                let slidemenu = document.querySelectorAll(".slide-menu");
                slidemenu.forEach((e) => {
                    if (e.classList.contains("double-menu-active")) {
                        e.classList.remove("double-menu-active");
                        html.setAttribute("data-toggled", "double-menu-close");
                    }
                });
            }
            checkElement.classList.add("double-menu-active");
            html.setAttribute("data-toggled", "double-menu-open");
        }
    }
}

export function openFullscreen() {
    // var elem = document.documentElement;
    let open = document.querySelector(".full-screen-open");
    let close = document.querySelector(".full-screen-close");
    if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
        }

        if (close) {
            close.classList.add("d-block");
            close.classList.remove("d-none");
        }
        if (open) {
            open.classList.add("d-none");
        }
    }
    else {

        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }

        if (close) {
            close.classList.remove("d-block");
            close.classList.add("d-none");
        }

        if (open) {
            open.classList.remove("d-none");
            open.classList.add("d-block");
        }
    }
}

export function openFullscreen2(){
    var docElm = document.documentElement;
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    }
}
