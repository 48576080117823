import types from '../actions/app.actions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ACTIVE_MODAL:
            return { ...state, activeModal: action.payload, showModalBackdrop: true };
        case types.CLEAR_ACTIVE_MODAL:
            return {
                ...state, activeModal: {
                    name: "",
                    isGlobal: false,
                    header: "",
                    text: "",
                    title: ""
                }, showModalBackdrop: false
            };

        case types.SET_ACTIVE_PICTUREMODAL:
            return { ...state, activePictureModal: action.payload, showModalBackdrop: true };
        case types.CLEAR_ACTIVE_PICTUREMODAL:
            return {
                ...state, activePictureModal: {
                    name: "",
                    isGlobal: false,
                    header: "",
                    text: "",
                    title: "",
                    imgsource: null
                }, showModalBackdrop: false
            };

        case types.SET_ACTIVE_PAGEFILTERS_MODAL:
            return { ...state, activePageFiltersModal: action.payload, showModalBackdrop: true };
        case types.CLEAR_ACTIVE_PAGEFILTERS_MODAL:
            return {
                ...state, activePageFiltersModal: {
                    name: "",
                    isGlobal: false,
                    filtersSource: [],
                    filterType: null
                }, showModalBackdrop: false
            };
        case types.SET_ACTIVE_CAPTCHA_MODAL:
            return { ...state, activeCaptchaModal: action.payload };
        case types.CLEAR_ACTIVE_CAPTCHA_MODAL:
            return {
                ...state, activeCaptchaModal: {
                    name: "",
                    isGlobal: false,
                    source: null
                }, showModalBackdrop: false
            };
        case types.SET_ACTIVE_SEARCH_TASK_PRODUCTS_MODAL:
            return { ...state, activeSearchTaskProductsModal: action.payload };
        case types.CLEAR_ACTIVE_SEARCH_TASK_PRODUCTS_MODAL:
            return {
                ...state, activeSearchTaskProductsModal: {
                    name: "",
                    isGlobal: false,
                    source: null
                }, showModalBackdrop: false
            };
        case types.SET_ACTIVE_CREATE_LABEL_MODAL:
            return { ...state, activeCreateLabelModal: action.payload };
        case types.CLEAR_ACTIVE_CREATE_LABEL_MODAL:
            return {
                ...state, activeCreateLabelModal: {
                    name: "",
                    isGlobal: false,
                    source: null
                }, showModalBackdrop: false
            };
        case types.SET_ACTIVE_ADD_MERCHANT_LIST_MODAL:
            return { ...state, activeAddMerchantListModal: action.payload };
        case types.CLEAR_ACTIVE_ADD_MERCHANT_LIST_MODAL:
            return {
                ...state, activeAddMerchantListModal: {
                    name: "",
                    isGlobal: false,
                    source: null
                }, showModalBackdrop: false
            };
        case types.SET_ACTIVE_SAVE_PAGEFILTER_MODAL:
            return { ...state, activeSavePageFilterModal: action.payload, showModalBackdrop: true };
        case types.CLEAR_ACTIVE_SAVE_PAGEFILTER_MODAL:
            return {
                ...state, activeSavePageFilterModal: {
                    name: "",
                    isGlobal: false,
                    source: null
                }, showModalBackdrop: false
            };
        case types.SET_ACTIVE_BUYOUT_CARRIER_SELECTION_MODAL:
            return { ...state, activeBuyoutCarrierSelectionModal: action.payload, showModalBackdrop: true };
        case types.CLEAR_ACTIVE_BUYOUT_CARRIER_SELECTION_MODAL:
            return {
                ...state, activeBuyoutCarrierSelectionModal: {
                    name: "",
                    isGlobal: false,
                    source: null
                }, showModalBackdrop: false
            };
        case types.SET_ACTIVE_TERMS_CONDITIONS_MODAL:
            return { ...state, activeTermAndConditionsModal: action.payload, showModalBackdrop: true };
        case types.CLEAR_ACTIVE_TERMS_CONDITIONS_MODAL:
            return {
                ...state, activeTermAndConditionsModal: {
                    name: "",
                    isGlobal: false,
                    source: null
                }, showModalBackdrop: false
            };
        case types.SET_LOADER_SPINNER:
            return { ...state, showLoader: action.payload };
        case types.SET_APP_ERROR:
            return { ...state, appError: action.payload };
        default:
            return state;
    }
};

export default reducer;

const initialState = {
    activeModal: {
        name: "",
        isGlobal: false,
        header: "",
        text: "",
        title: "",
        navigationPath: "",
        navigationText: ""
    },
    activePictureModal: {
        name: "",
        isGlobal: false,
        header: "",
        text: "",
        title: "",
        imgsource: null
    },
    activePageFiltersModal: {
        name: "",
        isGlobal: false,
        filtersSource: [],
        filterType: null
    },
    activeCaptchaModal: {
        name: "",
        isGlobal: false,
        source: null
    },
    activeSearchTaskProductsModal: {
        name: "",
        isGlobal: false,
        source: null
    },
    activeCreateLabelModal: {
        name: "",
        isGlobal: false,
        source: null
    },
    activeAddMerchantListModal: {
        name: "",
        isGlobal: false,
        source: null
    },
    activeSavePageFilterModal: {
        name: "",
        isGlobal: false,
        source: null
    },
    activeBuyoutCarrierSelectionModal: {
        name: "",
        isGlobal: false,
        source: null
    },
    activeTermAndConditionsModal: {
        name: "",
        isGlobal: false,
        source: null
    },
    showLoader: 0,
    showModalBackdrop: false,
    appError: null
};