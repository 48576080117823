const types = {
    GET_AGREEMENT_REQUESTED: "GET_AGREEMENT_REQUESTED",
    GET_AGREEMENT_SUCCESS: "GET_AGREEMENT_SUCCESS",
    CLEAR_ALL_AGREEMENTS: "CLEAR_ALL_AGREEMENTS"
}

export default types;

export const clearAllAgreements = () => {
    return { type: types.CLEAR_ALL_AGREEMENTS }
}

export const getAgreements = () => {
    return { type: types.GET_AGREEMENT_REQUESTED };
};

export const getAgreementsSuccess = (payload) => {
    return { type: types.GET_AGREEMENT_SUCCESS, payload };
};