import classNames from "classnames";
import React from "react";

function DrCardContainer({ children, shadow = false, backgroundClass = "", className }) {

    var cardClass = classNames({
        'card custom-card': true,
        [`${backgroundClass}`]: true,
        'shadow': shadow,
        "shadow-none": !shadow
    });

    var divClass = classNames({
        'row': true,
        [`${className}`]: true,
    });

    return (
        <div className={divClass}>
            <div className="col-lg-12 col-md-12 d-flex justify-content-center">
                <div className={cardClass}>
                    <div className="card-body">
                        <div className="wizard clearfix">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DrCardContainer;
