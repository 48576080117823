import React, { useEffect } from 'react'
import { getRouteByKey } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { DrInput, DrNavigator } from '../../components/component-index';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { useDispatch, useSelector } from 'react-redux';
import { withActionPromise } from '../../state-management/actions/app.actions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { setSmartPricerFilterType, setSmartPricerPageSubmission, setSmartPricerQuestions, setSmartPricerTemplateQuestions } from '../../state-management/actions/create-smartpricer-filter.actions';

import customLogo from '../../assets/img/pngs/custom8.png'
import buyboxLogo from '../../assets/img/pngs/buybox13.png'
import competeLogo from '../../assets/img/pngs/compete5.png'
import lowestLogo from '../../assets/img/pngs/lowest3.png'
import { templateBuyboxStrategy, templateLowestStrategy, templateStrategy1 } from '../../utils/constants';
import { getAllSmartPricerStrategies } from '../../state-management/actions/smartpricer.actions';

function SmartPricerFilterSelectionPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('smartPricerPage');
    const smartPricerFilterType = useSelector(state => state.smartPricerSnapshot.filterTypeSelection);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const customerStores = useSelector(state => state.stores.customerStores);
    const smartPricerStrategies = useSelector(state => state.smartPricer.smartPricerStrategies);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getAllSmartPricerStrategies({ customerMainSellerId: selectedStore?.customerMainSellerId, strategyTargetType: "-1" })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId]);

    const initialValues = {
        filterTypeSelection: smartPricerFilterType || ""
    };

    const smartpricerFilterTypeSelectionSchema = Yup.object().shape({
        filterTypeSelection: Yup.string().required('Filter Type is required')
    });

    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            enableReinitialize={true}
            validateOnMount={true}
            validationSchema={smartpricerFilterTypeSelectionSchema}
            validateOnChange={true}>
            {({
                isValid,
                values,
                errors,
                touched,
                handleBlur,
                setTouched,
                setFieldValue,
                handleChange
            }) => (
                <>

                    <div className="content clearfix">
                        <form name="smartpricer-filter-type-form" className="needs-validation" autoComplete="off">
                            {selectedStore &&
                                <>
                                    <section>
                                        <div className="row row-md d-flex justify-content-center">
                                            <div className="col-sm-8 col-lg-12 col-md-8">
                                                <div className="card custom-card card-body shadow-none">
                                                    <h3>{t('filterTypePage.contentTitle')}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="row justify-content-center">
                                                    <div className="col-auto px-1 d-flex">
                                                        <label htmlFor={("input_1")}
                                                            className={`card card-pricing custom-card border border-gray-300 selectable ${values.filterTypeSelection === "1" ? "active" : ""}`} >
                                                            <div className="card-body row " style={{ maxWidth: '285px', minWidth: '285px', minHeight: '240px' }}>
                                                                <div className="col-12 px-0">
                                                                    <div className="row m-0 p-0">
                                                                        <div className="bg-transparent border-0 m-auto">
                                                                            <div className="pricing-title text-start tx-bold noselect">{t('filterTypePage.lowestPrice')}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row m-0 px-0 py-3 justify-content-center">
                                                                        <div className="col-auto py-auto" style={{ minHeight: '72px' }}>
                                                                            <img src={lowestLogo} alt="img" className="img-md m-auto p-0 border-0 bg-transparent noselect" ></img>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row m-0 p-0">
                                                                        <p className="fs-13 text-center mb-3 mx-auto mt-1 noselect">
                                                                            {t('filterTypePage.competeLowestPrice')}
                                                                        </p>
                                                                    </div>
                                                                    <div className="row m-0 p-0">
                                                                        <DrInput id={("input_1")} key={("input_1")} value="1" checked={values.filterTypeSelection === "1"}
                                                                            className="custom-control-input" placeholder="" label="Filter Type" name="filterTypeSelection" type="checkbox"
                                                                            onChange={(e) => {
                                                                                setFieldValue("filterTypeSelection", "1");
                                                                                dispatch(withActionPromise(setSmartPricerTemplateQuestions(templateLowestStrategy.smartpricerQuestions)));
                                                                            }}
                                                                            onBlur={() => { setTouched({ ...touched, 'filterTypeSelection': true }) }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="col-auto px-1 d-flex">
                                                        <label htmlFor={("input_2")}
                                                            className={`card card-pricing custom-card border border-gray-300 selectable ${values.filterTypeSelection === "2" ? "active" : ""}`} >
                                                            <div className="card-body row " style={{ maxWidth: '285px', minWidth: '285px', minHeight: '240px' }}>
                                                                <div className="col-12 px-0">
                                                                    <div className="row m-0 p-0">
                                                                        <div className="bg-transparent border-0 m-auto">
                                                                            <div className="pricing-title text-start tx-bold noselect">{t('filterTypePage.buyBox')}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row m-0 px-0 py-3">
                                                                        <img src={buyboxLogo} alt="img" className="img-md m-auto p-0 border-0 bg-transparent noselect" ></img>
                                                                    </div>
                                                                    <div className="row m-0 p-0">
                                                                        <p className="fs-13 text-center mb-3 mx-auto mt-1 noselect">
                                                                            {t('filterTypePage.competeBuyBox')}
                                                                        </p>
                                                                    </div>
                                                                    <div className="row m-0 p-0">
                                                                        <DrInput id={("input_2")} key={("input_2")} value="2" checked={values.filterTypeSelection === "2"}
                                                                            className="custom-control-input" placeholder="" label="Filter Type" name="filterTypeSelection" type="checkbox"
                                                                            onChange={(e) => {
                                                                                setFieldValue("filterTypeSelection", "2");
                                                                                dispatch(withActionPromise(setSmartPricerTemplateQuestions(templateBuyboxStrategy.smartpricerQuestions)));
                                                                            }}
                                                                            onBlur={() => { setTouched({ ...touched, 'filterTypeSelection': true }) }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="col-auto px-1 d-flex">
                                                        <label htmlFor={("input_4")}
                                                            className={`card card-pricing custom-card selectable border border-gray-300 ${values.filterTypeSelection === "4" ? "active" : ""}`} >
                                                            <div className="card-body row " style={{ maxWidth: '285px', minWidth: '285px', minHeight: '240px' }}>
                                                                <div className="col-12 px-0">
                                                                    <div className="row m-0 p-0">
                                                                        <div className="bg-transparent border-0 m-auto">
                                                                            <div className="pricing-title text-start tx-bold noselect">{t('filterTypePage.customPreferences')}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row m-0 px-0 py-3">
                                                                        <img src={customLogo} alt="img" className="img-md m-auto p-0 border-0 bg-transparent noselect" ></img>
                                                                    </div>
                                                                    <div className="row m-0 p-0">
                                                                        <p className="fs-13 text-center mb-3 mx-auto mt-1 noselect">
                                                                            {t('filterTypePage.createCustomPreferences')}
                                                                        </p>
                                                                    </div>
                                                                    <div className="row m-0 p-0">
                                                                        <DrInput id={("input_4")} key={("input_4")} value="4" checked={values.filterTypeSelection === "4"}
                                                                            className="custom-control-input" placeholder="" label="Filter Type" name="filterTypeSelection" type="checkbox"
                                                                            onChange={(e) => {
                                                                                setFieldValue("filterTypeSelection", "4");
                                                                                dispatch(withActionPromise(setSmartPricerQuestions([])));
                                                                                dispatch(withActionPromise(setSmartPricerTemplateQuestions(null)));
                                                                            }}
                                                                            onBlur={() => { setTouched({ ...touched, 'filterTypeSelection': true }) }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    {
                                        <DrNavigator backAddress="" nextAddress="SMARTPRICER_FILTERS" disabled={!isValid}
                                            callback={() => {
                                                return Promise.all([
                                                    dispatch(withActionPromise(dispatch(setSmartPricerFilterType(values.filterTypeSelection)))),
                                                    dispatch(withActionPromise(dispatch(setSmartPricerPageSubmission({ filterTypeSelectionPage: true }))))
                                                ]);
                                            }}
                                        />
                                    }
                                </>
                            }
                            {!selectedStore &&
                                <>
                                    <div className="row row-md d-flex justify-content-center">
                                        <div className="col-sm-8 col-lg-12 col-md-8">
                                            <div className="card custom-card card-body shadow-none">
                                                {customerStores && customerStores.length > 0 && <h4>Lütfen Mağaza seçiniz!</h4>}
                                                {(!customerStores || (customerStores && customerStores.length === 0)) &&

                                                    <button className="btn btn-teal-ghost btn-wave waves-effect waves-light shadow" onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(clearCreateStoreInformationState());
                                                        navigate(getRouteByKey("MARKET_SELECTION"));
                                                    }}

                                                    >
                                                        <i className="me-2 fe fe-plus" />
                                                        Add New Store
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </form >
                    </div >
                </>
            )
            }
        </Formik >
    )
}

export default SmartPricerFilterSelectionPage;