import React from 'react'
import { useSelector } from 'react-redux';
import { ERRORS } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function ErrorPage({ customError }) {
  //   const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const { t } = useTranslation('errorPage');
  const appError = useSelector(state => state.app.appError)
  let error = null;

  if (customError) {
    error = customError;
  }
  else if (!appError) {
    error = ERRORS.pageNotFoundError;
  }
  else {
    switch (appError.status) {

      default:
        error = ERRORS.unexpectedError;
        break;
    }
  }

  return (

    // <div className="text-center max-h-full">
    //   <img src={error.image} alt="error" className="w-1/2 align-middle inline-block" />
    //   <h5 className="text-xl mt-2">{error.description} </h5>
    //   {error === ERRORS.unexpectedError && <h6>{appError.data?.message}</h6>}
    // </div>
    <>
      {(error === ERRORS.pageNotFoundError) &&
        <div className="page error-bg main-error-wrapper">
          <div className="row align-items-center d-flex flex-row">
            <div className="col-lg-6 pe-lg-4 tx-lg-right">
              <h1 className="display-1 mb-0">{t('pagenotfound.code')}</h1>
            </div>
            <div className="col-lg-6 tx-lg-left">
              <h2>{t('pagenotfound.title')}</h2>
              <h6>{t('pagenotfound.description')}</h6>
              <a className="btn ripple btn-primary text-center" href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/home")
                }}
              >
                {t('backtohome')}</a>
            </div>
          </div>
        </div>
      }
      {(error === ERRORS.unexpectedError) &&
        <div className="error-page error-bg main-error-wrapper">
          <div className="row align-items-center d-flex flex-row">
            <div>
              <div className="display-1 mb-2 mt-5">
                {t('unexpectederror.oops')}
              </div>
              <div className="h2 mb-6">{t('unexpectederror.title')}</div>
              <h6 className="tx-inverse">
                {t('unexpectederror.description')}
              </h6>
              <a className="btn ripple btn-primary mt-4 mb-4" href="/" onClick={(e) => {
                e.preventDefault();
                navigate("/home")
              }}
              >{t('backtohome')}</a>
            </div>
          </div>
        </div>
      }
    </>



  )
}

export default ErrorPage;