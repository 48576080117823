import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrInput } from '../../components/component-index';
import { updateListMockData } from '../../utils/mocks-data';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { timeOptions } from '../../components/hoc/Constants/dr-constants.component'
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductHistoryList } from '../../state-management/actions/inventory.actions';
import { setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { modals } from '../../utils/constants';
import moment from 'moment';


;

function UpdateListPage() {
  const navigate = useNavigate();
    const dispatch = useDispatch();
  const { t } = useTranslation('updatelistPage');
  const selectedStore = useSelector(state => state.stores.selectedStore);
  const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);
  const productHistoryList = useSelector(state => state.inventory.productHistoryList);
  const [tableData, setTableData] = useState([]);


  const initialValues = {
    asin: ''
};

  const columns = [
    { data: "productPicture", title: '', minWidth: "80px", searchable: false },
    { data: "productName", title: t('dataTable.productName'), minWidth: "300px", searchable: true },
    { data: "productSerialNumber", title: t('dataTable.productSerialNumber'), minWidth: "105px", searchable: true },
    { data: "brandName", title: t('dataTable.brandName'), minWidth: "105px", searchable: true },
    { data: "categoryName", title: t('dataTable.categoryName'), minWidth: "180px", searchable: true },
    { data: "desi", title: t('dataTable.desi'), minWidth: "105px", searchable: true },
    { data: "price", title: t('dataTable.price'), minWidth: "160px", searchable: true },
    { data: "quantity", title: t('dataTable.quantity'), minWidth: "105px", searchable: true },
    { data: "isFavourite", title: t('dataTable.isFavourite'), minWidth: "145px", searchable: true },
    { data: "isApprove", title: t('dataTable.isApprove'), minWidth: "145px", searchable: true },
    { data: "updatedDate", title: t('dataTable.updatedDate'), minWidth: "180px", searchable: true },
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: true,
      className: "text-start position-relative",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <img src={rowData.productPicture} alt="img" className="img-thumbnail" data-tag="pic-modal-opener"
            onClick={(e) => {
              e.preventDefault();
              if (rowData.productPicture) {
                dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [rowData.productPicture] }))
              }
            }}
          ></img>
        )
      }
    },
    {
      targets: 1,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1 text-wrap">{rowData.productName}</span><br />
          </>);
      }
    },
    {
      targets: 2,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.productSerialNumber}</span><br />
          </>);
      }
    },
    {
      targets: 3,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.brandName}</span><br />
          </>);
      }
    },
    {
      targets: 4,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="badge bg-info">{rowData.categoryName}</span><br />
          </>
        );
      }
    },
    {
      targets: 5,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{Number(rowData.desi).toFixed(2)}</span><br />
          </>);
      }
    },
    {
      targets: 6,
      orderable: true,
      searchable: false,
      className: "text-start",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div className="d-flex flex-wrap">
            <div className="row justify-content-start w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-success fs-11">{t('dataTable.price')}: {selectedStoreCurrencyType.currencyCode} {Number(rowData.price).toFixed(2)}</span>
              </div>
            </div>
            <div className="row justify-content-start w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-success fs-11">{t('dataTable.profit')}(%): {Number(rowData.profitPercent).toFixed(2)}%</span>
              </div>
            </div>
            <div className="row justify-content-start w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-success fs-11">{t('dataTable.profit')}: {selectedStoreCurrencyType.currencyCode} {Number(rowData.profitPrice).toFixed(2)}</span>
              </div>
            </div>
            <div className="row justify-content-start w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-danger fs-11">{t('dataTable.amazonFee')}(%): {Number(rowData.amazonFeePercent).toFixed(2)}%</span>
              </div>
            </div>
            <div className="row justify-content-start w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-danger fs-11">{t('dataTable.amazonFee')}: {selectedStoreCurrencyType.currencyCode} {Number(rowData.amazonFeePrice).toFixed(2)}</span>
              </div>
            </div>
            <div className="row justify-content-start w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-danger fs-11">{t('dataTable.warehousePrice')}: {selectedStoreCurrencyType.currencyCode} {Number(rowData.warehousePrice).toFixed(2)}</span>
              </div>
            </div>
            <div className="row justify-content-start w-90 ms-1">
              <div className="col-12 px-0">
                <span className="text-end badge rounded-pill bg-success fs-11">{t('dataTable.sellingPrice')}: {selectedStoreCurrencyType.currencyCode} {Number(rowData.sellingPrice).toFixed(2)}</span>
              </div>
            </div>
          </div>
        );
      }
    },
    {
      targets: 7,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.quantity}</span><br />
          </>);
      }
    },
    {
      targets: 8,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{
              rowData.isFavourite === true ?
                <span className="badge bg-success">{t('dataTable.true')}</span>
                :
                <span className="badge bg-danger">{t('dataTable.false')}</span>
            }</span><br />
          </>);
      }
    },
    {
      targets: 9,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{
              rowData.isApprove === true ?
                <span className="badge bg-success">{t('dataTable.true')}</span>
                :
                <span className="badge bg-danger">{t('dataTable.false')}</span>
            }</span><br />
          </>);
      }
    },
    {
      targets: 10,
      orderable: true,
      className: "text-start",
      createdCell: (td, cellData, rowData) => {
          ReactDOM.createRoot(td).render(
              <span className="mb-0 mt-1 fs-14 px-1 py-1">{moment(rowData.updatedDate).format('L LT')}</span>
          );
      }
  }
  ];

  const optionClickCallBack = (value) => {

  };

  const handleSubmit = async (values, { resetForm }) => {
    setTableData([]);
    dispatch(withActionPromise(getProductHistoryList({ customerMainSellerId: selectedStore.customerMainSellerId, asin: values.asin, skip: 0, pageSize: 1000 }))).then((response) => {

        setTableData(productHistoryList);

    });
      
};

  return (
    <div style={{ marginBottom: '150px' }}>
      <DrBreadCrump
        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
        { navigationText: i18next.t('constants:breadCrumps.updateHistory'), navigationPath: "" }]} >
      </DrBreadCrump>
      <div id="inventoryTable" className="row mb-5">
        <div className="col-lg-12 col-xl-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body horizontal-scrollable">
              {tableData &&
                <>
                  <Formik
                    initialValues={initialValues}
                    validateOnBlur={true}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                    validateOnMount={true}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isValid,
                      setFieldValue
                    }) => (
                      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(values, { resetForm: () => setFieldValue('asin', '') }); }}>
                        <div className="row row-md row-lg justify-content-center">
                          <div className="col-12 px-0">
                            <div className="form-group">
                              <DrInput
                                value={values.asin}
                                className="form-control"
                                placeholder={t('searchAsinText')}
                                name="asin"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                validationText={errors.asin}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row row-md row-lg justify-content-end mb-3 mt-2">
                          <div className="col-auto px-0">
                            <button
                              className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid && values.asin !== '' ? "" : "disabled"}`}
                              type="submit"
                            >
                              {t('searchAsinButton')}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <DrDataTable
                    data={tableData}
                    columns={columns}
                    columnDefs={columnDefs}
                    doesHaveBorder={false}
                    headerSearch={true}
                    //headerSelectbox={true}
                    //search={true}
                    minWidth="850px"
                  />
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default UpdateListPage