import { call, put, takeLatest, all } from 'redux-saga/effects'

import { apiGet, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getAgreementsSuccess } from '../actions/agreement.action';


const { REACT_APP_HOST_URL } = process.env;

function* runGetAgreements() {
    const response = yield call(apiGet(``, CONTROLLERS.AGREEMENT, true, true));

    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(getAgreementsSuccess(response.data));
    }
}

export default function* agreementSaga() {
    yield all([
        takeLatest(types.GET_AGREEMENT_REQUESTED, safe(runGetAgreements))
    ]);
}

