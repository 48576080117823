import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable } from '../../components/component-index';
import { duplicateMockData } from '../../utils/mocks-data';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { timeOptions } from '../../components/hoc/Constants/dr-constants.component';

function DuplicatePage() {
  const { t } = useTranslation('duplicatePage');
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(duplicateMockData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { data: "productName", title: t('dataTable.productName'), width: "100px", searchable: true },
    { data: "asin", title: t('dataTable.asin'), width: "125px", searchable: true },
    { data: "sku", title: t('dataTable.sku'), width: "5%", searchable: true },
    { data: "category", title: t('dataTable.category'), width: "5%", searchable: true },
    { data: "updateDate", title: t('dataTable.updateDate'), width: "20%", searchable: true },
    { data: null, title: "", width: "20%", searchable: false }
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: true,
      className: "text-center",
      width: "20%",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.productName}</span><br />
          </>)
      }
    },
    {
      targets: 1,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.asin}</span><br />
          </>);
      }
    },
    {
      targets: 2,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.sku}</span><br />
          </>);
      }
    },
    {
      targets: 3,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div className="text-center" style={{ fontSize: '16px' }}>
            <span className="badge bg-info">{rowData.category}</span><br />
          </div>);
      }
    },
    {
      targets: 4,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.updateDate}</span><br />
          </>);
      }
    },
    {
      targets: 5,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div>

            <span id="droprightMenuButton" className="text-muted" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18 cursor-pointer"></i></span>
            <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>
              <span className="dropdown-item d-flex align-items-center cursor-pointer">
                Detayları görüntüleyin
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer">
                Güncelleme geçmişini görüntüleyin
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer">
                Amazon.com'da görüntüleyin
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer">
                Amazon.ca'da görüntüleyin
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer">
                Amazon.ca'da teklifleri görüntüleyin
              </span>
            </div>
          </div>)
      }
    }
  ];

  const optionClickCallBack = (value) => {
   
  };

  return (
    <div style={{ marginBottom: '150px' }}>
      <div className="page-header mb-0 mt-4 mb-2 row row-md row-lg justify-content-between w-100">
      <DrBreadCrump
            breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
            { navigationText: i18next.t('constants:breadCrumps.duplicateListings'), navigationPath: "" }]} >
          </DrBreadCrump>
        
        
        {/* <div className="col-lg-auto col-md-auto col-sm-auto col-xs-12 px-0">
          <DrButtonDropdown className="shadow" ptions={timeOptions} optionClickCallBack={optionClickCallBack}>
          </DrButtonDropdown>
        </div> */}
      </div>
      <div id="inventoryTable" className="row mb-5">
        <div className="col-lg-12 col-xl-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body horizontal-scrollable">
              {tableData && <>
                <DrDataTable
                  data={tableData}
                  columns={columns}
                  columnDefs={columnDefs}
                  doesHaveBorder={false}
                  headerSearch={true}
                  //headerSelectbox={true}
                  //search={true}
                  minWidth="850px"
                />
              </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DuplicatePage