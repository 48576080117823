const types = {
    CLEAR_SETTINGS: "CLEAR_SETTINGS",
    SET_LANGUAGE: "SET_LANGUAGE",
    SET_THEME: "SET_THEME",
    SET_LAST_VISITED_MARKET: "SET_LAST_VISITED_MARKET",
    SET_LAST_VISITED_PAGE: "SET_LAST_VISITED_PAGE",
    SET_SELECTED_STORE_CURRENCY_CODE_COEFFICIENT: "SET_SELECTED_STORE_CURRENCY_CODE_COEFFICIENT",
    SET_SEARCH_TASK_PAGE_SELECTIONS: "SET_SEARCH_TASK_PAGE_SELECTIONS",
    SET_HOME_PAGE_SELECTIONS: "SET_HOME_PAGE_SELECTIONS",
    SET_ORDER_PAGE_SELECTIONS: "SET_ORDER_PAGE_SELECTIONS",
    SET_WAREHOUSE_ORDER_PAGE_SELECTIONS: "SET_WAREHOUSE_ORDER_PAGE_SELECTIONS",
    SET_WAREHOUSE_HOME_PAGE_SELECTIONS: "SET_WAREHOUSE_HOME_PAGE_SELECTIONS",
    SET_MY_STORE_PAGE_SELECTIONS: "SET_MY_STORE_PAGE_SELECTIONS",
    SET_MY_WALLET_PAGE_SELECTIONS: "SET_MY_WALLET_PAGE_SELECTIONS",
    SET_INVENTORY_PAGE_SELECTIONS: "SET_INVENTORY_PAGE_SELECTIONS",
    CLEAR_PAGE_SELECTIONS: "CLEAR_PAGE_SELECTIONS"
}

export default types;

export const clearSettings = () => {
    return { type: types.CLEAR_SETTINGS };
};

export const setLanguage = (payload) => {
    return { type: types.SET_LANGUAGE, payload };
};

export const setTheme = (payload) => {
    return { type: types.SET_THEME, payload };
};

export const setLastVisitedMarket = (payload) => {
    return { type: types.SET_LAST_VISITED_MARKET, payload };
};

export const setCurrencyCoefficient = (payload) => {
    return { type: types.SET_SELECTED_STORE_CURRENCY_CODE_COEFFICIENT, payload };
};

export const setLastVisitedPage = (payload) => {
    return { type: types.SET_LAST_VISITED_PAGE, payload };
};

export const setSearchTasksPageSelections = (payload) => {
    return { type: types.SET_SEARCH_TASK_PAGE_SELECTIONS, payload };
};

export const setHomePageSelections = (payload) => {
    return { type: types.SET_HOME_PAGE_SELECTIONS, payload };
};

export const setOrderPageSelections = (payload) => {
    return { type: types.SET_ORDER_PAGE_SELECTIONS, payload };
};

export const setWarehouseOrderPageSelections = (payload) => {
    return { type: types.SET_WAREHOUSE_ORDER_PAGE_SELECTIONS, payload };
};

export const setWarehouseHomePageSelections = (payload) => {
    return { type: types.SET_WAREHOUSE_HOME_PAGE_SELECTIONS, payload };
};


export const setMyStorePageSelections = (payload) => {
    return { type: types.SET_MY_STORE_PAGE_SELECTIONS, payload };
};

export const setMyWalletPageSelections = (payload) => {
    return { type: types.SET_MY_WALLET_PAGE_SELECTIONS, payload };
};

export const setInventoryPageSelections = (payload) => {
    return { type: types.SET_INVENTORY_PAGE_SELECTIONS, payload };
};

export const clearPageSelections = () => {
    return { type: types.CLEAR_PAGE_SELECTIONS };
};