
import { useTranslation } from 'react-i18next';
import { DrBreadCrump, DrInput, DrFilePondUploadInput } from '../../components/component-index';
import i18next from 'i18next';
import { Field, Formik } from "formik";
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getCustomerProfileInfo, upsertCustomerProfileInfo } from "../../state-management/actions/customer.actions";
import { withActionPromise } from '../../state-management/actions/app.actions';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { useNavigate } from 'react-router-dom';
import { getRouteByKey } from '../../routes';


function ProfilePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('profilepage');
    const customerProfileInfo = useSelector(state => state.customer.customerProfileInfo);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const phoneNumberRegex = /^\+?1?\d{10,15}$/;

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(getCustomerProfileInfo(selectedStore.customerMainSellerId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const initialValues = {
        customerMainSellerId: selectedStore?.customerMainSellerId || null,
        personName: customerProfileInfo?.personName || '',
        personCompanyName: customerProfileInfo?.personCompanyName || '',
        amazonStoreName: customerProfileInfo?.amazonStoreName || '',
        phoneNumber: customerProfileInfo?.phoneNumber || '',
        emailAddress: customerProfileInfo?.emailAddress || '',
        companyName: customerProfileInfo?.companyName || '',
        addressLine1: customerProfileInfo?.addressLine1 || '',
        addressLine2: customerProfileInfo?.addressLine2 || '',
        city: customerProfileInfo?.city || '',
        stateOrRegion: customerProfileInfo?.stateOrRegion || '',
        country: customerProfileInfo?.country || '',
        postCode: customerProfileInfo?.postCode || '',
        companyPhoneNumber: customerProfileInfo?.companyPhoneNumber || '',
        storeLogosu: customerProfileInfo?.storeLogosu || '',
    };

    const infoSchema = Yup.object().shape({
        personName: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Sender name is required'),

        personCompanyName: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Sender company name is required'),

        amazonStoreName: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Sender store name is required'),

        phoneNumber: Yup.string()
            .matches(phoneNumberRegex, 'Invalid phone number format')
            .min(10, 'Too Short!')
            .max(13, 'Too Long!'),

        emailAddress: Yup.string().email('Invalid email').required('Email is required'),

        companyName: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Company name is required'),

        addressLine1: Yup.string()
            .min(3, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Company address line one is required'),

        addressLine2: Yup.string()
            .min(3, 'Too Short!')
            .max(100, 'Too Long!'),

        city: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('City is required'),

        stateOrRegion: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!'),

        country: Yup.string()
            .min(3, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Country is required'),

        postCode: Yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Zip code is required'),

        companyPhoneNumber: Yup.string()
            .matches(phoneNumberRegex, 'Invalid phone number format')
            .min(10, 'Too Short!')
            .max(13, 'Too Long!')
            .required('Phone number is required'),

    });

    const handleSubmit = async (values) => {
        dispatch(upsertCustomerProfileInfo(values));
    }

    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            onSubmit={handleSubmit}
            validationSchema={infoSchema}
            enableReinitialize={true}
            validateOnMount={true}
        >
            {(
                {
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    setTouched,
                    setFieldValue,
                }
            ) => (
                <div >
                    <DrBreadCrump
                        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.logisticsManagement'), navigationPath: "" },
                        { navigationText: i18next.t('constants:breadCrumps.profile'), navigationPath: "" }]} >
                    </DrBreadCrump>

                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-xl-12 col-md-12">
                            <div className="card custom-card">
                                <div className="card-body">
                                    {selectedStore?.customerMainSellerId.length > 0 &&
                                        <>
                                            <p className="alert alert-danger">
                                                Gönderileriniz ile ilgili önemli ve acil durumlarda SellerDoping
                                                House anlaşmalı depo tarafından bilgilendirilmek için "
                                                <a href="/settings/notifications">Bildirimler</a>" sayfasından
                                                telefon numaranızı girmenizi öneririz.
                                            </p>
                                            <div className="row justify-content-center">
                                                <ul className="nav nav-tabs tab-style-2 justify-content-center mb-4" role="tablist" style={{ width: 'auto' }}>
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link active" data-bs-toggle="tab" data-bs-target="#sender" aria-current="page" href="#sender" aria-selected="true" role="tab">{t('sender')}</a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#company" href="#company" aria-selected="false" role="tab" tabIndex="-1">{t('company')}</a>
                                                    </li>

                                                </ul>
                                                <div className='tab-content'>
                                                    <div className="tab-pane active show" id="sender" role="tabpanel"> <div className="d-flex justify-content-center text-muted" >
                                                        <div className="col-12 col-md-6" >
                                                            <h2 className="text-center pb-3">
                                                                {t('senderInfoText')}
                                                            </h2>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="SenderPersonName"
                                                                >
                                                                    {t('personName')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.personName}
                                                                        className="form-control"
                                                                        placeholder={t('personName')}
                                                                        name="personName"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'personName': true }) }}
                                                                        isInvalid={(touched.personName && errors.personName)}
                                                                        isValid={touched.personName && !errors.personName}
                                                                        validationText={errors.personName}
                                                                        icon={<span className="fe fe-edit-3" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="personCompanyName"
                                                                >
                                                                    {t('companyName')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.personCompanyName}
                                                                        className="form-control"
                                                                        placeholder={t('companyName')}
                                                                        name="personCompanyName"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'personCompanyName': true }) }}
                                                                        isInvalid={(touched.personCompanyName && errors.personCompanyName)}
                                                                        isValid={touched.personCompanyName && !errors.personCompanyName}
                                                                        validationText={errors.personCompanyName}
                                                                        icon={<span className="fe fe-edit-3" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="amazonStoreName"
                                                                >
                                                                    {t('amazonStoreName')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.amazonStoreName}
                                                                        className="form-control"
                                                                        placeholder={t('amazonStoreName')}
                                                                        name="amazonStoreName"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'amazonStoreName': true }) }}
                                                                        isInvalid={(touched.amazonStoreName && errors.amazonStoreName)}
                                                                        isValid={touched.amazonStoreName && !errors.amazonStoreName}
                                                                        validationText={errors.amazonStoreName}
                                                                        icon={<span className="fe fe-edit-3" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="phoneNumber"
                                                                >
                                                                    {t('phoneNumber')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.phoneNumber}
                                                                        className="form-control"
                                                                        placeholder={t('phoneNumber')}
                                                                        name="phoneNumber"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'phoneNumber': true }) }}
                                                                        isInvalid={(touched.phoneNumber && errors.phoneNumber)}
                                                                        isValid={touched.phoneNumber && !errors.phoneNumber}
                                                                        validationText={errors.phoneNumber}
                                                                        icon={<span className="fe fe-phone" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="emailAddress"
                                                                >
                                                                    {t('emailAdress')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.emailAddress}
                                                                        className="form-control"
                                                                        placeholder={t('emailAdress')}
                                                                        name="emailAddress"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'emailAddress': true }) }}
                                                                        isInvalid={(touched.emailAddress && errors.emailAddress)}
                                                                        isValid={touched.emailAddress && !errors.emailAddress}
                                                                        validationText={errors.emailAddress}
                                                                        icon={<span className="fe fe-mail" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label"
                                                                    htmlFor="SenderStoreLogoName"
                                                                >
                                                                    {t('storeLogo')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <div className="row mt-2">
                                                                        <div className="col-12">
                                                                            <Field onBlur={() => { setTouched({ ...touched.pictures, 'pictures': true }) }}
                                                                                name={'pictures'}
                                                                                component={DrFilePondUploadInput}
                                                                                placeholder={t('selectFile')}
                                                                                selectedFiles={values.pictures}
                                                                                accept={['image/png']}
                                                                                maxFiles={1}
                                                                                maxFileSize={1024000}
                                                                                removeFileTypeStringFromContent={false}
                                                                                allowMultiple={true}
                                                                                labelIdle={t('fileuploadLabel')}
                                                                                onChange={e => {
                                                                                    // Set currently active file objects to this.state
                                                                                    // setFieldValue("pictures", e.filePondItems);
                                                                                    if (e.returnValueItems.length > 0) {
                                                                                        setFieldValue("storeLogosu", e.returnValueItems[0].attachment || "");
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <small>
                                                                {t('storeLogoInfo')}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="tab-pane" id="company" role="tabpanel"> <div className="d-flex justify-content-center text-muted">
                                                        <div className="col-12 col-md-6">
                                                            <h2 className="text-center pb-3">
                                                                {t('companyInfoText')}
                                                            </h2>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="BusinessName"
                                                                >
                                                                    {t('companyPersonName')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.companyName}
                                                                        className="form-control"
                                                                        placeholder={t('companyPersonName')}
                                                                        name="companyName"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'companyName': true }) }}
                                                                        isInvalid={(touched.companyName && errors.companyName)}
                                                                        isValid={touched.companyName && !errors.companyName}
                                                                        validationText={errors.companyName}
                                                                        icon={<span className="fe fe-edit-3" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="BusinessAddressLine1"
                                                                >
                                                                    {t('adressLine1')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.addressLine1}
                                                                        className="form-control"
                                                                        placeholder={t('adressLine1')}
                                                                        name="addressLine1"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'addressLine1': true }) }}
                                                                        isInvalid={(touched.addressLine1 && errors.addressLine1)}
                                                                        isValid={touched.addressLine1 && !errors.addressLine1}
                                                                        validationText={errors.addressLine1}
                                                                        icon={<span className="fe fe-home" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="BusinessAddressLine2"
                                                                >
                                                                    {t('adressLine2')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.addressLine2}
                                                                        className="form-control"
                                                                        placeholder={t('adressLine2')}
                                                                        name="addressLine2"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'addressLine2': true }) }}
                                                                        isInvalid={(touched.addressLine2 && errors.addressLine2)}
                                                                        isValid={touched.addressLine2 && !errors.addressLine2}
                                                                        validationText={errors.addressLine2}
                                                                        icon={<span className="fe fe-home" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="BusinessCity"
                                                                >
                                                                    {t('city')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.city}
                                                                        className="form-control"
                                                                        placeholder={t('city')}
                                                                        name="city"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'city': true }) }}
                                                                        isInvalid={(touched.city && errors.city)}
                                                                        isValid={touched.city && !errors.city}
                                                                        validationText={errors.city}
                                                                        icon={<span className="fe fe-map-pin" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="BusinessState"
                                                                >
                                                                    {t('stateOrTerritory')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.stateOrRegion}
                                                                        className="form-control"
                                                                        placeholder={t('stateOrTerritory')}
                                                                        name="stateOrRegion"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'stateOrRegion': true }) }}
                                                                        isInvalid={(touched.stateOrRegion && errors.stateOrRegion)}
                                                                        isValid={touched.stateOrRegion && !errors.stateOrRegion}
                                                                        validationText={errors.stateOrRegion}
                                                                        icon={<span className="fe fe-map-pin" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="BusinessCountry"
                                                                >
                                                                    {t('country')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.country}
                                                                        className="form-control"
                                                                        placeholder={t('country')}
                                                                        name="country"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'country': true }) }}
                                                                        isInvalid={(touched.country && errors.country)}
                                                                        isValid={touched.country && !errors.country}
                                                                        validationText={errors.country}
                                                                        icon={<span className="fe fe-map-pin" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="BusinessZipCode"
                                                                >
                                                                    {t('postCode')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.postCode}
                                                                        className="form-control"
                                                                        placeholder={t('postCode')}
                                                                        name="postCode"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'postCode': true }) }}
                                                                        isInvalid={(touched.postCode && errors.postCode)}
                                                                        isValid={touched.postCode && !errors.postCode}
                                                                        validationText={errors.postCode}
                                                                        icon={<span className="fe fe-hash" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <label
                                                                    className="col-sm-4 col-form-label text-right"
                                                                    htmlFor="BusinessPrimaryPhone"
                                                                >
                                                                    {t('phoneNumber')}
                                                                </label>
                                                                <div className="col-sm-8">
                                                                    <DrInput
                                                                        value={values.companyPhoneNumber}
                                                                        className="form-control"
                                                                        placeholder={t('phoneNumber')}
                                                                        name="companyPhoneNumber"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'companyPhoneNumber': true }) }}
                                                                        isInvalid={(touched.companyPhoneNumber && errors.companyPhoneNumber)}
                                                                        isValid={touched.companyPhoneNumber && !errors.companyPhoneNumber}
                                                                        validationText={errors.companyPhoneNumber}
                                                                        icon={<span className="fe fe-phone" />}
                                                                        iconPosition="right"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="justify-content-center text-center mt-4">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            data-toggle="disabled-button-if-valid"
                                                            data-is-loading="true"
                                                            data-form-id="FormSettingSHProfile"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (isValid) {
                                                                    handleSubmit(values);
                                                                }
                                                            }}
                                                        >
                                                            {t('save')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {!selectedStore &&
                                        <div className="row row-md row-lg justify-content-center">
                                            <div className="col-lg-auto col-md-2">
                                                <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        Promise.all([
                                                            dispatch(withActionPromise(clearCreateStoreInformationState()))
                                                        ]).then((e) => {
                                                            navigate(getRouteByKey("MARKET_SELECTION"));
                                                        });;

                                                    }}>
                                                    <i className="me-2 fe fe-chevrons-right"></i>
                                                    {t('gotoMarketSelection')}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            )
            }

        </Formik>
    );

}
export default ProfilePage
