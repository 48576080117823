import './introduction.scss'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { DrBreadCrump } from '../../components/component-index';

function PrivacyAndPolicyPage() {
  const { t } = useTranslation('privacyAndPolicyPage');

  return (
    <>
      <div id="introductiondiv">
        <div className="col-auto">
          <DrBreadCrump
            breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.privacyAndPolicy'), navigationPath: "" }]} >
          </DrBreadCrump>
        </div>
        <main id="main">
          <section className="inner-page">
            <div className="container">
              <p style={{ lineHeight: "normal", background: "white" }} >
                <span lang="TR"
                  style={{
                    fontSize: "24.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('gizlilikpolitikası')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('59')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('60')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('61')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('62')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('63')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151",

                  }}
                >
                  {t('130')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('64')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('65')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('66')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpFirst"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: "Wingdings",
                    color: "#334151",
                    marginLeft: "25px"
                  }}
                >
                  §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
                </span>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('67')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: "Wingdings",
                    color: "#334151",
                    marginLeft: "25px"
                  }}
                >
                  §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
                </span>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('68')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: "Wingdings",
                    color: "#334151",
                    marginLeft: "25px"
                  }}
                >
                  §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
                </span>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('69')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: "Wingdings",
                    color: "#334151",
                    marginLeft: "25px"
                  }}
                >
                  §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
                </span>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151",

                  }}
                >
                  {t('70')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpLast"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: "Wingdings",
                    color: "#334151",
                    marginLeft: "25px"
                  }}
                >
                  §<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
                </span>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('71')}
                  <br />
                  <br />
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('72')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontFamily: '"Arial",sans-serif',
                    color: "#41494F",
                    background: "white"
                  }}
                >
                  {" "}
                </span>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('73')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontFamily: '"Arial",sans-serif',
                      color: "#41494F",
                      background: "white"
                    }}
                  >
                    {t('74')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontFamily: '"Arial",sans-serif',
                    color: "#41494F",
                    background: "white"
                  }}
                >
                  {" "}
                </span>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('75')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontFamily: '"Arial",sans-serif',
                      color: "#41494F",
                      background: "white"
                    }}
                  >
                    {t('76')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('77')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontFamily: '"Arial",sans-serif',
                      color: "#41494F",
                      background: "white"
                    }}
                  >
                    {t('78')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('79')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('80')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('81')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('82')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('83')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('84')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    &nbsp;
                  </span>
                </b>
              </p>
              <p
                className="MsoListParagraphCxSpFirst"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    1.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('85')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('86')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    2.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('87')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('88')}
                </span>
              </p>
              <p className="MsoListParagraphCxSpMiddle">
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    3.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('89')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('90')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    4.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('91')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  :
                </span>
                <span lang="TR" style={{ fontSize: "13.5pt", color: "black" }}>
                  {" "}
                </span>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('92')}
                </span>
                <span lang="TR" style={{ fontSize: "13.5pt", color: "black" }}>
                  &nbsp;
                </span>
              </p>
              <p className="MsoListParagraphCxSpMiddle">
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    5.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('93')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('94')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('95')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpLast"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('96')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpFirst"
                style={{ marginLeft: "0in", lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    1.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('97')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {" "}
                  {t('98')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    2.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('99')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {" "}
                  {t('100')}
                </span>
              </p>
              <p className="MsoListParagraphCxSpMiddle">
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    3.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('101')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >

                  {t('102')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    4.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('103')}
                  </span>
                </b>
                <span lang="TR" style={{ fontSize: "13.5pt", color: "black" }}>
                  {" "}
                </span>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('104')}
                </span>
                <span lang="TR" style={{ fontSize: "13.5pt", color: "black" }}>
                  &nbsp;
                </span>
              </p>
              <p className="MsoListParagraphCxSpMiddle">
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "115%",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    5.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('105')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {" "}
                  {t('106')}
                </span>
                <span lang="TR" style={{ fontSize: "13.5pt", color: "black" }}>
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151",
                      marginLeft: "25px"
                    }}
                  >
                    6.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('107')}
                  </span>
                </b>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('108')}
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ lineHeight: "normal", background: "white" }}
              >
                <span lang="TR" style={{ fontSize: "13.5pt", color: "black" }}>
                  &nbsp;
                </span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  textIndent: "-.25in",
                  lineHeight: "normal",
                  background: "white"
                }}
              >
                <b>
                  <span lang="TR" style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151",
                    marginLeft: "25px"
                  }}>
                    7.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </b>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      fontFamily: '"Arial",sans-serif',
                      color: "#334151"
                    }}
                  >
                    {t('109')}
                  </span>
                </b>
                <span lang="TR" style={{ fontSize: "13.5pt", color: "black" }}>
                  {" "}
                </span>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('110')}
                </span>
                <span lang="TR" style={{ fontSize: "13.5pt", color: "black" }}>
                  {" "}
                  <br />
                  &nbsp;
                </span>
              </p>

              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('111')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('112')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('113')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('114')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('115')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('116')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('117')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('118')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('119')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('120')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('121')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('122')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('123')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('124')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('125')}
                </span>
              </p>
              <p

                style={{ lineHeight: "normal", background: "white" }}
              >
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#334151"
                  }}
                >
                  {t('126')}
                </span>
              </p>
              <p >
                <span lang="TR">&nbsp;</span>
              </p>
            </div>
          </section>
        </main>

      </div>
    </>
  )
}

export default PrivacyAndPolicyPage;