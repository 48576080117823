const types = {
    GET_ALL_MARKETS_REQUESTED: "GET_ALL_MARKETS_REQUESTED",
    GET_ALL_MARKETS_SUCCESS: "GET_ALL_MARKETS_SUCCESS",
    CLEAR_ALL_MARKETS:"CLEAR_ALL_MARKETS"
}

export default types;

export const getAllMarkets = () => {
    return { type: types.GET_ALL_MARKETS_REQUESTED }
} 

export const getAllMarketsSuccess = (payload) => {
    return { type: types.GET_ALL_MARKETS_SUCCESS, payload };
};

export const clearAllMarkets = () => {
    return { type: types.CLEAR_ALL_MARKETS }
}