import types from "../actions/wallet.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_WALLET_INFO_SUCCESS:
            return { ...state, walletInfo: action.payload };
        case types.GET_WALLET_INFO_FAILED:
            return { ...state, walletInfo: null };
        case types.GET_TRANSACTION_SUMMARY_SUCCESS:
            return { ...state, transactionSummary: action.payload };
        case types.GET_TRANSACTION_SUMMARY_FAILED:
            return { ...state, transactionSummary: [] };
        case types.SET_SELECTED_CARD:
            return { ...state, selectedCreditCard: action.payload };
        case types.CLEAR_ALL_WALLET_DATA:
            return initialState;
        default:
            return state;
    }
}

const initialState = {
    "walletInfo": null,
    "transactionSummary": [],
    "selectedCreditCard": null
}

export default reducer;