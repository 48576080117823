import { useTranslation } from 'react-i18next';
import { DrBreadCrump, DrWarehouseSelection } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { getShippings, getWarehouseShippings, getWarehouses } from '../../state-management/actions/warehouses.actions';
import { staticWarehouseSelectionComponentKey } from '../../utils/constants';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { getShipStationCarrierServices, getShipStationCarriers, getShipStationWarehouses } from '../../state-management/actions/shipstation.actions';

// import { warehousesMockData  as allWarehouses} from '../../utils/mocks-data';

function GeneralPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('generalpage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const allWarehouses = useSelector(state => state.warehouses.allWarehouses);
    const allWarehouseShippings = useSelector(state => state.warehouses.allWarehouseShippings);
    const [warehousesData, setWarehousesData] = useState([]);
    const shipstationCarriers = useSelector(state => state.shipstation.shipstationCarriers);
    const shipstationCarrierServices = useSelector(state => state.shipstation.shipstationCarrierServices);

    useEffect(() => {
        dispatch(withActionPromise(getShippings()));
        dispatch(withActionPromise(getWarehouses()));
        dispatch(withActionPromise(getShipStationWarehouses()));

        if (!(shipstationCarriers && shipstationCarriers?.length > 0)) {
            dispatch(withActionPromise(getShipStationCarriers()));
        }

        if (selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getWarehouseShippings({ customerMainSellerId: selectedStore.customerMainSellerId })));
        } else {
            dispatch(withActionPromise(getWarehouseShippings()));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, []);

    useEffect(() => {
        if (allWarehouses) {
            let data = Object.assign([], allWarehouses);

            var carrierCodeArr = [];

            data.forEach(warehouse => {
                warehouse.warehouseShippings = Object.assign([], allWarehouseShippings && allWarehouseShippings.find(x => x.wareHouseId === warehouse.id)?.shippings);
                warehouse.customerMainSellerId = selectedStore?.customerMainSellerId;
                warehouse.wareHouseSuitId = allWarehouseShippings && allWarehouseShippings.find(x => x.wareHouseId === warehouse.id)?.wareHouseSuitId;
                warehouse.mapWareHouseId = warehouse.warehouseShippings && warehouse.warehouseShippings.length > 0 ? warehouse.warehouseShippings[0].mapWareHouseId : 0;

                var result = false;
                allWarehouses.filter(x => x.marketId === warehouse.marketId)?.forEach(w => {

                    allWarehouseShippings.find(x => x.wareHouseId === w.id)?.shippings.forEach(s => {
                        if (s.isDefault === true) {
                            result = true;
                            return result;
                        }
                    });
                });

                warehouse.isAnyWarehouseSuitIsDefault = result;
                // performance
                warehouse.warehouseShippings.forEach(shipping => {
                    carrierCodeArr = carrierCodeArr.filter(x => x.code !== shipping.carrierCode).concat({ code: shipping.carrierCode })
                });

            });

            // var promises = [];

            // if (carrierCodeArr?.length > 0) {
            //     carrierCodeArr.forEach(carrierCode => {
            //         if (!(shipstationCarrierServices?.length > 0 && shipstationCarrierServices?.find(x => x.carrierCode === carrierCode))) {
            //             var p = new Promise((resolve, reject) => {
            //                 dispatch(getShipStationCarrierServices(carrierCode.code))
            //             });
            //             // var p = new Promise.resolve(dispatch(withActionPromise(getShipStationCarrierServices(carrierCode.code))));
            //             promises.push(p);
            //         }

            //     });
            // }

            // if (promises?.length > 0) {
            //     console.log(promises)
            //     Promise.allSettled(promises).then((results) =>
            //         results.forEach((result) => console.log(result.status)),
            //       ).then((response) => {
            //         console.log("promises response" , response)

            //     });
            //     setWarehousesData(data);
            //     // Promise.all(promises).then((response) => {
            //     
            //     //     setWarehousesData(data);
            //     // });
            // }
            // else {
            //     setWarehousesData(data);
            // }

            setWarehousesData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWarehouses, allWarehouses?.length, allWarehouseShippings, allWarehouseShippings?.length]);

    return (
        <div >
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.logisticsManagement'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.general'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row row-md row-lg justify-content-center">
                                <div className="col-12">
                                    <p className="alert alert-danger text-center">
                                        Gönderileriniz ile ilgili önemli ve acil durumlarda SellerDoping
                                        anlaşmalı depo tarafından bilgilendirilmek için iletişime geçiniz
                                    </p>
                                </div>
                            </div>
                            <div className="row row-md row-lg justify-content-center">
                                <div className="col-sm-11 col-md-10 col-lg-8">
                                    <ul className="op-6  mb-0">
                                        <li>
                                            Aboneliğiniz boyunca{" "}
                                            <span className="badge rounded-pill bg-info">
                                                tamamen ücretsiz!
                                            </span>
                                        </li>
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt ut labore et dolore magna
                                            aliqua.
                                        </li>
                                        <li>
                                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea commodo consequat.
                                        </li>
                                        <li>
                                            Duis aute irure dolor in reprehenderit in voluptate velit
                                            esse cillum dolore eu fugiat nulla pariatur.
                                        </li>
                                        <li>
                                            Excepteur sint occaecat cupidatat non proident, sunt in
                                            culpa qui officia deserunt mollit anim id est laborum.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <h3 className="mb-3 mt-3 ms-3">
                                {t('pleaseSelectWarehouseFulfillmentServiceProvider')}
                            </h3>

                            <DrWarehouseSelection componentKey={staticWarehouseSelectionComponentKey.saveorupdateWarehouseSuit}
                                source={warehousesData}
                            ></DrWarehouseSelection>

                            <div className="card bg-light mt-3 mb-0 border">
                                <div className="card-body">
                                    <p className="mb-2">
                                        <i className="fe fe-home" /> Depo ile gönderimi etkili bir
                                        şekilde kullanma ipuçları
                                    </p>
                                    <ul className="small text-muted pl-4 mb-0">
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna
                                            aliqua.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* <div className="row row-md row-lg justify-content-center my-3 px-1">
                                            <div className="col-lg-auto col-md-2">
                                                <button className={`btn ${true ? "ripple btn-main-primary " : "btn-secondary"}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleSubmit(values.selectedIds);
                                                    }}
                                                >{t('save')}</button>
                                            </div>
                                        </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default GeneralPage

