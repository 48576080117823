const types = {
    SET_PACKAGES_SNAPSHOT_META: "SET_PACKAGES_SNAPSHOT_META",
    SET_MAIN_PACKAGE: "SET_MAIN_PACKAGE",
    SET_PACKAGE_FEATURES:"SET_PACKAGE_FEATURES",
    SET_CREDIT_CARD_INFORMATION: "SET_CREDIT_CARD_INFORMATION",
    
    CREATE_CUSTOMER_PACKAGES_REQUESTED: "CREATE_CUSTOMER_PACKAGES_REQUESTED",
    CREATE_CUSTOMER_PACKAGES_SUCCESS: "CREATE_CUSTOMER_PACKAGES_SUCCESS",
    CREATE_CUSTOMER_PACKAGES_FAILED: "CREATE_CUSTOMER_PACKAGES_FAILED",
    SET_CREATE_PACKAGES_SNAPSHOT_PAGE_SUBMISSION: "SET_CREATE_PACKAGES_SNAPSHOT_PAGE_SUBMISSION",
    CLEAR_CREATE_PACKAGES_INFORMATION:"CLEAR_CREATE_PACKAGES_INFORMATION",
}

export default types;

export const clearCreatePackagesState = () => {
    return { type: types.CLEAR_CREATE_PACKAGES_INFORMATION };
}

export const setPackagesSnapshotMeta = (payload) => {
    return { type: types.SET_PACKAGES_SNAPSHOT_META, payload };
};

export const setPackagesSnapshotMainPackage = (payload) => {
    return { type: types.SET_MAIN_PACKAGE, payload };
};

export const setPackagesSnapshotPackageFeatures = (payload) => {
    return { type: types.SET_PACKAGE_FEATURES, payload };
};


export const setCreditCardInformation = (payload) => {
    return { type: types.SET_CREDIT_CARD_INFORMATION, payload };
};

export const clearCreateCustomerInformationState = () => {
    return { type: types.CLEAR_CREATE_CUSTOMER_INFORMATION };
}

export const createPackagePayment = (payload) => {
    return { type: types.CREATE_CUSTOMER_PACKAGES_REQUESTED, payload };
};

export const createPackagePaymentFailed = (payload) => {
    return { type: types.CREATE_CUSTOMER_PACKAGES_FAILED, payload };
};

export const createPackagePaymentSuccess = (payload) => {
    return { type: types.CREATE_CUSTOMER_PACKAGES_SUCCESS, payload };
};

export const setCreatePackageSnapshotPageSubmission = (payload) => {
    return { type: types.SET_CREATE_PACKAGES_SNAPSHOT_PAGE_SUBMISSION, payload };
};

