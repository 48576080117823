import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiPost, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getTrademarkResultSuccess } from '../actions/trademark.action';
import i18next from 'i18next';
import Swal from 'sweetalert2';

function* runGetTrademarkResult(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload, false, CONTROLLERS.TRADEMARK, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield all([
            put(getTrademarkResultSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* trademarkSaga() {
    yield all([
        takeLatest(types.GET_TRADEMARK_RESULT_REQUESTED, safe(runGetTrademarkResult)),
    ]);
}