import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { syncedState } from '../../state-management/actions/app.actions';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { getRouteByKey } from '../../routes';
import { createCustomerStore } from '../../state-management/actions/store.actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { DrNavigator } from '../../components/component-index';
import { useTranslation } from 'react-i18next';

function CompleteCreateStorePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation('completeCreateStorePage');
    const isSubmittedBefore = useSelector(state => state.createStoreSnapshot.customerStoreSubmitted);
    // const customerStores = useSelector(state => state.stores.customerStores);


    useEffect(() => {
        if (!isSubmittedBefore) {
            const submitStoreSnapshot = async () => {
                // await Promise.all([
                //     dispatch(withActionPromise(setMarketSnapshotMeta({ "customerStoreSubmitted": true })))
                // ]);

                const lastState = await dispatch(syncedState());

                let marketPreferences = lastState.createStoreSnapshot.marketPreferences;
                let connectToAmazonCriteria = lastState.createStoreSnapshot.connectToAmazonCriteria;
                let warehouseShippingIds = Object.assign([], lastState.createStoreSnapshot.shippingIds);
                let store = {
                    ...marketPreferences, ...connectToAmazonCriteria, warehouseShippingIds: []
                }

                store.warehouseShippingIds.push(...warehouseShippingIds);

                dispatch(createCustomerStore(store));

                window.history.replaceState(null, '', location.pathname)
            }
            submitStoreSnapshot();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmittedBefore, dispatch]);


    return (
        <>
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row row-md row-lg justify-content-center">
                                <h3 className="text-3xl text-center tx-bold mb-5">{t("header")}</h3>
                            </div>
                            <hr className="mt-2 mb-4" />
                            <div className="row row-md row-lg justify-content-center">
                                <h3 className="text-3xl text-center tx-bold mb-5">{t("headerDescription")}</h3>
                            </div>
                            <div className="row row-md row-lg justify-content-center">
                                {/* <h4 className="text-xs text-center mb-5"></h4> */}
                            </div>
                            <div className="row row-md row-lg justify-content-center">
                                <p className="alert alert-danger tx-12 w-100 text-center">
                                    {t("emailForwardingInfoDescription")}
                                </p>
                            </div>
                            <div className="row row-md row-lg justify-content-center">
                                <div className="col-lg-auto col-md-2">
                                    {isSubmittedBefore && <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(clearCreateStoreInformationState());
                                            navigate(getRouteByKey("MY_STORE"));
                                        }}>
                                        <i className="me-2 fe fe-chevrons-right"></i>
                                        {t("goToMyStore")}</button>}
                                </div>
                                <div className="col-lg-auto col-md-2">
                                    {isSubmittedBefore &&
                                        <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(clearCreateStoreInformationState());
                                                navigate(getRouteByKey("FORWARDING_EMAILS"));
                                            }}                                    >
                                            <i className="me-2 fe fe-chevrons-right"></i>
                                            {t("goToEmailForwarding")}
                                        </button>
                                    }
                                </div>
                            </div>

                            {!isSubmittedBefore && <DrNavigator backAddress="CONNECT_AMAZON" />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompleteCreateStorePage;