import React, { useEffect, useState } from 'react'
import { DrBreadCrump, DrInput, DrNavigator, DrSelectBox } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { clearActiveAddMerchantListModal, setActiveAddMerchantListModal, withActionPromise } from '../../state-management/actions/app.actions';
import { setSmartPricerPageSubmission, setSmartPricerQuestions } from '../../state-management/actions/create-smartpricer-filter.actions';
import { getSmartPricerStrategyById, updateSmartPricerStrategy } from '../../state-management/actions/smartpricer.actions';
// import { smartPricerQuestionMockData as smartPricerStrategyQuestions } from '../../utils/mocks-data';
import { modals } from '../../utils/constants';
import { autoReprizeMinutesIntervals, minOrMaxOptions, moreOrLessOptions } from '../../components/hoc/Constants/dr-constants.component';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

function SmartPricerEditPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('smartPricerPage');
    const { id } = useParams();
    const language = useSelector(state => state.settings.language);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const allMarkets = useSelector(state => state.markets.allMarkets);
    const smartPricerSnapshot = useSelector(state => state.smartPricerSnapshot);
    const [initialValues, setInitialValues] = useState(null);
    const smartPricerStrategyQuestions = useSelector(state => state.smartPricer.smartPricerStrategyQuestions);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            var requestData = {
                id: id
            }

            dispatch(withActionPromise(getSmartPricerStrategyById(requestData)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, id]);

    useEffect(() => {
        var questions = null;

        if (smartPricerStrategyQuestions && smartPricerStrategyQuestions.smartPricerCategories.length > 0) {
            questions = smartPricerStrategyQuestions.smartPricerCategories;

            questions.forEach(question => {
                var checkBoxAnswer = (question.inputType === "CheckBox" && !question ? "0" : question?.answerText) || "0";
                var valueAnswer = question?.answerText || null;

                question.answerId = question?.answerId || null;
                question.answerText = (question.inputType === "CheckBox" ? checkBoxAnswer : valueAnswer) || null;
                question.optionType = question.smartPricerTypeSubs?.find(x => x.id === question?.answerId)?.optionType || 0;
            });

        }

        if (questions && questions.length > 0) {
            var data = {
                ...smartPricerStrategyQuestions
            }
            data.smartPricerCategories = questions;

         
            setInitialValues(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [smartPricerStrategyQuestions]);

    function validateAllQuestions() {
        const { parent } = this;
        var result = true;
        if (parent && parent.smartPricerCategories?.length > 0) {
            parent.smartPricerCategories.forEach(element => {
                // if(element.inputType !== "CheckBox"){
                var a = element.answerId && element.answerId.length > 0 ? element.answerId : null;
                var b = element.answerText && element.answerText.length > 0 ? element.answerText : null;
                if (a === null && b === null) {
                    result = false;
                }
                // }
            });
            return result;
        }
        return true;
    }

    const smartpricerFiltersPageSchema = Yup.object().shape({
        smartPricerCategories: Yup.array()
            .of(Yup.object()
                .shape({
                    answerId: Yup.string().nullable(),
                    answerText: Yup.string().nullable(),
                })).test(
                    'answerId or answerText',
                    'answerId or answerText is required',
                    validateAllQuestions
                )
    });

    function addAndGetActiveValuesFormattedText(values, currentValue, index) {
        values[index].value = currentValue;
        var valueText = "";
        values.forEach(input => {
            if (valueText?.length > 0) {
                valueText += '|';
            }
            valueText += input.value;
        });

        return valueText
    };

    const handleSubmit = async (values) => {
        var data = {
            selectedStrategyTypeOption: null,
            selectedStrategyId: id,
            smartpricerData: {
                id: values.id,
                customerMainSellerId: selectedStore?.customerMainSellerId,
                smartPricerName: values.smartPricerName,
                smartPricerStrategy: values?.smartPricerStrategy,
                productCategoryId: values.productCategoryId || null,
                asins: values.asins || null,
                answerResults: values.smartPricerCategories.map(x => ({ questionId: x.id, answerId: x.answerId, answerText: x.answerText, optionType: x.optionType, activeValueInputFormat: x.activeValueInputFormat }))
            }
        }
        dispatch(updateSmartPricerStrategy(data));
    }

    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            // validationSchema={smartpricerFiltersPageSchema}
            enableReinitialize={true}
            validateOnMount={true}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setTouched,
                setFieldValue
            }) => (
                <>
                            <DrBreadCrump
                                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.smartpricer'), navigationPath: "/smartpricer" },
                                { navigationText: i18next.t('constants:breadCrumps.editSmartPricer'), navigationPath: "" }]} >
                            </DrBreadCrump>
                    <div className="row row-md row-lg d-flex justify-content-center">
                        <div className="col-md-8 col-lg-12">
                            <form name="smartpricer-edit-form" className="needs-validation" autoComplete="off">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div className="row row-md row-lg justify-content-center">
                                            <div className="col-lg-auto col-md-6 col-sm-6">
                                                <h3 className="text-center">{t('editSmartPricerPage.contentTitle')}</h3>
                                            </div>
                                        </div>
                                        <section>
                                            <div className="row row-md row-lg justify-content-center">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="card custom-card">
                                                        {values && values.smartPricerCategories?.length > 0 && values.smartPricerCategories?.map((question, index) => {

                                                            var dropdownOptions = question.smartPricerTypeSubs?.map(x => ({ value: x.id, label: x.typeSubName, optionType: x.optionType }));
                                                            var selectedOption = question.smartPricerTypeSubs?.find(x => x.id === values.smartPricerCategories[index].answerId);
                                                            var answerTextValues = question.answerText && question.answerText.length > 0 ? question.answerText.split('|') : [];
                                                            var activeInputFormats = [];
                                                            var totalInputFormatCount = 0;
                                                            var activeInputValues = [];
                                                            if (values.smartPricerCategories[index].activeValueInputFormat?.length > 0
                                                                && (selectedOption?.optionType === 1 || selectedOption?.optionType === 2 || selectedOption?.optionType === 999)) {
                                                                activeInputFormats = values.smartPricerCategories[index].activeValueInputFormat.split('|\n|').filter(x => x.length > 0);
                                                                totalInputFormatCount = values.smartPricerCategories[index].activeValueInputFormat.split('|').filter(x => x.length > 0 && !x.includes("\n"));
                                                                activeInputValues = values.smartPricerCategories[index].activeValueInputFormat.split('|').filter(x => x.length > 0 && !x.includes("\n")).map((x, index) => (
                                                                    {
                                                                        type: x, index: index,
                                                                        value: answerTextValues && answerTextValues.length > index ? answerTextValues[index] : "null"
                                                                    }
                                                                ))
                                                            }

                                                            return (
                                                                <div key={"smartpricer_question_" + (index + 1)} className="row px-3">
                                                                    <div className="col-lg-12 col-sm-12 py-3 border-top">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-md-6 col-lg-6 col-xl-7 col-7">
                                                                                <label className="noselect mt-3 text-start">
                                                                                    {question.categoryName}
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-6 col-lg-6 col-xl-5 col-5 text-end">
                                                                                {question.typeName === "Yes/No" && question.inputType === "CheckBox" &&
                                                                                    <label className="custom-switch cursor-pointer mt-2">
                                                                                        <DrInput value="" className="form-check-input" placeholder="" name="answerText" type="checkbox"
                                                                                            checked={(values.smartPricerCategories && values.smartPricerCategories[index].answerText === "1" ? true : false) || ''}
                                                                                            onChange={(e) => {
                                                                                                setFieldValue(`smartPricerCategories[${index}].answerText`, e.currentTarget.checked ? "1" : "0")
                                                                                            }}
                                                                                            onBlur={handleBlur}
                                                                                        />
                                                                                    </label>
                                                                                }
                                                                                {(question.inputType === "Textbox") &&
                                                                                    <div className="row m-0 p-0">
                                                                                        <div className="col-5 my-auto px-0">
                                                                                            {/* {question.typeName === "BiggerThan" && <span className="me-1">Bigger Than</span>}
                                        {question.typeName === "LessThan" && <span className="me-1">Less Than</span>} */}
                                                                                        </div>
                                                                                        <div className="col-7 mx-0 px-0">
                                                                                            <DrInput className="form-control" placeholder="" name="answerText" type="number" min="0"
                                                                                                value={(values.smartPricerCategories && values.smartPricerCategories[index].answerText) || ''}
                                                                                                onChange={(e) => {
                                                                                                    setFieldValue(`smartPricerCategories[${index}].answerText`, e.currentTarget.value)
                                                                                                }}
                                                                                                onBlur={handleBlur}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {(question.inputType === "TextBox-Numeric") &&
                                                                                    <div className="row m-0 p-0">
                                                                                        <div className="col-5 my-auto px-0">
                                                                                            {/* {question.typeName === "BiggerThan" && <span className="me-1">Bigger Than</span>}
                                        {question.typeName === "LessThan" && <span className="me-1">Less Than</span>} */}
                                                                                        </div>
                                                                                        <div className="col-7 mx-0 px-0">
                                                                                            <DrInput className="form-control" placeholder="" name="answerText" type="number" min="0"
                                                                                                value={(values.smartPricerCategories && values.smartPricerCategories[index].answerText) || ''}
                                                                                                onChange={(e) => {
                                                                                                    setFieldValue(`smartPricerCategories[${index}].answerText`, e.currentTarget.value)
                                                                                                }}
                                                                                                onBlur={handleBlur}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {question.inputType === "DropdownBox" &&
                                                                                    <div className="row m-0 p-0">
                                                                                        <div className="col-5 my-auto px-0">

                                                                                        </div>
                                                                                        <div className="col-7 mx-0 px-0 text-start">
                                                                                            <Field name={`smartPricerCategories[${index}].answerId`} component={DrSelectBox} placeholder="Select"
                                                                                                onBlur={handleBlur}
                                                                                                options={dropdownOptions}
                                                                                                onChange={(e) => {
                                                                                                    setFieldValue(`smartPricerCategories[${index}].answerId`, e.value);
                                                                                                }} />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                        <div className="row justify-content-end">
                                                                            <div className="col-auto px-0">
                                                                                {activeInputFormats?.map((mainInputText, mainIndex) => {
                                                                                    var inputs = mainInputText.split('|');
                                                                                    var previousInputCount = 0;
                                                                                    for (let i = 0; i < mainIndex; i++) {
                                                                                        previousInputCount += activeInputFormats[i].split('|').length;
                                                                                    }

                                                                                    return (
                                                                                        <div key={"detail_answer_row_" + mainIndex} className="row justify-content-start m-0 p-0 py-2">
                                                                                            {inputs?.map((inputText, rowInputIndex) => {
                                                                                                var isVisible = true;
                                                                                                if (inputText.includes("??")) {
                                                                                                    var type = inputText.split('??');
                                                                                                    isVisible = parseInt(type[0].split('=')[1]) === selectedOption.optionType;
                                                                                                }
                                                                                                return (
                                                                                                    <div key={"detail_answer_col_" + rowInputIndex} className="col-auto ps-0 pe-2 my-auto">

                                                                                                        {inputText.includes('text=') &&

                                                                                                            <label className="my-auto">{inputText.replace("text=", '')}</label>

                                                                                                        }
                                                                                                        {inputText.includes("more-less-dd") && isVisible &&
                                                                                                            <div className="form-group has-validation my-auto">
                                                                                                                <Field
                                                                                                                    onBlur={handleBlur}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (e) {
                                                                                                                            setFieldValue(`smartPricerCategories[${index}].answerText`, addAndGetActiveValuesFormattedText(activeInputValues, e.value, rowInputIndex + previousInputCount));
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    isMulti={false}
                                                                                                                    name=""
                                                                                                                    selectedValue={activeInputValues[rowInputIndex + previousInputCount].value || ""}
                                                                                                                    component={DrSelectBox}
                                                                                                                    placeholder="Select"
                                                                                                                    options={moreOrLessOptions}
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                        {inputText.includes("min-max-price-dd") && isVisible &&
                                                                                                            <div className="form-group has-validation my-auto">
                                                                                                                <Field
                                                                                                                    onBlur={handleBlur}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (e) {
                                                                                                                            setFieldValue(`smartPricerCategories[${index}].answerText`, addAndGetActiveValuesFormattedText(activeInputValues, e.value, rowInputIndex + previousInputCount));
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    isMulti={false}
                                                                                                                    name=""
                                                                                                                    selectedValue={activeInputValues[rowInputIndex + previousInputCount].value || ""}
                                                                                                                    component={DrSelectBox}
                                                                                                                    placeholder="Select"
                                                                                                                    options={minOrMaxOptions}
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                        {inputText.includes("price-percent-dd") && isVisible &&
                                                                                                            <div className="form-group has-validation my-auto" style={{ minWidth: '90px' }}>
                                                                                                                <Field
                                                                                                                    onBlur={handleBlur}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (e) {
                                                                                                                            setFieldValue(`smartPricerCategories[${index}].answerText`, addAndGetActiveValuesFormattedText(activeInputValues, e.value, rowInputIndex + previousInputCount));
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    isMulti={false}
                                                                                                                    name=""
                                                                                                                    selectedValue={activeInputValues[rowInputIndex + previousInputCount].value || ""}
                                                                                                                    component={DrSelectBox}
                                                                                                                    placeholder="Select"
                                                                                                                    options={[
                                                                                                                        { value: allMarkets.find(x => x.id === selectedStore.marketId)?.currencyCode, label: allMarkets.find(x => x.id === selectedStore.marketId)?.currencyCode },
                                                                                                                        { value: "%", label: "%" }
                                                                                                                    ]}
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                        {inputText.includes("numeric-textbox") && isVisible &&
                                                                                                            <DrInput key={mainIndex + "_" + rowInputIndex} className="form-control" placeholder="" name={"numeric-textbox_" + mainIndex} type="number" min="0"
                                                                                                                value={activeInputValues[rowInputIndex + previousInputCount].value || ""}
                                                                                                                onChange={(e) => {
                                                                                                                    setFieldValue(`smartPricerCategories[${index}].answerText`, addAndGetActiveValuesFormattedText(activeInputValues, e.currentTarget.value, rowInputIndex + previousInputCount));
                                                                                                                }}
                                                                                                                onBlur={handleBlur}
                                                                                                                icon={activeInputValues[rowInputIndex + previousInputCount - 1].value === allMarkets.find(x => x.id === selectedStore.marketId)?.currencyCode ? allMarkets.find(x => x.id === selectedStore.marketId)?.currencyCode//<i className="fe fe-dollar-sign"></i>
                                                                                                                    : (activeInputValues[rowInputIndex + previousInputCount - 1].value === "%" ? <i className="fe fe-percent"></i> : <i className="fe fe-package"></i>)}
                                                                                                                iconPosition={activeInputValues[rowInputIndex + previousInputCount - 1].value === "%" ? "right" : "left"}
                                                                                                            />
                                                                                                        }
                                                                                                        {inputText.includes("minutes-dd") && isVisible &&
                                                                                                            <div className="form-group has-validation w-fit my-auto">
                                                                                                                <Field
                                                                                                                    onBlur={handleBlur}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (e) {
                                                                                                                            setFieldValue(`smartPricerCategories[${index}].answerText`, addAndGetActiveValuesFormattedText(activeInputValues, e.value, rowInputIndex + previousInputCount));
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    isMulti={false}
                                                                                                                    name=""
                                                                                                                    selectedValue={activeInputValues[rowInputIndex + previousInputCount].value || ""}
                                                                                                                    component={DrSelectBox}
                                                                                                                    placeholder="Select"
                                                                                                                    options={autoReprizeMinutesIntervals}
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                        {inputText.includes("merchant-list-dd") && isVisible &&
                                                                                                            <div className="form-group has-validation w-fit my-auto">
                                                                                                                <Field
                                                                                                                    onBlur={handleBlur}
                                                                                                                    onChange={(e) => {
                                                                                                                        if (e && e.value) {
                                                                                                                            var list = JSON.parse(values.smartPricerCategories[index].answerText);
                                                                                                                            list.forEach(element => {
                                                                                                                                element.isSelected = false;
                                                                                                                                if (element.id === e.value) {
                                                                                                                                    element.isSelected = true;
                                                                                                                                }
                                                                                                                            });
                                                                                                                            setFieldValue(`smartPricerCategories[${index}].answerText`, JSON.stringify(list));
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    isMulti={false}
                                                                                                                    name=""
                                                                                                                    selectedValue={values.smartPricerCategories[index].answerText && values.smartPricerCategories[index].answerText.length > 0
                                                                                                                        ? JSON.parse(values.smartPricerCategories[index].answerText).find(x => x.isSelected)?.id : ""}
                                                                                                                    component={DrSelectBox}
                                                                                                                    placeholder="Select"
                                                                                                                    options={
                                                                                                                        values.smartPricerCategories[index].answerText && values.smartPricerCategories[index].answerText.length > 0
                                                                                                                            ? JSON.parse(values.smartPricerCategories[index].answerText)?.map(x => ({ value: x.id, label: x.listName })) : []
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                        {inputText.includes("add-merchants-button") && isVisible &&

                                                                                                            <div className="form-group has-validation w-fit my-auto">
                                                                                                                <button className="bbtn btn-teal-ghost btn-wave waves-effect waves-light shadow me-1" type="button"
                                                                                                                    onClick={(e) => {
                                                                                                                        e.stopPropagation();
                                                                                                                        dispatch(setActiveAddMerchantListModal({
                                                                                                                            name: modals.addMerchantList, isGlobal: true, source: values.smartPricerCategories[index].answerText,
                                                                                                                            addMerchantListCallback: (jsonSource) => {

                                                                                                                                setFieldValue(`smartPricerCategories[${index}].answerText`, jsonSource);
                                                                                                                                dispatch(clearActiveAddMerchantListModal());
                                                                                                                            }
                                                                                                                        }))
                                                                                                                    }}>
                                                                                                                        <i className="me-2 fe fe-plus" />
                                                                                                                    Yeni Liste Ekle
                                                                                                                </button>
                                                                                                            </div>

                                                                                                        }
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <small className="custom-switch-description">{question.categoryDescription}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-end">
                                                <div className="col-auto">
                                                    <button className="btn btn-primary btn-wave waves-effect waves-light shadow"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSubmit(values);
                                                        }}>

                                                        {t('editSmartPricerPage.saveChanges')}
                                                    </button>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>

                            </form>
                        </div>

                    </div>
                </>

            )
            }
        </Formik>
    )
}

export default SmartPricerEditPage;