import types from "../actions/reprizer.action";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ALL_SEARCH_TASKS:
            return {
                ...state, "searchTasks": action.payload
            };
        case types.CLEAR_ALL_SEARCH_TASKS:
            return initialState;
        default:
            return state;
    }
}

const initialState = {
    "searchTasks": []
}

export default reducer;