
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable } from '../../components/component-index';
import { salesReportMockData } from '../../utils/mocks-data';
//import {  timeOptions } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { timeOptions } from '../../components/hoc/Constants/dr-constants.component';


function SalesReportPage() {
    const { t } = useTranslation('salesReportPage');
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableData(salesReportMockData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const columns = [
        { data: "orderNo", title: t('dataTable.orderNo'), width: "15%", minWidth: "120px", searchable: true },
        { data: "buyerOrderNo", title: t('dataTable.buyerOrderNo'), width: "5%", minWidth: "10px", searchable: true },
        { data: "status", title: t('dataTable.status'), width: "5%", minWidth: "70px", searchable: true },
        { data: "price", title: t('dataTable.fiyat'), width: "5%", minWidth: "55px", searchable: true },
        { data: "earning", title: t('dataTable.earning'), width: "5%", minWidth: "20px", searchable: true },
        { data: "earning2", title: t('dataTable.earning2'), width: "10%", searchable: true },
        { data: "fee", title: t('dataTable.fee'), width: "20%", searchable: true },
        { data: "date", title: t('dataTable.date'), width: "20%", minWidth: "80px", searchable: true },
        { data: null, title: "", width: "3%", searchable: false }
    ];

    const columnDefs = [

        {
            targets: 0,
            orderable: true,
            className: "text-center",
            width: "20%",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.orderNo}</span><br />
                    </>)
            }
        },
        {
            targets: 1,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.buyerOrderNo}</span><br />
                    </>);
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.status}</span><br />
                    </>);
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.price}</span><br />
                    </>);
            }
        },
        {
            targets: 4,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div className="text-center" style={{ fontSize: '16px' }}>
                        <span className="badge bg-info">{rowData.price}</span><br />
                        <span className="badge bg-info">{rowData.earning}</span><br />
                        <span className="badge bg-info">Kâr: {rowData.earning2}</span>
                    </div>);
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.earning2}</span><br />
                    </>);
            }
        },
        {
            targets: 6,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.fee}</span><br />
                    </>);
            }
        },
        {
            targets: 7,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <span style={{ marginBottom: '85px' }}>{rowData.date}</span>

                    </div>);
            }
        },
        {
            targets: 8,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>

                        <span id="droprightMenuButton" className="text-muted" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18 cursor-pointer"></i></span>
                        <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Detayları görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Güncelleme geçmişini görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Amazon.com'da görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Amazon.ca'da görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Amazon.ca'da teklifleri görüntüleyin
                            </span>
                        </div>
                    </div>)
            }
        }
    ];

    const orderPageOptions = [
        { label: "Tümü", value: "1" },
        { label: "Sold", value: "2" },
        { label: "Placed", value: "3" },
        { label: "Shipped", value: "4" },
        { label: "Confirm", value: "5" },
        { label: "PreConfirm", value: "6" },
        { label: "Delivered", value: "7" },
        { label: "Canceled", value: "8" },
        { label: "Refunded", value: "9" },
        { label: "LateShipment", value: "10" },
        { label: "GoingToWarehouse", value: "11" },
        { label: "DeliveredToWarehouse", value: "12" },
        { label: "SentFromWarehouse", value: "13" },
        { label: "PackageMayBeLost", value: "14" },
        { label: "ReturnRequested", value: "15" },
        { label: "NotDeliveredOnTime", value: "16" },
        { label: "CheckDeliveryInfo", value: "17" }
    ];

    const optionClickCallBack = (value) => {
        // var val = value;

    };

    const orderPageOptionClickCallBack = (value) => {
        // var val = value;

    };

    return (
        <div style={{ marginBottom: '150px' }}>
            <div className="page-header mb-0 mt-4 mb-2 row row-md row-lg justify-content-between w-100">
                    <DrBreadCrump
                        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.reportingAndAnalysis'), navigationPath: "" },
                        { navigationText: i18next.t('constants:breadCrumps.salesReport'), navigationPath: "" }]} >
                    </DrBreadCrump>
                <div className="col-lg-auto col-md-auto col-sm-auto col-xs-12 px-0 pb-2">
                    <div className="row">
                        <div className="col-auto">
                            <DrButtonDropdown className="shadow" options={timeOptions} optionClickCallBack={optionClickCallBack}>
                            </DrButtonDropdown>
                        </div>
                        <div className="col-auto px-0">
                            <DrButtonDropdown className="ms-2 shadow" options={orderPageOptions} optionClickCallBack={orderPageOptionClickCallBack}>
                            </DrButtonDropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div id="inventoryTable" className="row mb-5">
                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                        <div className="card-body horizontal-scrollable">
                            {tableData && <>
                                <DrDataTable
                                    data={tableData}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    //search={true}
                                    minWidth="850px"
                                />
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SalesReportPage