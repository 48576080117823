import types from "../actions/smartpricer.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_SMARTPRICER_STRATEGIES_SUCCESS:
            return { ...state, smartPricerStrategies: action.payload };
        case types.GET_ALL_SMARTPRICER_STRATEGY_QUESTIONS_SUCCESS:
            return { ...state, smartPricerStrategyQuestions: action.payload };
        case types.GET_SMARTPRICER_STRATEGY_SUCCESS:
            return { ...state, smartPricerStrategyQuestions: action.payload };
        case types.CLEAR_ALL_SMARTPRICER_DATA:
            return initialState;;
        default:
            return state;
    }
}

const initialState = {
    "smartPricerStrategies": [],
    "smartPricerStrategyQuestions": null,
}

export default reducer;