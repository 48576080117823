import { useTranslation } from "react-i18next";
import { Field, Formik } from 'formik';
import { clearActiveSavePagefilterModal, withActionPromise } from "../../../../state-management/actions/app.actions";
import { useDispatch } from "react-redux";
import i18next from 'i18next';
import { savePageFilter, updatePageFilter } from '../../../../state-management/actions/page-filters.actions';
import { DrInput, DrSelectBox } from "../../../component-index";
import { autoDeleteIntervals } from "../../Constants/dr-constants.component";
import $ from 'jquery';
import Swal from "sweetalert2";
import { pageFilterTypes } from "../../../../utils/constants";

function DrSavePageFilter({ display, source, persistModal = false, pagefilterSavedCallBack }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drSavePageFilterModal');

    const handleSubmit = (values) => {

        if (values.id) {
            Promise.all([
                dispatch(withActionPromise(updatePageFilter(values)))
            ]).then((e) => {
                if (pagefilterSavedCallBack) {
                    pagefilterSavedCallBack(values);
                }
                Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info1'), 'success')
            });;
        } else {
            Promise.all([
                dispatch(withActionPromise(savePageFilter(values)))
            ]).then((e) => {
                if (pagefilterSavedCallBack) {
                    pagefilterSavedCallBack(values);
                }
                Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info1'), 'success')
            });
        }
    };

    /*
        filterName: "",
        isBatchStart: false,
        batchHours: 0,
        isNotification: false,
        notificationHours: 0

    */
    const initialValues = {
        ...source
    }

    // const captchaSchema = Yup.object().shape({
    //     filterName: Yup.string().required('Filter Name is required')
    // });

    return (

        <div className={`modal fade modal-background ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="baseModal" aria-modal="true" aria-hidden="true"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActiveSavePagefilterModal());
                }
            }}>
            <Formik
                initialValues={initialValues}
                validateOnBlur={true}
                // onSubmit={handleSubmit}
                enableReinitialize={true}
                validateOnMount={true}
            // validationSchema={captchaSchema}
            >
                {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setTouched,
                    setFieldValue
                }) => (

                    <div className="form needs-validation modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">{t('title')}</h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => dispatch(clearActiveSavePagefilterModal())} >
                                </button>
                            </div>
                            <div className="modal-body justify-contents-center">
                                <div className="row justify-content-center">
                                    <div className="col-12">

                                        <div className="row mt-3">
                                            <label className="noselect col-auto text-start my-auto">
                                                {t('filterName')}
                                            </label>
                                            <div className="col my-auto">
                                                <DrInput id="filterName" value={values.filterName || ""} className="form-control" placeholder={t('filterName')} name="filterName" type="text"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'filterName': true }) }}
                                                />
                                            </div>
                                        </div>
                                        {values.pageNumber === pageFilterTypes.inventory &&
                                            <>
                                                <hr className="my-3"></hr>

                                                <div className="row mt-3">
                                                    <label htmlFor="isBatchStart" className="noselect col my-auto text-start cursor-pointer">
                                                        {t('autoDelete')}
                                                    </label>
                                                    <div className="col-auto my-auto">
                                                        <label className="custom-switch cursor-pointer my-auto">
                                                            <DrInput id="isBatchStart" value="" checked={values.isBatchStart} className="form-check-input" placeholder="" name="isBatchStart" type="checkbox"
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'isBatchStart': true }) }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>

                                                {values.isBatchStart &&
                                                    <div className="row  mt-3 justify-content-between">
                                                        <div className="col">
                                                            <label className="noselect mt-3 text-start">
                                                                {t('autoDeleteInterval')}
                                                            </label>
                                                        </div>
                                                        <div className="col-auto">
                                                            <Field onBlur={() => { setTouched({ ...touched.batchHours, 'batchHours': true }) }}
                                                                name={'batchHours'}
                                                                component={DrSelectBox}
                                                                placeholder={t('autoDeleteInterval')}
                                                                options={autoDeleteIntervals} />
                                                            {(touched.batchHours && errors.batchHours) && <label type="invalid" className="form-control is-invalid">
                                                                {errors.batchHours}
                                                            </label>}
                                                        </div>
                                                    </div>
                                                }
                                                <hr className="my-3"></hr>
                                                <div className="row mt-3">
                                                    <label htmlFor="isNotification" className="noselect col my-auto text-start cursor-pointer">
                                                        {t('notification')}
                                                    </label>
                                                    <div className="col-auto my-auto">
                                                        <label className="custom-switch cursor-pointer my-auto">
                                                            <DrInput id="isNotification" value="" checked={values.isNotification} className="form-check-input" placeholder="" name="isNotification" type="checkbox"
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'isNotification': true }) }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                {values.isNotification &&
                                                    <div className="row  mt-3 justify-content-between">
                                                        <div className="col">
                                                            <label className="noselect mt-3 text-start">
                                                                {t('notificationInterval')}
                                                            </label>
                                                        </div>
                                                        <div className="col-auto">
                                                            <Field onBlur={() => { setTouched({ ...touched.notificationHours, 'notificationHours': true }) }}
                                                                name={'notificationHours'}
                                                                component={DrSelectBox}
                                                                placeholder={t('notificationInterval')}
                                                                options={autoDeleteIntervals} />
                                                            {(touched.notificationHours && errors.notificationHours) && <label type="invalid" className="form-control is-invalid">
                                                                {errors.notificationHours}
                                                            </label>}
                                                        </div>
                                                    </div>
                                                }
                                                <hr className="my-3"></hr>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row justify-content-end w-100">
                                    <div className="col-auto">
                                        <button type="submit" className="btn btn-primary btn-wave waves-effect waves-light shadow"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (isValid) {
                                                    handleSubmit(values);
                                                }
                                            }}>
                                            {t('save')}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
                }
            </Formik>
        </div>

    )
}

export default DrSavePageFilter