import React from "react";
import Chart from "react-apexcharts";
import './chart.scss'

function DrColumnChart({ chartData }) {

    var options = {
        series: [{
            name: chartData?.peakText || "",
            data: chartData?.series || []
        }],
        chart: {
            height: "100%",
            type: 'bar',
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                }
            }
        },
        grid: {
            borderColor: '#f2f5f7',
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            // formatter: function (val) {
            //     return val + "%";
            // },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#8c9097"]
            }
        },
        colors: chartData?.colors || ['var(--info-color)', 'var(--danger-color)', 'var(--success-color)'],
        xaxis: {
            categories: chartData?.categories || [],
            // tickPlacement: 'on',
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
            },
            labels: {
                show: true,
                style: {
                    colors: "#8c9097",
                    fontSize: '11px',
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-label',
                },
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
                // formatter: function (val) {
                //     return val + "%";
                // }
            }

        },
        title: {
            text: chartData?.title || "",
            floating: true,
            offsetY: 0,
            align: 'center',
            style: {
                color: '#444'
            }
        }
    };


    return (


        <Chart
            options={options}
            series={options.series}
            type="bar"
            height="250px"
            width="100%"
            className="apexcharts-canvas apexcharts7ngldghr apexcharts-theme-light"
        />

    );
}

export default DrColumnChart;

