import { useTranslation } from "react-i18next";
import { Formik } from 'formik';
import { clearActiveCaptchaModal } from "../../../../state-management/actions/app.actions";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import $ from 'jquery';

function DrCaptchaModal({ display, source, persistModal = false, captchaTextInputCallback }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drCaptchaModal');

    const handleSubmit = (values) => {
        if (captchaTextInputCallback) {
            captchaTextInputCallback(values);
        }
    };

    const initialValues = {
        ...source
    }

    const captchaSchema = Yup.object().shape({
        captchaText: Yup.string().required('Captcha text is required')
    });

    return (

        <div className={`modal fade modal-background ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="captchaModal" aria-modal="true" aria-hidden="true"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActiveCaptchaModal());
                }
            }}>
            <Formik
                initialValues={initialValues}
                validateOnBlur={true}
                // onSubmit={handleSubmit}
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={captchaSchema}
            >
                {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setTouched,
                    setFieldValue
                }) => (

                    <div className="form needs-validation modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header" >
                                <h6 className="modal-title" >{t('title')}</h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => dispatch(clearActiveCaptchaModal())} >
                                </button>
                            </div>
                            <div className="modal-body justify-contents-center">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        {values?.imgUrl && <img src={values?.imgUrl} alt="img" className="img-xl p-0 m-0 w-100"></img>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row justify-content-between w-100">
                                    <div className="col-6">
                                        <div className="form-group has-validation">
                                            <input value={values?.captchaText || ""} className={`form-control ${(touched.captchaText && errors.captchaText) ? " is-invalid" : ""}`} placeholder={(touched.captchaText && errors.captchaText) ? errors.captchaText : t('captchaText')} name="captchaText" type="text"
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'captchaText': true }) }} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <button type="submit" className="btn btn-primary btn-wave waves-effect waves-light shadow"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setTouched({ ...touched, 'captchaText': true });
                                                if (isValid) {
                                                    handleSubmit(values);
                                                }
                                            }}>
                                            {t('save')}
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                )
                }
            </Formik>
        </div>

    )
}

export default DrCaptchaModal