const types = {
    GET_ORDERS_REQUESTED: "GET_ORDERS_REQUESTED",
    GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
    GET_ORDERS_FAILED: "GET_ORDERS_FAILED",
    GET_WAREHOUSE_ORDERS_REQUESTED: "GET_WAREHOUSE_ORDERS_REQUESTED",
    GET_WAREHOUSE_ORDERS_SUCCESS: "GET_WAREHOUSE_ORDERS_SUCCESS",
    GET_WAREHOUSE_ORDERS_FAILED: "GET_WAREHOUSE_ORDERS_FAILED",
    GET_LATEST_ORDERS_REQUESTED: "GET_LATEST_ORDERS_REQUESTED",
    GET_LATEST_ORDERS_SUCCESS: "GET_LATEST_ORDERS_SUCCESS",
    GET_LATEST_ORDERS_FAILED: "GET_LATEST_ORDERS_FAILED",
    GET_ORDER_ITEM_REQUESTED: "GET_ORDER_ITEM_REQUESTED",
    GET_ORDER_ITEM_SUCCESS: "GET_ORDER_ITEM_SUCCESS",
    GET_ORDER_ITEM_FAILED: "GET_ORDER_ITEM_FAILED",
    GET_ORDER_ITEM_BY_ORDERNUMBER_REQUESTED: "GET_ORDER_ITEM_BY_ORDERNUMBER_REQUESTED",

    GET_ORDER_ITEM_SUMMARY_REQUESTED: "GET_ORDER_ITEM_SUMMARY_REQUESTED",
    GET_ORDER_ITEM_SUMMARY_SUCCESS: "GET_ORDER_ITEM_SUMMARY_SUCCESS",
    GET_ORDER_ITEM_SUMMARY_FAILED: "GET_ORDER_ITEM_SUMMARY_FAILED",

    GET_ORDER_TRACKING_STATUS_REQUESTED: "GET_ORDER_TRACKING_STATUS_REQUESTED",
    GET_ORDER_TRACKING_STATUS_SUCCESS: "GET_ORDER_TRACKING_STATUS_SUCCESS",
    GET_ORDER_TRACKING_STATUS_FAILED: "GET_ORDER_TRACKING_STATUS_FAILED",
    UPDATE_ORDERITEM_AMAZON_ORDER_NUMBER_REQUESTED: "UPDATE_ORDERITEM_AMAZON_ORDER_NUMBER_REQUESTED",

    GET_ORDER_NOTES_REQUESTED: "GET_ORDER_NOTES_REQUESTED",
    GET_ORDER_NOTES_SUCCESS: "GET_ORDER_NOTES_SUCCESS",
    SAVE_ORDER_NOTE_REQUESTED: "SAVE_ORDER_NOTE_REQUESTED",
    DELETE_ORDER_NOTE_REQUESTED: "DELETE_ORDER_NOTE_REQUESTED",
    SAVE_ORDER_FLAG_REQUESTED: "SAVE_ORDER_FLAG_REQUESTED",

    CLEAR_ALL_ORDERS: "CLEAR_ALL_ORDERS",

    GET_INVOICE_REQUESTED: "GET_INVOICE_REQUESTED",
    GET_INVOICE_SUCCESS: "GET_INVOICE_SUCCESS",

    GET_ORDER_SHIPPING_CALCULATE_REQUESTED: "GET_ORDER_SHIPPING_CALCULATE_REQUESTED",
    GET_ORDER_SHIPPING_CALCULATE_SUCCESS: "GET_ORDER_SHIPPING_CALCULATE_SUCCESS",

    UPDATE_ORDER_SHIPPING_REQUESTED: "UPDATE_ORDER_SHIPPING_REQUESTED"

}

export default types;

export const getOrders = (payload) => {
    return { type: types.GET_ORDERS_REQUESTED, payload };
};

export const getOrdersSuccess = (payload) => {
    return { type: types.GET_ORDERS_SUCCESS, payload };
};

export const getWarehouseOrders = (payload) => {
    return { type: types.GET_WAREHOUSE_ORDERS_REQUESTED, payload };
};

export const getWarehouseOrdersSuccess = (payload) => {
    return { type: types.GET_WAREHOUSE_ORDERS_SUCCESS, payload };
};

export const getLatestOrders = (payload) => {
    return { type: types.GET_LATEST_ORDERS_REQUESTED, payload };
};

export const getLatestOrdersSuccess = (payload) => {
    return { type: types.GET_LATEST_ORDERS_SUCCESS, payload };
};

export const getOrderItem = (payload) => {
    return { type: types.GET_ORDER_ITEM_REQUESTED, payload };
};

export const getOrderItemByOrderNumber = (payload) => {
    return { type: types.GET_ORDER_ITEM_BY_ORDERNUMBER_REQUESTED, payload };
};

export const getOrderItemSuccess = (payload) => {
    return { type: types.GET_ORDER_ITEM_SUCCESS, payload };
};

export const getOrderDetailSaleSummary = (payload) => {
    return { type: types.GET_ORDER_ITEM_SUMMARY_REQUESTED, payload };
};

export const getOrderDetailSaleSummarySuccess = (payload) => {
    return { type: types.GET_ORDER_ITEM_SUMMARY_SUCCESS, payload };
};

export const getOrderTrackingStatus = (payload) => {
    return { type: types.GET_ORDER_TRACKING_STATUS_REQUESTED, payload };
};

export const getOrderTrackingStatusSuccess = (payload) => {
    return { type: types.GET_ORDER_TRACKING_STATUS_SUCCESS, payload };
};

export const updateOrderItemAmazonOrderNumber = (payload) => {
    return { type: types.UPDATE_ORDERITEM_AMAZON_ORDER_NUMBER_REQUESTED, payload };
};

export const getOrderNotes = (payload) => {
    return { type: types.GET_ORDER_NOTES_REQUESTED, payload };
};

export const getOrderNotesSuccess = (payload) => {
    return { type: types.GET_ORDER_NOTES_SUCCESS, payload };
};

export const saveOrderNote = (payload) => {
    return { type: types.SAVE_ORDER_NOTE_REQUESTED, payload };
};

export const deleteOrderNote = (payload) => {
    return { type: types.DELETE_ORDER_NOTE_REQUESTED, payload };
};

export const saveOrderFlag = (payload) => {
    return { type: types.SAVE_ORDER_FLAG_REQUESTED, payload };
};

export const clearAllOrders = () => {
    return { type: types.CLEAR_ALL_ORDERS };
};

export const getInvoice = (payload) => {
    return { type: types.GET_INVOICE_REQUESTED, payload };
};

export const getInvoiceSuccess = (payload) => {
    return { type: types.GET_INVOICE_SUCCESS, payload };
};

export const getOrderShippingCalculate = (payload) => {
    return { type: types.GET_ORDER_SHIPPING_CALCULATE_REQUESTED, payload };
};

export const getOrderShippingCalculateSuccess = (payload) => {
    return { type: types.GET_ORDER_SHIPPING_CALCULATE_SUCCESS, payload };
};

export const updateOrderShipping = (payload) => {
    return { type: types.UPDATE_ORDER_SHIPPING_REQUESTED, payload };
};

