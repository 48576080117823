import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function DrStepTracker({ steps, currentStep, pageSubmissions, stepsClass = "col-12 px-0" }) {
    const navigate = useNavigate();
    const { t } = useTranslation('drSteptracker');
    // const pageSubmissions = useSelector(state => state.createCustomerSnapshot.pageSubmissions);

    var stepClass = classNames({
        [`${stepsClass}`]: true
    });

    return (
        <>
            {/* 
            <ul className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block" id="myTab1" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="order-tab" data-bs-toggle="tab" data-bs-target="#order-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"><i className="ri-gift-line me-1 align-middle" />Ordered</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="confirmed-tab" data-bs-toggle="tab" data-bs-target="#confirm-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"><i className="ri-check-double-line me-1 align-middle" />Confirmed</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="shipped-tab" data-bs-toggle="tab" data-bs-target="#shipped-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false"><i className="ri-shopping-bag-3-line me-1 align-middle" />Shipped</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="delivered-tab" data-bs-toggle="tab" data-bs-target="#delivered-tab-pane" type="button" role="tab" aria-selected="false"><i className="ri-truck-line me-1 align-middle" />Delivery</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade text-muted" id="order-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                    <ul className="ps-3 mb-0">
                        <li>Contrary to popular belief, Lorem Ipsum is not simply random text. It
                            has roots in a piece of classical Latin literature from 45 BC, making it
                            over 2000 years old. Richard McClintock.</li>
                    </ul>
                </div>
                <div className="tab-pane fade show active text-muted" id="confirm-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>
                    <ul className="ps-3 mb-0">
                        <li>As opposed to using 'Content here, content here', making it look like
                            readable English. Many desktop publishing packages and web page editors
                            now use Lorem Ipsum as their default model text, and a search.</li>
                    </ul>
                </div>
                <div className="tab-pane fade text-muted" id="shipped-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex={0}>
                    <ul className="ps-3 mb-0">
                        <li>but also the leap into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the release of Letraset
                            sheets containing Lorem Ipsum passages, and more recently.</li>
                    </ul>
                </div>
                <div className="tab-pane fade text-muted" id="delivered-tab-pane" role="tabpanel" tabIndex={0}>
                    <ul className="list-unstyled mb-0">
                        <li>A Latin professor at Hampden-Sydney College in Virginia, looked up one
                            of the more obscure Latin words, consectetur, from a Lorem Ipsum
                            passage, and going through the cites of the word in classical
                            literature.</li>
                    </ul>
                </div>
            </div> */}

            {currentStep &&
                <div className="steps clearfix">
                    <div className="row justify-content-center">
                        <div className={stepsClass}>
                            <ul className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block" id="myTab1" role="tablist">

                                {steps.map((step, index) => {
                                    let disabled = currentStep.id < step.id;
                                    let isCurrent = currentStep.id === step.id;
                                    let stepclassName = classNames({
                                        'current': currentStep.id === step.id,
                                        // 'done': (currentStep.id > step.id || currentStep.id === steps.length),
                                        'disabled': disabled,
                                        'nav-item': true,
                                        'cursor-pointer': true
                                    });
                                    let navlinkClass = classNames({

                                        'active': currentStep.id === step.id,
                                        // 'done': (currentStep.id > step.id || currentStep.id === steps.length),
                                        'disabled': disabled,
                                        'nav-link': true
                                    });

                                    let iconClass = classNames({
                                        'd-inline-flex align-items-center rounded-100 p-auto': true,
                                        'bg-primary bg-opacity-10 text-primary': currentStep.id === step.id,
                                        'bg-light': currentStep.id !== step.id,
                                        'text-default': !disabled && currentStep.id !== step.id,
                                        'text-muted': disabled && currentStep.id !== step.id,
                                        'badge': !step.icon
                                    });
                                    return (
                                        <li key={(index + 1)} role="presentation" className={stepclassName} aria-disabled={disabled} aria-selected={isCurrent}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (!disabled
                                                    || ((step.key === "MAIN_PACKAGES") ||
                                                        (pageSubmissions?.mainPackagesPage && step.key === "SUB_FEATURES") ||
                                                        (pageSubmissions?.packageFeaturesPage && step.key === "PAYMENT"))
                                                    || ((step.key === "MARKET_SELECTION") ||
                                                        (pageSubmissions?.marketSelectionPage && step.key === "STORE_PREFERENCES") ||
                                                        (pageSubmissions?.storePreferencesPage && step.key === "WAREHOUSE_SELECTION") ||
                                                        (pageSubmissions?.wareHouseSelectionPage && step.key === "CONNECT_AMAZON") ||
                                                        (pageSubmissions?.connectAmazonPage && step.key === "COMPLETE_CREATESTORE"))

                                                    || ((step.key === "SMARTPRICER_OPTIONS") ||
                                                        (pageSubmissions?.filterTypeSelectionPage && step.key === "SMARTPRICER_FILTERS") ||
                                                        (pageSubmissions?.smartpricerQuestionsPage && step.key === "SMARTPRICER_TARGET") ||
                                                        (pageSubmissions?.smartpricerTargetPage && step.key === "SMARTPRICER_COMPLETE") ||
                                                        (pageSubmissions?.smartpricerNamePage && step.key === "SMARTPRICER_COMPLETED"))
                                                ) {
                                                    navigate(step.pathname);
                                                }

                                            }}>
                                            {/* <span id={'link_' + (index + 1)} aria-disabled={disabled}
                aria-controls="wizard1-p-0">
                <span className="number">{(index + 1)}</span>
                {step.key === "MAIN_PACKAGES" && <span className="title">{t('choosePlan')}</span>}
                {step.key === "CUSTOMER_SETUP" && <span className="title">{t('register')}</span>}
                {step.key === "CUSTOMER_INVOICE" && <span className="title">{t('invoiceInfo')}</span>}
                {step.key === "PAYMENT" && <span className="title">{t('payment')}</span>}

                {step.key === "MARKET_SELECTION" && <span className="title">{t('marketSelection')}</span>}
                {step.key === "STORE_PREFERENCES" && <span className="title">{t('storePreferences')}</span>}
                {step.key === "WAREHOUSE_SELECTION" && <span className="title">{t('warehouseSelection')}</span>}
                {step.key === "CONNECT_AMAZON" && <span className="title">{t('connectAmazon')}</span>}
                {step.key === "COMPLETE_CREATESTORE" && <span className="title">{t('completeStoreCreate')}</span>}

                {step.key === "SMARTPRICER_OPTIONS" && <span className="title">{t('smartPricerFilterType')}</span>}
                {step.key === "SMARTPRICER_FILTERS" && <span className="title">{t('smartPricerFilters')}</span>}
                {step.key === "SMARTPRICER_TARGET" && <span className="title">{t('smartPricerTarget')}</span>}
                {step.key === "SMARTPRICER_COMPLETE" && <span className="title">{t('smartPricerComplete')}</span>}
            </span> */}
                                            <span className={iconClass}
                                                style={{ width: '30px', height: '30px' }}>
                                                {step.icon && step.icon}
                                                {!step.icon && <p className="p-auto m-auto">{index + 1}</p>}
                                            </span>

                                            <button id={'link_' + (index + 1)} aria-disabled={disabled} className={navlinkClass}
                                                type="button" role="tab" aria-controls="wizard1-p-0" aria-selected="true">


                                                {/* {(index + 1)} */}
                                                {step.key === "MAIN_PACKAGES" && t('choosePlan')}
                                                {step.key === "SUB_FEATURES" && t('chooseFeatures')}
                                                {step.key === "PAYMENT" && t('payment')}

                                                {step.key === "MARKET_SELECTION" && t('marketSelection')}
                                                {step.key === "STORE_PREFERENCES" && t('storePreferences')}
                                                {step.key === "WAREHOUSE_SELECTION" && t('warehouseSelection')}
                                                {step.key === "CONNECT_AMAZON" && t('connectAmazon')}
                                                {step.key === "COMPLETE_CREATESTORE" && t('completeStoreCreate')}

                                                {step.key === "SMARTPRICER_OPTIONS" && t('smartPricerFilterType')}
                                                {step.key === "SMARTPRICER_FILTERS" && t('smartPricerFilters')}
                                                {step.key === "SMARTPRICER_TARGET" && t('smartPricerTarget')}
                                                {step.key === "SMARTPRICER_COMPLETE" && t('smartPricerComplete')}
                                            </button>
                                        </li>
                                    )
                                })}
                            </ul>

                        </div>
                    </div>

                </div >
            }
        </>
    )
}

export default DrStepTracker