import { useDispatch, useSelector } from "react-redux";
import DrNavigator from "../../components/hoc/Navigator/dr-navigator.component";
import { Formik } from "formik";
import { withActionPromise } from "../../state-management/actions/app.actions";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { setCreatePackageSnapshotPageSubmission, setPackagesSnapshotMainPackage } from "../../state-management/actions/create-customer-packages.actions";
import { getPackages } from "../../state-management/actions/packages.action";
import { useEffect } from "react";
import classNames from "classnames";
import Restricted from "../../security/Restricted";


function MainPackages() {
    const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_GUEST, REACT_APP_ROLES_USER } = process.env;
    const dispatch = useDispatch();
    const { t } = useTranslation('subscriptionPlanPage');

    const createPackagesSnapshot = useSelector(state => state.createPackagesSnapshot);
    const allPackages = useSelector(state => state.packages.allPackages);
    const customerInfo = useSelector(state => state.customer.customerInfo);
    const customerPackageInfo = useSelector(state => state.customer.customerPackageInfo);

    useEffect(() => {
        if (!(allPackages?.length > 0)) {
            dispatch(getPackages())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const initialValues = {
        mainPackageId: createPackagesSnapshot.mainPackageId || "",
        packageFeaturesIds: createPackagesSnapshot.packageFeaturesIds || []
    };

    const mainPackagePlanSchema = Yup.object().shape({
        mainPackageId: customerInfo?.role?.includes(REACT_APP_ROLES_ADMIN)
            ? (customerPackageInfo?.customerPackages?.filter(x => x.mainPlan) ? Yup.string() : Yup.string().min(3, "Main Package plan is required").required('Main Package plan is required'))
            : Yup.string().min(3, "Main Package plan is required").required('Main Package plan is required')
    });

    const findMaxProfitPackage = (arr) => {
        let maxValue = Number.MIN_VALUE;
        let packageId = null;
        arr.forEach(mainPackage => {
            if ((mainPackage.price * mainPackage.discount / 100) > maxValue) {
                maxValue = (mainPackage.price * mainPackage.discount / 100);
                packageId = mainPackage.id;
            }

        });
        return packageId;
    }

    return (
        <Formik
            initialValues={initialValues}
            // validateOnBlur={true}
            enableReinitialize={true}
            isInitialValid={customerInfo?.role?.includes(REACT_APP_ROLES_ADMIN) ? ((customerPackageInfo?.customerPackages?.filter(x => x.mainPlan) ? true : false)) : false}
            validateOnMount={true}
            validationSchema={mainPackagePlanSchema} >
            {({
                isValid,
                values,
                errors,
                touched,
                handleBlur,
                setTouched,
                setFieldValue,
                resetForm
            }) => (
                <div className="content clearfix">
                    <form>
                        <section>
                            <div className="row">
                                {allPackages && allPackages.filter(x => x.mainPlan === true && !x.isDisable && x.isVisible === true)?.sort((a, b) => a.price - b.price).map((mainPackage, index) => {

                                    let cardTopClass = classNames({
                                        'card custom-card': true,
                                        'ribbone-card': true,
                                        'overflow-hidden': false,
                                        'border border-success shadow': values.mainPackageId === mainPackage.id && mainPackage.price === 0,
                                        'border border-primary shadow': values.mainPackageId === mainPackage.id && mainPackage.price > 0

                                    });

                                    let discountedPrice = mainPackage.discount && mainPackage.discount > 0 ? Number((mainPackage.price - (mainPackage.price * mainPackage.discount / 100))).toFixed(0) : null;

                                    // let bestSeller = allPackages.reduce((max, current) => Math.max(max.discount, current[0].discount), -Infinity);
                                    let bestSellerPackageId = findMaxProfitPackage(allPackages.filter(x => x.mainPlan === true && !x.isDisable && x.isVisible === true && x.discount > 0));
                                    return (
                                        <div key={"_main_packages_" + index} className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 d-flex">
                                            <div className={cardTopClass}>


                                                {bestSellerPackageId === mainPackage.id && <div className="ribbone ribbone-top-left text-danger">
                                                    <span className="bg-danger">
                                                        Best Seller
                                                    </span>
                                                </div>}
                                                {mainPackage.discount > 0 && <div className="arrow-ribbone-right bg-teal">Save {mainPackage.discount}%</div>}

                                                <div className="card-body p-0">
                                                    <div className={`px-1 py-2 op-3 ${mainPackage.price === 0 ? "bg-success" : "bg-info"}`} />
                                                    <div className="p-4 h-100 d-flex flex-column">
                                                        <div className="d-flex justify-content-center align-items-center mb-3">
                                                            <div className="fs-16 fw-semibold" style={{ minHeight: '55px' }}>{mainPackage.planName}</div>
                                                        </div>
                                                        <div className="fs-24 fw-bold mb-1">
                                                            <span>
                                                                {discountedPrice ? ("$" + discountedPrice) : ("$" + Number(mainPackage.price).toFixed(0))}
                                                                {discountedPrice &&
                                                                    <span className="fs-14 text-muted text-decoration-line-through ms-1 d-inline-block op-6">
                                                                        {("$" + Number(mainPackage.price).toFixed(2))}
                                                                    </span>}
                                                            </span>

                                                            {/* {discountedPrice && <span className="badge rounded-pill bg-warning fs-11">Save {mainPackage.discount}%</span>} */}
                                                        </div>
                                                        <div className="mb-1 text-muted">{mainPackage.description}</div>
                                                        <div className="fs-12 mb-3"><u>Billed when depleted</u></div>
                                                        <div className="flex-grow-shrink row">
                                                            <div className="col mt-auto">
                                                                <ul className="list-unstyled mb-0">
                                                                    <li className="d-flex align-items-center mb-2">
                                                                        <span className="me-2">
                                                                            <i className={`ri-checkbox-circle-line fs-15 ${mainPackage.productCount > 0 ? "text-success" : " text-muted op-3"}`} />
                                                                        </span>
                                                                        <span>
                                                                            <strong className="me-1">{mainPackage.productCount} Product</strong> count
                                                                        </span>
                                                                    </li>
                                                                    <li className="d-flex align-items-center mb-2">
                                                                        <span className="me-2">
                                                                            <i className={`ri-checkbox-circle-line fs-15 ${mainPackage.scanningCount > 0 ? "text-success" : " text-muted op-3"}`} />
                                                                        </span>
                                                                        <span>
                                                                            <strong className="me-1">{mainPackage.scanningCount} Scanning</strong> count
                                                                        </span>
                                                                    </li>
                                                                    <li className="d-flex align-items-center mb-2">
                                                                        <span className="me-2">
                                                                            <i className={`ri-checkbox-circle-line fs-15 ${mainPackage.sellerCount > 0 ? "text-success" : " text-muted op-3"}`} />
                                                                        </span>
                                                                        <span>
                                                                            <strong className="me-1">{mainPackage.sellerCount} Seller</strong> count
                                                                        </span>
                                                                    </li>
                                                                    <li className="d-flex align-items-center mb-2">
                                                                        <span className="me-2">
                                                                            <i className={`ri-checkbox-circle-line fs-15 ${mainPackage.userCount > 0 ? "text-success" : " text-muted op-3"}`} />
                                                                        </span>
                                                                        <span>
                                                                            <strong className="me-1">{mainPackage.userCount} User</strong> count
                                                                        </span>
                                                                    </li>

                                                                    <li className="d-flex align-items-center mb-2">
                                                                        <span className="me-2">
                                                                            <i className={`ri-checkbox-circle-line fs-15 ${mainPackage.isFBA ? "text-success" : " text-muted op-3"}`} />
                                                                        </span>
                                                                        <span className={`${mainPackage.isFBA ? "fw-bold" : ""}`}>
                                                                            FBA Option
                                                                        </span>
                                                                    </li>
                                                                    <li className="d-flex align-items-center mb-2">
                                                                        <span className="me-2">
                                                                            <i className={`ri-checkbox-circle-line fs-15 ${mainPackage.isWarehouse ? "text-success" : " text-muted op-3"}`} />
                                                                        </span>
                                                                        <span className={`${mainPackage.isWarehouse ? "fw-bold" : ""}`}>
                                                                            Warehouse Option
                                                                        </span>
                                                                    </li>
                                                                    <li className="d-flex align-items-center mb-2">
                                                                        <span className="me-2">
                                                                            <i className={`ri-checkbox-circle-line fs-15 ${mainPackage.isDropAmazon2Amazon ? "text-success" : " text-muted op-3"}`} />
                                                                        </span>
                                                                        <span className={`${mainPackage.isDropAmazon2Amazon ? "fw-bold" : ""}`}>
                                                                            Drop Amazon to Amazon
                                                                        </span>
                                                                    </li>
                                                                    <li className="d-flex align-items-center mb-2">
                                                                        <span className="me-2">
                                                                            <i className={`ri-checkbox-circle-line fs-15 ${mainPackage.isSmartPricer ? "text-success" : " text-muted op-3"}`} />
                                                                        </span>
                                                                        <span className={`${mainPackage.isSmartPricer ? "fw-bold" : ""}`}>
                                                                            SmartPricer
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="card-footer p-0 border border-0">
                                                    <div className="row justify-content-center my-3">
                                                        <div className="col-auto">
                                                            <button className={`btn  btn-wave waves-effect waves-light shadow ${mainPackage.id === values.mainPackageId ? "btn-danger" : "btn-primary"}`} type="button" value={mainPackage.id}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setFieldValue("packageFeaturesIds", []);
                                                                    if (mainPackage.id === values.mainPackageId) {
                                                                        resetForm()
                                                                        setFieldValue("mainPackageId", "");

                                                                    }
                                                                    else {
                                                                        resetForm()
                                                                        setFieldValue("mainPackageId", e.target.value)
                                                                    }
                                                                }}
                                                            >
                                                                {mainPackage.id === values.mainPackageId ? "Remove Plan" : "Choose Plan"}
                                                            </button>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </section>
                        <Restricted allowedTo={[
                            REACT_APP_ROLES_GUEST
                        ]}>
                            {<DrNavigator backAddress="" nextAddress="SUB_FEATURES" disabled={!isValid}
                                callback={() => {
                                    return Promise.all([
                                        dispatch(withActionPromise(dispatch(setPackagesSnapshotMainPackage(values.mainPackageId)))),
                                        dispatch(withActionPromise(dispatch(setCreatePackageSnapshotPageSubmission({ mainPackagesPage: true }))))
                                    ]);
                                }}
                            />}
                        </Restricted>
                        <Restricted allowedTo={[
                            REACT_APP_ROLES_USER,
                            REACT_APP_ROLES_SUPERADMIN,
                            REACT_APP_ROLES_ADMIN
                        ]}>
                            {<DrNavigator backAddress="" nextAddress="SUB_FEATURES" disabled={!isValid}
                                callback={() => {
                                    return Promise.all([
                                        dispatch(withActionPromise(dispatch(setPackagesSnapshotMainPackage(values.mainPackageId)))),
                                        dispatch(withActionPromise(dispatch(setCreatePackageSnapshotPageSubmission({ mainPackagesPage: true }))))
                                    ]);
                                }}
                            />}
                        </Restricted>
                    </form>
                </div>
            )
            }
        </Formik>
    )
}

export default MainPackages
