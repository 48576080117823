import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrDualDatePicker, DrInventoryFilter, DrPageFilter } from '../../components/component-index';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { timeOptions } from '../../components/hoc/Constants/dr-constants.component';
import { pageFilterTypes } from '../../utils/constants';
import { ExcelExport2 } from '../../excelexport';
import { generatePdfReport } from '../../utils/utils';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAsinPools, saveProductAsinBlock, saveProductBrandBlock } from '../../state-management/actions/inventory.actions';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { getRouteByKey } from '../../routes';
import DataTables from 'datatables.net';
import { getPageFilters } from '../../state-management/actions/page-filters.actions';




function CommonProductPoolPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('commonProductPoolPage');
  const asinPools = useSelector(state => state.inventory.asinPools);
  const selectedStore = useSelector(state => state.stores.selectedStore);



  useEffect(() => {
    if (selectedStore?.customerMainSellerId) {
        dispatch(getAsinPools());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const columns = [
    { data: null, title: "", minWidth: "40px", maxWidth: "40px", searchable: false, checkall: true, checkData: "asin" },
    { data: "asin", title: t('asin'), width: "125px", searchable: true },
    { data: "brandName", title: t('brand'), width: "125px", searchable: true },
    { data: "bannedType", title: t('type'), width: "20%", searchable: true },
    { data: "createdDate", title: t('createdAt'), width: "20%", searchable: false },
    { data: null, title: t('action'), width: "20%", searchable: false }
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <input type="checkbox" className="ckbox ms-3" name="name" value={rowData.asin + '#' + rowData.brandName}
              onChange={(e) => {
                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
              }}
            />
          </>
        )
      }
    },
    {
      targets: 1,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.asin}</span><br />
          </>);
      }
    },
    {
      targets: 2,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.brandName}</span><br />
          </>);
      }
    },
    {
      targets: 3,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.bannedType}</span><br />
          </>);
      }
    },
    {
      targets: 4,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{moment(rowData.createdDate).format('L LT')}</span><br />
          </>);
      }
    },
    {
      targets: 5,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div>
            <span id="droprightMenuButton" className="text-muted" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18 cursor-pointer"></i></span>
            <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>
              <span className="dropdown-item d-flex align-items-center cursor-pointer"
              onClick={(e) => {
                  dispatch(saveProductBrandBlock({ customerMainSellerId: selectedStore.customerMainSellerId, productBrandNames: [rowData.brandName], isBlocked: true }));
                }}>
              {t('blockBrand')}
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer" 
              onClick={(e) => {
                  dispatch(saveProductAsinBlock({ customerMainSellerId: selectedStore.customerMainSellerId, asinList: [rowData.asin]}));
                }}>
              
              {t('blockProduct')}
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer"
              onClick={(e) => {
                  dispatch(saveProductBrandBlock({ customerMainSellerId: selectedStore.customerMainSellerId, productBrandNames: [rowData.brandName], isBlocked: true }));
                  dispatch(saveProductAsinBlock({ customerMainSellerId: selectedStore.customerMainSellerId, asinList: [rowData.asin]}));
                }}
              >
              {t('blockBoth')}
              </span>
            </div>
          </div>)
      }
    }
  ];



  const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {
    if (checkedValues && checkedValues.length > 0) {

      if (selectedOperation === "0" || selectedOperation === "1") //export
      {
        var data = asinPools.filter(item1 =>
          !!checkedValues.find(item2 => item1.asin === item2));

        if (selectedOperation === "0") {

          const tableColumn = [
            {
              header: "ASIN",
              key: "asin",
              width: "100"
            },
            {
              header: "BRAND",
              key: "brandName",
              width: "100"
            },
            {
              header: "TYPE",
              key: "bannedType",
              width: "100"
            },
            {
              header: "CREATED DATE",
              key: "createdDate",
              width: "150"
            }
          ];

          const tableRows = [];

          // for each ticket pass all its data into an array
          data.forEach(product => {
            const productData = {
              asin: product.asin,
              brandName: product.brandName,
              bannedType: product.bannedType,
              createdDate: moment(product.createdDate).isValid() ? moment(product.createdDate).format('L LT') : ""
            };
            // push each tickcet's info into a row
            tableRows.push(productData);
          });

          ExcelExport2({ excelData: tableRows, columns: tableColumn, fileName: "common_product_pool" });

        }
        else if (selectedOperation === "1") {
          const tableColumn = ["ASIN", "BRAND", "TYPE", "CREATED DATE"];
          // define an empty array of rows
          const tableRows = [];

          // for each ticket pass all its data into an array
          data.forEach(product => {
            const productData = {
              asin: product.asin,
              brand: product.brandName,
              type: product.bannedType,
              createdDate: moment(product.createdDate).isValid() ? moment(product.createdDate).format('L LT') : ""
            };
            // push each tickcet's info into a row
            tableRows.push(productData);
          });
          generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Common Product Pool", orientation: 'p', reportName: "common_product_pool" });

        }
      }
      else if (selectedOperation === "13") {
        dispatch(saveProductBrandBlock({ customerMainSellerId: selectedStore.customerMainSellerId, productBrandNames: checkedValues.map(item => item.split('#')[1])  , isBlocked: true }));

      }
      else if (selectedOperation === "14") {
        dispatch(saveProductAsinBlock({ customerMainSellerId: selectedStore.customerMainSellerId, asinList: checkedValues.map(item => item.split('#')[0]) }));

      }
      else if (selectedOperation === "15") {
        dispatch(saveProductBrandBlock({ customerMainSellerId: selectedStore.customerMainSellerId, productBrandNames: checkedValues.map(item => item.split('#')[1]) , isBlocked: true }));
        dispatch(saveProductAsinBlock({ customerMainSellerId: selectedStore.customerMainSellerId, asinList: checkedValues.map(item => item.split('#')[0]) }));
    }
  }
  }

  return (
    <div style={{ marginBottom: '150px' }}>
      <div className="page-header mb-0 mt-4 mb-2 row row-md row-lg justify-content-between w-100">
        <DrBreadCrump
          breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
          { navigationText: i18next.t('constants:breadCrumps.commonProductPool'), navigationPath: "" }]} >
        </DrBreadCrump>
      </div>
      <div id="inventoryTable" className="row mb-5">
        <div className="col-lg-12 col-xl-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body horizontal-scrollable">
              {selectedStore?.customerMainSellerId.length > 0 &&
                <>
                  <DrDataTable
                    data={asinPools}
                    columns={columns}
                    columnDefs={columnDefs}
                    doesHaveBorder={false}
                    headerSearch={true}
                    //headerSelectbox={true}
                    //search={true}
                    minWidth="850px"
                    search={true}
                    isMultiSelectEnabled={true}
                    detailedSearchChildren={
                    <DrPageFilter
                      selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                      isSearchActive={false}
                      filterType={pageFilterTypes.commonProductPool}
                    >
                    </DrPageFilter>
                  }
                  ></DrDataTable>
                </>



              }
              {!selectedStore &&
                <div className="row row-md row-lg justify-content-center">
                  <div className="col-lg-auto col-md-2">
                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                      onClick={(e) => {
                        e.preventDefault();
                        Promise.all([
                          dispatch(withActionPromise(clearCreateStoreInformationState()))
                        ]).then((e) => {
                          navigate(getRouteByKey("MARKET_SELECTION"));
                        });;

                      }}>
                      <i className="me-2 fe fe-chevrons-right"></i>
                      {t('gotoMarketSelection')}
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CommonProductPoolPage