import React, { useState } from 'react';
import { getRouteByKey } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

function DrNavigateLink({
    name,
    className,
    children,
    navigateKey,
    navigatePath,
    navigateHash,
    ...rest }) {

    if (rest.disabled && rest.disabled === "") {
        delete rest.disabled;
    }
    const navigate = useNavigate();

    function CloseAllSlideMenu(e) {

        var slidemenus = document.querySelectorAll('.slide-menu.open');
        if (slidemenus) {
            $(slidemenus).removeClass('open');
            $(slidemenus).attr("display", "none");
            $(slidemenus).css('display', 'none');
            var slides = $(slidemenus).closest('.slide.is-expanded');
            if (slides && slides.length > 0) {
                $(slides).removeClass('is-expanded');
            }
        }
        var sidebar = document.querySelectorAll('.app.sidebar-gone.sidenav-toggled');
        if (sidebar) {
            $(sidebar).removeClass('sidenav-toggled');
        }
    }

    return (
        <>
            <a className={className} href={navigateHash ? navigateHash : "#"}
                onClick={(e) => {
                    e.preventDefault();
                    var path = navigateKey ? getRouteByKey(navigateKey) : navigatePath;
                    if (path) {
                        if (window.location.pathname !== path) {
                            navigate(path + (navigateHash ? "/" +navigateHash : ""));
                        }
                        // if (navigateHash) {
                        //     window.location.hash = navigateHash;
                        // }
                    }
                    CloseAllSlideMenu(e);
                }}

            >
                {children}
            </a>
        </>
    )
}

export default DrNavigateLink;
