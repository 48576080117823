const types = {
    GET_ALL_SEARCH_TASKS_REQUESTED: "GET_ALL_SEARCH_TASKS_REQUESTED",
    GET_SEARCH_TASK_REQUESTED: "GET_SEARCH_TASK_REQUESTED",
    SAVE_SEARCH_TASKS_REQUESTED: "SAVE_SEARCH_TASKS_REQUESTED",
    DELETE_SEARCH_TASKS_REQUESTED: "DELETE_SEARCH_TASKS_REQUESTED",
    CLEAR_ALL_SEARCH_TASKS: "CLEAR_ALL_SEARCH_TASKS",
    SET_ALL_SEARCH_TASKS: "SET_ALL_SEARCH_TASKS",

}

export default types;

export const getAllSearchTasks = (payload) => {
    return { type: types.GET_ALL_SEARCH_TASKS_REQUESTED, payload };
};

export const getSearchTask = (payload) => {
    return { type: types.GET_SEARCH_TASK_REQUESTED, payload };
};

export const saveSearchTasks = (payload) => {
    return { type: types.SAVE_SEARCH_TASKS_REQUESTED, payload };
};

export const deleteSearchTasks = (payload) => {
    return { type: types.DELETE_SEARCH_TASKS_REQUESTED, payload };
};

export const clearSearchTasks = () => {
    return { type: types.CLEAR_ALL_SEARCH_TASKS };
};

export const setSearchTasks = (payload) => {
    return { type: types.SET_ALL_SEARCH_TASKS, payload };
};
