const types = {
    GET_WALLET_INFO_REQUESTED: "GET_WALLET_INFO_REQUESTED",
    GET_WALLET_INFO_SUCCESS: "GET_WALLET_INFO_SUCCESS",
    GET_WALLET_INFO_FAILED: "GET_WALLET_INFO_FAILED",

    GET_TRANSACTION_SUMMARY_REQUESTED: "GET_TRANSACTION_SUMMARY_REQUESTED",
    GET_TRANSACTION_SUMMARY_SUCCESS: "GET_TRANSACTION_SUMMARY_SUCCESS",
    GET_TRANSACTION_SUMMARY_FAILED: "GET_TRANSACTION_SUMMARY_FAILED",

    MAKE_PAYMENT_TO_WALLET_REQUESTED: "MAKE_PAYMENT_TO_WALLET_REQUESTED",

    DELETE_SAVED_CARD_REQUESTED: "DELETE_SAVED_CARD_REQUESTED",
    SAVE_CARD_IS_FAVOURITE_REQUESTED: "SAVE_CARD_IS_FAVOURITE_REQUESTED",

    CLEAR_ALL_WALLET_DATA: "CLEAR_ALL_WALLET_DATA",
    SET_SELECTED_CARD: "SET_SELECTED_CARD",
}

export default types;

export const getWalletInfo = (payload) => {
    return { type: types.GET_WALLET_INFO_REQUESTED, payload };
};

export const getWalletInfoSuccess = (payload) => {
    return { type: types.GET_WALLET_INFO_SUCCESS, payload };
};

export const getTransactionSummary = (payload) => {
    return { type: types.GET_TRANSACTION_SUMMARY_REQUESTED, payload };
};

export const getTransactionSummarySuccess = (payload) => {
    return { type: types.GET_TRANSACTION_SUMMARY_SUCCESS, payload };
};

export const makePaymentToWallet = (payload) => {
    return { type: types.MAKE_PAYMENT_TO_WALLET_REQUESTED, payload };
};

export const deleteSavedCard = (payload) => {
    return { type: types.DELETE_SAVED_CARD_REQUESTED, payload };
};

export const saveCardIsFavourite = (payload) => {
    return { type: types.SAVE_CARD_IS_FAVOURITE_REQUESTED, payload };
};

export const clearAllWalletData = () => {
    return { type: types.CLEAR_ALL_WALLET_DATA };
};

export const setSelectedCreditCard = (payload) => {
    return { type: types.SET_SELECTED_CARD, payload };
};
