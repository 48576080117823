import types from '../actions/create-customer-packages.actions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_PACKAGES_SNAPSHOT_META:
            return { ...state, ...action.payload };
        case types.SET_MAIN_PACKAGE:
            return { ...state, "mainPackageId": action.payload };
        case types.SET_PACKAGE_FEATURES:
            return { ...state, "packageFeaturesIds": action.payload };
        case types.SET_CREDIT_CARD_INFORMATION:
            return {
                ...state, "creaditCardInfo": {
                    ...state.creaditCardInfo,
                    ...action.payload
                }

            };
        case types.CLEAR_CREATE_PACKAGES_INFORMATION:
            return initialState;

        case types.SET_CREATE_PACKAGES_SNAPSHOT_PAGE_SUBMISSION:
            return {
                ...state, "pageSubmissions": {
                    ...state.pageSubmissions,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default reducer;

const initialState = {
    "mainPackageId": "",
    "packageFeaturesIds": [],

    "creaditCardInfo":
    {
        "cardHolderName": "",
        "cardNumber": "",
        "expirationDate": "",
        "cvc": "",
        "isSaveCard":false
    },
    "pageSubmissions":
    {
        "mainPackagesPage": false,
        "packageFeaturesPage": false,
        "paymentPage": false
    },
    "packagesCreateSubmitted": false,
    "packagesSuccessfullyCreated": false
}
