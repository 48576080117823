import { BiError } from "react-icons/bi"

function DrErrorModal({ error }) {
    return (
        <div className="w-90vw md:w-108">
            <div className=""><BiError className="text-red-500 m-auto" size="72" /></div>
            <p className="text-3xl text-center font-bold mb-5">Ooops!</p>
            <p className="text-base text-center">{error.userFriendlyErrorMessage}</p>
        </div>
    )
}

export default DrErrorModal