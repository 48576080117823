import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiDelete, apiGet, apiPost, apiPut, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getAllSmartPricerStrategies, getAllSmartPricerStrategiesSuccess, getSmartPricerStrategyById, getSmartPricerStrategyByIdSuccess } from '../actions/smartpricer.actions';
import { setSmartPricerSnapshotMeta } from '../actions/create-smartpricer-filter.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';

function* runGetAllSmartPricerStrategies(action) {
    const payload = action.payload;

    var path = `?CustomerMainSellerId=${payload.customerMainSellerId}&SmartPricerStrategy=${payload.strategyTargetType}`;
    const response = yield call(apiGet(path, CONTROLLERS.SMARTPRICER, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getAllSmartPricerStrategiesSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error28') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetSmartPricerStrategyById(action) {
    const payload = action.payload;

    var path = `/GetId?id=${payload.id}`;
    if (payload.IsLowestGroup) {
        path += `&IsLowestGroup=${payload.IsLowestGroup}`;
    }
    if (payload.IsBuyBoxGroup) {
        path += `&IsBuyBoxGroup=${payload.IsBuyBoxGroup}`;
    }

    const response = yield call(apiGet(path, CONTROLLERS.SMARTPRICER, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(getSmartPricerStrategyByIdSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error27') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteSmartPricerStrategyById(action) {
    const payload = action.payload;
    var path = `?id=${payload.id}`;
    const response = yield call(apiDelete(path, CONTROLLERS.SMARTPRICER, true, true));
    if (response && response.isSuccess) {
        yield all([
            put(getAllSmartPricerStrategies({ customerMainSellerId: payload.customerMainSellerId, strategyTargetType: payload.strategyTargetType })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info8'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error31') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetSmartPricerStrategQuestions(action) {
    const payload = action.payload;

    var path = `/GetListBySellerId?CustomerMainSellerId=${payload.customerMainSellerId}`;
    const response = yield call(apiGet(path, CONTROLLERS.SMARTPRICER, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {


    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error32') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runCreateSmartPricerStrategy(action) {
    const payload = action.payload;
    const response = yield call(apiPost(``, payload, false, CONTROLLERS.SMARTPRICER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(setSmartPricerSnapshotMeta({ "smartpricerFilterSubmitted": true })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info7'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error29') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpdateSmartPricerStrategy(action) {
    const payload = action.payload;
    const response = yield call(apiPut(``, payload.smartpricerData, false, CONTROLLERS.SMARTPRICER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        if (payload.selectedStrategyTypeOption) {
            yield all([
                put(getAllSmartPricerStrategies({ customerMainSellerId: payload.smartpricerData.customerMainSellerId, strategyTargetType: payload.selectedStrategyTypeOption }))
            ]);
        }
        if (payload.selectedStrategyId) {
            yield all([
                put(getSmartPricerStrategyById({ id: payload.selectedStrategyId }))
            ]);
        }

        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info7'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error30') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


function* runAddRemoveAsinsFromSmartPricerStrategy(action) {

    const payload = action.payload;

    var addData = {
        smartPricerId: payload.currentSmartPricerId,
        asinList: payload.asins
    };

    if (payload.previousSmartPricerId && payload.previousSmartPricerId !== "-1") {
        var deleteData = {
            smartPricerId: payload.previousSmartPricerId,
            asinList: payload.asins
        };

        const responseDelete = yield call(apiPost(`/DeleteAsin`, deleteData, false, CONTROLLERS.SMARTPRICER, true, true));

        if (responseDelete) {
            if (addData.smartPricerId && addData.smartPricerId !== "-1") {
                const responseAdd = yield call(apiPost(`/AddAsin`, addData, false, CONTROLLERS.SMARTPRICER, true, true));
                if (responseAdd && responseAdd.isSuccess && responseAdd.resultType === 1) {
                    yield all([
                        put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info31'), 'success'))
                    ]);
                }
            }
        }
    }
    else {
        if (addData.smartPricerId && addData.smartPricerId !== "-1") {
            const responseAdd = yield call(apiPost(`/AddAsin`, addData, false, CONTROLLERS.SMARTPRICER, true, true));
            if (responseAdd && responseAdd.isSuccess && responseAdd.resultType === 1) {
                yield all([
                    put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info31'), 'success'))
                ]);
            }
        }
    }
}

function* runUpdateDefaultStrategy(action) {
    const payload = action.payload;
    const response = yield call(apiPut(`/UpdateDefaultStrategy`, { id: payload.selectedStrategyId }, false, CONTROLLERS.SMARTPRICER, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getAllSmartPricerStrategies({ customerMainSellerId: payload.customerMainSellerId, strategyTargetType: payload.selectedStrategyTypeOption })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info7'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error30') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


export default function* smartpricerSaga() {
    yield all([
        takeLatest(types.GET_ALL_SMARTPRICER_STRATEGIES_REQUESTED, safe(runGetAllSmartPricerStrategies)),
        takeLatest(types.GET_ALL_SMARTPRICER_STRATEGY_QUESTIONS_REQUESTED, safe(runGetSmartPricerStrategQuestions)),
        takeLatest(types.GET_SMARTPRICER_STRATEGY_REQUESTED, safe(runGetSmartPricerStrategyById)),
        takeLatest(types.DELETE_SMARTPRICER_STRATEGY_REQUESTED, safe(runDeleteSmartPricerStrategyById)),
        takeLatest(types.CREATE_SMARTPRICER_STRATEGY_REQUESTED, safe(runCreateSmartPricerStrategy)),
        takeLatest(types.UPDATE_SMARTPRICER_STRATEGY_REQUESTED, safe(runUpdateSmartPricerStrategy)),
        takeLatest(types.ADD_REMOVE_ASINS_SMARTPRICER_STRATEGY_REQUESTED, safe(runAddRemoveAsinsFromSmartPricerStrategy)),
         takeLatest(types.UPDATE_SMARTPRICER_DEFAULT_REQUESTED, safe(runUpdateDefaultStrategy))
    ]);
}