import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable } from '../../components/component-index';
import { setActivePictureModal } from '../../state-management/actions/app.actions';
import { modals } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { restrictedAndExcludedProductsMockData } from '../../utils/mocks-data';
import { ExportToExcel } from '../../excelexport';
import headphoneImg from '../../assets/img/media/headphone1.jpg'
import { useTranslation } from 'react-i18next';
import { getRouteByKey } from '../../routes';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

function RestrictedAndExcludedProductsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation('restrictedandexcludeproductsPage');
  const [tableData, setTableData] = useState([]);
  const [navigatorText2, setNavigatorText2] = useState(t('pageOptions.prohibitedAndOutdatedProducts'));
  const [inventoryPageOption, setInventoryPageOption] = useState("1");
  const language = useSelector(state => state.settings.language);

  const dispatch = useDispatch();

  useEffect(() => {
    setTableData(restrictedAndExcludedProductsMockData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inventoryPageOption === "1") {
      setNavigatorText2(t('pageOptions.prohibitedProducts'));
    }
    else if (inventoryPageOption === "2") {
      setNavigatorText2(t('pageOptions.outdatedProducts'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, inventoryPageOption]);


  const columns = [
    { data: "asin", title: "", width: "13%", minWidth: "130px", searchable: false, checkall: true, checkData: "asin" },
    { data: "productName", title: t('dataTable.productName'), width: "20%", minWidth: "200px", searchable: true },
    { data: "asin", title: t('dataTable.asin'), width: "125px", searchable: true },
    { data: "brand", title: t('dataTable.brand'), width: "15%", searchable: true },
    { data: "createdDate", title: t('dataTable.created'), width: "20%", searchable: false },
    { data: null, title: "", width: "20%", searchable: false }
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <input type="checkbox" className="ckbox" name="name" value={rowData.asin}
              onChange={(e) => {
                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
              }}
            />
            <div className="card-aside-img" style={{ marginLeft: '35px', marginBottom: '5px', textAlign: 'start' }} >
              <img src={headphoneImg} alt="img" data-tag="pic-modal-opener" className="img-sm p-0 rounded-0"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "pisture", imgsource: [headphoneImg] }))
                }}
              ></img>
              <div className="btn-light" style={{ width: '64px', height: '20px', textAlign: 'center', color: 'rgb(111, 66, 193)' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(getRouteByKey("ORDERDETAIL"));
                }}
              >
                <i className='fa fa-search' ></i>
              </div>
            </div>
          </>);
      }
    },
    {
      targets: 1,
      orderable: false,
      className: "text-center",
      width: "20%",
      searchable: true,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div className="media-body mt-1" style={{ width: '200px' }}>
            <div className="card-item-desc mt-0">
              <h6 className="font-weight-normal mt-0 text-uppercase">HEAD PHONES</h6>
              <dl className="card-item-desc-1">
                <dt>{rowData.productName}</dt>
              </dl>
            </div>
          </div>);
      }
    },
    {
      targets: 2,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.asin}</span><br />
          </>);
      }
    },
    {
      targets: 3,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div className="text-center">
            <p><i className="fa fa-circle" style={{ marginRight: '10px', color: '#ff0000' }}></i>{rowData.brand}</p>
          </div>);
      }
    },
    {
      targets: 4,
      orderable: true,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div>
            <span style={{ marginBottom: '85px' }}>{rowData.createdDate}</span>

          </div>);
      }
    },
    {
      targets: 5,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div>

            <span id="droprightMenuButton" className="text-muted" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18 cursor-pointer"></i></span>
            <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>
              <span className="dropdown-item d-flex align-items-center cursor-pointer">
                Detayları görüntüleyin
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer">
                Güncelleme geçmişini görüntüleyin
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer">
                Amazon.com'da görüntüleyin
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer">
                Amazon.ca'da görüntüleyin
              </span>
              <span className="dropdown-item d-flex align-items-center cursor-pointer">
                Amazon.ca'da teklifleri görüntüleyin
              </span>
            </div>
          </div>)
      }
    }
  ];

  const inventoryPageOptions = [
    { label: t('pageOptions.prohibitedProducts'), value: "1" },
    { label: t('pageOptions.outdatedProducts'), value: "2" },
  ];

  const optionClickCallBack = (value) => {
    // var val = value;
    setInventoryPageOption(value);
    //setNavigatorText2(inventoryPageOptions.find(x => x.value === value).text);
  };

  const checkedValuesCallBack = (checkedValues) => {
    var data = restrictedAndExcludedProductsMockData.filter(item1 =>
      !!checkedValues.find(item2 => item1.asin === item2));

    ExportToExcel({ excelData: data, fileName: "restrictedandecludedproducts" });
  }

  return (
    <>
      <div style={{ marginBottom: '150px' }}>
        <div className="page-header mb-0 mt-4 mb-2 row row-md row-lg justify-content-between w-100">
          <div className="col-lg-auto col-md-auto col-sm-auto col-xs-12">
          <DrBreadCrump
            breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
            { navigationText: navigatorText2, navigationPath: "" }]} >
          </DrBreadCrump>
          </div>
          <div className="col-lg-auto col-md-auto col-sm-auto col-xs-12 px-0" >
            <DrButtonDropdown options={inventoryPageOptions} optionClickCallBack={optionClickCallBack}>
            </DrButtonDropdown>
          </div>
        </div>
        <div id="inventoryTable" className="row mb-5">
          <div className="col-lg-12 col-xl-12 col-md-12">
            <div className="card custom-card">
              <div className="card-body horizontal-scrollable">
                {tableData && <>
                  <DrDataTable
                    data={tableData}
                    columns={columns}
                    columnDefs={columnDefs}
                    doesHaveBorder={false}
                    headerSearch={true}
                    //headerSelectbox={true}
                    //search={true}
                    minWidth="850px"
                    isMultiSelectEnabled={true}
                    checkedValuesCallBack={checkedValuesCallBack}
                  />
                </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RestrictedAndExcludedProductsPage;