import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrLineChart } from '../../components/component-index';
import { DrDataTable } from '../../components/component-index';
import { salesReportMockData } from '../../utils/mocks-data';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function ProfitReportPage() {
    const [tableData, setTableData] = useState([]);
    const { t } = useTranslation('profitReportPage');

    useEffect(() => {
        setTableData(salesReportMockData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { data: "orderNo", title: t('dataTable.orderNo'), width: "15%", minWidth: "120px", searchable: true },
        { data: "buyerOrderNo", title: t('dataTable.buyerOrderNo'), width: "5%", minWidth: "10px", searchable: true },
        { data: "status", title: t('dataTable.status'), width: "5%", minWidth: "70px", searchable: true },
        { data: "price", title: t('dataTable.fiyat'), width: "5%", minWidth: "55px", searchable: true },
        { data: "earning", title: t('dataTable.earning'), width: "5%", minWidth: "20px", searchable: true },
        { data: "earning2", title: t('dataTable.earning2'), width: "10%", searchable: true },
        { data: "fee", title: t('dataTable.fee'), width: "20%", searchable: true },
        { data: "date", title: t('dataTable.date'), width: "20%", minWidth: "80px", searchable: true },
        { data: null, title: "", width: "3%", searchable: false }
    ];

    const columnDefs = [

        {
            targets: 0,
            orderable: true,
            className: "text-center",
            width: "20%",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.orderNo}</span><br />
                    </>)
            }
        },
        {
            targets: 1,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.buyerOrderNo}</span><br />
                    </>);
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.status}</span><br />
                    </>);
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.price}</span><br />
                    </>);
            }
        },
        {
            targets: 4,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div className="text-center" style={{ fontSize: '16px' }}>
                        <span className="badge bg-info">{rowData.price}</span><br />
                        <span className="badge bg-info">{rowData.earning}</span><br />
                        <span className="badge bg-info">Kâr: {rowData.earning2}</span>
                    </div>);
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.earning2}</span><br />
                    </>);
            }
        },
        {
            targets: 6,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.fee}</span><br />
                    </>);
            }
        },
        {
            targets: 7,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <span style={{ marginBottom: '85px' }}>{rowData.date}</span>

                    </div>);
            }
        },
        {
            targets: 8,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>

                        <span id="droprightMenuButton" className="text-muted" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18 cursor-pointer"></i></span>
                        <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Detayları görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Güncelleme geçmişini görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Amazon.com'da görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Amazon.ca'da görüntüleyin
                            </span>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer">
                                Amazon.ca'da teklifleri görüntüleyin
                            </span>
                        </div>
                    </div>)
            }
        }
    ];

    const data2 = {
        labels: ["Ocak 2023", "Şubat 2023", "Mart 2023", "Nisan 2023", "Mayıs 2023", "Haziran 2023", "Temmuz 2023", "Ağustos 2023", "Eylül 2023", "Ekim 2023", "Kasım 2023", "Aralık 2023"],
        type: 'line',
        datasets: [{
            label: 'Maximum',
            data: [47, 45, 154, 38, 156, 24, 65, 31, 137, 39, 162, 51, 35, 141, 35, 27, 93, 53],
            //backgroundColor: 'linear-gradient(90deg, rgba(86,56,255,0.8) 0%, rgba(86,56,255,0.2) 35%, rgba(0,212,255,1) 100%)',
            borderColor: '#8760fb',
            pointBackgroundColor: '#fff',
            pointHoverBackgroundColor: '#8760fb',//
            pointBorderColor: '#8760fb',
            pointHoverBorderColor: '#fff',//
            pointBorderWidth: 0,
            pointRadius: 0,
            pointHoverRadius: 0,
            lineTension: 0.2,
            borderWidth: 2,
            fill: 'origin'
        },
        {
            label: 'Minimum',
            data: [61, 27, 54, 143, 119, 46, 47, 45, 54, 138, 56, 24, 165, 31, 37, 39, 62, 51, 35, 41],
            //backgroundColor:  '#fff',
            borderColor: '#01b8ff',
            pointBackgroundColor: '#fff',
            pointHoverBackgroundColor: '#8760fb',//
            pointBorderColor: '#01b8ff',
            pointHoverBorderColor: '#fff',//
            pointBorderWidth: 0,
            pointRadius: 0,
            pointHoverRadius: 0,
            lineTension: 0.2,
            borderWidth: 2,
            fill: 'origin'
        }]
    }

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            mode: 'index',
            titleFontSize: 12,
            titleFontColor: '#000',
            bodyFontColor: '#000',
            backgroundColor: '#fff',
            cornerRadius: 3,
            intersect: false,
        },
        stepsize: 200,
        min: 0,
        max: 400,
        legend: {
            display: true,
            labels: {
                usePointStyle: false,
            },
        },
        scales: {
            x: {
                display: true,
                grid: {
                    display: true,
                    drawBorder: false,
                    zeroLineColor: 'rgba(142, 156, 173,0.1)',
                    color: 'rgba(119, 119, 142, 0.08)'
                },
                ticks: {
                    autoSkip: true,
                    color: '#b0bac9'
                },
                scaleLabel: {
                    display: false,
                    labelString: 'Month',
                    fontColor: 'transparent'
                }
            },
            y: {
                ticks: {
                    color: "#b0bac9",
                },
                display: true,
                grid: {
                    display: true,
                    drawBorder: false,
                    zeroLineColor: 'rgba(142, 156, 173,0.1)',
                    color: "rgba(142, 156, 173,0.1)",
                },
                scaleLabel: {
                    display: false,
                    labelString: 'sales',
                    fontColor: 'transparent'
                }
            }
        },
        title: {
            display: false,
            text: 'Normal Legend'
        },
    }

    const chartData = {
        labels: data2.labels,
        datasets: data2.datasets,
        options: chartOptions
    };

    return (
        <div>
            <div className="page-header mb-0 mt-4 mb-2 row row-md row-lg justify-content-between w-100">
                    <DrBreadCrump
                        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.reportingAndAnalysis'), navigationPath: "" },
                        { navigationText: i18next.t('constants:breadCrumps.profitReport'), navigationPath: "" }]} >
                    </DrBreadCrump>
                <div className="col-lg-auto col-md-auto col-sm-auto col-xs-12 px-0 pb-2">
                    <div className="row">
                        <div className="col-auto">
                            <span className="btn ripple btn-primary dropdown-toggle mb-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                Tüm Zamanlar<i className="fa fa-caret-down ms-1" />
                            </span>
                            <div className="dropdown-menu tx-13">
                                <span className="dropdown-item">
                                    7 Gün
                                </span>
                                <span className="dropdown-item">
                                    15 Gün
                                </span>
                                <span className="dropdown-item">
                                    30 Gün
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row row-sm">
                <div className="col-sm-6 col-xl-3 col-lg-6">
                    <div className="card custom-card h-eq-20px">
                        <div className="card-body dash1">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-1 tx-inverse">Ürün Satışları</p>
                                </div>
                                <div className="col-auto ms-auto">
                                    <i className="fa fa-usd fs-20 text-success" />
                                </div>
                            </div>
                            <div>
                                <h3 className="dash-25">CN $65.788</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3 col-lg-6">
                    <div className="card custom-card h-eq-20px">
                        <div className="card-body dash1">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-1 tx-inverse">Toplam Kar</p>
                                </div>
                                <div className="col-auto ms-auto">
                                    <i className="fa fa-usd fs-20 text-success" />
                                </div>
                            </div>
                            <div>
                                <h3 className="dash-25">CN $65.788</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3 col-lg-6">
                    <div className="card custom-card h-eq-20px">
                        <div className="card-body dash1">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-1 tx-inverse">Teslim Edilen Kar</p>
                                </div>
                                <div className="col-auto ms-auto">
                                    <i className="fa fa-usd fs-20 text-success" />
                                </div>
                            </div>
                            <div>
                                <h3 className="dash-25">CN$ 4.358.50</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3 col-lg-6">
                    <div className="card custom-card h-eq-20px">
                        <div className="card-body dash1">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-1 tx-inverse">Sipariş Ortalama Kar</p>
                                </div>
                                <div className="col-auto ms-auto">
                                    <i className="fa fa-usd fs-20 text-success" />
                                </div>
                            </div>
                            <div>
                                <h3 className="dash-25">CN$6.10</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-6 col-lg-6">
                    <div className="card custom-card h-eq-20px">
                        <div className="card-body dash1">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-1 tx-inverse">Toplam Maliyet</p>
                                </div>
                                <div className="col-auto ms-auto">
                                    <i className="fa fa-usd fs-20 text-success" />
                                </div>
                            </div>
                            <div>
                                <h3 className="dash-25">CN$42.153.91</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-6 col-lg-6">
                    <div className="card custom-card h-eq-20px">
                        <div className="card-body dash1">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-1 tx-inverse">Ortalama Sipariş Maliyet</p>
                                </div>
                                <div className="col-auto ms-auto">
                                    <i className="fa fa-usd fs-20 text-success" />
                                </div>
                            </div>
                            <div>
                                <h3 className="dash-25">CN$58.14</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row row-sm">
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    <div className="card custom-card overflow-hidden h-eq-20px">
                        <div className="card-body">
                            <div className="card-option d-flex">
                                <div>
                                    <h6 className="card-title mb-1">Sipariş Geçimişi</h6>
                                </div>
                                <div className="card-option-title ms-auto">
                                    {/* <div className="btn-group p-0">
                                        <button className="btn btn-light btn-sm" type="button">Sipariş</button>
                                        <button className="btn btn-outline-light btn-sm" type="button">Kâr</button>
                                        <button className="btn btn-outline-light btn-sm" type="button">Maliyet</button>
                                    </div> */}
                                </div>
                            </div>
                            <div>
                                <DrLineChart chartData={chartData} options={chartOptions} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row row-sm">

                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                        <div className="card-body horizontal-scrollable">
                            <div className="card-option d-flex">
                                <div>
                                    <h6 className="card-title mb-1">En Karlı Satışlar</h6>
                                </div>
                                <div className="card-option-title ms-auto">
                                    {/* <div className="btn-group p-0">
                                        <button className="btn btn-light btn-sm" type="button">Sipariş</button>
                                        <button className="btn btn-outline-light btn-sm" type="button">Kâr</button>
                                        <button className="btn btn-outline-light btn-sm" type="button">Maliyet</button>
                                    </div> */}
                                </div>
                            </div>
                            {tableData && <>
                                <DrDataTable
                                    data={tableData}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    //search={true}
                                    minWidth="850px"
                                />
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfitReportPage;