import types from '../actions/create-customer.actions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_EXTENSION_CUSTOMERS_SUCCESS:
            return {
                ...state, "preRegisteredPersonList": action.payload
            }
        default:
            return state;
    }
};

export default reducer;

const initialState = {
    "preRegisteredPersonList": []
}
