import types from "../actions/customer.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_ACCOUNT_WITH_EMAIL_FAILED:
        case types.CLEAR_CREATE_ACCOUNT_EMAIL_SENT_STATE:
        case types.LOGOUT_USER:
            return { ...initialState }
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedin: action.payload.customerId ? true : false, ...action.payload
            }
        case types.SET_CUSTOMER_INFO:
            return {
                ...state, "customerInfo": {
                    ...state.customerInfo,
                    ...action.payload
                }
            }
        case types.SET_CUSTOMER_INFO_ROLE:
            return {
                ...state, "customerInfo": {
                    ...state.customerInfo,
                    "role": action.payload
                }
            };
        case types.SET_CUSTOMER_INFO_AUTHORIZATION:
            return {
                ...state, "customerInfo": {
                    ...state.customerInfo,
                    "authorization": action.payload
                }
            };
        case types.SET_SUBUSERS:
            return {
                ...state, "subUsers": action.payload
            }
        case types.GET_ROLES_SUCCESS:
            return {
                ...state, "roles": action.payload
            }
        case types.GET_ROLES_FAILED:
            return {
                ...state, "roles": []
            }
        case types.SET_IS_EXTENSION:
            return {
                ...state, "isExtension": action.payload
            }
        case types.SET_CUSTOMER_PROFILE_INFO_SUCCESS:
            return {
                ...state, "customerProfileInfo": action.payload
            }
        case types.GET_CUSTOMER_PACKAGE_INFO_SUCCESS:
            return {
                ...state, "customerPackageInfo": action.payload
            }
        default:
            return state;
    }
}

const initialState = {
    "isLoggedin": false,
    "isExtension": false,
    "token": "",
    "customerInfo":
    {
        "id": "",
        "firstName": "",
        "lastName": "",
        "profilePicture": "",
        "email": "",
        "role": [],
        "authorization": [],
    },
    "subUsers": [],
    "roles": [],
    "customerProfileInfo": null,
    "customerPackageInfo": null
}

export default reducer;