import React, { useEffect } from "react";
import { DrBreadCrump } from "../../components/component-index";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import i18next from "i18next";
import { withActionPromise } from "../../state-management/actions/app.actions";
import { getNotifications } from "../../state-management/actions/notification.actions";

import seller_central1 from "../../assets/img/media/seller_central1.JPG";
import seller_central2 from "../../assets/img/media/seller_central2.JPG";
import seller_central3 from "../../assets/img/media/seller_central3.JPG";
import seller_central4 from "../../assets/img/media/seller_central4.JPG";
import seller_central5 from "../../assets/img/media/seller_central5.JPG";
import seller_central6 from "../../assets/img/media/seller_central6.JPG";
import seller_central7 from "../../assets/img/media/seller_central7.JPG";
import seller_central8 from "../../assets/img/media/seller_central8.JPG";

import { getCustomerStore } from "../../state-management/actions/store.actions";
import { siteExtensions } from "../../utils/constants";

import { toastr } from "react-redux-toastr";

function EmailFeedbackConfigPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation("feedbackConfigPage");
    const myStore = useSelector((state) => state.stores.myStore);
    const selectedStore = useSelector((state) => state.stores.selectedStore);

    useEffect(() => {
        dispatch(withActionPromise(getNotifications()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(
                getCustomerStore({
                    marketId: selectedStore?.marketId,
                    customerMainSellerId: selectedStore?.customerMainSellerId,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId]);

    return (
        <>
            <div>
                <DrBreadCrump
                    breadCrumps={[
                        {
                            navigationText: i18next.t("constants:breadCrumps.feedbackConfig"),
                            navigationPath: "",
                        },
                    ]}
                ></DrBreadCrump>
                <div className="row row-md row-lg d-flex justify-content-center" >
                    <div className="col-md-8 col-lg-12" >
                        <div className="card custom-card">
                            <div className="card-body">

                                <ul>
                                    <li>
                                        <i className="fa fa-angle-double-right mb-2 me-2"></i>
                                        {t('infoTextOne')}
                                    </li>
                                    <li>
                                        <i className="fa fa-angle-double-right mb-2 me-2"></i>
                                        {t('infoTextTwo')}
                                    </li>
                                    <li>
                                        <i className="fa fa-angle-double-right mb-2 me-2"></i>
                                        {t('infoTextThree')}
                                    </li>
                                </ul>

                                <div className="row border m-2">
                                    <div className="col-12">
                                        <div className="row p-4">
                                            <div className="col-12">
                                                <div className="row align-items-center">
                                                    {myStore.customerNotificationEmail &&
                                                        <>
                                                            <div className="col-auto">
                                                                <label>{t('copyAdress')}</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                {myStore.customerNotificationEmail}
                                                            </div>
                                                            <div className="col-auto px-0 my-auto cursor-pointer">
                                                                <span
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        navigator.clipboard.writeText("123456");
                                                                        toastr.info(
                                                                            "Info",
                                                                            myStore.customerNotificationEmail + " is copied to clipboard",
                                                                            { timeOut: 1000 }
                                                                        );
                                                                    }}
                                                                >
                                                                    <i className="fe fe-copy fw-bold text-primary"></i>
                                                                </span>
                                                            </div>
                                                        </>
                                                    }
                                                    {!myStore.customerNotificationEmail &&
                                                        <strong className="text-warning">
                                                            {t('noEmail')}
                                                        </strong>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row p-4">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col">
                                                        <ul>
                                                            <li>
                                                                <label>
                                                                    <Trans
                                                                        i18nKey={t('stepOne')}
                                                                        components={{
                                                                            span: (
                                                                                <span className="btn btn-sm btn-success d-none d-md-inline-block"
                                                                                    onClick={() => window.open(
                                                                                        "https://sellercentral.amazon." +
                                                                                        siteExtensions.find(
                                                                                            (x) =>
                                                                                                x.countryCode ===
                                                                                                (selectedStore?.countryCode || "US")
                                                                                        )?.extension
                                                                                    )}
                                                                                    target="_blank"
                                                                                    rel="external">
                                                                                </span>
                                                                            ),
                                                                            badge: (
                                                                                <span className="badge bg-primary mt-1">
                                                                                    {"www.amazon." +
                                                                                        siteExtensions.find(
                                                                                            (x) => x.countryCode === (selectedStore?.countryCode || "US")
                                                                                        )?.extension}
                                                                                </span>
                                                                            ),

                                                                        }}
                                                                        values={{
                                                                            storeUrl:
                                                                                "www.amazon." +
                                                                                siteExtensions.find(
                                                                                    (x) => x.countryCode === (selectedStore?.countryCode || "US")
                                                                                )?.extension,
                                                                        }}
                                                                    />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div >
                                                    <button
                                                        className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#feedback-step-2"}
                                                    >
                                                        <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                    </button>

                                                </div>
                                                <div
                                                    id="feedback-step-2"
                                                    className="row py-3 collapse justify-content-center"
                                                >
                                                    <div className="col-12 col-xl-9">
                                                        <img
                                                            src={seller_central1}
                                                            alt=""
                                                            style={{ width: "100%" }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row p-4">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col">
                                                        <ul>
                                                            <li>
                                                                <label>
                                                                    <Trans
                                                                        i18nKey={t('stepTwo')}
                                                                        components={{
                                                                            span: (
                                                                                <span className="btn btn-sm btn-success d-none d-md-inline-block"
                                                                                    target="_blank"
                                                                                    rel="external"
                                                                                    onClick={() => window.open(
                                                                                        "https://sellercentral.amazon." +
                                                                                        siteExtensions.find(
                                                                                            (x) => x.countryCode === (selectedStore?.countryCode || "US")
                                                                                        )?.extension + "/messaging/permissions"
                                                                                    )}
                                                                                >
                                                                                </span>
                                                                            ),
                                                                            badge: (
                                                                                <span className="badge bg-primary mt-1">
                                                                                    {"www.amazon." +
                                                                                        siteExtensions.find(
                                                                                            (x) => x.countryCode === (selectedStore?.countryCode || "US")
                                                                                        )?.extension}
                                                                                </span>
                                                                            ),
                                                                        }}
                                                                    />

                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div >
                                                    <button
                                                        className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#feedback-step-3"}
                                                    >
                                                        <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                    </button>

                                                </div>
                                                <div
                                                    id="feedback-step-3"
                                                    className="row py-3 collapse justify-content-center"
                                                >
                                                    <div className="col-12 col-xl-9">
                                                        <img
                                                            src={seller_central2}
                                                            alt=""
                                                            style={{ width: "100%" }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row p-4">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col">
                                                        <ul>
                                                            <li>
                                                                <label>
                                                                    <Trans
                                                                        i18nKey={t('stepThree')}
                                                                        components={{
                                                                            bold: <strong />,
                                                                            underline: <u />
                                                                        }}
                                                                    />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div >
                                                    <button
                                                        className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#feedback-step-4"}
                                                    >
                                                        <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                    </button>

                                                </div>
                                                <div
                                                    id="feedback-step-4"
                                                    className="row py-3 collapse justify-content-center"
                                                >
                                                    <div className="col-12 col-xl-9">
                                                        <img
                                                            src={seller_central3}
                                                            alt=""
                                                            style={{ width: "100%" }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row p-4">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col">
                                                        <ul>
                                                            <li >
                                                                <label>
                                                                    <Trans
                                                                        i18nKey={t('stepFour')}
                                                                        components={{
                                                                            span: (
                                                                                <span className="btn btn-sm btn-success d-none d-md-inline-block"
                                                                                    onClick={() => window.open(
                                                                                        "https://sellercentral.amazon." +
                                                                                        siteExtensions.find(
                                                                                            (x) =>
                                                                                                x.countryCode ===
                                                                                                (selectedStore?.countryCode || "US")
                                                                                        )?.extension +
                                                                                        "/notifications/preferences"
                                                                                    )}
                                                                                    target="_blank"
                                                                                    rel="external">
                                                                                </span>
                                                                            ),

                                                                        }}
                                                                        values={{
                                                                            storeUrl:
                                                                                "www.amazon." +
                                                                                siteExtensions.find(
                                                                                    (x) => x.countryCode === (selectedStore?.countryCode || "US")
                                                                                )?.extension,
                                                                        }}
                                                                    />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div >
                                                    <button
                                                        className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#feedback-step-5"}
                                                    >
                                                        <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                    </button>
                                                </div>
                                                <div
                                                    id="feedback-step-5"
                                                    className="row py-3 collapse justify-content-center"
                                                >
                                                    <div className="col-12 col-xl-9">
                                                        <img
                                                            src={seller_central4}
                                                            alt=""
                                                            style={{ width: "100%" }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row p-4">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col">
                                                        <ul>
                                                            <li>
                                                                <label>
                                                                    <Trans
                                                                        i18nKey={t('stepFive')}
                                                                        components={{
                                                                            bold: <strong />,
                                                                            underline: <u />
                                                                        }}
                                                                    />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div >
                                                    <button
                                                        className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#feedback-step-6"}
                                                    >
                                                        <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                    </button>
                                                </div>
                                                <div
                                                    id="feedback-step-6"
                                                    className="row py-3 collapse justify-content-center"
                                                >
                                                    <div className="col-12 col-xl-9">
                                                        <img
                                                            src={seller_central5}
                                                            alt=""
                                                            style={{ width: "100%" }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row p-4">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col">
                                                        <ul>
                                                            <li>
                                                                <label>
                                                                    <Trans
                                                                        i18nKey={t('stepSix')}
                                                                        components={{
                                                                            bold: <strong />,
                                                                            underline: <u />
                                                                        }}
                                                                    />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div >
                                                    <button
                                                        className="cursor-pointer collapsed ms-4 tx-bold btn btn-primary"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#feedback-step-7"}
                                                    >
                                                        <i className="fe fe-camera tx-bold" /> {t('showImage')}
                                                    </button>
                                                </div>
                                                <div
                                                    id="feedback-step-7"
                                                    className="row py-3 collapse justify-content-center"
                                                >
                                                    <div className="col-12 col-xl-9">
                                                        <img
                                                            src={seller_central7}
                                                            alt=""
                                                            style={{ width: "100%" }}
                                                        ></img>
                                                        <img
                                                            src={seller_central8}
                                                            alt=""
                                                            style={{ width: "100%" }}
                                                        ></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailFeedbackConfigPage;
