const types = {
    SET_SMARTPRICER_SNAPSHOT_META:"SET_SMARTPRICER_SNAPSHOT_META",
    SET_SMARTPRICER_FILTER_TYPE:"SET_SMARTPRICER_FILTER_TYPE",
    SET_SMARTPRICER_QUESTIONS:"SET_SMARTPRICER_QUESTIONS",
    SET_SMARTPRICER_TEMPLATE_QUESTIONS:"SET_SMARTPRICER_TEMPLATE_QUESTIONS",
    SET_SMARTPRICER_TARGET_CATEGORY:"SET_SMARTPRICER_TARGET_CATEGORY",
    SET_SMARTPRICER_TARGET_ASINS:"SET_SMARTPRICER_TARGET_ASINS",
    SET_SMARTPRICER_NAME: "SET_SMARTPRICER_NAME",
    CLEAR_SMARTPRICER_INFORMATION:"CLEAR_SMARTPRICER_INFORMATION",
    SET_SNAPSHOT_SMARTPRICER_PAGE_SUBMISSION:"SET_SNAPSHOT_SMARTPRICER_PAGE_SUBMISSION"
}

export default types;

export const setSmartPricerSnapshotMeta = (payload) => {
    return { type: types.SET_SMARTPRICER_SNAPSHOT_META, payload };
};

export const setSmartPricerFilterType = (payload) => {
    return { type: types.SET_SMARTPRICER_FILTER_TYPE, payload };
};

export const setSmartPricerQuestions = (payload) => {
    return { type: types.SET_SMARTPRICER_QUESTIONS, payload };
};

export const setSmartPricerTemplateQuestions = (payload) => {
    return { type: types.SET_SMARTPRICER_TEMPLATE_QUESTIONS, payload };
};

export const setSmartPricerTargetCategory = (payload) => {
    return { type: types.SET_SMARTPRICER_TARGET_CATEGORY, payload };
};

export const setSmartPricerTargetAsins = (payload) => {
    return { type: types.SET_SMARTPRICER_TARGET_ASINS, payload };
};

export const setSmartPricerName = (payload) => {
    return { type: types.SET_SMARTPRICER_NAME, payload };
};

export const clearSmartPricerState = () => {
    return { type: types.CLEAR_SMARTPRICER_INFORMATION };
}

export const setSmartPricerPageSubmission = (payload) => {
    return { type: types.SET_SNAPSHOT_SMARTPRICER_PAGE_SUBMISSION, payload };
};;