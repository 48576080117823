import { useState } from "react";
import ReactDOM from 'react-dom/client';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DrBreadCrump, DrDataTable, DrPageFilter, DrPDFViewer } from "../../components/component-index";
import i18next, { use } from 'i18next';
import { withActionPromise } from "../../state-management/actions/app.actions";
import { clearCreateStoreInformationState } from "../../state-management/actions/create-store.actions";
import { getRouteByKey } from "../../routes";
import { useEffect } from "react";
import { getWarehouseOrderInvoiceById, getWarehouseOrdersInvoices } from "../../state-management/actions/warehouses.actions";
import moment from "moment";


function InvoicesPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('invoicesPage');
    const [tableData, setTableData] = useState([]);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);
    const warehouseOrdersInvoices = useSelector(state => state.warehouses.warehouseOrdersInvoices);
    const warehouseOrderInvoiceById = useSelector(state => state.warehouses.warehouseOrderInvoiceById);
    const [showPDF, setShowPDF] = useState(false);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getWarehouseOrdersInvoices(selectedStore?.customerMainSellerId)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId]);

    const getBadgeClass = (statu) => {
        switch (statu) {
            case 'Canceled':
                return 'bg-danger';
            case 'PartiallyShipped':
                return 'bg-warning';
            case 'Shipped':
                return 'bg-success';
            case 'Unshipped':
                return 'bg-info';
            default:
                return 'bg-info';
        }
    };

    const columns = [
        { data: "orderNumber", title: '', maxWidth: "60px", minWidth: "60px", searchable: false },
        { data: "orderNumber", title: t('dataTable.orderNumber'), minWidth: "200px", searchable: true },
        { data: "statu", title: t('dataTable.statu'), minWidth: "130px", searchable: true },
        { data: "paymentAmount", title: t('dataTable.paymentAmount'), minWidth: "100px", searchable: true },
        { data: "paymentType", title: t('dataTable.paymentType'), minWidth: "150px", searchable: true },
        { data: "paymentDate", title: t('dataTable.paymentDate'), minWidth: "180px", searchable: true },
        { data: null, title: '', maxWidth: "70px", minWidth: "70px", searchable: false }
    ]

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <input type="checkbox" className="ckbox" name="name" value={rowData.orderNumber}
                            onChange={(e) => {
                                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                            }}
                        />
                    </>
                )
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-14 px-1 py-1">{rowData.orderNumber}</span><br />
                    </>);
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className={`badge fs-11 ${getBadgeClass(rowData.statu)}`}>{rowData.statu}</span><br />
                    </>);
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="text-end badge bg-info fs-11">{selectedStoreCurrencyType.currencyCode} {Number(rowData.paymentAmount).toFixed(2)}</span>
                    </>);
            }
        },
        {
            targets: 4,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-14 px-1 py-1">{rowData.paymentType}</span><br />
                    </>);
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-14 px-1 py-1">{moment(rowData.paymentDate).format('L LT')}</span><br />
                    </>);
            }
        },
        {
            targets: 6,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <span id="droprightMenuButton" className="text-muted" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18 cursor-pointer"></i></span>
                        <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>
                            <span className="dropdown-item d-flex align-items-center cursor-pointer"
                                onClick={(e) => {
                                    dispatch(withActionPromise(getWarehouseOrderInvoiceById({ customerMainSellerId: selectedStore.customerMainSellerId, orderId: [rowData.orderNumber] }))).then(() => {
                                        setShowPDF(true);
                                    });
                                }}
                            >
                                {t('downloadInvoice')}
                            </span>
                        </div>
                    </div>)
            }
        }
    ]

    const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {
        if (checkedValues && checkedValues.length > 0) {
        }
    }

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.logisticsManagement'), navigationPath: "/inventory" },
                { navigationText: i18next.t('constants:breadCrumps.invoices'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div id="inventoryTable" className="row mb-5">
                <div className="col-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            {selectedStore?.customerMainSellerId.length > 0 &&
                                <DrDataTable
                                    data={warehouseOrdersInvoices?.sort((a, b) => new Date(b.paymentDate) - new Date(a.paymentDate))}
                                    order={[[5, 'desc']]}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    //search={true}
                                    minWidth="850px"
                                    search={true}
                                    isMultiSelectEnabled={true}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                            isSearchActive={false}
                                        >
                                        </DrPageFilter>
                                    }
                                />
                            }
                            {!selectedStore &&
                                <div className="row row-md row-lg justify-content-center">
                                    <div className="col-lg-auto col-md-2">
                                        <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                Promise.all([
                                                    dispatch(withActionPromise(clearCreateStoreInformationState()))
                                                ]).then((e) => {
                                                    navigate(getRouteByKey("MARKET_SELECTION"));
                                                });;
                                            }}>
                                            <i className="me-2 fe fe-chevrons-right"></i>
                                            {t('gotoMarketSelection')}
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {showPDF && selectedStore?.customerMainSellerId && warehouseOrderInvoiceById &&
                <DrPDFViewer
                    selectedStore={selectedStore.customerMainSellerId}
                    //   orderId={orderId}
                    pdfData={warehouseOrderInvoiceById}
                />
            }
        </>
    )
}

export default InvoicesPage;