import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiPost, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types from '../actions/utilities.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';

function* runSendMail(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/SendEmail`, payload, false, CONTROLLERS.UTILS, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        //send email
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info15'), 'success'))
        ]);
    }
    else {

        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error41') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* utilitiesSaga() {
    yield all([
        takeLatest(types.SEND_MAIL_REQUESTED, safe(runSendMail))
    ]);
}