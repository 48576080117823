const types = {
    SET_MARKET_SNAPSHOT_META:"SET_MARKET_SNAPSHOT_META",
    SET_MARKET_SELECTION:"SET_MARKET_SELECTION",
    SET_MARKET_PREFERENCES:"SET_MARKET_PREFERENCES",
    SET_SHIPPING_IDS:"SET_SHIPPING_IDS",
    SET_CONNECT_TO_AMAZON_CRITERIA:"SET_CONNECT_TO_AMAZON_CRITERIA",
    CLEAR_CREATE_STORE_INFORMATION: "CLEAR_CREATE_STORE_INFORMATION",
    SET_SNAPSHOT_STORE_PAGE_SUBMISSION:"SET_SNAPSHOT_STORE_PAGE_SUBMISSION",
    GET_AMAZON_LWA_CODE_REQUESTED:"GET_AMAZON_LWA_CODE_REQUESTED",
    GET_AMAZON_REFRESH_TOKEN_REQUESTED:"GET_AMAZON_REFRESH_TOKEN_REQUESTED",
    GET_AMAZON_REFRESH_TOKEN_FROMUI_REQUESTED:"GET_AMAZON_REFRESH_TOKEN_FROMUI_REQUESTED"

}

export default types;

export const setMarketSnapshotMeta = (payload) => {
    return { type: types.SET_MARKET_SNAPSHOT_META, payload };
};

export const setMarketSelection = (payload) => {
    return { type: types.SET_MARKET_SELECTION, payload };
};

export const setMarketPreferences = (payload) => {
    return { type: types.SET_MARKET_PREFERENCES, payload };
};

export const setConnectToAmazonCriteria = (payload) => {
    return { type: types.SET_CONNECT_TO_AMAZON_CRITERIA, payload };
};

export const setShippingIds = (payload) => {
    return { type: types.SET_SHIPPING_IDS, payload };
};

export const setSnapshotStorePageSubmission = (payload) => {
    return { type: types.SET_SNAPSHOT_STORE_PAGE_SUBMISSION, payload };
};

export const clearCreateStoreInformationState = () => {
    return { type: types.CLEAR_CREATE_STORE_INFORMATION };
}

export const getAmazonLWACode = (payload) => {
    return { type: types.GET_AMAZON_LWA_CODE_REQUESTED, payload };
};

export const getAmazonRefreshToken = (payload) => {
    return { type: types.GET_AMAZON_REFRESH_TOKEN_REQUESTED, payload };
};

export const getAmazonRefreshTokenFromUI = (payload) => {
    return { type: types.GET_AMAZON_REFRESH_TOKEN_FROMUI_REQUESTED, payload };
};