import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { DrButtonDropdown, DrDualDatePicker, DrInput, DrSelectBox } from '../../component-index';
import { useDispatch, useSelector } from 'react-redux';
import { buttonVariants, modals, pageFilterTypes } from '../../../utils/constants';
import { savePageFilter, updatePageFilter } from '../../../state-management/actions/page-filters.actions';
import { setActiveModal, clearActiveSavePagefilterModal, setActiveSavePagefilterModal, withActionPromise } from '../../../state-management/actions/app.actions';
import i18next from 'i18next';
import { detailedFilterMinMaxOptions, detailedFilterYesNoOptions, flagOptions, inventoryPageOptions, maxDeliveryDaysAll, operationsOptions, orderStatusOptions, timeOptions } from '../Constants/dr-constants.component';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { setInventoryPageSelections } from '../../../state-management/actions/inventory.actions';

function DrProductVariationsFilter({ detailedSearchClickCallBack }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('storeFiltersPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const pageFilters = useSelector(state => state.pageFilter);
    const productCategories = useSelector(state => state.inventory.productsCategories);
    const productsBrands = useSelector(state => state.inventory.productsBrands);

    const defaultValues = {

        customerMainSellerId: selectedStore?.customerMainSellerId,
        productCategoryId: null,
        subProductCategoryId: null,
        brandId: null,
        isSoldMeProduct: false,//
        isBuybox: false,//
        isLowest: false,//
        isBeforeSoldMeProduct: false,//

        asin: null,
        parentAsin: null
    }

    const [filter, setFilter] = useState(null);
    const [formData, setFormData] = useState((pageFilters.productVariationsPageFilters?.find(x => x.isSelected === true)?.filterJson && JSON.parse(pageFilters.productVariationsPageFilters.find(x => x.isSelected === true)?.filterJson)) || defaultValues);

    useEffect(() => {
        if (pageFilters.productVariationsPageFilters && pageFilters.productVariationsPageFilters.length > 0) {
            var selectedFilter = pageFilters.productVariationsPageFilters.find(x => x.isSelected === true);
            setFilter(selectedFilter || null);
            setFormData(selectedFilter?.filterJson ? JSON.parse(selectedFilter.filterJson) : defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageFilters]);

    const detailedSearchClick = (e, searchModel) => {
        detailedSearchClickCallBack(e, searchModel);
    };

    return (
        <div>
            {formData &&
                <Formik
                    initialValues={formData}
                    validateOnBlur={true}
                    // onSubmit={handleSubmit}
                    enableReinitialize={true}
                    validateOnMount={true}
                    validateOnChange={true}
                >
                    {({
                        isValid,
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setTouched,
                        setFieldValue,
                        resetForm
                    }) => (
                        <form>
                            <div id="dtableDetailSearch" className="row row-md row-lg">
                                <div className="col-lg-12 col-xl-12 col-md-12 px-0">
                                    <div className="card custom-card bg-info bg-opacity-10">
                                        <div className="card-body">
                                            <div className={`row row-md row-lg my-1 justify-content-end`} >
                                                <div className="col-auto my-auto">
                                                    <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setFormData(defaultValues);
                                                            setFilter(null);
                                                            resetForm();
                                                        }}>
                                                        <i className="w-6 me-2 fa fa-plus"></i>
                                                        Yeni Filtre
                                                    </button>
                                                </div>
                                            </div>

                                            {/* body */}
                                            <div className="row">
                                                <div className="col-12 col-xxl-6 col-lg-6 col-md-6 col-sm-12 my-1 px-1 d-flex">
                                                    <div className="card custom-card bg-light p-3 m-0">
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="isBuybox" className="form-check-input form-checked-success" role="switch" placeholder="" name="isBuybox" type="checkbox"
                                                                        value=""
                                                                        checked={values.isBuybox}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'isBuybox': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.isBuybox) ? "" : "text-muted"}`} htmlFor="isBuybox"
                                                                    style={{ marginTop: '2px' }}>
                                                                    If I only have the Buybox
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="isLowest" className="form-check-input form-checked-success" role="switch" placeholder="" name="isLowest" type="checkbox"
                                                                        value=""
                                                                        checked={values.isLowest}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'isLowest': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.isLowest) ? "" : "text-muted"}`} htmlFor="isLowest"
                                                                    style={{ marginTop: '2px' }}>
                                                                    If I only have the Lowest
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="isSoldMeProduct" className="form-check-input form-checked-success" role="switch" placeholder="" name="isSoldMeProduct" type="checkbox"
                                                                        value=""
                                                                        checked={values.isSoldMeProduct}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'isSoldMeProduct': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.isSoldMeProduct) ? "" : "text-muted"}`} htmlFor="isSoldMeProduct"
                                                                    style={{ marginTop: '2px' }}>
                                                                    If I only sold by me
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="isBeforeSoldMeProduct" className="form-check-input form-checked-success" role="switch" placeholder="" name="isBeforeSoldMeProduct" type="checkbox"
                                                                        value=""
                                                                        checked={values.isBeforeSoldMeProduct}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'isBeforeSoldMeProduct': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.isBeforeSoldMeProduct) ? "" : "text-muted"}`} htmlFor="isBeforeSoldMeProduct"
                                                                    style={{ marginTop: '2px' }}>
                                                                    If I already sold before
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xxl-6 col-lg-6 col-md-6 col-sm-12 my-1 px-1 d-flex">
                                                    <div className="card custom-card bg-light p-3 m-0">
                                                        <div className="row my-1">
                                                            <div className="col-5">
                                                                Categories
                                                            </div>
                                                            <div className="col">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.productCategoryId, 'productCategoryId': true }) }}
                                                                    onChange={(e) => {
                                                                        setFieldValue("productCategoryId", e.value);
                                                                    }}
                                                                    setFieldValue={values.productCategoryId}
                                                                    name={'productCategoryId'}
                                                                    component={DrSelectBox}
                                                                    placeholder="Select"
                                                                    options={productCategories?.map(x => ({ value: x.id, label: (x.categoryName + (x.isBlocked ? "(Blocked)" : "")) }))} />
                                                                {(touched.productCategoryId && errors.productCategoryId) &&
                                                                    <label type="invalid" className="form-control is-invalid">
                                                                        {errors.productCategoryId}
                                                                    </label>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-5">
                                                                Sub Categories
                                                            </div>
                                                            <div className="col">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.subProductCategoryId, 'subProductCategoryId': true }) }}
                                                                    onChange={(e) => {
                                                                        setFieldValue("subProductCategoryId", e.value);
                                                                    }}
                                                                    setFieldValue={values.subProductCategoryId}
                                                                    name={'subProductCategoryId'}
                                                                    component={DrSelectBox}
                                                                    placeholder="Select"
                                                                    options={productCategories?.find(x => x.id === values.productCategoryId)?.subCategories?.map(x => ({ value: x.id, label: (x.categoryName + (x.isBlocked ? "(Blocked)" : "")) })) || []} />
                                                                {(touched.subProductCategoryId && errors.subProductCategoryId) &&
                                                                    <label type="invalid" className="form-control is-invalid">
                                                                        {errors.subProductCategoryId}
                                                                    </label>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-5">
                                                                Brands
                                                            </div>
                                                            <div className="col">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.brandId, 'brandId': true }) }}
                                                                    onChange={(e) => {
                                                                        setFieldValue("brandId", e.value);
                                                                    }}
                                                                    setFieldValue={values.brandId}
                                                                    name={'brandId'}
                                                                    component={DrSelectBox}
                                                                    placeholder="Select"
                                                                    options={productsBrands?.map(x => ({ value: x.id, label: (x.brandName + (x.isBlocked ? "(Blocked)" : "")) }))} />
                                                                {(touched.brandId && errors.brandId) &&
                                                                    <label type="invalid" className="form-control is-invalid">
                                                                        {errors.brandId}
                                                                    </label>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* body */}

                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <div className="row justify-content-between">

                                                        <div className="col-auto">
                                                            <button className="btn btn-primary btn-wave waves-effect waves-light shadow"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    // detailedSearchSave(e, values);

                                                                    var filterData = {
                                                                        id: filter?.id,
                                                                        customerMainSellerId: selectedStore?.customerMainSellerId,
                                                                        pageNumber: pageFilterTypes.productVariations,
                                                                        pageName: Object.keys(pageFilterTypes)[Object.values(pageFilterTypes).indexOf(7)],
                                                                        methodName: filter?.methodName || "getProductVariations",
                                                                        moduleName: filter?.moduleName || "productVariations",
                                                                        description: '',
                                                                        filterJson: JSON.stringify(values),
                                                                        isSelected: true,
                                                                        filterName: filter?.filterName || "",
                                                                    }

                                                                    dispatch(setActiveSavePagefilterModal({
                                                                        name: modals.pageFilterSave, isGlobal: true, source: filterData,
                                                                        pagefilterSavedCallBack: (response) => {
                                                                            dispatch(clearActiveSavePagefilterModal());
                                                                        }
                                                                    }));
                                                                }}>
                                                                <i className="fa fa-floppy-o me-2"></i>
                                                                Kaydet
                                                            </button>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="row">
                                                                <div className="col-auto ">
                                                                    <button defaultValue="0" className="btn btn-info btn-wave waves-effect waves-light shadow" data-bs-toggle="collapse" data-bs-target="#detailedSearchContent" aria-expanded="false" aria-controls="detailedSearchContent"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            values.isApplied = false;
                                                                            detailedSearchClick(this, values);
                                                                        }}>
                                                                        <i className="fe fe-search me-2"></i>
                                                                        Ara
                                                                    </button>
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <button defaultValue="0" className="btn btn-danger btn-wave waves-effect waves-light shadow" data-bs-toggle="collapse" data-bs-target="#detailedSearchContent" aria-expanded="false" aria-controls="detailedSearchContent"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                        }}>
                                                                        <i className="fe fe-x me-2"></i>
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                    }
                </Formik>
            }
        </div >

    )
}

export default DrProductVariationsFilter;