import React from 'react'
import { DrInput, DrNavigator } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { setSmartPricerName, setSmartPricerPageSubmission } from '../../state-management/actions/create-smartpricer-filter.actions';

function SmartPricerCompletePage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('smartPricerPage');
    const smartPricerSnapshot = useSelector(state => state.smartPricerSnapshot);

    const initialValues = {
        smartpricerName: smartPricerSnapshot?.smartpricerName || ""
    }

    const smartpricerNamePageSchema = Yup.object().shape({
        smartpricerName: Yup.string()
            .min(3, 'Too Short!')
            .max(255, 'Too Long!')
            .required('Smartpricer Name is required'),
    });


    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            validationSchema={smartpricerNamePageSchema}
            enableReinitialize={true}
            validateOnMount={true}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setTouched,
                setFieldValue
            }) => (

                <div className="content clearfix">
                    <form name="smartpricer-target-form" className="needs-validation" autoComplete="off">

                        <div className="row row-md d-flex justify-content-center">
                            <div className="col-sm-8 col-lg-12 col-md-8">
                                <div className="card custom-card card-body shadow-none">
                                    <h3>{t('completePage.contentTitle')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row row-md row-lg justify-content-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="card custom-card">
                                    <section>
                                        <div className="row row-md row-lg justify-content-center">
                                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-10 col-11">
                                                <div className="form-group">
                                                    <h6>{t('completePage.smartPricerName')}</h6>
                                                    <DrInput value={values.smartpricerName} className={`form-control`} placeholder={t('completePage.smartPricerName')} name="smartpricerName" type="text"
                                                        onChange={handleChange}
                                                        onBlur={() => { setTouched({ ...touched, 'smartpricerName': true }) }}
                                                        isInvalid={(touched.smartpricerName && errors.smartpricerName)}
                                                        isValid={touched.smartpricerName && !errors.smartpricerName}
                                                        validationText={errors.smartpricerName}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        {<DrNavigator backAddress="SMARTPRICER_TARGET" nextAddress="SMARTPRICER_COMPLETED" disabled={!isValid} showCompleteButton={true}
                            callback={() => {
                                return Promise.all([
                                    dispatch(withActionPromise(dispatch(setSmartPricerName(values.smartpricerName)))),
                                    dispatch(withActionPromise(dispatch(setSmartPricerPageSubmission({ smartpricerNamePage: true }))))
                                ]);
                            }}
                        />}
                    </form>
                </div>
            )
            }
        </Formik>
    )
}

export default SmartPricerCompletePage;