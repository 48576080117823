import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DrBreadCrump } from '../../components/component-index';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { siteExtensions } from '../../utils/constants';
import { useParams } from 'react-router-dom';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { getProductsDetailsByAsins } from '../../state-management/actions/inventory.actions';
import noImage from '../../assets/img/pngs/no-image.png'
import { getProductKeepaDetails } from '../../state-management/actions/keepa.actions';

function ProductDetailPage() {
    const dispatch = useDispatch();
    const { asin } = useParams();
    const { t } = useTranslation('productDetailPage');
    const selectedProducts = useSelector(state => state.inventory.selectedProducts);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const selectedProductKeepaDetails = useSelector(state => state.keepa.selectedProductKeepaDetails);
    const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);

    useEffect(() => {
        if (asin && selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getProductsDetailsByAsins({
                asinList: [asin],
                customerMainSellerId: selectedStore?.customerMainSellerId
            })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asin]);

    useEffect(() => {
        if (selectedProducts && selectedProducts.length > 0) {
            dispatch(withActionPromise(getProductKeepaDetails({ productMarketId: selectedProducts[0].productMarketId })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProducts]);

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
                { navigationText: i18next.t('constants:breadCrumps.productDetail'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            {selectedProducts && selectedProducts.length > 0 &&
                                <form>
                                    <div className="row row-md row-lg justify-content-sm-center justify-content-md-center justify-content-center">
                                        {/* <div className="custom-card bg-gray-300 col-xl-5 col-lg-5 col-md-10 col-sm-10 col-10">
                                            <img alt="" className="w-100 my-3" src={selectedProducts[0].productPicture || noImage} />
                                        </div> */}
                                        <div className="col-xl-4 col-lg-4 col-md-10 col-sm-10 col-10 ps-2">
                                            <div className="w-100 bg-gray-300 p-2">
                                                <img alt="" className="w-100" src={selectedProducts[0].productPicture || noImage} />

                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4 className="mb-3">{selectedProducts[0].productName}</h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2">
                                                    <label className="card-title">{t("price")}</label>
                                                </div>
                                                <div className="col-10">
                                                    <label><span>{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0].price).toFixed(2)}</span></label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2">
                                                    <label className="card-title">ASIN:</label>
                                                </div>
                                                <div className="col-10">
                                                    <label><span>{selectedProducts[0].asin}</span></label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2">
                                                    <label className="card-title">SKU:</label>
                                                </div>
                                                <div className="col-10">
                                                    <label><span>{selectedProducts[0].sku}</span></label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2">
                                                    <label className="card-title">URL:</label>
                                                </div>
                                                <div className="col-10">
                                                    <label>
                                                        <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                            href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedStore.countryCode)?.extension + "/" + selectedProducts[0].modelNumber + "/dp/" + selectedProducts[0].asin}>
                                                            Amazonda Görüntüle
                                                        </a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h4 className="mb-3">{t("productProperties")}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                            <label className="card-title">{t("width")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                            <span >{selectedProducts[0].itemWidthValue && Number(selectedProducts[0].itemWidthValue).toFixed(2)} {selectedProducts[0].itemWidthUnit}</span>
                                                        </div>
                                                        <div className="col-auto ps-0 pe-0">

                                                        </div>
                                                        <div className="col-4 col-xl-auto col-md-auto col-sm-auto">
                                                            <label className="card-title">{t("packageWidth")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                            <span >{selectedProducts[0].packageWidthValue && Number(selectedProducts[0].packageWidthValue).toFixed(2)} {selectedProducts[0].packageWidthUnit}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                            <label className="card-title">{t("length")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                            <span >{selectedProducts[0].itemLengthValue && Number(selectedProducts[0].itemLengthValue).toFixed(2)} {selectedProducts[0].itemLengthUnit}</span>
                                                        </div>
                                                        <div className="col-auto ps-0 pe-0">

                                                        </div>
                                                        <div className="col-4 col-xl-auto col-md-auto col-sm-auto">
                                                            <label className="card-title">{t("packageLength")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                            <span >{selectedProducts[0].packageLengthValue && Number(selectedProducts[0].packageLengthValue).toFixed(2)} {selectedProducts[0].packageLengthUnit}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                            <label className="card-title">{t("height")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                            <span >{selectedProducts[0].itemHeightValue && Number(selectedProducts[0].itemHeightValue).toFixed(2)} {selectedProducts[0].itemHeightUnit}</span>
                                                        </div>
                                                        <div className="col-auto ps-0 pe-0">

                                                        </div>
                                                        <div className="col-4 col-xl-auto col-md-auto col-sm-auto">
                                                            <label className="card-title">{t("packageHeight")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                            <span >{selectedProducts[0].packageHeightValue && Number(selectedProducts[0].packageHeightValue).toFixed(2)} {selectedProducts[0].packageHeightUnit}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                            <label className="card-title">{t("weight")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                            <span >{selectedProducts[0].itemWeightValue && Number(selectedProducts[0].itemWeightValue).toFixed(2)} {selectedProducts[0].itemWeightUnit}</span>
                                                        </div>
                                                        <div className="col-auto ps-0 pe-0">

                                                        </div>
                                                        <div className="col-4 col-xl-auto col-md-auto col-sm-auto">
                                                            <label className="card-title">{t("packageWeight")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                            {selectedProducts[0].packageWeightValue && <span >{Number(selectedProducts[0].packageWeightValue).toFixed(2)} {selectedProducts[0].packageWeightUnit}</span>}
                                                            {!selectedProducts[0].packageWeightValue && <span>n/a</span>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                            <label className="card-title">{t("partNumber")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                            <span >{selectedProducts[0].partNumber}</span>
                                                        </div>
                                                        <div className="col-auto ps-0 pe-0">

                                                        </div>
                                                        <div className="col-4 col-xl-auto col-md-auto col-sm-auto">
                                                            <label className="card-title">{t("packageQuantity")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                            <span >{selectedProducts[0].itemPackageQuantity}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                            <label className="card-title">{t("variation")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-10 col-md-10 col-sm-10 ps-0">
                                                            <span >{selectedProducts[0].size} {selectedProducts[0].color} {selectedProducts[0].style}</span>
                                                        </div>
                                                    </div>
                                                    {selectedProducts[0].fabricType && selectedProducts[0].fabricType.length > 0 &&
                                                        <div className="row">
                                                            <div className="col-4 col-xl-3 col-md-3 col-sm-3">
                                                                <label className="card-title">{t("fabricType")}</label>
                                                            </div>
                                                            <div className="col-8 col-xl-9 col-md-9 col-sm-9 ps-0">
                                                                <span >{selectedProducts[0].fabricType}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {selectedProducts[0].isLiquidContent && <div className="row">
                                                        <div className="col-4 col-xl-3 col-md-3 col-sm-3">
                                                            <label className="card-title">{t("isLiquidContent")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-9 col-md-9 col-sm-9 ps-0">
                                                            <span >{selectedProducts[0].isLiquidContent && t("yes")}</span>
                                                        </div>
                                                    </div>}
                                                    {selectedProducts[0].isBatteryRequired && <div className="row">
                                                        <div className="col-4 col-xl-3 col-md-3 col-sm-3">
                                                            <label className="card-title">{t("isBatteryRequired")}</label>
                                                        </div>
                                                        <div className="col-8 col-xl-9 col-md-9 col-sm-9 ps-0">
                                                            <span >{selectedProducts[0].isBatteryRequired && t("yes")}</span>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border mt-2">
                                        <div className="bg-light row mx-auto justify-content-center">
                                            <nav className="nav nav-tabs">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#product-detail-tab">Product Detail</a>
                                                <a className="nav-link" data-bs-toggle="tab" href="#keepa-product-detail-tab">Keepa Product Detail</a>
                                            </nav>
                                        </div>
                                        <div className="card-body tab-content">
                                            <div id="product-detail-tab" className="row tab-pane active show">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("sourceMarket")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span > {selectedProducts[0].sourceMarketCountryCode} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("buyPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span > ?? </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("estimatedShippingPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span >{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0].shippingPrice).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("totalPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            {/* <span >{selectedProducts[0].shippingPriceCurrencyCode} {Number(selectedProducts[0].shippingPrice + selectedProducts[0].listPrice).toFixed(2) }</span> */}
                                                            <span> ?? </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("sellPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span >{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0].price).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("amazonCommission")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span > ?? </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("listingPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0].listPrice).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isBuyboxSeller")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{selectedProducts[0].isBuybox ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isLowestSeller")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{selectedProducts[0].isLowest ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("stock")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{selectedProducts[0].stock}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("minPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> ?? </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("maxPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> ?? </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isAmazonSeller")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{selectedProducts[0].isSoldByAmazon ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("primeSellerCount")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].primeSellerCount} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("amazonSellerBrandCount")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].totalSellerCount} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("review")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].review} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("buyboxPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0].buyboxPrice).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("lowestPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0].lowestPrice).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isPrimeSellerExist")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].primeSellerCount && selectedProducts[0].primeSellerCount > 0 ? <i className="fa fa-check text-success"></i> : <i className="fa fa-check text-close"></i>}  </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("sellerCount")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].totalSellerCount}  </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("buyboxOffersCount")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> ?? </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("brand")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].brandName}  </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("handlingTime")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> ?? </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("category1")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].displayGroupRankTitle} ({selectedProducts[0].displayGroupRank})</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("category2")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].classificationRankTitle} ({selectedProducts[0].classificationRank})</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isShippingAvailableForBuyersLocation")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> ?? </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("totalCustomsDutyPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0].customsDutyPrice).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("totalShippingPrice")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span>{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0].shippingPrice).toFixed(2)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isUnavailable")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].stock && selectedProducts[0].stock > 0 ? <i className="fa fa-close text-danger"></i> : <i className="fa fa-check text-success"></i>}  </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isFBAproduct")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].isSoldByAmazon ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("priceShownAtBasket")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].price && selectedProducts[0].price > 0 ? <i className="fa fa-close text-danger"></i> : <i className="fa fa-check text-success"></i>} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isAddonProduct")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> ?? </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isDiscounted")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].isDiscounted ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isPrimeExclusive")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].isPrimeExclusive ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isChineseSeller")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> {selectedProducts[0].isChineseSellerExists ? <i className="fa fa-check text-success"></i> : <i className="fa fa-close text-danger"></i>} </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("isParentAsin")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> ?? </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 text-end">
                                                            <label className="card-title">{t("maxDeliveryTime")}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <span> ?? </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {selectedProductKeepaDetails &&
                                                <div id="keepa-product-detail-tab" className="row tab-pane">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.salesRank")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.salesRank30DayAvg")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank30DayAvg} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.salesRank90DayAvg")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank90DayAvg} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.salesRank30DayDrop")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank30DayDrop} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.salesRank90DayDrop")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank90DayDrop} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.salesRank30DropLastDay")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank30DropLastDay} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.salesRank90DropLastDay")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank90DropLastDay} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.amazonCurrentPrice")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0].amazonCurrentPrice).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.referralFeePercent")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.referralFeePercent}%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.referralFeePercent")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0].referralFeeBasedOnCurrentBuyBoxPrice).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.fBAPickAndPackFee")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>USD {Number(selectedProductKeepaDetails.fBAPickAndPackFee).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.newOfferCount")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.newOfferCount}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.newOfferCountCurrent")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.newOfferCountCurrent}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.newOfferCount30DayAvg")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.newOfferCount30DayAvg}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.newFBMRetrievedLiveOfferCount")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.newFBMRetrievedLiveOfferCount}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.newFBARetrievedLiveOfferCount")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.newFBARetrievedLiveOfferCount}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.buyBoxCurrent")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>USD {Number(selectedProductKeepaDetails.buyBoxCurrent).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.buyBox30DayAvg")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>USD {Number(selectedProductKeepaDetails.buyBox30DayAvg).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.buyBox30DayDropPercent")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.buyBox30DayDropPercent}%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.buyBox90DayDropPercent")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.buyBox90DayDropPercent}%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.buyBoxSeller")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.buyBoxSeller}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.buyBoxUnqualified")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.buyBoxUnqualified ? <i className="fa fa-close text-danger"></i> : <i className="fa fa-check text-success"></i>}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.boughtInPastMonth")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.boughtInPastMonth}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <label className="card-title">{t("keepa.freqBoughtTogether")}</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.freqBoughtTogether}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProductDetailPage