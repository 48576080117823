import { all } from 'redux-saga/effects'
import customerSaga from './customer.saga';
import createCustomerSaga from './create-customer.saga';
import marketSaga from './market.saga';
import storeSaga from './store.saga';
import inventorySaga from './inventory.saga';
import createStoreSaga from './create-store.saga';
import utilitiesSaga from './utilities.saga';
import ordersSaga from './orders.saga';
import reprizerSaga from './reprizer.saga';
import warehousesSaga from './warehouses.saga';
import keepaSaga from './keepa.saga';
import smartpricerSaga from './smartpricer.saga';
import shipstationSaga from './shipstation.saga';
import pageFilterSaga from './page-filters.saga';
import walletSaga from './wallet.saga';
import notificationSaga from './notification.saga';
import reportsSaga from './reports.saga';
import trademarkSaga from './trademark.saga';
import packagesSaga from './packages.saga';
import createCustomerPackagesSaga from './create-customer-packages.saga';
import agreementSaga from './agreement.saga';

export default function* rootSaga() {
    yield all([
        customerSaga(),
        createCustomerSaga(),
        marketSaga(),
        storeSaga(),
        inventorySaga(),
        createStoreSaga(),
        utilitiesSaga(),
        ordersSaga(),
        reprizerSaga(),
        warehousesSaga(),
        keepaSaga(),
        smartpricerSaga(),
        pageFilterSaga(),
        shipstationSaga(),
        walletSaga(),
        notificationSaga(),
        reportsSaga(),
        trademarkSaga(),
        packagesSaga(),
        createCustomerPackagesSaga(),
        agreementSaga()
    ]);
}