
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrInput, DrPageFilter, DrSelectBox, DrSweetAlertButton } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { sweetAlertVariants } from '../../utils/constants';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import i18next from 'i18next';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import $ from 'jquery';
import Restricted from '../../security/Restricted';
import { createPackage, createPackageCoupon, deletePackage, getCoupons, getPackageById, getPackages, updatePackage } from '../../state-management/actions/packages.action';

function CreatePackagesPage() {
    const { REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_STORE } = process.env;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('createPackagesPage');
    const allPackages = useSelector(state => state.packages.allPackages);
    const allCoupons = useSelector(state => state.packages.allCoupons);
    const selectedPackageById = useSelector(state => state.packages.selectedPackage);

    const [selectedPackage, setSelectedPackage] = useState(null);
    const [couponOptions, setCouponOptions] = useState(null);
    const [packageOptions, setPackageOptions] = useState(null);
    const [selectedPackageMustPlanOptions, setSelectedPackageMustPlanOptions] = useState(null);

    useEffect(() => {
        dispatch(withActionPromise(getCoupons()));
        dispatch(withActionPromise(getPackages()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {


        var arr = []

        allPackages?.filter(x => !x.isDisable && x.isVisible === true)?.sort((a, b) => b.mainPlan - a.mainPlan).forEach(element => {
            arr.push({ value: element.id, label: (element.planName + (element.mainPlan ? "(Main Plan)" : "")), otherValues: { ...element } });

        });
        setPackageOptions(arr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPackages]);

    useEffect(() => {
        var arr = []

        allCoupons?.forEach(coupon => {
            arr.push({ value: coupon.id, label: coupon.couponName });

        });
        setCouponOptions(arr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allCoupons]);


    useEffect(() => {

        var options = packageOptions?.filter(x => x.value !== selectedPackage.id && ((selectedPackage.mainPlan && x.otherValues.mainPlan === true)
            || (!selectedPackage.mainPlan && (
                (selectedPackage.isSmartPricer === true && x.otherValues.isSmartPricer !== true)
                || (selectedPackage.isFBA === true && x.otherValues.isFBA !== true)
                || (selectedPackage.isWarehouse === true && x.otherValues.isWarehouse !== true)
                || (selectedPackage.isDropAmazon2Amazon === true && x.otherValues.isDropAmazon2Amazon !== true)


            )))
        );

        // console.log(selectedPackage?.mustPlan?.length > 0 ? selectedPackageMustPlanOptions?.filter(function (o) {
        //     return selectedPackage.mustPlan?.split(',')?.find(r => r === o.value);
        // }) : [])
        setSelectedPackageMustPlanOptions(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPackage]);

    const columns = [
        { data: "planName", title: t('dataTable.planName'), minWidth: "260px", maxWidth: "260px", searchable: true },
        { data: "price", title: t('dataTable.price'), minWidth: "90px", maxWidth: "90px", searchable: true },
        { data: "mainPlan", title: t('dataTable.mainPlan'), minWidth: "100px", maxWidth: "100px", searchable: false },
        { data: "productCount", title: t('dataTable.productCount'), minWidth: "100px", maxWidth: "100px", searchable: true },
        { data: "scanningCount", title: t('dataTable.scanningCount'), minWidth: "100px", maxWidth: "100px", searchable: true },
        { data: null, title: t('dataTable.options'), minWidth: "200px", maxWidth: "200px", searchable: false },
        { data: "isVisible", title: t('dataTable.isVisible'), minWidth: "100px", maxWidth: "100px", searchable: false },
        { data: "isDisable", title: t('dataTable.isDisable'), minWidth: "100px", maxWidth: "100px", searchable: false },
        { data: "createdDate", title: t('dataTable.createdDate'), minWidth: "160px", maxWidth: "160px", searchable: false },
        { data: null, title: "", minWidth: "80px", maxWidth: "80px", searchable: false },
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0 text-wrap">{rowData.planName}</span>
                );
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="text-end badge rounded-pill bg-info fs-11">${Number(rowData.price).toFixed(2)}</span>
                );
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {rowData.mainPlan && <span className="text-end badge rounded-pill bg-success fs-11">{t('dataTable.mainPlanTrue')}</span>}
                        {!rowData.mainPlan && <span className="text-end badge rounded-pill bg-warning fs-11">{t('dataTable.mainPlanFalse')}</span>}
                    </>
                );
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">{rowData.productCount}</span>
                );
            }
        },
        {
            targets: 4,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">{rowData.scanningCount}</span>
                );
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <div className="d-flex flex-column">
                            {rowData.isFBA &&
                                <div className="row my-1 justify-content-center">
                                    <div className="col-11 px-0">
                                        <span className="text-center w-100 badge rounded-pill bg-info fs-11">{t('dataTable.isFBA')}</span>
                                    </div>
                                </div>
                            }
                            {rowData.isSmartPricer &&
                                <div className="row my-1 justify-content-center">
                                    <div className="col-11 px-0">
                                        <span className="text-center w-100 badge rounded-pill bg-purple fs-11">{t('dataTable.isSmartPricer')}</span>
                                    </div>
                                </div>
                            }
                            {rowData.isWarehouse &&
                                <div className="row my-1 justify-content-center">
                                    <div className="col-11 px-0">
                                        <span className="text-center w-100 badge rounded-pill bg-orange fs-11">{t('dataTable.isWarehouse')}</span>
                                    </div>
                                </div>
                            }
                            {rowData.isDropAmazon2Amazon &&
                                <div className="row my-1 justify-content-center">
                                    <div className="col-11 px-0">
                                        <span className="text-center w-100 badge rounded-pill bg-yellow fs-11">{t('dataTable.isDropAmazon2Amazon')}</span>
                                    </div>
                                </div>
                            }
                        </div>

                    </>

                );
            }
        },
        {
            targets: 6,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {rowData.isVisible && <span className="text-end badge rounded-pill bg-success fs-11">{t('dataTable.isVisibleTrue')}</span>}
                        {!rowData.isVisible && <span className="text-end badge rounded-pill bg-warning fs-11">{t('dataTable.isVisibleFalse')}</span>}
                    </>
                );
            }
        },
        {
            targets: 7,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {rowData.isDisable && <span className="text-end badge rounded-pill bg-danger fs-11">{t('dataTable.isDisableTrue')}</span>}
                        {!rowData.isDisable && <span className="text-end badge rounded-pill bg-success fs-11">{t('dataTable.isDisableFalse')}</span>}
                    </>
                );
            }
        },
        {
            targets: 8,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">{moment(rowData.createdDate).format('L LT')}</span>
                );
            }
        },
        {
            targets: 9,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <DrSweetAlertButton
                        className={`ms-2 btn btn-wave waves-effect waves-light shadow btn-sm  ${rowData.isBlocked ? "btn-warning" : "btn-danger"}`}
                        buttonText=""
                        buttonIcon={<i className="fe fe-trash-2 me-2"></i>}

                        onCloseConfirmCallBack={(value) => {
                            if (value) {
                                dispatch(withActionPromise(deletePackage(rowData.id)));
                            }
                        }}
                        sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
                    >
                    </DrSweetAlertButton>
                )
            }
        }
    ];

    const initialValues = {
        id: selectedPackage?.id || null,
        planName: selectedPackage?.planName || "",//** */
        productCount: selectedPackage?.productCount || 0,//** */
        isFBA: selectedPackage?.isFBA || false,//*
        isSmartPricer: selectedPackage?.isSmartPricer || false,//*
        isWarehouse: selectedPackage?.isWarehouse || false,//*
        price: selectedPackage?.price || 0,//** */
        discount: selectedPackage?.discount || 0,//
        userCount: selectedPackage?.userCount || 0,//
        sellerCount: selectedPackage?.sellerCount || 0,
        isDisable: selectedPackage?.isDisable || false,//
        description: selectedPackage?.description || "",
        mainPlan: selectedPackage?.mainPlan || false,//** */
        isDropAmazon2Amazon: selectedPackage?.isDropAmazon2Amazon || false,//*
        trail: selectedPackage?.trail || 0,//
        isVisible: selectedPackage?.isVisible || false,//
        scanningCount: selectedPackage?.scanningCount || 0,//** */
        mustPlan: selectedPackage?.mustPlan?.length > 0 ? selectedPackageMustPlanOptions?.filter(function (o) {
            return selectedPackage.mustPlan?.split(',')?.find(r => r === o.value);
        }) : [],

        couponList: selectedPackageById?.couponList?.length > 0 ? couponOptions.filter(function (o) {
            return selectedPackageById?.couponList.find(r => r.id === o.value);
        }) : []
    };

    const unCheckAllSelections = () => {
        $(document.querySelectorAll('#packagesTable tr')).removeClass("selected");
        $(document.querySelectorAll('input[type="checkbox"]:checked')).prop("checked", false);
    }

    const packageSchema = Yup.object().shape({

        planName: Yup.string().required('Please enter package name!')
            .min(3, 'Package Name needs to be minimum 3 character')
            .max(50, 'Package Name needs to be minimum 50 character'),

        // price: Yup.string().nullable().matches(/^[0-9]+$/, "Must be only digits")
        price: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                // (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),

        discount: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                // (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),

        userCount: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                // (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),

        sellerCount: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                // (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),

        trail: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                // (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),

        scanningCount: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                // (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),
        productCount: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                // (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),


        // brandList: Yup.array().nullable().when('serialNumber', {
        //     is: (value) => value && value !== '',
        //     then: () => Yup.array().nullable(),
        //     otherwise: () => Yup.array().required('You need to enter keywords if no Serial Number entered!').test({
        //       message: 'At least one brand name is required',
        //       test: arr => arr?.length !== 0,
        //     }).ensure().required('Brand names are required')
        //   })

    });

    const rowClickedCallBack = async (rowData) => {
        if (rowData) {
            setSelectedPackage({ ...rowData });
            dispatch(withActionPromise(getPackageById(rowData.id)));
        }

    }

    const refreshClickedCallBack = () => {
        dispatch(withActionPromise(getPackages()));
    };


    return (
        <>

            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.administrator'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.createPackages'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div className="row mb-5">
                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                        <div className="card-body horizontal-scrollable">

                            <Formik
                                initialValues={initialValues}
                                validateOnBlur={true}
                                // onSubmit={handleSubmit}
                                validationSchema={packageSchema}
                                enableReinitialize={true}
                                validateOnMount={true}
                            // validateOnChange={true}
                            >
                                {({
                                    isValid,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    setTouched,
                                    setFieldValue,
                                    resetForm

                                }) => (
                                    <form>
                                        <div className={`row my-1 justify-content-end`} >
                                            <div className="col-auto my-auto">
                                                <Restricted allowedTo={[REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_STORE]}>
                                                    <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            unCheckAllSelections();
                                                            setSelectedPackage(null);
                                                            resetForm();
                                                        }}>
                                                        <i className="me-2 fe fe-plus"></i>
                                                        {t('addNewPackage')}
                                                    </button>
                                                </Restricted>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xxl-4 col-xl-6 col-lg-6 col-12 d-flex flex-column">
                                                <div className="card custom-card card-body bg-info bg-opacity-10">
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('planName')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput value={values.planName || ""} className={`form-control`}
                                                                placeholder={t('planName')}
                                                                name="planName"
                                                                type="text"
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'planName': true }) }}
                                                                isInvalid={(touched.planName && errors.planName)}
                                                                isValid={touched.planName && !errors.planName}
                                                                validationText={errors.planName}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('packagePrice')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('packagePrice')} name="price" type="text" min="0" step="50"
                                                                value={values.price || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'price': true }) }}
                                                                icon={<i className="bx bx-dollar"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.price && errors.price)}
                                                                isValid={touched.price && !errors.price}
                                                                validationText={errors.price} />

                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('discount')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('discount')} name="discount" type="text" min="0" step="1"
                                                                value={values.discount || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'discount': true }) }}
                                                                icon={<i className="bx bxs-discount"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.discount && errors.discount)}
                                                                isValid={touched.discount && !errors.discount}
                                                                validationText={errors.discount} />

                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('productCount')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('productCount')} name="productCount" type="text" min="0" step="100"
                                                                value={values.productCount || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'productCount': true }) }}
                                                                icon={<i className="bx bx-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.productCount && errors.productCount)}
                                                                isValid={touched.productCount && !errors.productCount}
                                                                validationText={errors.productCount} />

                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('userCount')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('userCount')} name="userCount" type="text" min="0" step="1"
                                                                value={values.userCount || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'userCount': true }) }}
                                                                icon={<i className="bx bxs-user"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.userCount && errors.userCount)}
                                                                isValid={touched.userCount && !errors.userCount}
                                                                validationText={errors.userCount} />

                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('sellerCount')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('sellerCount')} name="sellerCount" type="text" min="0" step="1"
                                                                value={values.sellerCount || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'sellerCount': true }) }}
                                                                icon={<i className="bx bxs-store"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.sellerCount && errors.sellerCount)}
                                                                isValid={touched.sellerCount && !errors.sellerCount}
                                                                validationText={errors.sellerCount} />

                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('trail')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('trail')} name="trail" type="text" min="0" step="1"
                                                                value={values.trail || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'trail': true }) }}
                                                                icon={<i className="bx bx-plus"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.trail && errors.trail)}
                                                                isValid={touched.trail && !errors.trail}
                                                                validationText={errors.trail} />

                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('scanningCount')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('scanningCount')} name="scanningCount" type="text" min="0" step="0.1"
                                                                value={values.scanningCount || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'scanningCount': true }) }}
                                                                icon={<i className="bx bx-scan"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.scanningCount && errors.scanningCount)}
                                                                isValid={touched.scanningCount && !errors.scanningCount}
                                                                validationText={errors.scanningCount} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-3 col-xl-6 col-lg-6 col-12 d-flex flex-column">
                                                <div className="card custom-card card-body bg-info bg-opacity-10">
                                                    <div className="row my-2">
                                                        <div className="col-auto mt-0">
                                                            <div className="form-check form-check-md form-switch">
                                                                <DrInput id="isDisable" className="form-check-input form-checked-success" role="switch" placeholder="" name="isDisable" type="checkbox"
                                                                    value=""
                                                                    checked={values.isDisable}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'isDisable': true }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col mt-0">
                                                            <label className={`form-check-label cursor-pointer no-select ${(values.isDisable) ? "" : "text-muted"}`} htmlFor="isDisable"
                                                                style={{ marginTop: '2px' }}>
                                                                {t('isDisable')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row my-2">
                                                        <div className="col-auto mt-0">
                                                            <div className="form-check form-check-md form-switch">
                                                                <DrInput id="mainPlan" className="form-check-input form-checked-success" role="switch" placeholder="" name="mainPlan" type="checkbox"
                                                                    value=""
                                                                    checked={values.mainPlan}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'mainPlan': true }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col mt-0">
                                                            <label className={`form-check-label cursor-pointer no-select ${(values.mainPlan) ? "" : "text-muted"}`} htmlFor="mainPlan"
                                                                style={{ marginTop: '2px' }}>
                                                                {t('isMainPlan')}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="row my-2">
                                                        <div className="col-auto mt-0">
                                                            <div className="form-check form-check-md form-switch">
                                                                <DrInput id="isVisible" className="form-check-input form-checked-success" role="switch" placeholder="" name="isVisible" type="checkbox"
                                                                    value=""
                                                                    checked={values.isVisible ? true : false}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col mt-0">
                                                            <label className={`form-check-label cursor-pointer no-select ${(values.isVisible) ? "" : "text-muted"}`} htmlFor="isVisible"
                                                                style={{ marginTop: '2px' }}>
                                                                {t('isVisible')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row my-2">
                                                        <div className="col-auto mt-0">
                                                            <div className="form-check form-check-md form-switch">
                                                                <DrInput id="isSmartPricer" className="form-check-input form-checked-success" role="switch" placeholder="" name="isSmartPricer" type="checkbox"
                                                                    value=""
                                                                    checked={values.isSmartPricer}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'isSmartPricer': true }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col mt-0">
                                                            <label className={`form-check-label cursor-pointer no-select ${(values.isSmartPricer) ? "" : "text-muted"}`} htmlFor="isSmartPricer"
                                                                style={{ marginTop: '2px' }}>
                                                                {t('isSmartPricer')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row my-2">
                                                        <div className="col-auto mt-0">
                                                            <div className="form-check form-check-md form-switch">
                                                                <DrInput id="isFBA" className="form-check-input form-checked-success" role="switch" placeholder="" name="isFBA" type="checkbox"
                                                                    value=""
                                                                    checked={values.isFBA}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'isFBA': true }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col mt-0">
                                                            <label className={`form-check-label cursor-pointer no-select ${(values.isFBA) ? "" : "text-muted"}`} htmlFor="isFBA"
                                                                style={{ marginTop: '2px' }}>
                                                                {t('isFBA')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row my-2">
                                                        <div className="col-auto mt-0">
                                                            <div className="form-check form-check-md form-switch">
                                                                <DrInput id="isWarehouse" className="form-check-input form-checked-success" role="switch" placeholder="" name="isWarehouse" type="checkbox"
                                                                    value=""
                                                                    checked={values.isWarehouse}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'isWarehouse': true }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col mt-0">
                                                            <label className={`form-check-label cursor-pointer no-select ${(values.isWarehouse) ? "" : "text-muted"}`} htmlFor="isWarehouse"
                                                                style={{ marginTop: '2px' }}>
                                                                {t('isWarehouse')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row my-2">
                                                        <div className="col-auto mt-0">
                                                            <div className="form-check form-check-md form-switch">
                                                                <DrInput id="isDropAmazon2Amazon" className="form-check-input form-checked-success" role="switch" placeholder="" name="isDropAmazon2Amazon" type="checkbox"
                                                                    value=""
                                                                    checked={values.isDropAmazon2Amazon}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'isDropAmazon2Amazon': true }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col mt-0">
                                                            <label className={`form-check-label cursor-pointer no-select ${(values.isDropAmazon2Amazon) ? "" : "text-muted"}`} htmlFor="isDropAmazon2Amazon"
                                                                style={{ marginTop: '2px' }}>
                                                                {t('isDropAmazon2Amazon')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col col-xl-12 col-xxl d-flex flex-column">
                                                <div className="card custom-card card-body bg-info bg-opacity-10">
                                                    <div className="row my-1">
                                                        <div className="col-3 my-auto">
                                                            <label className="text-wrap">{t('mustPlan')}</label>
                                                        </div>
                                                        <div className="col ps-0">
                                                            <Field
                                                                onBlur={() => { setTouched({ ...touched.mustPlan, 'mustPlan': true }) }}
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        var result = e.map(a => a.value);


                                                                        setFieldValue("mustPlan", result);
                                                                    }
                                                                }}
                                                                isMulti={true}
                                                                name={'mustPlan'}
                                                                component={DrSelectBox}
                                                                placeholder={t('mustPlan')}
                                                                options={selectedPackageMustPlanOptions} />
                                                        </div>
                                                    </div>
                                                    <div className="row my-1 flex-grow-shrink">
                                                        <div className="col-3 my-top">
                                                            <label className="text-wrap">{t('description')}</label>
                                                        </div>
                                                        <div className="col ps-0">
                                                            <textarea id="description-textarea" className={`form-control py-0 w-100  d-flex flex-column`} placeholder={t('description')} type="text"
                                                                style={{ minHeight: '100px', height: "100%" }}
                                                                value={values.description}
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    var textarea = document.getElementById("description-textarea");
                                                                    textarea.style.height = (textarea.parentElement.clientHeight > Math.min(textarea.scrollHeight, 500) ? textarea.parentElement.clientHeight : Math.min(textarea.scrollHeight, 500)) + "px";

                                                                    setFieldValue("description", e.currentTarget.value)
                                                                }}
                                                                onBlur={() => { setTouched({ ...touched.description, 'description': true }) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="row justify-content-end my-3">
                                            {values.id &&
                                                <>
                                                    <div className="col-auto">
                                                        <Field
                                                            onBlur={() => { setTouched({ ...touched.couponList, 'couponList': true }) }}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    var result = e.map(a => a.id);


                                                                    setFieldValue("couponList", result);
                                                                }
                                                            }}
                                                            isMulti={true}
                                                            name={'couponList'}
                                                            component={DrSelectBox}
                                                            placeholder={t('couponList')}
                                                            options={couponOptions} />
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className={`btn btn-primary btn-wave waves-effect waves-light shadow`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (isValid) {

                                                                    if (values.id && values.id !== "-1" && values.couponList?.length > 0) {
                                                                        dispatch(withActionPromise(createPackageCoupon({
                                                                            couponIds: values.couponList.map(a => a.value),
                                                                            packageId: values.id
                                                                        })))
                                                                    }
                                                                }
                                                            }}
                                                        >{t('saveCouponChanges')}</button>
                                                    </div>
                                                </>
                                            }
                                            <div className="col-auto">
                                                <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        let requestData = Object.assign({}, values);
                                                        requestData.mustPlan = values.mustPlan?.length > 0 ? values.mustPlan.map(a => a.value).join(',') : "";

                                                        if (isValid) {
                                                            if (requestData.id) {
                                                                dispatch(withActionPromise(updatePackage(requestData)));
                                                            } else {
                                                                dispatch(withActionPromise(createPackage(requestData)));
                                                            }
                                                        }
                                                    }}
                                                >{t('saveChanges')}</button>
                                            </div>
                                        </div>
                                    </form>
                                )
                                }
                            </Formik>
                            <div id="packagesTable" className="row">
                                <div className="col-12">
                                    <DrDataTable
                                        data={allPackages}
                                        columns={columns}
                                        columnDefs={columnDefs}
                                        doesHaveBorder={false}
                                        headerSearch={true}
                                        search={true}
                                        minWidth="850px"
                                        pageLength={20}
                                        isRowClickEnabled={true}
                                        rowClickedCallBack={rowClickedCallBack}
                                        detailedSearchChildren={
                                            <DrPageFilter
                                                refreshClickedCallBack={refreshClickedCallBack}
                                            >
                                            </DrPageFilter>
                                        }

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreatePackagesPage