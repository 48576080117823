import types from "../actions/keepa.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PRODUCT_KEEPA_DETAILS_SUCCESS:
            return { ...state, selectedProductKeepaDetails: action.payload };
        case types.CLEAR_KEEPA_DATA:
            return initialState;;
        default:
            return state;
    }
}

const initialState = {
    "selectedProductKeepaDetails": null
}

export default reducer;