import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'
const ExcelJS = require('exceljs');
export const ExportToExcel = ({ excelData, fileName }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xls';

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xls', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);

}

export const ExcelExport2 = ({ excelData, columns, fileName }) => {
    const ExcelJS = require('exceljs');
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("MySheet");
    // worksheet.properties.defaultRowHeight = 80;
    worksheet.pageSetup.orientation = 'landscape';

    worksheet.columns = columns;
    worksheet.addRows(excelData);

    worksheet.getRow(1).border = {
        top: { style: "thin", color: { argb: "ff030303" } },
        left: { style: "thin", color: { argb: "ff030303" } },
        bottom: { style: "thin", color: { argb: "ff030303" } },
        right: { style: "thin", color: { argb: "ff030303" } },
    }
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: "ff1e76c1" }
    }

    worksheet.getRow(1).font = { bold: true, size: 16, color: { argb: 'ffffffff' } };

    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        if (rowNumber !== 1) {
            row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                cell.alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
            });
        }
    });

    workbook.xlsx.writeBuffer().then(excelData => {
        const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        // const url = window.URL.createObjectURL(blob);
        // const anchor = document.createElement('a');
        // anchor.href = url;
        // anchor.download = fileName + ".xlsx";
        // anchor.click();
        // window.URL.revokeObjectURL(url);

        FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });

}